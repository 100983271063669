export const WEIGHT_ID = "0";
export const ENERGY_ID = "112";
export const ENERGY_CAL_ID = "1067";

export interface DartNutrient {
  id: number;
  name: string;
  derivation: number;
  value: number;
  unit: string;
  category: number;
}

export interface Nutrient {
  altNameWhenParentIsVisible: string;
  category: string;
  decimalPlaces: number;
  description: string;
  displayOrder: number;
  isCalculated: boolean;
  isRatio: boolean;
  name: string;
  parentNutId: string;
  shortName: string;
  units: string;
}
export const nutrientConverter = {
  toFirestore: function (nutrient: Nutrient): firebase.firestore.DocumentData {
    return {
      altNameWhenParentIsVisible: nutrient.altNameWhenParentIsVisible,
      category: nutrient.category,
      decimalPlaces: nutrient.decimalPlaces,
      description: nutrient.description,
      displayOrder: nutrient.displayOrder,
      isCalculated: nutrient.isCalculated,
      isRatio: nutrient.isRatio,
      name: nutrient.name,
      parentNutId: nutrient.parentNutId,
      shortName: nutrient.shortName,
      units: nutrient.units,
    };
  },

  fromFirestore: function (
    snapshot: firebase.firestore.QueryDocumentSnapshot
  ): Nutrient {
    const data: firebase.firestore.DocumentData = snapshot.data();

    return {
      altNameWhenParentIsVisible: data.altNameWhenParentIsVisible,
      category: data.category,
      decimalPlaces: data.decimalPlaces,
      description: data.description,
      displayOrder: data.displayOrder,
      isCalculated: data.isCalculated,
      isRatio: data.isRatio,
      name: data.name,
      parentNutId: data.parentNutId,
      shortName: data.shortName,
      units: data.units,
    };
  },
};
