import { Action } from "redux";
import {
  RetentionFactorData,
  NutrientMap,
  CategoryMap,
  ReferenceMeasureMap,
} from "../reducers/referenceData";

export const SET_RETENTION_FACTOR_DATA = "SET_RETENTION_FACTOR_DATA";
export const SET_NUTRIENT_DATA = "SET_NUTRIENT_DATA";
export const SET_CATEGORIES = "SET_CATEGORIES";
export const SET_REFERENCE_MEASURES = "SET_REFERENCE_MEASURES";

export interface IActionsSetRetentionFactorData extends Action {
  type: "SET_RETENTION_FACTOR_DATA";
  retentionFactorData: RetentionFactorData;
}

export interface IActionsSetNutrientData extends Action {
  type: "SET_NUTRIENT_DATA";
  nutrientData: NutrientMap;
}

export interface IActionsSetCategories extends Action {
  type: "SET_CATEGORIES";
  categories: CategoryMap;
}
export interface IActionsSetReferenceMeasures extends Action {
  type: "SET_REFERENCE_MEASURES";
  referenceMeasures: ReferenceMeasureMap;
}

export type ReferenceDataActions =
  | IActionsSetRetentionFactorData
  | IActionsSetNutrientData
  | IActionsSetCategories
  | IActionsSetReferenceMeasures;
