import React, { FunctionComponent, ReactNode, useMemo } from "react";

import {
  makeStyles,
  Collapse,
  Typography,
  Divider,
  List,
  IconButton,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

import { OverridesNutrientRow, NutrientRowProps } from "./OverridesNutrientRow";
import { appTheme } from "../../../../../styling/style";

const EXPANDED_ON_LOAD = true;

const useStyles = makeStyles({
  subTable: {
    border: `1px solid ${appTheme.colors.gainsbro}`,
    borderRadius: 4,
    margin: 5,
    marginBottom: 0,
  },
  categoryList: {
    padding: 0,
  },
  categoryText: {
    display: "flex",
    alignItems: "center",
    marginLeft: 5,
    flex: 1,
  },
  text: {
    marginLeft: 5,
  },
});

export interface CategoryRowProps {
  categoryId: string;
  categoryName: string;
  nutrients: NutrientRowProps[];
}

export const CategoryRow: FunctionComponent<CategoryRowProps> = ({
  categoryName,
  nutrients,
}) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(EXPANDED_ON_LOAD);

  const nutrientRows = useMemo(
    (): React.ReactNode =>
      nutrients.map(
        (nutrient): ReactNode => (
          <OverridesNutrientRow
            key={`nutrient-${nutrient.id}`}
            id={nutrient.id}
            name={nutrient.name}
            unit={nutrient.unit}
            description={nutrient.description}
          />
        )
      ),
    [nutrients]
  );

  return (
    <>
      <div className={classes.categoryText}>
        <IconButton
          data-cy="overridesCategory"
          color="secondary"
          onClick={() => setOpen(!open)}
          size="small"
        >
          {open ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
        <Typography noWrap className={classes.text} variant="h4">
          {categoryName}
        </Typography>
      </div>
      <Divider variant="middle" />
      <Collapse in={open} timeout={50} unmountOnExit={true}>
        <div className={classes.subTable}>
          <List className={classes.categoryList}>{nutrientRows}</List>
        </div>
      </Collapse>
    </>
  );
};
