import {
  RetentionFactorData,
  NutrientMap,
  CategoryMap,
  ReferenceMeasureMap,
} from "../reducers/referenceData";
import {
  IActionsSetRetentionFactorData,
  IActionsSetNutrientData,
  IActionsSetCategories,
  IActionsSetReferenceMeasures,
} from "../actions/referenceData";

export const setRetentionFactorData = (
  retentionFactorData: RetentionFactorData
): IActionsSetRetentionFactorData => {
  return {
    type: "SET_RETENTION_FACTOR_DATA",
    retentionFactorData: retentionFactorData,
  };
};

export const setNutrientData = (
  nutrientData: NutrientMap
): IActionsSetNutrientData => {
  return {
    type: "SET_NUTRIENT_DATA",
    nutrientData: nutrientData,
  };
};

export const setCategories = (
  categories: CategoryMap
): IActionsSetCategories => {
  return {
    type: "SET_CATEGORIES",
    categories: categories,
  };
};

export const setReferenceMeasures = (
  referenceMeasures: ReferenceMeasureMap
): IActionsSetReferenceMeasures => {
  return {
    type: "SET_REFERENCE_MEASURES",
    referenceMeasures: referenceMeasures,
  };
};
