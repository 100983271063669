import { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { isFood, isRecipe } from "../../../../../constants/FoodTemplate";
import { CurrentDocumentIdSelector } from "../../../../../store/data/current-document/selectors/currentDocument";
import {
  databaseIdSelector,
  userIngredientSummariesSelector,
} from "../../../../../store/data/selectors/database";
import { RootState } from "../../../../../store/reducers";
import { IngredientSummaryItem } from "./editing_grid/cells/IngredientCell";

export const useSummaries = (
  publicSummaries: IngredientSummaryItem[]
): IngredientSummaryItem[] => {
  const [summaries, setSummaries] = useState<IngredientSummaryItem[]>([]);

  const documentId: string = useSelector<RootState, string>(
    CurrentDocumentIdSelector
  );

  const databaseId: string = useSelector<RootState, string>(databaseIdSelector);

  const userIngredientSummaries: IngredientSummaryItem[] = useSelector<
    RootState,
    IngredientSummaryItem[]
  >(userIngredientSummariesSelector);

  const filterSummaries = (
    summaries: IngredientSummaryItem[],
    databaseId: string,
    documentId: string
  ) =>
    summaries.filter(
      (item: IngredientSummaryItem): boolean =>
        (isRecipe(item.templateId.toString()) ||
          isFood(item.templateId.toString())) &&
        item.foodId.identifier !== `${databaseId}:${documentId}`
    );

  useEffect(() => {
    setSummaries(
      filterSummaries(userIngredientSummaries, databaseId, documentId).concat(
        publicSummaries
      )
    );
  }, [publicSummaries, userIngredientSummaries, databaseId, documentId]);

  return summaries;
};
