import React, { FunctionComponent, ReactNode } from "react";

import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import { batch, useDispatch } from "react-redux";

import { FOOD_TEMPLATES, FoodTemplate } from "../../constants/FoodTemplate";
import { createDocument } from "../../store/data/current-document/thunks/currentDocument";
import { FoodworksMenu } from "./FoodworksMenu";
import { appTheme } from "../../styling/style";

export interface CreateMenuProps {
  handleClose: () => void;
  anchorElement: HTMLElement | null;
}

const useStyles = makeStyles(() => ({
  icon: {
    fill: appTheme.colors.primary,
    width: 24,
    height: 24,
  },
  skinnyAvatar: {
    minWidth: 40,
    display: "flex",
    alignItems: "center",
  },
}));

export const DocumentMenu: FunctionComponent<CreateMenuProps> = ({
  handleClose,
  anchorElement,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onResetDocument = (template: FoodTemplate) =>
    dispatch(createDocument(template));

  const handleClickForTemplate = (template: FoodTemplate) => {
    handleClose();

    batch(() => {
      onResetDocument(template);
    });
  };

  const menuItemForTemplate = (template: FoodTemplate) => (
    <ListItem
      data-cy="createMenuItem"
      button
      onClick={() => handleClickForTemplate(template)}
      key={template.id}
    >
      <ListItemAvatar className={classes.skinnyAvatar}>
        <template.createIcon className={classes.icon} />
      </ListItemAvatar>
      <ListItemText
        primary={`Create ${template.title.slice(0, -1).toLowerCase()}`}
        primaryTypographyProps={{ variant: "body1" }}
      />
    </ListItem>
  );

  return (
    <FoodworksMenu
      id="simple-menu"
      anchorEl={anchorElement}
      open={Boolean(anchorElement)}
      onClose={handleClose}
    >
      <List disablePadding>
        {FOOD_TEMPLATES.map(
          (template: FoodTemplate): ReactNode => menuItemForTemplate(template)
        )}
      </List>
    </FoodworksMenu>
  );
};
