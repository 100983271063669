import { Action } from "redux";

import { UserDatabaseSummary } from "../../../data/models/userDatabase";

export const SET_USER_DATABASE_SUMMARIES = "SET_USER_DATABASE_SUMMARIES";
export const ADD_USER_DATABASE_SUMMARY = "ADD_USER_DATABASE_SUMMARY";
export const REMOVE_USER_DATABASE_SUMMARY = "REMOVE_USER_DATABASE_SUMMARY";

export interface IActionsSetUserDatabaseSummaries extends Action {
  type: "SET_USER_DATABASE_SUMMARIES";
  databaseSummaries: UserDatabaseSummary[];
}

export interface IActionsAddUserDatabaseSummary extends Action {
  type: "ADD_USER_DATABASE_SUMMARY";
  databaseSummary: UserDatabaseSummary;
}

export interface IActionsRemoveUserDatabaseSummary extends Action {
  type: "REMOVE_USER_DATABASE_SUMMARY";
  databaseId: string;
}

export type UserActions =
  | IActionsSetUserDatabaseSummaries
  | IActionsAddUserDatabaseSummary
  | IActionsRemoveUserDatabaseSummary;
