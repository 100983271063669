import React, { FunctionComponent } from "react";

import { makeStyles, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";

import { appTheme } from "../../../styling/style";
import { InfoTooltip } from "../../common/InfoTooltip";
import { BaseCompositionValueSelector } from "../../../store/data/current-document/selectors/currentDocument";
import { RootState } from "../../../store/reducers";

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: "flex",
    flex: 1,
    alignContent: "space-between",
    overflow: "hidden",
  },
  nameText: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    textAlign: "start",
    marginTop: 0,
    marginLeft: 1,
    marginBottom: 0,
    minWidth: "4ch",
  },
  quantityText: {
    marginBlockStart: 0,
    marginBlockEnd: 0,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  unknownValue: {
    color: appTheme.colors.warning,
  },
  zeroValue: {
    color: appTheme.colors.white[20],
  },
  dottedLine: {
    height: 1,
    width: "100%",
    border: 0,
    borderBottomWidth: 1,
    borderStyle: "dashed",
    flex: 1,
    borderColor: appTheme.colors.gainsbro,
    alignSelf: "flex-end",
    margin: "0px 3px 4px 3px",
  },
}));

const UNKNOWN = "?";
const ZERO = "0.000";

export interface NutrientItemProps {
  nutrientId: string;
  name: string;
  value: string;
  measure: string;
  maxValueLength: number;
}

export const NutrientItem: FunctionComponent<NutrientItemProps> = ({
  nutrientId,
  name,
  value,
  measure,
  maxValueLength,
}) => {
  const classes = useStyles();

  const baseCompositionValue: number | undefined = useSelector<
    RootState,
    number | undefined
  >((state: RootState) => BaseCompositionValueSelector(state, nutrientId));

  const formattedValue = value === UNKNOWN ? UNKNOWN : `${value} ${measure}`;

  let nutrientValueStyling: string = classes.quantityText;

  if (formattedValue === UNKNOWN)
    nutrientValueStyling = nutrientValueStyling.concat(
      ` ${classes.unknownValue}`
    );
  else if (value === ZERO)
    nutrientValueStyling = nutrientValueStyling.concat(` ${classes.zeroValue}`);

  return (
    <div className={classes.root}>
      {!!baseCompositionValue && baseCompositionValue.toFixed(3) !== value && (
        <InfoTooltip
          title={`The ${name} value has been re-derived or overriden.`}
        />
      )}
      <Typography
        data-cy="nutrientName"
        variant="body1"
        className={classes.nameText}
      >
        {name}
      </Typography>

      <div
        className={classes.dottedLine}
        style={{
          minWidth: `${maxValueLength - value.length}ch`,
        }}
      />

      <Typography
        variant="body1"
        data-cy="nutrientValue"
        className={nutrientValueStyling}
      >
        {formattedValue}
      </Typography>
    </div>
  );
};
