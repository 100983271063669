import React, {
  FunctionComponent,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";

import { SectionTag } from "../../../data/models/documentProperties/section";
import {
  addSectionTags,
  updateSectionTag,
} from "../../../store/data/current-document/action-creators/sectionTags";
import { sectionTagsSelector } from "../../../store/data/current-document/selectors/sectionTags";
import { RootState } from "../../../store/reducers";
import { appTheme } from "../../../styling/style";
import { DisabledButtonToolTip } from "../../common/InfoTooltip";
import { SectionTagCreation } from "./components/SectionTagCreation";

const useStyles = makeStyles((theme) => ({
  titleBar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  closeIcon: {
    width: 40,
    height: 40,
  },
}));

interface SimpleCreateSectionTagDialogProps {
  onClose: () => void;
  isEditing: boolean;
  tagId?: string;
}

export const SimpleCreateSectionTagDialog: FunctionComponent<SimpleCreateSectionTagDialogProps> = ({
  onClose,
  isEditing,
  tagId,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onCreateNewTag = (tag: SectionTag) => dispatch(addSectionTags([tag]));

  const onUpdateTag = (tag: SectionTag) => dispatch(updateSectionTag(tag));

  const documentSectionTags: SectionTag[] = useSelector<
    RootState,
    SectionTag[]
  >(sectionTagsSelector);

  const [tagLabel, setTagLabel] = useState("");
  const [colorIndex, setColorIndex] = useState(0);

  useEffect(() => {
    if (tagId) {
      const sectionTag: SectionTag = documentSectionTags.find(
        (tag: SectionTag): boolean => tag.id === tagId
      )!;
      setTagLabel(sectionTag.label);
      setColorIndex(appTheme.colors.activeTags.indexOf(sectionTag.activeColor));
    }
  }, [tagId, documentSectionTags]);

  const labelInputRef = useRef<HTMLInputElement>(null);

  const allSectionTagLabels: string[] = documentSectionTags.map(
    (tag: SectionTag): string => tag.label
  );

  const onClickSaveTag = () => {
    if (isEditing) {
      onUpdateTag({
        id: tagId!,
        label: tagLabel,
        activeColor: appTheme.colors.activeTags[colorIndex],
        inactiveColor: appTheme.colors.inactiveTags[colorIndex],
      });
      onClose();
    } else {
      onCreateNewTag({
        id: uuidv4(),
        label: tagLabel,
        activeColor: appTheme.colors.activeTags[colorIndex],
        inactiveColor: appTheme.colors.inactiveTags[colorIndex],
      });
      labelInputRef.current?.focus();
      labelInputRef.current?.select();
    }
  };

  const titleBar: ReactNode = (
    <div className={classes.titleBar}>
      <DialogTitle id="create-section-tag-dialog-title">
        {isEditing ? "Edit section tag" : "Create a new section tag"}
      </DialogTitle>
      <IconButton size="small" className={classes.closeIcon} onClick={onClose}>
        <Close />
      </IconButton>
    </div>
  );

  const dialogActions: ReactNode = [
    <Button onClick={onClose} color="default">
      Close
    </Button>,
    <DisabledButtonToolTip
      disabled={
        !tagLabel ||
        (!isEditing && allSectionTagLabels.includes(tagLabel.trim())) ||
        (isEditing &&
          !!documentSectionTags.find(
            (tag: SectionTag): boolean =>
              tag.id !== tagId && tagLabel.trim() === tag.label
          ))
      }
      label="Save tag"
      tooltip="Tag label must be unique"
      buttonProps={{ onClick: onClickSaveTag, color: "secondary" }}
    />,
  ];

  return (
    <Dialog maxWidth="md" open={true} onBackdropClick={onClose}>
      {titleBar}
      <DialogContent>
        <div>
          <SectionTagCreation
            tagLabel={tagLabel}
            colorIndex={colorIndex}
            setTagLabel={setTagLabel}
            setColorIndex={setColorIndex}
            ref={labelInputRef}
          />
        </div>
      </DialogContent>
      <DialogActions>{dialogActions}</DialogActions>
    </Dialog>
  );
};
