import React, { FunctionComponent, ReactNode } from "react";

import { makeStyles, IconButton } from "@material-ui/core";
import { Block } from "@material-ui/icons";
import { batch, useDispatch, useSelector } from "react-redux";

import { RootState } from "../../../../../store/reducers";
import { selectedRowsSelector } from "../../../../../store/ui/selectors/recipeGrid";
import { IngredientRowDelete } from "./IngredientRowDelete";
import { IngredientRowCopy } from "./editing_grid/IngredientRowCopy";
import { FoodItemPosition } from "../../../../../data/models/foodItemPosition";
import { NutrientValueSelection } from "./NutrientValueSelection";
import { setSelectedRows } from "../../../../../store/ui/actionCreators/recipeGrid";
import { FoodworksTooltip } from "../../../../common/InfoTooltip";
import { setSelectedSectionTags } from "../../../../../store/ui/actionCreators/nutritionPaneActionCreators";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },

  toolbarIcons: {
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
    alignItems: "center",
    margin: 5,
  },
  filterOption: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  selection: {
    width: 300,
  },
  leftButtonsContainer: {
    display: "flex",
  },
}));

export interface RecipeGridToolbarProps {
  dayIndex: number;
}

export const RecipeGridToolbar: FunctionComponent<RecipeGridToolbarProps> = ({
  dayIndex,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onResetSelection = () =>
    batch(() => {
      dispatch(setSelectedRows([]));
      dispatch(setSelectedSectionTags([]));
    });

  const selectedRows: FoodItemPosition[] = useSelector<
    RootState,
    FoodItemPosition[]
  >(selectedRowsSelector);

  const clearSelectionButton: ReactNode = (
    <FoodworksTooltip title="Clear selection">
      <IconButton
        size="small"
        onClick={onResetSelection}
        disabled={!selectedRows.length}
      >
        <Block />
      </IconButton>
    </FoodworksTooltip>
  );

  const toolbarButtons = (
    <div className={classes.toolbarIcons}>
      {/* <Tooltip title="Undo">
        <IconButton
          className={classes.iconPadding}
          aria-label="undo"
          onClick={() => {}}
        >
          <UndoIcon className={classes.iconPadding} />
        </IconButton>
      </Tooltip>
      <Divider orientation="vertical" flexItem></Divider>
      <Tooltip title="Redo">
        <IconButton className={classes.iconPadding} onClick={() => {}}>
          <RedoIcon className={classes.iconPadding} />
        </IconButton>
      </Tooltip> */}
      <div className={classes.leftButtonsContainer}>
        <IngredientRowDelete dayIndex={dayIndex} rowsToRemove={selectedRows} />
        <IngredientRowCopy dayIndex={dayIndex} selectedRows={selectedRows} />
        {clearSelectionButton}
      </div>
    </div>
  );

  return (
    <div className={classes.root}>
      {toolbarButtons}
      <NutrientValueSelection />
    </div>
  );
};
