import React, { FunctionComponent, ReactNode, useMemo, useState } from "react";

import { makeStyles, createStyles, Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { useSelector, useDispatch, batch } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import { appTheme } from "../../../../../../styling/style";
import { RootState } from "../../../../../../store/reducers";
import { IngredientSummaryItem } from "./cells/IngredientCell";
import { RecipeSection } from "./section/Section";
import { addSection } from "../../../../../../store/data/current-document/action-creators/sections";
import {
  Section,
  Sections,
  SectionTag,
} from "../../../../../../data/models/documentProperties/section";
import { RetentionFactor } from "../../../../../../data/models/documentProperties/retentionFactor";
import { getRetentionFactorMap } from "../../../../../../store/data/selectors/referenceData";
import { templateIdSelector } from "../../../../../../store/data/current-document/selectors/document";
import { RECIPES } from "../../../../../../constants/FoodTemplate";
import {
  daySelector,
  sectionCountSelector,
} from "../../../../../../store/data/current-document/selectors/days";
import {
  ClipboardState,
  ClipboardDataType,
} from "../../../../../../store/reducers/clipboardReducers";
import { CurrentClipboardSelector } from "../../../../../../store/selectors/clipboardSelectors";
import { addSectionsFromIndex } from "../../../../../../store/data/current-document/thunks/sections";
import { setSelectedSectionTags } from "../../../../../../store/ui/actionCreators/nutritionPaneActionCreators";
import { setSelectedRows } from "../../../../../../store/ui/actionCreators/recipeGrid";
import { sectionTagsSelector } from "../../../../../../store/data/current-document/selectors/sectionTags";
import { Day } from "../../../../../../data/models/documentProperties/day";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: appTheme.colors.white[2],
      border: `1px solid ${appTheme.colors.gainsbro}`,
      borderRadius: 4,
      boxShadow: "none",
      flex: 4,
      overflowY: "auto",
      padding: 4,
      paddingBottom: 600,
    },
    addSectionButton: {
      color: appTheme.colors.xiketic,
    },
    pasteSectionButton: {
      color: appTheme.colors.xiketic,
      marginLeft: 25,
    },
    addIcon: {
      color: appTheme.colors.success,
    },
    buttonContainer: {
      marginLeft: 10,
      marginTop: 10,
      display: "flex",
    },
  })
);

interface CellRefPairing {
  currentCellRef: string;
  nextCellRef: string;
}

export interface CellMapping {
  mappings: CellRefPairing[];
}

const useSectionCount = (dayIndex: number): number => {
  const selectSectionCount = useMemo(sectionCountSelector, []);

  const sectionCount: number = useSelector<RootState, number>(
    (state: RootState) => selectSectionCount(state, dayIndex)
  );

  return sectionCount;
};

export interface RecipeGridProps {
  summaries: IngredientSummaryItem[];
  dayIndex: number;
  searchTermMap: Map<string, string[]>;
}

export const RecipeGrid: FunctionComponent<RecipeGridProps> = ({
  summaries,
  dayIndex,
  searchTermMap,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onAddSection = (sectionIndex: number) => {
    const newSectionId: string = uuidv4();
    dispatch(
      addSection(
        dayIndex,
        Section.fromObject(
          {
            id: newSectionId,
            index: sectionIndex,
            foodItems: [],
            title: "New section",
            isCollapsed: false,
            tags: [],
          },
          retentionFactorMap
        )
      )
    );
  };

  const onResetSelection = () =>
    batch(() => {
      dispatch(setSelectedRows([]));
      dispatch(setSelectedSectionTags([]));
    });

  const onPasteFoodItems = () => {
    const sections = clipboard.clipboardData?.data as Sections;

    batch(() => {
      dispatch(
        addSectionsFromIndex(
          sections.items.map(
            (section: Section): Section => {
              const currentDocumentTagsIds: string[] = documentSectionTags.map(
                (tag: SectionTag): string => tag.id
              );
              const tagsToCopy: string[] = section.tags.reduce<string[]>(
                (tags, tagId) => {
                  if (currentDocumentTagsIds.includes(tagId)) {
                    tags.push(tagId);
                  }
                  return tags;
                },
                []
              );
              return new Section(
                uuidv4(),
                section.index,
                section.foodItems,
                section.title,
                false,
                tagsToCopy
              );
            }
          ),
          dayIndex,
          day.sections.length
        )
      );
      onResetSelection();
    });
  };

  const retentionFactorMap: Map<string, RetentionFactor> = useSelector<
    RootState,
    Map<string, RetentionFactor>
  >(getRetentionFactorMap);

  const documentTemplate: string = useSelector<RootState, string>(
    templateIdSelector
  );

  const clipboard: ClipboardState = useSelector<RootState, ClipboardState>(
    CurrentClipboardSelector
  );

  const day: Day = useSelector<RootState, Day>(
    (state: RootState) => daySelector(state, dayIndex)!
  );

  const documentSectionTags: SectionTag[] = useSelector<
    RootState,
    SectionTag[]
  >(sectionTagsSelector);

  const [focusedCell, setFocusedCell] = useState<string>("");

  const sectionCount: number = useSectionCount(dayIndex);

  /**
   * Create preprocessed data for searching
   */

  const isOnlySectionOfRecipe: boolean =
    sectionCount === 1 && documentTemplate === RECIPES.id.toString();

  const sections: ReactNode[] = [];

  for (let i = 0; i < sectionCount; i++) {
    sections.push(
      <RecipeSection
        key={i}
        dayIndex={dayIndex}
        sectionIndex={i}
        summaries={summaries}
        searchTermMap={searchTermMap}
        focusedCell={focusedCell}
        setFocusedCell={setFocusedCell}
        isLastSection={i === sectionCount - 1}
      />
    );
  }

  const addSectionButton: ReactNode = (
    <Button
      data-cy="addSection"
      className={classes.addSectionButton}
      startIcon={<AddIcon className={classes.addIcon} />}
      onClick={() => onAddSection(sectionCount)}
      size="small"
    >
      {isOnlySectionOfRecipe ? "Create sections" : "Add section"}
    </Button>
  );

  const hasSectionInClipboard: boolean =
    !!clipboard.clipboardData &&
    clipboard.clipboardData?.dataType === ClipboardDataType.SECTIONS;

  const pasteSectionButton: ReactNode = (
    <Button
      data-cy="pasteSection"
      className={classes.pasteSectionButton}
      onClick={onPasteFoodItems}
      size="small"
    >
      Paste section
    </Button>
  );

  return (
    <div className={classes.root} data-cy="recipeGrid">
      {sections}
      <div className={classes.buttonContainer}>
        {addSectionButton}
        {hasSectionInClipboard && pasteSectionButton}
      </div>
    </div>
  );
};
