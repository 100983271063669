import React, { FunctionComponent, ReactNode } from "react";

import { makeStyles, Theme, createStyles, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";

import { RetentionFactorGroupsSelector } from "../../../../store/data/selectors/referenceData";
import { RootState } from "../../../../store/reducers";
import { FoodWorksTextInput } from "../../../common/FoodWorksTextInput";
import {
  RetentionFactorListBox,
  RetentionFactorListItem,
} from "./RetentionFactorListBox";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexDirection: "column",
      flex: "1",
      display: "flex",
    },
  })
);

const GROUP_HEADER = "Select Retention Factor Group:";

export interface GroupListBoxProps {
  currentSelectedGroupId: string | null;
  onSelectGroup: (selectedGroupId: string) => void;
  searchInput: string;
  setSearchInput: (input: string) => void;
}

export const GroupListBox: FunctionComponent<GroupListBoxProps> = ({
  currentSelectedGroupId,
  onSelectGroup,
  searchInput,
  setSearchInput,
}) => {
  const classes = useStyles();

  /* *** State *** */

  const retentionFactorGroups: [string, string][] = useSelector<
    RootState,
    [string, string][]
  >(RetentionFactorGroupsSelector);

  /* ----- */

  const handleSelectGroup = (group: string): void => onSelectGroup(group);

  const updateSearchInput = (updatedInput: string): void =>
    setSearchInput(updatedInput);

  const header: ReactNode = (
    <Typography color="textPrimary" variant="subtitle1">
      {GROUP_HEADER}
    </Typography>
  );

  const listItems: RetentionFactorListItem[] = retentionFactorGroups.map(
    (group: [string, string]): RetentionFactorListItem => {
      return {
        id: group[0],
        name: group[1],
      };
    }
  );

  return (
    <div className={classes.root}>
      {header}
      <FoodWorksTextInput
        value={searchInput}
        onChange={(event) => updateSearchInput(event.target.value)}
        placeholder={"Search groups..."}
      />
      <RetentionFactorListBox
        selectedOption={currentSelectedGroupId}
        selectOption={handleSelectGroup}
        listItems={listItems}
        searchInput={searchInput}
      />
    </div>
  );
};
