import {
  DELETED_DOCUMENTS_FILTER,
  QuickFilter,
} from "../../../../../../../constants/QuickFilter";
import { DocumentSummary } from "../../../../../../../data/models/userDatabase";
import { NavigationSearchFilters } from "../../../../../navigation/NavigationSearch";
import { GridCellType } from "./AutoCompleteCell";
import { IngredientSummaryItem } from "./IngredientCell";
import { AUS_FOODS_ID } from "../../../../../../../constants/datasources";

const matchesQuickFilters = (
  quickFilters: QuickFilter[],
  option: FoodSummaryType
): boolean => {
  if ("isDeleted" in option && option.isDeleted) {
    return quickFilters.includes(DELETED_DOCUMENTS_FILTER);
  }

  if (!quickFilters.length) return true;
  for (const quickFilter of quickFilters) {
    if (quickFilter.filter(option)) {
      return true;
    }
  }
  return false;
};

export type FoodSummaryType = DocumentSummary | GridCellType;

const generateSearchTerms = (searchText: string): string[] => {
  searchText = searchText.toLowerCase();

  let searchTerms: string[] = [];

  const splitOnSpaces = searchText.split(" ");

  for (const term of splitOnSpaces) {
    searchTerms = searchTerms.concat(term.split(","));
  }

  return searchTerms;
};

export const filterIngredients = (
  filter: NavigationSearchFilters,
  options: FoodSummaryType[],
  searchTermMap: Map<string, string[]>
): FoodSummaryType[] => {
  const searchTerms = generateSearchTerms(filter.searchText);

  return options.filter((option: FoodSummaryType) => {
    if (!matchesQuickFilters(filter.quickFilters, option)) return false;
    if (!filter.searchText) return true;
    if ("searchableProperties" in option) {
      if (
        option.searchableProperties.primary === filter.searchText ||
        option.searchableProperties.alternate === filter.searchText
      ) {
        return true;
      }
    }
    let words = [...(searchTermMap.get(option.label) || [])];
    for (const term of searchTerms) {
      let matched = false;
      for (const word of [...words]) {
        if (word.startsWith(term)) {
          let index = words.findIndex((aWord) => word === aWord);
          words.splice(index, 1);
          matched = true;
          break;
        }
      }
      if (!matched) return false;
    }
    return true;
  });
};

export const createSearchMap = (
  summaries: IngredientSummaryItem[] | DocumentSummary[]
) => {
  const optionToWords = new Map<string, string[]>();
  for (const summary of summaries) {
    const optionWords = summary.label.toLowerCase().split(",");
    let actualWords: string[] = [];

    for (const word of optionWords) {
      actualWords = actualWords.concat(...word.split(" "));
    }
    optionToWords.set(summary.label, actualWords);
  }

  return optionToWords;
};

export const documentsSort = (
  searchText: string | undefined,
  searchTermMap: Map<string, string[]>
) => (a: FoodSummaryType, b: FoodSummaryType) => {
  // For now this is just doing any user documents, then aus foods, then all other public
  if ("foodId" in a && "foodId" in b) {
    const aIsAusFood = a.foodId.datasourceId === AUS_FOODS_ID;
    const bIsAusFood = b.foodId.datasourceId === AUS_FOODS_ID;

    if (!a.isPublic || !b.isPublic) {
      if (!a.isPublic && !b.isPublic) {
        return a.label > b.label ? 1 : -1;
      }
      return !a.isPublic ? -1 : 1;
    }

    if (aIsAusFood || bIsAusFood) {
      if (aIsAusFood && bIsAusFood) {
        let aMatches = false;
        let bMatches = false;

        for (const term of generateSearchTerms(searchText || "")) {
          if (!aMatches) {
            const aContainsSearchedKeyword = searchTermMap
              .get(a.label)!
              [KEYWORD_INDEX].startsWith(term);
            if (aContainsSearchedKeyword) aMatches = true;
          }

          if (!bMatches) {
            const bContainsSearchedKeyword = searchTermMap
              .get(b.label)!
              [KEYWORD_INDEX].startsWith(term);
            if (bContainsSearchedKeyword) bMatches = true;
          }
        }

        if (aMatches || bMatches) {
          if (aMatches && bMatches) return a.label > b.label ? 1 : -1;
          return aMatches ? -1 : 1;
        }

        return a.label > b.label ? 1 : -1;
      }
      return aIsAusFood ? -1 : 1;
    }

    return a.label > b.label ? 1 : -1;
  }

  return a.label > b.label ? 1 : -1;
};

const KEYWORD_INDEX = 0;

export const filterOptions = (
  options: GridCellType[],
  searchText: string | undefined,
  searchTermMap: Map<string, string[]>
): GridCellType[] => {
  const filtered = filterIngredients(
    { searchText: searchText || "", quickFilters: [] },
    options,
    searchTermMap
  );

  return filtered.sort(
    documentsSort(searchText || "", searchTermMap)
  ) as IngredientSummaryItem[];
};
