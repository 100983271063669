import React, { FunctionComponent, useState, ReactNode } from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  makeStyles,
} from "@material-ui/core";
import * as Bowser from "bowser";
import ImageUploader from "react-images-upload";
import { useAuthState } from "react-firebase-hooks/auth";
import { useSelector } from "react-redux";

import { FoodWorksTextInput } from "../common/FoodWorksTextInput";
import Firebase, { withFirebase } from "../../data/Firebase";
import { databaseIdSelector } from "../../store/data/selectors/database";
import { RootState } from "../../store/reducers";

export interface FeedbackDialogProps {
  onClose: () => void;
  onSend: () => void;
  firebase?: Firebase;
}

const useStyles = makeStyles(() => ({
  textBox: {
    paddingTop: 10,
  },
  description: {
    paddingTop: 20,
  },
}));

interface UploadResponse {
  upload: {
    token: string;
  };
}

const ZENDESK_DATABASE_ID_FIELD = 360002625896;
const ZENDESK_OS_FIELD = 360002538615;
const ZENDESK_BROWSER_FIELD = 360002624776;
const ZENDESK_FW_USER_ID_FIELD = 360002538655;
const ZENDESK_USER_EMAIL_FIELD = 360002538635;

export const FeedbackDialogInner: FunctionComponent<FeedbackDialogProps> = ({
  onClose,
  onSend,
  firebase,
}) => {
  const classes = useStyles();

  const [user] = useAuthState(firebase!.auth);

  const [subject, setSubject] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [uploadedImages, setUploadedImages] = useState<File[] | null>(null);

  const currentDatabaseId: string = useSelector<RootState, string>(
    databaseIdSelector
  );

  const url: string = "https://xyris.zendesk.com/api/v2/requests.json";

  const createZendeskRequestBody = async (uploadTokens: string[]) => {
    const userSystemInfo = Bowser.parse(window.navigator.userAgent);

    //TODO : temporary until ryan merges his profile work, will get user name data from redux
    const userObject = await firebase?.doGetUser(user!.uid);

    return {
      request: {
        subject: subject,
        comment: { body: description, uploads: uploadTokens },
        requester: {
          name: `${userObject!.data()!.firstName} ${
            userObject!.data()!.lastName
          }`,
        },
        custom_fields: [
          { id: ZENDESK_FW_USER_ID_FIELD, value: user!.uid },
          { id: ZENDESK_USER_EMAIL_FIELD, value: user!.email },
          {
            id: ZENDESK_BROWSER_FIELD,
            value: `${userSystemInfo.browser.name} ${userSystemInfo.browser.version}`,
          },
          {
            id: ZENDESK_OS_FIELD,
            value: `${userSystemInfo.os.name} ${userSystemInfo.os.version} ${userSystemInfo.os.versionName}`,
          },
          { id: ZENDESK_DATABASE_ID_FIELD, value: currentDatabaseId },
        ],
      },
    };
  };

  const sendFeedBack = async () => {
    const uploadTokens: string[] = [];

    if (uploadedImages) {
      for (const file of uploadedImages) {
        const attachmentUrl: string = `https://xyris.zendesk.com/api/v2/uploads.json?filename=${file.name}`;

        const uploadResponse = await fetch(attachmentUrl, {
          method: "POST",
          headers: { "Content-Type": file.type },
          body: file,
        });

        const uploadToken: UploadResponse = await uploadResponse.json();
        uploadTokens.push(uploadToken.upload.token);
      }
    }

    const body = await createZendeskRequestBody(uploadTokens);

    const response = await fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      console.log(
        `Request to Zendesk api failed with status code:${response.status} ${response.statusText}`
      );
    }
  };

  const subjectText: ReactNode = (
    <>
      <Typography variant="h2">Subject:</Typography>
      <FoodWorksTextInput
        className={classes.textBox}
        multiline={false}
        rows={1}
        placeholder={"What is the subject of your request?"}
        onChange={(event) => setSubject(event.target.value)}
      />
    </>
  );

  const textArea: ReactNode = (
    <>
      <Typography className={classes.description} variant="h2">
        Description:
      </Typography>
      <FoodWorksTextInput
        className={classes.textBox}
        multiline={true}
        rows={7}
        placeholder={"Enter feedback here."}
        onChange={(event) => setDescription(event.target.value)}
      />
    </>
  );

  const imageUploader: ReactNode = (
    <ImageUploader
      buttonText="Choose an image"
      withPreview
      withIcon={false}
      label="Optional Image Upload"
      onChange={(files) => setUploadedImages(files ? files : null)}
      imgExtension={[".jpg", ".png"]}
      maxFileSize={5242880}
    />
  );

  const onClickSend = () => {
    sendFeedBack();
    onSend();
  };

  return (
    <Dialog maxWidth="lg" open={true} onClose={onClose} fullWidth>
      <DialogTitle id="alert-dialog-title">
        Send feedback to the Foodworks team
      </DialogTitle>
      <DialogContent>
        {subjectText}
        {textArea}
        {imageUploader}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="default">
          Cancel
        </Button>
        <Button onClick={onClickSend} color="secondary">
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const FeedbackDialog = withFirebase(FeedbackDialogInner);
