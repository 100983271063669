import { Action } from "redux";
import { ThunkAction } from "redux-thunk";

import Firebase from "../../../data/Firebase";
import {
  IActionsSetRetentionFactorData,
  IActionsSetCategories,
  IActionsSetReferenceMeasures,
  IActionsSetNutrientData,
} from "../actions/referenceData";
import {
  NutrientMap,
  RetentionFactorProfileMap,
  CategoryMap,
  ReferenceMeasureMap,
  RetentionFactorGroupMap,
} from "../reducers/referenceData";
import {
  setRetentionFactorData,
  setNutrientData,
  setCategories,
  setReferenceMeasures,
} from "../action-creators/referenceData";
import { RootState } from "../../reducers";

export const fetchReferenceData = (): ThunkAction<
  Promise<void>,
  RootState,
  Firebase,
  Action<any>
> => async (dispatch) => {
  await dispatch(fetchNutrientData());
  await dispatch(fetchCategoryData());
  await dispatch(fetchReferenceMeasureData());
  await dispatch(fetchRetentionFactorData());
};

export const fetchNutrientData = (): ThunkAction<
  Promise<void>,
  RootState,
  Firebase,
  IActionsSetNutrientData
> => async (dispatch, getState, firebase) => {
  const nutrientData: NutrientMap = await firebase!.doFetchNutrients();

  dispatch(setNutrientData(nutrientData));
};

export const fetchCategoryData = (): ThunkAction<
  Promise<void>,
  RootState,
  Firebase,
  IActionsSetCategories
> => async (dispatch, getState, firebase) => {
  const categoryData: CategoryMap = await firebase!.doFetchCategories();

  dispatch(setCategories(categoryData));
};

export const fetchReferenceMeasureData = (): ThunkAction<
  Promise<void>,
  RootState,
  Firebase,
  IActionsSetReferenceMeasures
> => async (dispatch, getState, firebase) => {
  const referenceMeasureData: ReferenceMeasureMap = await firebase!.doFetchReferenceMeasures();

  dispatch(setReferenceMeasures(referenceMeasureData));
};

export const fetchRetentionFactorData = (): ThunkAction<
  Promise<void>,
  RootState,
  Firebase,
  IActionsSetRetentionFactorData
> => async (dispatch, getState, firebase) => {
  const profileMap: RetentionFactorProfileMap = await firebase!.doFetchRetentionFactorProfiles();

  const groupMap: RetentionFactorGroupMap = await firebase!.doFetchRetentionFactorGroups();

  dispatch(
    setRetentionFactorData({
      retentionFactorProfileMap: profileMap,
      retentionFactorGroupMap: groupMap,
    })
  );
};
