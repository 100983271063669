import React from "react";

import { useDispatch, useSelector, batch } from "react-redux";
import { makeStyles, createStyles, IconButton } from "@material-ui/core";
import CopyIcon from "@material-ui/icons/FileCopy";
import DeleteIcon from "@material-ui/icons/Delete";

import { RetentionFactor } from "../../../../../../data/models/documentProperties/retentionFactor";
import { setClipboard } from "../../../../../../store/action_creators/clipboardActionCreators";
import { removeFoodItem } from "../../../../../../store/data/current-document/action-creators/foodItems";
import { getRetentionFactorMap } from "../../../../../../store/data/selectors/referenceData";
import { RootState } from "../../../../../../store/reducers";
import { ClipboardDataType } from "../../../../../../store/reducers/clipboardReducers";
import { setSelectedRows } from "../../../../../../store/ui/actionCreators/recipeGrid";
import {
  FoodItem,
  FoodItems,
} from "../../../../../../data/models/documentProperties/foodItem";
import { FoodworksTooltip } from "../../../../../common/InfoTooltip";
import { setSelectedSectionTags } from "../../../../../../store/ui/actionCreators/nutritionPaneActionCreators";

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      width: 40,
      minWidth: 40,
    },
  })
);

interface CopyIngredientRowButtonProps {
  foodItem: FoodItem;
}

export const CopyIngredientRowButton = React.memo<CopyIngredientRowButtonProps>(
  ({ foodItem }) => {
    const dispatch = useDispatch();

    const onSetClipboard = () =>
      dispatch(
        setClipboard({
          dataType: ClipboardDataType.INGREDIENT_ROWS,
          data: new FoodItems([foodItem.object], retentionFactorMap),
        })
      );

    const retentionFactorMap: Map<string, RetentionFactor> = useSelector<
      RootState,
      Map<string, RetentionFactor>
    >(getRetentionFactorMap);

    const classes = useStyles();
    return (
      <FoodworksTooltip title="Copy food item" placement="bottom">
        <div>
          <IconButton
            size="small"
            data-cy="copyButton"
            className={`notSelectable ${classes.button}`}
            onClick={onSetClipboard}
          >
            <CopyIcon className={classes.button} />
          </IconButton>
        </div>
      </FoodworksTooltip>
    );
  }
);

interface DeleteIngredientRowButtonProps {
  dayIndex: number;
  sectionIndex: number;
  rowIndex: number;
}

export const DeleteIngredientRowButton = React.memo<DeleteIngredientRowButtonProps>(
  ({ dayIndex, sectionIndex, rowIndex }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const onDeleteSection = () =>
      batch(() => {
        dispatch(removeFoodItem(dayIndex, sectionIndex, rowIndex));
        dispatch(setSelectedRows([]));
        dispatch(setSelectedSectionTags([]));
      });

    return (
      <FoodworksTooltip title="Delete food item" placement="bottom">
        <div>
          <IconButton
            size="small"
            data-cy="deleteFoodItemButton"
            className={`notSelectable ${classes.button}`}
            onClick={onDeleteSection}
          >
            <DeleteIcon className={classes.button} />
          </IconButton>
        </div>
      </FoodworksTooltip>
    );
  }
);
