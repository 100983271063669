import { UserDatabaseSummary } from "../../../data/models/userDatabase";
import {
  UserActions,
  SET_USER_DATABASE_SUMMARIES,
  ADD_USER_DATABASE_SUMMARY,
  REMOVE_USER_DATABASE_SUMMARY,
} from "../actions/user";

export interface UserState {
  databaseSummaries: UserDatabaseSummary[];
}

export const initialUserState: UserState = {
  databaseSummaries: [],
};

export const user = (
  state: UserState = initialUserState,
  action: UserActions
) => {
  switch (action.type) {
    case SET_USER_DATABASE_SUMMARIES:
      return { ...state, databaseSummaries: action.databaseSummaries };
    case ADD_USER_DATABASE_SUMMARY:
      return {
        ...state,
        databaseSummaries: [...state.databaseSummaries, action.databaseSummary],
      };
    case REMOVE_USER_DATABASE_SUMMARY:
      return {
        ...state,
        databaseSummaries: [
          ...state.databaseSummaries.filter(
            (summary: UserDatabaseSummary): boolean =>
              summary.id !== action.databaseId
          ),
        ],
      };
    default:
      return state;
  }
};
