import React, {
  FunctionComponent,
  ReactNode,
  useEffect,
  useState,
} from "react";

import { Button, Card, List, makeStyles, Typography } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { batch, useDispatch, useSelector } from "react-redux";

import { Days } from "../../../../data/models/documentProperties/day";
import { SectionTag } from "../../../../data/models/documentProperties/section";
import { daysSelector } from "../../../../store/data/current-document/selectors/days";
import { sectionTagsSelector } from "../../../../store/data/current-document/selectors/sectionTags";
import { RootState } from "../../../../store/reducers";
import { SelectSectionsList } from "./SelectSectionsList";
import { SimpleCreateSectionTagDialog } from "../SimpleCreateSectionTagDialog";
import {
  addSectionTag,
  removeSectionTag,
} from "../../../../store/data/current-document/action-creators/sections";
import { deleteSectionTag } from "../../../../store/data/current-document/action-creators/sectionTags";
import { ImportSectionTagsDialog } from "../ImportSectionTagsDialog";
import { TagListItem } from "./TagListItem";
import { appTheme } from "../../../../styling/style";

const useStyles = makeStyles((theme) => ({
  sectionSelectorContainer: {
    display: "flex",
    flexDirection: "column",
  },
  selectButtonsContainer: {
    display: "flex",
  },
  sectionsList: {
    height: 300,
    overflowY: "auto",
  },
  root: {
    display: "flex",
    flexDirection: "column",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  addIcon: {
    color: appTheme.colors.primary,
  },
}));

export const SectionTagManagement: FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onAddSectionTag = (
    dayIndex: number,
    sectionIndex: number,
    tagId: string
  ) => dispatch(addSectionTag(dayIndex, sectionIndex, tagId));

  const onRemoveSectionTag = (
    dayIndex: number,
    sectionIndex: number,
    tagId: string
  ) => dispatch(removeSectionTag(dayIndex, sectionIndex, tagId));

  const onDeleteSectionTag = (tagId: string) =>
    dispatch(deleteSectionTag(tagId));

  const documentSectionTags: SectionTag[] = useSelector<
    RootState,
    SectionTag[]
  >(sectionTagsSelector);

  const days: Days = useSelector<RootState, Days>(daysSelector);

  const [selectedSections, setSelectedSections] = useState<
    { day: number; section: number }[]
  >([]);
  const [selectedTag, setSelectedTag] = useState("");
  const [showImportTagsDialog, setShowImportTagsDialog] = useState(false);
  const [showCreateTagDialog, setShowCreateTagDialog] = useState(false);
  const [showEditTagDialog, setShowEditTagDialog] = useState(false);

  useEffect(() => {
    if (selectedTag) {
      const sections = days.getSectionsWithTag([selectedTag]);
      setSelectedSections(sections);
    }
  }, [selectedTag, setSelectedSections, days]);

  const onClickDelete = (tagId: string) => {
    batch(() => {
      for (const position of days.getSectionsWithTag([tagId])) {
        onRemoveSectionTag(position.day, position.section, tagId);
      }
      onDeleteSectionTag(tagId);
    });
    if (selectedTag === tagId) {
      setSelectedTag("");
      setSelectedSections([]);
    }
  };

  const onSelectTag = (tagId: string) => {
    selectedTag === tagId ? setSelectedTag("") : setSelectedTag(tagId);
  };

  const onClickEditTag = (tagId: string) => {
    if (selectedTag !== tagId) {
      setSelectedTag(tagId);
    }
    setShowEditTagDialog(true);
  };

  const createTagButton: ReactNode = (
    <Button
      data-cy="createSectionTag"
      startIcon={<Add className={classes.addIcon} />}
      onClick={() => setShowCreateTagDialog(true)}
      size="small"
    >
      Create tag
    </Button>
  );

  const currentDocumentTags = (
    <Card>
      <List className={classes.sectionsList}>
        {createTagButton}
        {documentSectionTags.map(
          (tag: SectionTag): ReactNode => (
            <TagListItem
              sectionTag={tag}
              isSelected={tag.id === selectedTag}
              onSelectTag={onSelectTag}
              onDeleteTag={onClickDelete}
              onEditTag={onClickEditTag}
            />
          )
        )}
      </List>
    </Card>
  );

  const haveSectionTagsChanged = (): boolean => {
    const sectionsWithTags = days.getSectionsWithTag(
      selectedTag ? [selectedTag] : []
    );
    if (sectionsWithTags.length !== selectedSections.length) {
      return true;
    }
    for (const position of selectedSections) {
      if (
        !sectionsWithTags.find(
          (section: { day: number; section: number }): boolean =>
            section.day === position.day && section.section === position.section
        )
      ) {
        return true;
      }
    }
    return false;
  };

  const onApplySectionTags = () => {
    batch(() => {
      for (const position of days.getSectionsWithTag([selectedTag])) {
        onRemoveSectionTag(position.day, position.section, selectedTag);
      }
    });

    if (selectedSections.length) {
      batch(() => {
        for (const section of selectedSections) {
          onAddSectionTag(section.day, section.section, selectedTag);
        }
      });
    }
  };

  return (
    <>
      <div className={classes.root}>
        <div>
          <div className={classes.headerContainer}>
            <Typography variant="h3">Available section tags:</Typography>
            <Button
              color="secondary"
              onClick={() => setShowImportTagsDialog(true)}
            >
              Import tags
            </Button>
          </div>
          {currentDocumentTags}
        </div>
        <div>
          <SelectSectionsList
            selectedSections={selectedSections}
            setSelectedSections={setSelectedSections}
            tagId={selectedTag || undefined}
            disabled={!selectedTag}
          />
          <Button
            color="secondary"
            disabled={!selectedTag || !haveSectionTagsChanged()}
            onClick={onApplySectionTags}
          >
            Apply tags
          </Button>
        </div>
      </div>
      {(showCreateTagDialog || showEditTagDialog) && (
        <SimpleCreateSectionTagDialog
          onClose={() =>
            showEditTagDialog
              ? setShowEditTagDialog(false)
              : setShowCreateTagDialog(false)
          }
          tagId={(showEditTagDialog && selectedTag) || undefined}
          isEditing={showEditTagDialog}
        />
      )}
      {showImportTagsDialog && (
        <ImportSectionTagsDialog
          onClose={() => setShowImportTagsDialog(false)}
        />
      )}
    </>
  );
};
