import { put, takeLatest, select, ForkEffect, call } from "redux-saga/effects";

import {
  UPDATE_QUANTITY,
  CLEAR_FOOD_ITEM,
  CLEAR_QUANTITY,
  UPDATE_RETENTION_FACTOR,
  REMOVE_FOOD_ITEM,
  FOOD_ITEMS_PASTED,
} from "../current-document/actions/foodItems";
import {
  UPDATE_DOCUMENT_SERVES,
  UPDATE_DOCUMENT_YIELD,
  SET_DOCUMENT_MAPPING_ID,
  UPDATE_CALCULATION_METHOD,
} from "../current-document/actions/document";
import { ADD_ERROR, RESET_ERROR } from "../../actions/errorActions";
import { documentSelector } from "../current-document/selectors/document";
import { Document } from "../../../data/models/document";
import { FoodItem } from "../../../data/models/documentProperties/foodItem";
import { ServeType } from "../../../data/models/documentProperties/serve";
import {
  customCompositionAmountSelector,
  compositionOptionSelector,
} from "../../ui/selectors/nutritionPaneSelectors";
import {
  UPDATE_SELECTED_AMOUNT,
  UPDATE_CUSTOM_AMOUNT,
} from "../../ui/actions/nutritionPaneActions";
import { NutritionRadioOption } from "../../ui/reducers/nutritionPaneReducers";
import {
  InvalidServeQuantityError,
  InvalidServeError,
  YieldWeightError,
  InvalidVolumeConversionError,
  InvalidVolumeQuantityError,
  UnknownMeasureError,
  UnknownQuantityError,
} from "../../../errors";
import {
  ADD_NUTRIENT_OVERRIDE,
  UPDATE_NUTRIENT_OVERRIDE_VALUE,
  REMOVE_NUTRIENT_OVERRIDE,
} from "../current-document/actions/nutrientOverrides";
import { allCachedDocumentsSelector } from "../selectors/documentCache";
import { All_DOCUMENTS_FETCHED } from "../current-document/actions/currentDocument";
import { ReferenceMeasure } from "../../../data/models/referenceMeasure";
import {
  getRetentionFactorMap,
  ReferenceMeasuresSelector,
} from "../selectors/referenceData";

import { SET_SELECTED_ROWS } from "../../ui/actions/recipeGrid";
import { selectedRowsSelector } from "../../ui/selectors/recipeGrid";
import { FINAL_WEIGHT_EXCEEDS_DRY_WEIGHT } from "../../../data/dart_to_js_conversion/dartErrors";
import { FoodItemPosition } from "../../../data/models/foodItemPosition";
import { DELETE_SECTIONS } from "../current-document/actions/sections";
import { CompositionCalculator } from "../../../data/models/compositionCalculator";
import { compositionCacheSelector } from "../selectors/compositionCache";
import {
  updateBaseComposition,
  updateFinalComposition,
} from "../current-document/action-creators/currentDocument";
import { VolumeConversionFactor } from "../../../data/models/documentProperties/volumeConversionFactor";
import { isFood, isRecipe } from "../../../constants/FoodTemplate";
import { Composition } from "../../../data/models/composition";

const actionsToListenTo: string[] = [
  UPDATE_DOCUMENT_SERVES,
  UPDATE_QUANTITY,
  ADD_NUTRIENT_OVERRIDE,
  UPDATE_NUTRIENT_OVERRIDE_VALUE,
  CLEAR_FOOD_ITEM,
  REMOVE_NUTRIENT_OVERRIDE,
  CLEAR_QUANTITY,
  UPDATE_SELECTED_AMOUNT,
  UPDATE_CUSTOM_AMOUNT,
  All_DOCUMENTS_FETCHED,
  UPDATE_RETENTION_FACTOR,
  REMOVE_FOOD_ITEM,
  FOOD_ITEMS_PASTED,
  SET_DOCUMENT_MAPPING_ID,
  UPDATE_CALCULATION_METHOD,
  SET_SELECTED_ROWS,
  UPDATE_DOCUMENT_YIELD,
  DELETE_SECTIONS,
];

export const getFoodItemsForComposition = (
  compositionCalculator: CompositionCalculator,
  currentDocument: Document,
  selectedRows: FoodItemPosition[]
) => compositionCalculator.getValidFoodItems(currentDocument, selectedRows);

export const getComposition = (
  document: Document,
  foodItems: FoodItem[],
  amountType: string,
  amount: number,
  compositionCalculator: CompositionCalculator,
  useBaseComposition: boolean
): Composition => {
  const composition: Composition = useBaseComposition
    ? isRecipe(document.templateId)
      ? compositionCalculator.calculateBaseRecipe(foodItems, document.yield)
      : compositionCalculator.calculateBaseMappedFood(
          document.documentMappingId
        )
    : compositionCalculator.calculateComposition(document, foodItems);

  let weightFactor: number;

  switch (amountType) {
    case NutritionRadioOption.ONE_HUNDRED_G:
      weightFactor = 100 / composition.weight;

      return compositionCalculator.applyFinalWeightToComposition(
        composition,
        weightFactor
      );

    case NutritionRadioOption.CUSTOM:
      weightFactor = amount / composition.weight;

      return compositionCalculator.applyFinalWeightToComposition(
        composition,
        weightFactor
      );

    case NutritionRadioOption.PER_SERVE:
      const serveAmount: number =
        document.serve.type === ServeType.PERCENT
          ? composition.weight / document.serve.value!
          : document.serve.value!;
      weightFactor = serveAmount / composition.weight;

      return compositionCalculator.applyFinalWeightToComposition(
        composition,
        weightFactor
      );

    case NutritionRadioOption.ONE_HUNDRED_ML:
      weightFactor =
        (100 / composition.weight) *
        new VolumeConversionFactor(document.volumeConversion).factor!;

      return compositionCalculator.applyFinalWeightToComposition(
        composition,
        weightFactor
      );
    case NutritionRadioOption.MEGAJOULE:
      weightFactor = 1000 / composition.energy;
      return compositionCalculator.applyFinalWeightToComposition(
        composition,
        weightFactor
      );
    case NutritionRadioOption.DAY:
      weightFactor = 1 / document.days.length;
      return compositionCalculator.applyFinalWeightToComposition(
        composition,
        weightFactor
      );

    default:
      return composition;
  }
};

export function* handleCompositionErrors(error: Error) {
  if (error instanceof InvalidServeError) {
    yield put({
      type: ADD_ERROR,
      error: new InvalidServeQuantityError(error.documentName),
    });
  } else if (error instanceof InvalidVolumeConversionError) {
    yield put({
      type: ADD_ERROR,
      error: new InvalidVolumeQuantityError(error.documentName),
    });
  } else if (error instanceof UnknownMeasureError) {
    yield put({
      type: ADD_ERROR,
      error: new UnknownQuantityError(error.documentName),
    });
  } else if (error.message === FINAL_WEIGHT_EXCEEDS_DRY_WEIGHT) {
    yield put({
      type: ADD_ERROR,
      error: new YieldWeightError(),
    });
  }
}

export function* updateComposition() {
  const currentDocument: Document = yield select(documentSelector);

  const documentCache = yield select(allCachedDocumentsSelector);

  const compositionCache = yield select(compositionCacheSelector);

  const retentionFactorMap = yield select(getRetentionFactorMap);

  const referenceMeasures: ReferenceMeasure[] = yield select(
    ReferenceMeasuresSelector
  );

  const selectedRows: FoodItemPosition[] = yield select(selectedRowsSelector);

  const compositionAmountType: string = yield select(compositionOptionSelector);

  const compositionCustomAmount: number = yield select(
    customCompositionAmountSelector
  ) || 100;

  const compositionCalculator = new CompositionCalculator(
    compositionCache,
    documentCache,
    referenceMeasures,
    retentionFactorMap
  );

  const filteredFoodItems: FoodItem[] = yield call(
    getFoodItemsForComposition,
    compositionCalculator,
    currentDocument,
    selectedRows
  );

  let allRequiredDocumentsFetched: boolean = true;
  for (const item of filteredFoodItems) {
    if (!(item.foodId!.identifier in documentCache)) {
      allRequiredDocumentsFetched = false;
      break;
    }
  }

  if (allRequiredDocumentsFetched) {
    let finalComposition: Composition = new Composition({});
    let baseComposition: Composition = new Composition({});
    try {
      const finalCompositionResponse: Composition = yield call(
        getComposition,
        currentDocument,
        filteredFoodItems,
        compositionAmountType,
        compositionCustomAmount,
        compositionCalculator,
        false
      );
      finalComposition.addComposition(finalCompositionResponse);

      if (
        isRecipe(currentDocument.templateId) ||
        isFood(currentDocument.templateId)
      ) {
        const baseCompositionResponse: Composition = yield call(
          getComposition,
          currentDocument,
          filteredFoodItems,
          compositionAmountType,
          compositionCustomAmount,
          compositionCalculator,
          true
        );
        baseComposition.addComposition(baseCompositionResponse);
      }
      yield put({
        type: RESET_ERROR,
      });
    } catch (error) {
      yield call(handleCompositionErrors, error);
    }

    yield put(updateFinalComposition(finalComposition.object));
    yield put(updateBaseComposition(baseComposition.object));
  }
}

export function* compositionSaga(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest(
    actionsToListenTo,

    updateComposition
  );
}
