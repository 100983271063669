import React, { FunctionComponent } from "react";

import { ThemeProvider } from "@material-ui/core";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";

import { Home } from "./components/document_view/Home";
import { muiTheme } from "./styling/style";
import SignUp from "./components/onboarding/SignUp";
import SignIn from "./components/onboarding/SignIn";
import * as ROUTES from "./constants/routes";

export interface AppProps {}

const App: FunctionComponent<AppProps> = () => {
  return (
    <ThemeProvider theme={muiTheme}>
      <Router>
        <Route path={ROUTES.SIGN_UP} component={SignUp} />
        <Route path={ROUTES.SIGN_IN} component={SignIn} />
        <Route exact path={`${ROUTES.HOME}/:id`} component={Home} />
        <Route exact path={ROUTES.HOME} component={Home} />
        <Route exact path="/">
          <Redirect to={ROUTES.HOME} />
        </Route>
      </Router>
    </ThemeProvider>
  );
};

export default App;
