import {
  ReferenceDataActions,
  SET_RETENTION_FACTOR_DATA,
  SET_NUTRIENT_DATA,
  SET_CATEGORIES,
  SET_REFERENCE_MEASURES,
} from "../actions/referenceData";
import { Nutrient } from "../../../data/models/nutrient";
import {
  NutrientRetentionValue,
  RetentionFactorProfile,
} from "../../../data/models/documentProperties/retentionFactor";
import { Category } from "../../../data/models/category";
import { ReferenceMeasureObject } from "../../../data/models/referenceMeasure";

export interface NutrientMap {
  [field: string]: Nutrient;
}

export interface CategoryMap {
  [field: string]: Category;
}

export interface ReferenceMeasureMap {
  [field: string]: ReferenceMeasureObject;
}

export interface NutrientRetentionValueMap {
  [key: string]: NutrientRetentionValue[];
}

export interface RetentionFactorGroupMap {
  [key: string]: string;
}

export interface RetentionFactorProfileMap {
  [key: string]: RetentionFactorProfile[];
}

export interface RetentionFactorData {
  retentionFactorGroupMap: RetentionFactorGroupMap;
  retentionFactorProfileMap: RetentionFactorProfileMap;
}

export interface ReferenceDataState {
  categories: CategoryMap;
  referenceMeasures: ReferenceMeasureMap;
  nutrientData: NutrientMap;
  retentionFactorData: RetentionFactorData;
}

export const initialRetentionFactorData: RetentionFactorData = {
  retentionFactorGroupMap: {},
  retentionFactorProfileMap: {},
};

export const initialReferenceDataState: ReferenceDataState = {
  categories: {},
  referenceMeasures: {},
  nutrientData: {},
  retentionFactorData: initialRetentionFactorData,
};

export const ENABLED_REFERENCE_MEASURES = [
  "5",
  "10",
  "13",
  "18",
  "17",
  "1",
  "11",
];

//TODO: This list can be filtered down further on Shian's advice perhaps
export const ENABLED_NUTRIENTS = [
  "0",
  "3",
  "4",
  "6",
  "5",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "38",
  "40",
  "43",
  "48",
  "52",
  "54",
  "58",
  "61",
  "63",
  "95",
  "97",
  "101",
  "104",
  "105",
  "107",
  "112",
  "113",
  "114",
  "176",
  "185",
  "186",
  "187",
  "189",
  //TODO: Intolerance nutrients turned off until non-numeric nutrient values are implemented
  // "301",
  // "302",
  // "303",
  "322",
  "323",
  "324",
  "325",
  "326",
  "328",
  "329",
  "330",
  "331",
  "332",
  "334",
  "335",
  "336",
  "337",
  "338",
  "339",
  "340",
  "341",
  "342",
  "343",
  "345",
  "346",
  "347",
  "348",
  "349",
  "350",
  "351",
  "353",
  "354",
  "355",
  "356",
  "357",
  "1067",
  "1321",
  "1324",
  "1327",
  "1333",
  "1344",
];

export const referenceData = (
  state: ReferenceDataState = initialReferenceDataState,
  action: ReferenceDataActions
): ReferenceDataState => {
  switch (action.type) {
    case SET_RETENTION_FACTOR_DATA:
      return { ...state, retentionFactorData: action.retentionFactorData };
    case SET_NUTRIENT_DATA:
      return { ...state, nutrientData: action.nutrientData };
    case SET_CATEGORIES:
      return { ...state, categories: action.categories };
    case SET_REFERENCE_MEASURES:
      return { ...state, referenceMeasures: action.referenceMeasures };
    default:
      return state;
  }
};
