import { Action } from "redux";

export const SET_ENABLED_DATASOURCES = "SET_ENABLED_DATASOURCES";
export const SET_DISPLAYED_NUTRIENTS = "SET_DISPLAYED_NUTRIENTS";

export interface IActionsSetEnabledDatasources extends Action {
  type: "SET_ENABLED_DATASOURCES";
  sources: string[];
}

export interface IActionsSetDisplayedNutrients extends Action {
  type: "SET_DISPLAYED_NUTRIENTS";
  nutrientIds: string[];
}

export type DatabasePropertyActions =
  | IActionsSetEnabledDatasources
  | IActionsSetDisplayedNutrients;
