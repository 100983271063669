import { Day, DayState } from "../../../../data/models/documentProperties/day";
import {
  ADD_DAY,
  DaysActions,
  SET_DAYS,
  UPDATE_DAY_DATE,
  UPDATE_DAY_TITLE,
} from "../actions/days";
import {
  ADD_FOOD_ITEM,
  CLEAR_FOOD_ITEM,
  CLEAR_QUANTITY,
  CLEAR_RETENTION_FACTOR,
  REMOVE_FOOD_ITEM,
  UPDATE_FOOD_ITEM,
  UPDATE_NOTE,
  UPDATE_QUANTITY,
  UPDATE_RETENTION_FACTOR,
} from "../actions/foodItems";
import {
  ADD_SECTION,
  ADD_SECTION_TAG,
  DELETE_SECTIONS,
  REMOVE_SECTION_TAG,
  UPDATE_SECTION,
  UPDATE_SECTION_IS_COLLAPSED,
  UPDATE_SECTION_TITLE,
} from "../actions/sections";
import { sections } from "./sections";

export const initialDaysState: DayState[] = [];

export const days = (
  state: DayState[] = initialDaysState,
  action: DaysActions
): DayState[] => {
  switch (action.type) {
    case ADD_DAY:
      return [...state, action.day.object];
    case SET_DAYS:
      return action.days.map((day: Day): DayState => day.object);
    case UPDATE_DAY_TITLE:
      return state.map(
        (dayState: DayState): DayState =>
          dayState.index === action.dayIndex
            ? { ...dayState, title: action.title }
            : dayState
      );
    case UPDATE_DAY_DATE:
      return state.map(
        (dayState: DayState): DayState =>
          dayState.index === action.dayIndex
            ? { ...dayState, date: action.date }
            : dayState
      );
    case ADD_SECTION:
    case UPDATE_SECTION:
    case UPDATE_SECTION_TITLE:
    case DELETE_SECTIONS:
    case UPDATE_SECTION_IS_COLLAPSED:
    case ADD_SECTION_TAG:
    case REMOVE_SECTION_TAG:
    case ADD_FOOD_ITEM:
    case REMOVE_FOOD_ITEM:
    case CLEAR_FOOD_ITEM:
    case UPDATE_FOOD_ITEM:
    case UPDATE_QUANTITY:
    case CLEAR_QUANTITY:
    case UPDATE_NOTE:
    case UPDATE_RETENTION_FACTOR:
    case CLEAR_RETENTION_FACTOR:
      return [
        ...state.map(
          (day: DayState): DayState =>
            day.index === action.dayIndex
              ? { ...day, sections: sections(day.sections, action) }
              : { ...day }
        ),
      ];
    default:
      return state;
  }
};
