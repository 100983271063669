import {
  FOODS,
  FoodTemplate,
  FOOD_TEMPLATES,
  RECIPES,
  TWENTY_FOUR_HOUR_RECALL,
} from "./FoodTemplate";
import { FoodSummaryType } from "../components/document_view/foods/tabs/ingredients/editing_grid/cells/ingredientUtils";

export const DELETE_FILTER_ID = 10;

// Temporary Food Item class for basic functionality before knowing template API
export class QuickFilter {
  id: number;
  text: string;
  filter: (summary: FoodSummaryType) => boolean;

  constructor(
    id: number,
    text: string,
    filter: (summary: FoodSummaryType) => boolean
  ) {
    this.id = id;
    this.text = text;
    this.filter = filter;
  }
}

function templateFilter(template: FoodTemplate) {
  return new QuickFilter(
    template.id,
    template.title,
    (summary: FoodSummaryType) => {
      if ("templateId" in summary) {
        return summary.templateId === template.id;
      }
      return false;
    }
  );
}
export const FOODS_FILTER = templateFilter(FOODS);
export const RECIPES_FILTER = templateFilter(RECIPES);
export const TWENTY_FOUR_HOUR_RECALL_FILTER = templateFilter(
  TWENTY_FOUR_HOUR_RECALL
);
//export const MEAL_PLANS_FILTER = templateFilter(MEAL_PLANS);
//export const FOOD_RECORDS_FILTER = templateFilter(FOOD_RECORDS);

export const DELETED_DOCUMENTS_FILTER = new QuickFilter(
  DELETE_FILTER_ID,
  "Deleted",
  (summary: FoodSummaryType) => {
    if ("isDeleted" in summary) {
      return summary.isDeleted;
    }
    return false;
  }
);

export const QUICK_FILTERS = FOOD_TEMPLATES.map(
  (template: FoodTemplate): QuickFilter => templateFilter(template)
).concat([DELETED_DOCUMENTS_FILTER]);
