import React, { FunctionComponent, useState, ReactNode } from "react";

import {
  makeStyles,
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Typography,
} from "@material-ui/core";
import { AccountCircle, HelpOutline } from "@material-ui/icons";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import TelegramIcon from "@material-ui/icons/Telegram";
import { useDispatch } from "react-redux";

import logo from "../../images/foodworks_horizontal.png";
import Firebase, { withFirebase } from "../../data/Firebase";
import { DocumentMenu } from "../common/DocumentMenu";
import { FeedbackDialog } from "../dialogs/FeedbackDialog";
import DatabaseDialog from "../dialogs/DatabaseDialog";
import { DatabaseMenu } from "../common/DatabaseMenu";
import { AccountDialog } from "../dialogs/AccountDialog";
import { appTheme } from "../../styling/style";
import { AccountMenu } from "../common/AccountMenu";
import { FoodworksTooltip } from "../common/InfoTooltip";
import { addAlert } from "../../store/ui/actionCreators/alert";

export interface AppBarProps {
  firebase?: Firebase;
}

const useStyles = makeStyles(() => ({
  root: { minHeight: 50, height: 50 },
  logo: { height: 40, marginBottom: 7, marginRight: 40 },
  dropdownButton: { marginLeft: 10, marginTop: 7 },
  successAlert: { marginTop: 40 },
  growDiv: { flex: 1 },
  buttonStyle: { textTransform: "none" },
  appBarButton: {
    color: appTheme.colors.xiketic,
    "&:hover": {
      color: appTheme.colors.primary,
    },
  },
}));

const HomeAppBar: FunctionComponent<AppBarProps> = ({ firebase }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  // *** State ***
  const [showFeedbackDialog, setShowFeedbackDialog] = useState(false);
  const [showDatabaseDialog, setShowDatabaseDialog] = useState(false);
  const [showAccountDialog, setShowAccountDialog] = useState(false);
  const [dbAnchor, setDbAnchor] = React.useState<null | HTMLElement>(null);
  const [docAnchor, setDocAnchor] = React.useState<null | HTMLElement>(null);
  const [accountAnchor, setAccountAnchor] = React.useState<null | HTMLElement>(
    null
  );

  // *** ----- ***

  const onAddSuccessAlert = (message: string) => dispatch(addAlert(message));

  const onCreateDocumentClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setDocAnchor(event.currentTarget);
  };

  const onDatabasesClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setDbAnchor(event.currentTarget);
  };

  const onAccountClicked = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAccountAnchor(event.currentTarget);
  };

  const databaseButton: ReactNode = (
    <>
      <Button
        className={classes.dropdownButton}
        data-cy="databaseMenuButton"
        variant="text"
        color="secondary"
        size="small"
        endIcon={<ArrowDropDownIcon />}
        onClick={onDatabasesClick}
      >
        <Typography variant="h3" className={classes.buttonStyle}>
          Databases
        </Typography>
      </Button>
      <DatabaseMenu
        setShowDatabaseDialog={setShowDatabaseDialog}
        handleClose={() => setDbAnchor(null)}
        anchorElement={dbAnchor}
      />
    </>
  );

  const createNewButton: ReactNode = (
    <>
      <Button
        className={classes.dropdownButton}
        data-cy="createButton"
        variant="text"
        color="secondary"
        size="small"
        endIcon={<ArrowDropDownIcon />}
        onClick={onCreateDocumentClick}
      >
        <Typography variant="h3" className={classes.buttonStyle}>
          Documents
        </Typography>
      </Button>
      <DocumentMenu
        handleClose={() => setDocAnchor(null)}
        anchorElement={docAnchor}
      />
    </>
  );

  const onFeedbackSend = () => {
    setShowFeedbackDialog(false);
    onAddSuccessAlert("Thank you for your feedback!");
  };

  const sendFeedbackButton: ReactNode = (
    <>
      <FoodworksTooltip title="Send Feedback">
        <IconButton
          className={classes.appBarButton}
          onClick={() => setShowFeedbackDialog(true)}
        >
          <TelegramIcon />
        </IconButton>
      </FoodworksTooltip>
      {showFeedbackDialog ? (
        <FeedbackDialog
          onClose={() => setShowFeedbackDialog(false)}
          onSend={onFeedbackSend}
        />
      ) : null}
    </>
  );

  const helpButton: ReactNode = (
    <FoodworksTooltip title="Get help">
      <a
        href="https://support.foodworks.online/hc/en-au"
        target="_blank"
        rel="noopener noreferrer"
      >
        <IconButton className={classes.appBarButton}>
          <HelpOutline />
        </IconButton>
      </a>
    </FoodworksTooltip>
  );

  const accountButton = (
    <>
      <FoodworksTooltip title="Account">
        <IconButton className={classes.appBarButton} onClick={onAccountClicked}>
          <AccountCircle />
        </IconButton>
      </FoodworksTooltip>
      <AccountMenu
        setShowAccountDialog={setShowAccountDialog}
        handleClose={() => setAccountAnchor(null)}
        anchorElement={accountAnchor}
      />
    </>
  );

  return (
    <AppBar
      data-cy="appBar"
      position="sticky"
      className={classes.root}
      elevation={1}
    >
      <Toolbar className={classes.root}>
        <img src={logo} alt="Logo" className={classes.logo} />
        {databaseButton}
        {createNewButton}
        <div className={classes.growDiv} />
        {sendFeedbackButton}
        {helpButton}
        {showDatabaseDialog ? (
          <DatabaseDialog open onClose={() => setShowDatabaseDialog(false)} />
        ) : null}

        {accountButton}
        {showAccountDialog ? (
          <AccountDialog onClose={() => setShowAccountDialog(false)} />
        ) : null}
      </Toolbar>
    </AppBar>
  );
};

const FoodWorksAppBar = withFirebase(HomeAppBar);

export default FoodWorksAppBar;
