import React, { FunctionComponent, ReactNode } from "react";

import {
  AppBar,
  Tabs,
  Tab,
  makeStyles,
  Divider,
  Typography,
} from "@material-ui/core";

import { appTheme } from "../../styling/style";

const useStyles = makeStyles((theme: any) => ({
  root: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  appBar: {
    background: appTheme.colors.white[0],
    boxShadow: "none",
    borderRadius: 5,
  },
  tabSize: {
    minWidth: 120,
  },
  tab: {
    color: appTheme.colors.primary,
    "&:hover": {
      backgroundColor: appTheme.colors.oceanBlue[0],
    },
  },
  tabText: { color: appTheme.colors.xiketic },
  tabPanel: {
    display: "flex",
    overflow: "hidden",
  },
}));

interface TabbedPaneTab {
  title: string;
  content: ReactNode;
}

interface TabbedPaneProps {
  tabs: TabbedPaneTab[];
  currentTab: number;
  onChangeTab: (index: number) => void;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const classes = useStyles();
  const { children, value, index } = props;

  return (
    <div
      hidden={value !== index}
      className={classes.tabPanel}
      style={{ flex: value === index ? 1 : 0 }}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

export const TabbedPane: FunctionComponent<TabbedPaneProps> = ({
  tabs,
  currentTab,
  onChangeTab,
}) => {
  const classes = useStyles();

  const handleChange = (_: any, newValue: number) => {
    onChangeTab(newValue);
  };

  const tabTitles = tabs.map((tab: TabbedPaneTab) => (
    <Tab
      classes={{ root: classes.tabSize }}
      className={classes.tab}
      key={tab.title}
      label={
        <Typography variant="h3" className={classes.tabText}>
          {tab.title}
        </Typography>
      }
      data-cy="tab"
    />
  ));
  const panels = tabs.map((tab, index) => (
    <TabPanel key={index} index={index} value={currentTab}>
      {tab.content}
    </TabPanel>
  ));

  return (
    <div className={classes.root}>
      <div data-cy="tabs">
        <AppBar position="static" className={classes.appBar} elevation={0}>
          <Tabs
            indicatorColor="secondary"
            value={currentTab}
            onChange={handleChange}
          >
            {tabTitles}
          </Tabs>
        </AppBar>
      </div>
      <Divider />
      {panels}
    </div>
  );
};
