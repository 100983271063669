import React, { FunctionComponent } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core";

import { FoodWorksTextInput } from "../../common/FoodWorksTextInput";
import { appTheme } from "../../../styling/style";
import { RootState } from "../../../store/reducers";
import {
  methodSelector,
  templateIdSelector,
} from "../../../store/data/current-document/selectors/document";
import { updateDocumentMethod } from "../../../store/data/current-document/action-creators/document";
import { hasYieldAndServe } from "../../../constants/FoodTemplate";
import { YieldAndServeSelection } from "./tabs/ingredients/YieldAndServeSelection";

const useStyles = makeStyles((theme: any) => ({
  textArea: {
    borderStyle: "none",
    borderRadius: 4,
    marginTop: 5,
    background: appTheme.colors.white[0],
  },
  paper: {
    display: "flex",
    width: "100%",
    flex: 1,
    flexDirection: "column",
    padding: 10,
    marginTop: 10,
    background: appTheme.colors.white[0],
    overflowY: "auto",
  },
}));

export const MethodTab: FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const documentMethod = useSelector<RootState, string>(methodSelector);

  const templateId: string = useSelector<RootState, string>(templateIdSelector);

  const handleMethodUpdate = (newInput: string) => {
    dispatch(updateDocumentMethod(newInput));
  };
  return (
    <div className={classes.paper}>
      {hasYieldAndServe(templateId) && <YieldAndServeSelection />}
      <FoodWorksTextInput
        value={documentMethod}
        multiline
        rows={50}
        onChange={(event) => handleMethodUpdate(event.currentTarget.value)}
        className={classes.textArea}
        placeholder={"Specify a method for your recipe here."}
      />
    </div>
  );
};
