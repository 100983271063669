import React, { FunctionComponent } from "react";

import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import { AccordionList, AccordionProps } from "../../common/AccordionList";
import { tappedCategoryAccordion } from "../../../store/ui/actionCreators/nutritionPaneActionCreators";
import { Category } from "../../../data/models/category";
import { NutrientItem } from "./NutrientItem";
import { Nutrient } from "../../../data/models/nutrient";
import {
  NutrientsDataSelector,
  CategoriesSelector,
} from "../../../store/data/selectors/referenceData";
import { RootState } from "../../../store/reducers";
import { expandedCategoriesSelector } from "../../../store/ui/selectors/nutritionPaneSelectors";
import { NutrientValues } from "../../../data/models/nutrientValue";

const useStyles = makeStyles((theme: any) => ({
  root: {
    overflowY: "auto",
    overflowX: "hidden",
    flex: 1,
  },
}));

interface NutrientAnalysisProps {
  nutrientValues: NutrientValues;
}

export const NutrientAnalysis: FunctionComponent<NutrientAnalysisProps> = ({
  nutrientValues,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onCategoryTapped = (categoryId: string) =>
    dispatch(tappedCategoryAccordion(categoryId));

  const nutrients: Map<string, Nutrient> = useSelector<
    RootState,
    Map<string, Nutrient>
  >(NutrientsDataSelector);

  const nutrientCategories: [string, Category][] = useSelector<
    RootState,
    [string, Category][]
  >(CategoriesSelector);

  const categoriesExpanded: string[] = useSelector<RootState, string[]>(
    expandedCategoriesSelector
  );

  const getNutrientValue = (id: string): string | undefined =>
    nutrientValues.nutrientValue(id).displayValue;

  let maxNutrientValueLength = 0;

  for (const [id] of Array.from(nutrients.entries())) {
    const formattedNutrientValue: string = getNutrientValue(id) || "?";
    if (formattedNutrientValue.length > maxNutrientValueLength)
      maxNutrientValueLength = formattedNutrientValue.length;
  }

  const buildNutrientAccordionItems = (): AccordionProps[] => {
    const categoryMap = new Map<string, AccordionProps>(
      nutrientCategories.map(([id, category]: [string, Category]): [
        string,
        AccordionProps
      ] => [
        id,
        {
          id: Number(id),
          header: category.name,
          items: [],
          open: categoriesExpanded.includes(id),
        },
      ])
    );

    for (const [id, nutrient] of Array.from(nutrients.entries())) {
      if (categoryMap.has(nutrient.category)) {
        categoryMap.get(nutrient.category)!.items.push({
          id: id,
          item: (
            <NutrientItem
              key={id}
              nutrientId={id}
              name={nutrient.name}
              value={getNutrientValue(id) || "?"}
              measure={nutrient.units}
              maxValueLength={maxNutrientValueLength}
            />
          ),
        });
      }
    }

    //Filters out the empty categories
    const filteredCategories = Array.from(
      categoryMap.values()
    ).filter((props: AccordionProps): boolean => Boolean(props.items.length));

    return filteredCategories;
  };

  return (
    <div className={classes.root}>
      <AccordionList
        toggledCallback={(categoryId: number) =>
          onCategoryTapped(categoryId.toString())
        }
        values={buildNutrientAccordionItems()}
      />
    </div>
  );
};
