import React, { FunctionComponent, useState, useEffect } from "react";

import { useDispatch, batch } from "react-redux";
import { makeStyles, Theme, createStyles } from "@material-ui/core";

import { appTheme } from "../../../../../../../styling/style";
import { GridCellInput } from "../../../../../../common/GridAutoCompleteInput";
import { updateNote } from "../../../../../../../store/data/current-document/action-creators/foodItems";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divChildFlex: {
      display: "flex",
      flex: 1,
    },
    auto: {
      flex: 1,
      border: 0,
      fontSize: appTheme.fontSize.textInput,
      fontFamily: appTheme.fontFamily.textInput,
      background: "transparent",
    },
  })
);

const INGREDIENT_COLUMN_INDEX = 0;

interface NoteCellProps {
  initialInput: string;
  dayIndex: number;
  sectionIndex: number;
  rowIndex: number;
  columnIndex: number;
  rowHovered: boolean;
  currentFocusedCell: string;
  setFocusedCell: (cell: string) => void;
}

export const NoteCell: FunctionComponent<NoteCellProps> = ({
  initialInput,
  dayIndex,
  sectionIndex,
  rowIndex,
  columnIndex,
  rowHovered,
  currentFocusedCell,
  setFocusedCell,
}) => {
  const classes = useStyles();

  // *** Actions ***
  const dispatch = useDispatch();

  const onUpdateFocusedCell = (focusedCell: string) =>
    setFocusedCell(focusedCell);

  const onUpdateNote = (rowIndex: number, note: string) =>
    dispatch(updateNote(dayIndex, sectionIndex, rowIndex, note));
  // *** ----- ***

  // *** State ***
  const [cellValue, setInputValue] = useState(initialInput);

  const ref: React.RefObject<HTMLInputElement> = React.useRef<HTMLInputElement>(
    null
  );
  // *** ----- ***

  // *** Effects ***
  useEffect(() => {
    if (ref.current) {
      if (currentFocusedCell === `${sectionIndex}-${rowIndex}-${columnIndex}`) {
        ref?.current?.focus();
      }
    }
  }, [currentFocusedCell, rowIndex, columnIndex, sectionIndex]);
  // *** ----- ***

  useEffect(() => {
    setInputValue(initialInput);
  }, [initialInput]);

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    if (event.key === "Enter") {
      onUpdateFocusedCell(
        `${sectionIndex}-${rowIndex + 1}-${INGREDIENT_COLUMN_INDEX}`
      );
    }
  };

  const onFocus = (): void => {
    onUpdateFocusedCell(`${sectionIndex}-${rowIndex}-${columnIndex}`);
  };

  const onBlur = (): void => {
    batch(() => {
      onUpdateNote(rowIndex, cellValue);
      onUpdateFocusedCell("");
    });
  };

  const isCellFocused =
    currentFocusedCell === `${sectionIndex}-${rowIndex}-${columnIndex}`;

  return (
    <div className={classes.divChildFlex}>
      <GridCellInput
        value={cellValue}
        inputRef={ref}
        onChange={(event) => setInputValue(event.target.value)}
        onBlur={() => onBlur()}
        onFocus={() => onFocus()}
        onKeyDown={(event) => handleKeyDown(event)}
        placeholder={isCellFocused || rowHovered ? "Note" : ""}
        className={classes.auto}
        type="text"
      />
    </div>
  );
};
