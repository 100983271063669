import React, { FunctionComponent } from "react";

import { FixedSizeList, ListChildComponentProps } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { ListItem, Button, Typography, makeStyles } from "@material-ui/core";

import { IngredientSummaryItem } from "../document_view/foods/tabs/ingredients/editing_grid/cells/IngredientCell";
import { appTheme } from "../../styling/style";

const useStyles = makeStyles(() => ({
  listItemText: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    textTransform: "none",
  },
  selectedListButton: {
    backgroundColor: appTheme.colors.oceanBlue[0],
    color: appTheme.colors.primary,
    "&:hover": {
      backgroundColor: appTheme.colors.oceanBlue[0],
      borderColor: appTheme.colors.oceanBlue[0],
      boxShadow: "none",
      color: appTheme.colors.primary,
    },
  },
  none: {},
  casing: {
    flex: 1,
    padding: 10,
  },
}));

const DELAY = 200;

interface VirtualisedDocumentListProps {
  summaries: IngredientSummaryItem[];
  onListItemClicked: (item: IngredientSummaryItem) => void;
  onListItemDoubleClicked?: (item: IngredientSummaryItem) => void;
  selectedItem?: string;
}

export const VirtualizedDocumentList: FunctionComponent<VirtualisedDocumentListProps> = ({
  summaries,
  onListItemClicked,
  onListItemDoubleClicked,
  selectedItem,
}) => {
  const classes = useStyles();

  let timer: NodeJS.Timeout;
  let preventClick = false;

  const onItemDoubleClicked =
    onListItemDoubleClicked || ((item: IngredientSummaryItem) => {});

  const handleClick = (item: IngredientSummaryItem) => {
    timer = setTimeout(function () {
      if (!preventClick) {
        onListItemClicked(item);
      }
      preventClick = false;
    }, DELAY);
  };
  const handleDoubleClick = (item: IngredientSummaryItem) => {
    clearTimeout(timer);
    preventClick = true;
    onItemDoubleClicked(item);
  };

  const VirtualizedListItem = ({
    index,
    style,
    data,
  }: ListChildComponentProps) => {
    const item: IngredientSummaryItem = data[index];
    return (
      <ListItem
        data-cy="documentListItem"
        style={style}
        className={
          item.foodId.identifier === selectedItem
            ? classes.selectedListButton
            : classes.none
        }
        dense
        component={Button}
        onDoubleClick={() => handleDoubleClick(item)}
        onClick={() => handleClick(item)}
        color="secondary"
      >
        <Typography
          variant="body1"
          color={
            item.foodId.identifier === selectedItem ? "inherit" : "textPrimary"
          }
          className={classes.listItemText}
        >
          {item.label}
        </Typography>
      </ListItem>
    );
  };

  return (
    <div className={classes.casing}>
      <AutoSizer>
        {({ width, height }) => (
          <FixedSizeList
            height={height}
            width={width}
            itemCount={summaries.length}
            itemSize={30}
            itemData={summaries}
          >
            {VirtualizedListItem}
          </FixedSizeList>
        )}
      </AutoSizer>
    </div>
  );
};
