import { DocumentSummary, Database } from "../../models/userDatabase";
import Firebase from "../firebase";

export const fetchNavigationSummaries = async (
  firebase: Firebase,
  databaseIds: string[]
): Promise<DocumentSummary[]> => {
  let summaryItems: DocumentSummary[] = [];

  for (const databaseId of databaseIds) {
    const items = await fetchDatabaseSummaries(databaseId, firebase);
    summaryItems = summaryItems.concat(items);
  }

  return summaryItems;
};

const fetchDatabaseSummaries = async (
  databaseId: string,
  firebase: Firebase
): Promise<DocumentSummary[]> => {
  let database: Database | undefined;
  try {
    database = await firebase?.doGetUserDatabaseDocument(databaseId);
  } catch (error) {
    if (error.code === "permission-denied") {
      console.log(
        "Permission Denied Error for databaseId:",
        databaseId,
        error.message
      );
    }
    console.log(error);
    return [];
  }

  if (!database) {
    // Todo error handling with UI
    return [];
  }
  return database.documentSummaries;
};
