import React, { FunctionComponent, ReactNode } from "react";

import DateFnsUtils from "@date-io/date-fns";
import {
  createMuiTheme,
  IconButton,
  makeStyles,
  TextField,
  ThemeProvider,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";

import { Day } from "../../../../data/models/documentProperties/day";
import { appTheme } from "../../../../styling/style";
import { FoodWorksTextInput } from "../../../common/FoodWorksTextInput";
import { FoodworksTooltip } from "../../../common/InfoTooltip";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-evenly",
  },
  titleInput: {
    marginTop: 6,
  },
  calendarInput: {
    marginTop: 0,
    marginLeft: 10,
  },
  icon: {
    width: 40,
  },
}));

const materialCalendarTheme = createMuiTheme({
  overrides: {
    //@ts-ignore
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: appTheme.colors.secondary,
      },
    },
    MuiPickersDay: {
      day: {
        color: appTheme.colors.xiketic,
      },
      daySelected: {
        color: appTheme.colors.primary,
        "&:nth-child(1)": {
          color: "white",
        },
      },
      dayDisabled: {
        color: appTheme.colors.oceanBlue[100],
      },
      current: {
        color: appTheme.colors.primary,
      },
    },
  },
});

interface DaySummaryListItemProps {
  day: Day;
  onUpdateTitle: (title: string) => void;
  onUpdateDate: (date: string) => void;
  onDelete: () => void;
}

export const DaySummaryListItem: FunctionComponent<DaySummaryListItemProps> = ({
  day,
  onUpdateTitle,
  onUpdateDate,
  onDelete,
}) => {
  const classes = useStyles();

  const titleInput: ReactNode = (
    <FoodWorksTextInput
      key={`${day.index}-title`}
      className={classes.titleInput}
      value={day.title}
      onChange={(event) => onUpdateTitle(event.currentTarget.value)}
    />
  );

  const dateInput: ReactNode = (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <ThemeProvider theme={materialCalendarTheme}>
        <DatePicker
          clearable
          variant="dialog"
          format="dd/MM/yyyy"
          margin="normal"
          key={`${day.index}-calendar`}
          label="Date"
          value={new Date()}
          TextFieldComponent={(params) => (
            <TextField
              key={`${day.index}-date`}
              {...params}
              className={classes.calendarInput}
              value={day.date}
            />
          )}
          onChange={(event) => onUpdateDate(event?.toLocaleDateString() || "")}
        />
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );

  const deleteDayButton: ReactNode = (
    <FoodworksTooltip title="Delete day">
      <IconButton
        key={`${day.index}-delete`}
        onClick={onDelete}
        className={classes.icon}
        size="small"
      >
        <Close />
      </IconButton>
    </FoodworksTooltip>
  );

  return (
    <div className={classes.root}>
      {titleInput} {dateInput} {deleteDayButton}
    </div>
  );
};
