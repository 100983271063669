import React, {
  FunctionComponent,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";

import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { Action } from "redux";

import { FoodWorksDialog, DialogOption } from "./FoodWorksDialog";
import { saveDocument } from "../../store/data/current-document/thunks/currentDocument";
import { updateCurrentDocument } from "../../store/data/current-document/action-creators/currentDocument";
import {
  Document,
  documentsAreEqual,
  getDocumentErrors,
} from "../../data/models/document";
import {
  ServerDocumentSelector,
  CurrentDocumentIdSelector,
} from "../../store/data/current-document/selectors/currentDocument";
import { RootState } from "../../store/reducers";
import { documentSelector } from "../../store/data/current-document/selectors/document";
import { useBeforeunload } from "react-beforeunload";
import { DocumentSummary } from "../../data/models/userDatabase";
import { userDocumentSummariesSelector } from "../../store/data/selectors/database";
import { unsavedDocumentChangesDataSelector } from "../../store/ui/selectors/documentSaving";
import {
  setUnsavedDocumentChanges,
  setDocumentIdToChangeTo,
} from "../../store/ui/actionCreators/documentSaving";
import { changeCurrentDatabase } from "../../store/data/thunks/database";
import { RouterHistory } from "../../store/ui/thunks/login";
import { appTheme } from "../../styling/style";
import Firebase from "../../data/Firebase";
import { ReferenceMeasure } from "../../data/models/referenceMeasure";
import { ReferenceMeasuresSelector } from "../../store/data/selectors/referenceData";

const TITLE: string = "You have made unsaved changes to this document.";
const DEFAULT_DESCRIPTION: string =
  "What would you like to do with these changes ?";

export interface SaveDocumentDialogProps {
  uid: string;
  history: RouterHistory;
}

type AppDispatch = ThunkDispatch<RootState, Firebase, Action<any>>;

const useOpenDialogHook = (openDialog: boolean): [boolean, () => void] => {
  const [open, setOpen] = useState(false);

  const closeDialog = () => setOpen(false);

  useEffect(() => {
    if (openDialog) setOpen(true);
  }, [openDialog]);

  return [open, closeDialog];
};

export const SaveDocumentDialog: FunctionComponent<SaveDocumentDialogProps> = ({
  uid,
  history,
}) => {
  const dispatch: AppDispatch = useDispatch();

  const onSaveDocument = (previousId: string) =>
    dispatch(saveDocument(uid, previousId));

  const resetUnsavedChangesState = () =>
    dispatch(setUnsavedDocumentChanges(false, "", ""));

  const onSetDocumentId = useCallback(
    (id: string) => dispatch(setDocumentIdToChangeTo(id)),
    [dispatch]
  );

  const onUpdateCurrentDocument = (document: Document) =>
    dispatch(updateCurrentDocument(document));

  const onChangeCurrentDatabase = (databaseId: string) =>
    dispatch(changeCurrentDatabase(databaseId, history));

  const currentDocument: Document = useSelector<RootState, Document>(
    documentSelector
  );

  const serverDocument: Document | undefined = useSelector<
    RootState,
    Document | undefined
  >(ServerDocumentSelector);

  const currentDocumentId: string = useSelector<RootState, string>(
    CurrentDocumentIdSelector
  );

  const unsavedDocumentChangesData: RootState["documentSaving"] = useSelector<
    RootState,
    RootState["documentSaving"]
  >(unsavedDocumentChangesDataSelector);

  const userDocumentSummaries: DocumentSummary[] = useSelector<
    RootState,
    DocumentSummary[]
  >(userDocumentSummariesSelector);

  const referenceMeasures: ReferenceMeasure[] = useSelector<
    RootState,
    ReferenceMeasure[]
  >(ReferenceMeasuresSelector);

  const databaseChanged: boolean = Boolean(
    unsavedDocumentChangesData.databaseIdToChangeTo
  );

  const [showDialog, closeDialog] = useOpenDialogHook(
    unsavedDocumentChangesData.openSaveDialog
  );

  const onDiscardChanges = () => {
    dispatch(updateCurrentDocument(serverDocument!));

    if (databaseChanged) {
      onUpdateCurrentDocument(serverDocument!);
      onChangeCurrentDatabase(unsavedDocumentChangesData.databaseIdToChangeTo);
    } else {
      onSetDocumentId(unsavedDocumentChangesData.documentIdToChangeTo);
    }

    resetUnsavedChangesState();
  };

  const onHandleSaveChanges = async () => {
    await onSaveDocument(currentDocumentId);

    databaseChanged
      ? onChangeCurrentDatabase(unsavedDocumentChangesData.databaseIdToChangeTo)
      : onSetDocumentId(unsavedDocumentChangesData.documentIdToChangeTo);

    resetUnsavedChangesState();
  };

  const onDiscard = () => {
    closeDialog();
    onDiscardChanges();
  };

  const onSave = () => {
    closeDialog();
    onHandleSaveChanges();
  };

  const onDialogExit = () => {
    closeDialog();
    resetUnsavedChangesState();
  };

  const documentHasUnsavedChanges: boolean = !documentsAreEqual(
    currentDocument,
    serverDocument
  );

  useBeforeunload((event: Event) => {
    if (documentHasUnsavedChanges) event.preventDefault();
  });

  const documentErrors: string[] | undefined = getDocumentErrors(
    currentDocument,
    userDocumentSummaries,
    databaseChanged
      ? unsavedDocumentChangesData.documentIdToChangeTo
      : currentDocumentId,
    referenceMeasures.map((measure: ReferenceMeasure): string => measure.name)
  );

  const errorBody: ReactNode = (
    <div>
      {"Please fix the following errors in your document: "}
      <ul>
        {documentErrors?.map(
          (error: string): ReactNode => (
            <li key={error}>{error}</li>
          )
        )}
      </ul>
    </div>
  );

  const documentDialogActions: DialogOption[] = [
    {
      text: "Cancel",
      onClick: onDialogExit,
      color: appTheme.colors.xiketic,
    },
    {
      text: "Discard Changes",
      onClick: onDiscard,
      color: appTheme.colors.warning,
    },
  ];

  if (!documentErrors.length) {
    documentDialogActions.push({
      text: "Save Changes",
      onClick: onSave,
      color: appTheme.colors.primary,
    });
  }

  return (
    <FoodWorksDialog
      data-cy="saveDialog"
      open={showDialog}
      onClose={onDialogExit}
      options={documentDialogActions}
      title={TITLE}
      description={documentErrors.length ? errorBody : DEFAULT_DESCRIPTION}
    />
  );
};
