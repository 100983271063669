import {
  IActionsAddDocument,
  IActionsRemoveDocument,
  IActionsUpdateDocument,
  IActionsFetchDocument,
} from "../actions/documentCache";
import { Document } from "../../../data/models/document";
import { FoodId } from "../../../data/models/documentProperties/foodId";

export const addDocument = (
  document: Document,
  documentMapId: string
): IActionsAddDocument => {
  return {
    type: "ADD_DOCUMENT",
    documentMapId: documentMapId,
    document: document,
  };
};

export const removeDocument = (
  documentMapId: string
): IActionsRemoveDocument => {
  return {
    type: "REMOVE_DOCUMENT",
    documentMapId: documentMapId,
  };
};

export const updateDocument = (
  documentMapId: string,
  document: Document
): IActionsUpdateDocument => {
  return {
    type: "UPDATE_DOCUMENT",
    documentMapId: documentMapId,
    document: document,
  };
};

export const fetchDocument = (
  foodId: FoodId,
  isPublic: boolean,
  isMapped: boolean
): IActionsFetchDocument => {
  return {
    type: "FETCH_DOCUMENT",
    foodId: foodId,
    isPublic: isPublic,
    isMapped: isMapped,
  };
};
