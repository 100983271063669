import React, {
  FunctionComponent,
  ReactNode,
  useEffect,
  useState,
} from "react";

import {
  makeStyles,
  createStyles,
  IconButton,
  TextField,
  Chip,
  Typography,
  Checkbox,
} from "@material-ui/core";
import FilterIcon from "@material-ui/icons/FilterList";
import {
  Autocomplete,
  AutocompleteGetTagProps,
  AutocompleteRenderInputParams,
} from "@material-ui/lab";
import { batch, useDispatch, useSelector } from "react-redux";

import { appTheme } from "../../../styling/style";
import { FoodworksTooltip } from "../../common/InfoTooltip";
import { QuantityRadioGroup } from "./RadioButtons";
import { SectionTag } from "../../../data/models/documentProperties/section";
import { sectionTagsSelector } from "../../../store/data/current-document/selectors/sectionTags";
import { RootState } from "../../../store/reducers";
import { selectedSectionTagsSelector } from "../../../store/ui/selectors/nutritionPaneSelectors";
import { setSelectedSectionTags } from "../../../store/ui/actionCreators/nutritionPaneActionCreators";
import { FoodItemPosition } from "../../../data/models/foodItemPosition";
import { daysSelector } from "../../../store/data/current-document/selectors/days";
import { Days } from "../../../data/models/documentProperties/day";
import { setSelectedRows } from "../../../store/ui/actionCreators/recipeGrid";

const useStyles = makeStyles(() =>
  createStyles({
    advancedOptions: {
      paddingLeft: 15,
      paddingRight: 5,
      paddingBottom: 10,
    },
    searchBar: {
      padding: "2px 4px",
      display: "flex",
      alignItems: "center",
    },
    iconButton: {
      padding: 10,
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      overflowX: "auto",
    },
    tag: {
      fontSize: 10,
      marginLeft: 1,
      marginRight: 1,
      borderRadius: 4,
      overflow: "ellipsis",
      whiteSpace: "nowrap",
      maxWidth: 60,
    },
    input: {
      flexWrap: "nowrap",
    },
    textField: {
      marginTop: 5,
    },
    filterOption: {
      display: "flex",
      justifyContent: "space-evenly",
      alignItems: "center",
    },
    root: {
      overflow: "hidden",
      display: "flex",
      flexDirection: "column",
      flex: 5,
    },
    colorButton: {
      height: 10,
      width: 10,
      minWidth: 10,
      marginTop: 2,
      marginRight: 5,
      borderRadius: 4,
    },
  })
);

export const CompositionSelectionBar: FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onSetSelectedRows = (rowsToSelect: FoodItemPosition[]) =>
    dispatch(setSelectedRows(rowsToSelect));

  const onSetSelectedSectionTags = (tagIds: string[]) =>
    dispatch(setSelectedSectionTags(tagIds));

  const selectedSectionTags: string[] = useSelector<RootState, string[]>(
    selectedSectionTagsSelector
  );

  const days: Days = useSelector<RootState, Days>(daysSelector);

  const documentSectionTags: SectionTag[] = useSelector<
    RootState,
    SectionTag[]
  >(sectionTagsSelector);

  const [filtersEnabled, setFiltersEnabled] = useState(false);

  useEffect(() => {
    if (!filtersEnabled) {
      setFiltersEnabled(!!selectedSectionTags.length);
    }
  }, [selectedSectionTags.length, filtersEnabled]);

  const onClickFilterButton = () => {
    if (filtersEnabled) {
      batch(() => {
        onSetSelectedSectionTags([]);
        if (selectedSectionTags.length) {
          onSetSelectedRows([]);
        }
      });
    }
    setFiltersEnabled((prev) => !prev);
  };

  const sectionTagFilterButton: ReactNode = (
    <FoodworksTooltip title="Section tag filters">
      <IconButton
        className={classes.iconButton}
        style={{
          background: filtersEnabled ? appTheme.colors.oceanBlue[0] : "inherit",
          color: filtersEnabled ? appTheme.colors.primary : "inherit",
        }}
        onClick={onClickFilterButton}
        data-cy="sectionTagFilter"
      >
        <FilterIcon />
      </IconButton>
    </FoodworksTooltip>
  );

  const onTagAutocompleteChange = (_: any, newValue: SectionTag[] | null) => {
    onSetSelectedSectionTags(
      newValue?.map((sectionTag: SectionTag): string => sectionTag.id) || []
    );
    let rowsToSelect: FoodItemPosition[] = days.getTaggedRowPositions(
      newValue?.map((tag: SectionTag): string => tag.id) || []
    );
    onSetSelectedRows(rowsToSelect);
  };

  const selectOption = (option: SectionTag): ReactNode => (
    <div className={classes.filterOption}>
      <Checkbox checked={selectedSectionTags.includes(option.id)} />
      <div
        className={classes.colorButton}
        style={{ backgroundColor: option.activeColor }}
      />
      <Typography variant="body1">{option.label}</Typography>
    </div>
  );

  const sectionTag = (
    tag: SectionTag,
    index: number,
    getTagProps: AutocompleteGetTagProps
  ): ReactNode => (
    <FoodworksTooltip title={tag.label}>
      <Chip
        {...getTagProps({ index })}
        variant="default"
        size="small"
        key={tag.id}
        label={tag.label}
        className={classes.tag}
        style={{ backgroundColor: tag.activeColor }}
      />
    </FoodworksTooltip>
  );

  const autocompleteInput = (
    params: AutocompleteRenderInputParams
  ): ReactNode => (
    <TextField
      {...params}
      className={classes.textField}
      InputProps={{
        ...params.InputProps,
        disableUnderline: true,
      }}
      autoFocus={true}
      data-cy="quickFilterInput"
      placeholder="Tags"
    />
  );

  const listboxProps = {
    style: {
      overflowY: "auto",
      maxHeight: 300,
    },
  };

  const tagSelection = (
    <Autocomplete
      multiple
      openOnFocus
      onChange={onTagAutocompleteChange}
      limitTags={3}
      value={documentSectionTags.filter((tag: SectionTag): boolean =>
        selectedSectionTags.includes(tag.id)
      )}
      disableCloseOnSelect
      options={documentSectionTags}
      getOptionLabel={(option) => option.label}
      ListboxProps={listboxProps}
      renderOption={selectOption}
      renderTags={(sectionTags, getTagProps) =>
        sectionTags.map(
          (tag: SectionTag, index: number): ReactNode =>
            sectionTag(tag, index, getTagProps)
        )
      }
      renderInput={autocompleteInput}
    />
  );

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        {sectionTagFilterButton}
        <QuantityRadioGroup />
      </div>
      {filtersEnabled && tagSelection}
    </div>
  );
};
