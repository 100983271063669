import React, { FunctionComponent } from "react";

import {
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { UserDatabaseSummary } from "../../data/models/userDatabase";
import { RootState } from "../../store/reducers";
import { userDatabaseSummariesSelector } from "../../store/data/selectors/user";
import { databaseIdSelector } from "../../store/data/selectors/database";
import { appTheme } from "../../styling/style";
import { FoodworksMenu } from "./FoodworksMenu";
import { changeCurrentDatabase } from "../../store/data/thunks/database";
import { ReactComponent as DatabasePlusIcon } from "../../images/icons/Database_Plus.svg";

export interface CreateMenuProps {
  handleClose: () => void;
  anchorElement: HTMLElement | null;
  setShowDatabaseDialog: (showDialog: boolean) => void;
}

const MAX_DATABASE_SUMMARIES_TO_SHOW = 4;

const useStyles = makeStyles(() => ({
  databaseIcon: {
    fill: appTheme.colors.primary,
    width: 24,
    height: 24,
  },
  skinnyAvatar: {
    minWidth: 40,
    display: "flex",
    alignItems: "center",
  },
  divider: { margin: "5px 0px" },
}));

export const DatabaseMenuInner: FunctionComponent<
  CreateMenuProps & RouteComponentProps
> = ({ history, handleClose, anchorElement, setShowDatabaseDialog }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onChangeCurrentDatabase = (databaseId: string) =>
    dispatch(changeCurrentDatabase(databaseId, history));

  const userDatabaseSummaries: UserDatabaseSummary[] = useSelector<
    RootState,
    UserDatabaseSummary[]
  >(userDatabaseSummariesSelector);

  const currentDatabaseId = useSelector<RootState, string>(databaseIdSelector);

  const mostRecentDatabases = userDatabaseSummaries
    .slice(0, MAX_DATABASE_SUMMARIES_TO_SHOW)
    .map((summary: UserDatabaseSummary) => {
      const rowBackground =
        summary.id !== currentDatabaseId
          ? "inherit"
          : appTheme.colors.oceanBlue[1];

      return (
        <ListItem
          button
          dense
          style={{
            background: rowBackground,
          }}
          onClick={() => {
            onChangeCurrentDatabase(summary.id);
            handleClose();
          }}
          key={summary.id}
        >
          <ListItemAvatar className={classes.skinnyAvatar}>
            <DatabasePlusIcon className={classes.databaseIcon} />
          </ListItemAvatar>
          <ListItemText
            primary={summary.name}
            primaryTypographyProps={{ variant: "body1" }}
            secondary={`Modified:
            ${new Date(summary.date.lastModified).toLocaleDateString()}`}
            secondaryTypographyProps={{ variant: "caption" }}
          />
        </ListItem>
      );
    });

  return (
    <FoodworksMenu
      id="simple-menu"
      anchorEl={anchorElement}
      open={Boolean(anchorElement)}
      onClose={handleClose}
    >
      <MenuItem disabled>
        <Typography variant="caption">RECENT</Typography>
      </MenuItem>
      <List disablePadding>{mostRecentDatabases}</List>
      <Divider className={classes.divider} />
      <MenuItem
        data-cy="seeAllDatabases"
        onClick={() => {
          setShowDatabaseDialog(true);
          handleClose();
        }}
      >
        See all databases
      </MenuItem>
    </FoodworksMenu>
  );
};

export const DatabaseMenu = withRouter(DatabaseMenuInner);
