import React, { FunctionComponent, useCallback } from "react";

import { IconButton } from "@material-ui/core";
import CopyIcon from "@material-ui/icons/FileCopy";
import { useDispatch, useSelector } from "react-redux";

import { FoodworksTooltip } from "../../../../../common/InfoTooltip";
import { Day } from "../../../../../../data/models/documentProperties/day";
import {
  FoodItems,
  FoodItemState,
} from "../../../../../../data/models/documentProperties/foodItem";
import { RetentionFactor } from "../../../../../../data/models/documentProperties/retentionFactor";
import {
  SectionState,
  Sections,
  Section,
} from "../../../../../../data/models/documentProperties/section";
import { FoodItemPosition } from "../../../../../../data/models/foodItemPosition";
import { setClipboard } from "../../../../../../store/action_creators/clipboardActionCreators";
import { daySelector } from "../../../../../../store/data/current-document/selectors/days";
import { getRetentionFactorMap } from "../../../../../../store/data/selectors/referenceData";
import { RootState } from "../../../../../../store/reducers";
import {
  ClipboardDataType,
  ClipboardType,
} from "../../../../../../store/reducers/clipboardReducers";
import { addAlert } from "../../../../../../store/ui/actionCreators/alert";
import { countSelectedRowsInSection } from "./section/sectionUtils";

export const checkForOnlySectionsSelected = (
  day: Day,
  selectedRows: FoodItemPosition[]
): number[] => {
  const sectionsSelected: number[] = [];
  let totalFoodItemCountOfSelectedSections = 0;
  for (const section of day.sections.items) {
    if (
      section.foodItems.length &&
      countSelectedRowsInSection(day.index, section, selectedRows) ===
        section.foodItems.length
    ) {
      sectionsSelected.push(section.index);
      totalFoodItemCountOfSelectedSections += section.foodItems.length;
    }
  }
  return totalFoodItemCountOfSelectedSections === selectedRows.length
    ? sectionsSelected
    : [];
};

export const getSelectedFoodItems = (
  dayIndex: number,
  sections: Section[],
  selectedRows: FoodItemPosition[],
  retentionFactorMap: Map<string, RetentionFactor>
): FoodItems => {
  const foodItems: FoodItemState[] = [];
  for (const section of sections) {
    for (const foodItem of section.foodItems.items) {
      if (
        selectedRows.find((foodItemPosition: FoodItemPosition): boolean =>
          new FoodItemPosition(
            dayIndex,
            section.index,
            foodItem.rowIndex
          ).isEqual(foodItemPosition)
        )
      ) {
        foodItems.push(foodItem.object);
      }
    }
  }
  return new FoodItems(foodItems, retentionFactorMap);
};

interface IngredientRowCopyProps {
  dayIndex: number;
  selectedRows: FoodItemPosition[];
}

interface CopyButtonProps {
  onClick: () => void;
  disabled: boolean;
}

const CopyButton = React.memo<CopyButtonProps>(({ onClick, disabled }) => {
  return (
    <FoodworksTooltip title="Copy selection">
      <IconButton data-cy="copyButton" onClick={onClick} disabled={disabled}>
        <CopyIcon />
      </IconButton>
    </FoodworksTooltip>
  );
});

export const IngredientRowCopy: FunctionComponent<IngredientRowCopyProps> = ({
  dayIndex,
  selectedRows,
}) => {
  const dispatch = useDispatch();

  const onSetClipboard = useCallback(
    (clipboard: ClipboardType) => dispatch(setClipboard(clipboard)),
    [dispatch]
  );

  const onAddAlert = useCallback(
    (message: string) => dispatch(addAlert(message)),
    [dispatch]
  );

  const day: Day = useSelector<RootState, Day>(
    (state: RootState) => daySelector(state, dayIndex)!
  );

  const retentionFactorMap: Map<string, RetentionFactor> = useSelector<
    RootState,
    Map<string, RetentionFactor>
  >(getRetentionFactorMap);

  const onCopyItems = useCallback(() => {
    const sectionIndexesSelected: number[] = checkForOnlySectionsSelected(
      day,
      selectedRows
    );

    if (sectionIndexesSelected.length) {
      const sectionsSelected: SectionState[] = [];

      for (const index of sectionIndexesSelected) {
        sectionsSelected.push(day.sections.items[index].object);
      }

      const sectionsToCopy: Sections = new Sections(
        sectionsSelected,
        retentionFactorMap
      );

      onSetClipboard({
        dataType: ClipboardDataType.SECTIONS,
        data: sectionsToCopy,
      });
    } else {
      const foodItemsToCopy: FoodItems = getSelectedFoodItems(
        dayIndex,
        day.sections.items,
        selectedRows,
        retentionFactorMap
      );

      onSetClipboard({
        dataType: ClipboardDataType.INGREDIENT_ROWS,
        data: foodItemsToCopy,
      });
    }
  }, [day, dayIndex, selectedRows, retentionFactorMap, onSetClipboard]);

  const onClickCopyButton = useCallback(() => {
    onCopyItems();
    onAddAlert("Food items copied successfully!");
  }, [onCopyItems, onAddAlert]);

  return (
    <>
      <CopyButton onClick={onClickCopyButton} disabled={!selectedRows.length} />
    </>
  );
};
