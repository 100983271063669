import { DocumentSummary } from "../../../data/models/userDatabase";
import { QuickFilter } from "../../../constants/QuickFilter";
import { filterIngredients } from "../foods/tabs/ingredients/editing_grid/cells/ingredientUtils";

export interface NavigationSearchFilters {
  searchText: string;
  quickFilters: QuickFilter[];
}

export function SearchDocuments(
  items: DocumentSummary[],
  filter: NavigationSearchFilters,
  searchTermMap: Map<string, string[]>
): DocumentSummary[] {
  return filterIngredients(filter, items, searchTermMap) as DocumentSummary[];
}
