import { RootState } from "../../reducers";
import { DocumentSummary } from "../../../data/models/userDatabase";
import { createSelector } from "reselect";

import { IngredientSummaryItem } from "../../../components/document_view/foods/tabs/ingredients/editing_grid/cells/IngredientCell";
import { FoodId } from "../../../data/models/documentProperties/foodId";

export const databaseIdSelector = (state: RootState): string =>
  state.database.databaseId;

export const databaseNameSelector = (state: RootState): string =>
  state.database.name;

export const userDocumentSummariesSelector = (
  state: RootState
): DocumentSummary[] =>
  state.database.userDocumentSummaries.sort((a, b) => {
    var textA = a.label.toUpperCase();
    var textB = b.label.toUpperCase();
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });

export const userIngredientSummariesSelector = createSelector<
  RootState,
  DocumentSummary[],
  string,
  IngredientSummaryItem[]
>(
  userDocumentSummariesSelector,
  databaseIdSelector,
  (documentSummaries, databaseId) =>
    documentSummaries.map(
      (documentSummary: DocumentSummary): IngredientSummaryItem => {
        return {
          foodId: new FoodId({
            datasourceId: databaseId,
            documentId: documentSummary.documentId,
          }),
          label: documentSummary.label,
          isPublic: false,
          isDeleted: documentSummary.isDeleted,
          templateId: documentSummary.templateId,
        };
      }
    )
);
