import React, { FunctionComponent, useEffect, useState } from "react";

import { makeStyles, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import { addCommonMeasure } from "../../../../../store/data/current-document/action-creators/commonMeasures";
import { Document } from "../../../../../data/models/document";
import { fetchDocument } from "../../../../../data/Firebase/helpers/fetchDocument";
import Firebase, { withFirebase } from "../../../../../data/Firebase";
import { FoodWorksDialog } from "../../../../common/FoodWorksDialog";
import { IngredientSummaryItem } from "../ingredients/editing_grid/cells/IngredientCell";
import { CommonMeasure } from "../../../../../data/models/documentProperties/measure";
import {
  createSearchMap,
  filterIngredients,
  filterOptions,
} from "../ingredients/editing_grid/cells/ingredientUtils";
import { appTheme } from "../../../../../styling/style";
import {
  AutoCompleteCell,
  GridCellType,
} from "../ingredients/editing_grid/cells/AutoCompleteCell";

const useStyles = makeStyles(() => ({
  root: {
    flex: 1,
  },
  autoCompleteWrapper: {
    display: "flex",
    flex: 1,
    background: appTheme.colors.input,
    border: `1px solid ${appTheme.colors.gainsbro}`,
    "&:hover": {
      background: appTheme.colors.inputHover,
    },
    borderRadius: 4,
    height: 30,
    marginLeft: 20,
  },
  listItemText: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    textTransform: "none",
  },
  measureLabel: {
    minWidth: 140,
  },
  autocomplete: {
    display: "flex",
    alignItems: "center",
    minWidth: 100,
  },
}));

export interface MeasuresSearchProps {
  combinedSummaries: IngredientSummaryItem[];
  firebase?: Firebase;
}

const MeasuresSearchInner: FunctionComponent<MeasuresSearchProps> = ({
  combinedSummaries,
  firebase,
}) => {
  const classes = useStyles();

  // *** Actions ***
  const dispatch = useDispatch();

  const onMeasureAdded = (measure: CommonMeasure) =>
    dispatch(
      addCommonMeasure({
        id: uuidv4(),
        name: measure.name,
        value: measure.value,
        description: measure.description,
        usedIn: [],
      })
    );
  // *** ----- ***

  // *** State ***

  const [searchInput, setSearchInput] = useState<string>("");
  const [lastSelectedMeasure, setLastSelectedMeasure] = useState("");
  const [document, setDocument] = useState<Document | null>(null);
  // *** ----- ***

  /**
   * Create preprocessed data for searching
   */
  const [searchTermMap, setSearchTermMap] = useState(
    new Map<string, string[]>()
  );

  const ref: React.RefObject<HTMLInputElement> = React.useRef<HTMLInputElement>(
    null
  );

  useEffect(() => {
    setSearchTermMap(createSearchMap(combinedSummaries));
  }, [combinedSummaries]);

  const summaries = filterIngredients(
    { searchText: searchInput.toLowerCase(), quickFilters: [] },
    combinedSummaries,
    searchTermMap
  ) as IngredientSummaryItem[];

  const handleAdd = () => {
    document?.commonMeasures.measures.forEach(
      (measure: CommonMeasure): void => {
        onMeasureAdded(measure);
      }
    );

    setDocument(null);
  };

  const validDocumentDialog = (
    <FoodWorksDialog
      open={document !== null && document?.commonMeasures.measures.length > 0}
      onClose={() => setDocument(null)}
      title={`Copy measures from ${lastSelectedMeasure}`}
      description="Are you sure you want to add these measures?"
      // Use different help text until Replace measures is implemented
      //   title="How should we handle these measures?"
      //   description="Should we replace your existing measures or add these to your existing
      // measures?"
      options={[
        {
          text: "Cancel",
          onClick: () => setDocument(null),
          color: "default",
        },
        // Hide button for now until we have a way to delete measures and this is implemented
        // { text: "Replace", onClick: handleReplace, color: "secondary" },
        { text: "Add", onClick: handleAdd, color: "secondary" },
      ]}
    />
  );

  const invalidDocumentDialog = (
    <FoodWorksDialog
      open={document !== null && document?.commonMeasures.measures.length === 0}
      onClose={() => setDocument(null)}
      title={`${lastSelectedMeasure} contains no common measures`}
      description="The document you selected does not contain any non-default common
      measures, so we can't add any!"
      options={[
        {
          text: "Ok",
          onClick: () => setDocument(null),
          color: "secondary",
        },
      ]}
    />
  );

  const onListItemClicked = async (newValue: GridCellType): Promise<void> => {
    if (newValue && "foodId" in newValue) {
      setLastSelectedMeasure(newValue.label);
      const document: Document = await fetchDocument(
        firebase!,
        newValue.foodId,
        newValue.isPublic
      );
      setDocument(document);
    }
  };

  const renderItem = (option: GridCellType) => {
    return <Typography>{option.label}</Typography>;
  };

  const autocomplete = (
    <div className={classes.autocomplete}>
      <Typography variant="body1" className={classes.measureLabel}>
        Copy measures from:
      </Typography>
      <div className={classes.autoCompleteWrapper}>
        <AutoCompleteCell
          onFocus={() => {}}
          onBlur={() => setSearchInput("")}
          onInputChange={(event, value, reason) =>
            reason === "input" ? setSearchInput(value) : setSearchInput("")
          }
          ref={ref}
          filterOptions={(options: GridCellType[]) =>
            filterOptions(options, searchInput, searchTermMap)
          }
          useSetSize={false}
          placeholder={"Select food item..."}
          cellSize={150}
          initialInput={searchInput || ""}
          onSelect={onListItemClicked}
          items={summaries}
          renderOption={renderItem}
        />
      </div>
    </div>
  );

  return (
    <div className={classes.root}>
      {autocomplete}
      {validDocumentDialog}
      {invalidDocumentDialog}
    </div>
  );
};

export const MeasuresSearch = withFirebase(MeasuresSearchInner);
