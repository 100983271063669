import React, { FunctionComponent, ReactNode } from "react";

import { Button, makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import { FoodWorksNumberInput } from "../../common/FoodWorksTextInput";
import {
  NutritionPaneState,
  NutritionRadioOption,
} from "../../../store/ui/reducers/nutritionPaneReducers";
import {
  customCompositionAmountSelector,
  compositionOptionSelector,
} from "../../../store/ui/selectors/nutritionPaneSelectors";
import { RootState } from "../../../store/reducers";
import {
  updateCustomAmount,
  updateSelectedAmount,
} from "../../../store/ui/actionCreators/nutritionPaneActionCreators";
import { appTheme } from "../../../styling/style";
import {
  is24HourRecall,
  isFood,
  isFoodRecord,
  isMealPlan,
} from "../../../constants/FoodTemplate";
import {
  isLiquidSelector,
  templateIdSelector,
} from "../../../store/data/current-document/selectors/document";

const useStyles = makeStyles((theme: any) => ({
  root: { flex: 1, display: "flex", justifyContent: "flex-end" },
  customInputTypography: {
    alignSelf: "center",
    margin: "0px 6px",
  },
  customInputDiv: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  radioButton: {
    width: 60,
    padding: 3,
    margin: 2,
  },
  selected: {
    color: appTheme.colors.white[0],
  },
  noTextTransform: { textTransform: "none" },
  input: {
    margin: 0,
    maxLength: 10,
    "&:focus": {
      border: `1px solid ${appTheme.colors.xiketic}`,
      margin: 0,
      background: appTheme.colors.white[0],
    },
  },
}));

export const QuantityRadioGroup: FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const selectedRadioValue = useSelector<
    RootState,
    NutritionPaneState["selectedAmount"]
  >(compositionOptionSelector);

  const customAmount = useSelector<
    RootState,
    NutritionPaneState["customAmount"]
  >(customCompositionAmountSelector);

  const templateId: string = useSelector<RootState, string>(templateIdSelector);

  const isLiquid: boolean = useSelector<RootState, boolean>(isLiquidSelector);

  const onUpdateSelectedAmount = (selectedAmount: string) =>
    dispatch(updateSelectedAmount(selectedAmount));

  const onUpdateCustomAmount = (value: string) =>
    dispatch(updateCustomAmount(Number(value)));

  const customAmountButtonContent = (
    <div className={classes.customInputDiv}>
      <FoodWorksNumberInput
        key={"customInput"}
        data-cy="customInput"
        className={classes.input}
        setValue={onUpdateCustomAmount}
        inputProps={{
          value: customAmount,
        }}
      />
      g
    </div>
  );

  const selectableButton = (option: NutritionRadioOption) => {
    const buttonVariant =
      selectedRadioValue === option.toString() ? "contained" : "outlined";

    return (
      <Button
        key={option}
        className={classes.radioButton}
        variant={buttonVariant}
        color="secondary"
        onClick={() => onUpdateSelectedAmount(option)}
        size="small"
      >
        {option === NutritionRadioOption.CUSTOM
          ? customAmountButtonContent
          : option.toString()}
      </Button>
    );
  };

  const generateRadioOptions = (): ReactNode => {
    const buttons: ReactNode[] = [];

    if (
      isMealPlan(templateId) ||
      isFoodRecord(templateId) ||
      is24HourRecall(templateId)
    ) {
      if (!is24HourRecall(templateId)) {
        buttons.push(selectableButton(NutritionRadioOption.DAY));
      }
      buttons.push(selectableButton(NutritionRadioOption.MEGAJOULE));
    } else {
      buttons.push(selectableButton(NutritionRadioOption.CUSTOM));
      isLiquid
        ? buttons.push(selectableButton(NutritionRadioOption.ONE_HUNDRED_ML))
        : buttons.push(selectableButton(NutritionRadioOption.ONE_HUNDRED_G));

      if (!isFood(templateId)) {
        buttons.push(selectableButton(NutritionRadioOption.PER_SERVE));
      }
    }

    if (!isFood(templateId)) {
      buttons.push(selectableButton(NutritionRadioOption.TOTAL));
    }
    return buttons;
  };

  return (
    <div className={classes.root} data-cy="compositionRadio">
      {generateRadioOptions()}
    </div>
  );
};
