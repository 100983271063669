import React, { FunctionComponent, useState } from "react";

import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import logo from "../../images/favicon.png";
import { SIGN_UP } from "../../constants/routes";
import {
  signInUserWithEmailAndPassword,
  LoginError,
  sendForgotPasswordEmail,
} from "../../store/ui/thunks/login";
import { RootState } from "../../store/reducers";
import { signUpErrorSelector } from "../../store/ui/selectors/login";
import { SignUpErrorTypes } from "./SignUp";

function Copyright() {
  return (
    <Typography color="textPrimary" variant="body1" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://xyris.com.au/">
        Xyris Pty Ltd
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  checkbox: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  logo: {
    height: 60,
    width: 60,
    marginBottom: 10,
  },
  errorText: {
    color: theme.palette.error.main,
    display: "flex",
    alignItems: "center",
  },
  copyrightContainer: {
    display: "flex",
    justifyContent: "center",
  },
}));

const SignInInner: FunctionComponent<RouteComponentProps> = ({ history }) => {
  const dispatch = useDispatch();

  const onSignInUserWithEmailAndPassword = (email: string, password: string) =>
    dispatch(
      signInUserWithEmailAndPassword(email, password, rememberSignIn, history)
    );

  const onSendPasswordReset = (email: string) =>
    dispatch(sendForgotPasswordEmail(email));

  const classes = useStyles();
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [rememberSignIn, setRememberSignIn] = useState(false);

  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [forgotEmail, setForgotEmail] = useState("");
  const [forgotPasswordTooltip, setForgotPasswordTooltip] = useState("");

  const error: LoginError = useSelector<RootState, LoginError>(
    signUpErrorSelector
  );

  const isEmailError = error.type === SignUpErrorTypes.EMAIL;
  const isPasswordError = error.type === SignUpErrorTypes.PASSWORD;

  const signInForm = (
    <form
      className={classes.form}
      onSubmit={(event) => {
        event.preventDefault();
        onSignInUserWithEmailAndPassword(email, password);
      }}
    >
      <Container maxWidth="xs">
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email address"
          name="email"
          autoComplete="email"
          autoFocus
          value={email}
          color="secondary"
          onChange={(event) => setEmail(event.target.value)}
          error={isEmailError}
          helperText={isEmailError && error.message}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          color="secondary"
          autoComplete="current-password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          error={isPasswordError}
          helperText={isPasswordError && error.message}
        />
        <FormControlLabel
          className={classes.checkbox}
          control={
            <Checkbox
              checked={rememberSignIn}
              data-cy="rememberMeCheckBox"
              color="secondary"
              onChange={(event) => setRememberSignIn(!rememberSignIn)}
            />
          }
          label="Remember me"
        />
        {error.type === SignUpErrorTypes.GENERAL ? (
          <div className={classes.errorText}>
            <ErrorOutlineIcon color="error" data-cy="errorIcon" />
            {error.message}
          </div>
        ) : null}
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          className={classes.submit}
          type="submit"
        >
          Sign in
        </Button>
        <Grid container>
          <Grid item xs>
            <Link
              href="#"
              variant="body2"
              color="secondary"
              onClick={() => setShowForgotPassword(true)}
            >
              Forgot password?
            </Link>
          </Grid>
          <Grid item>
            <Link href={SIGN_UP} variant="body2" color="secondary">
              {"Don't have an account? Sign up"}
            </Link>
          </Grid>
        </Grid>
      </Container>
    </form>
  );

  const forgotPasswordForm = (
    <>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email address"
        name="email"
        autoComplete="email"
        autoFocus
        value={forgotEmail}
        color="secondary"
        onChange={(event) => setForgotEmail(event.target.value)}
        error={error.type === SignUpErrorTypes.EMAIL}
        helperText={error.message}
      />
      <Button
        fullWidth
        variant="contained"
        color="secondary"
        className={classes.submit}
        onClick={() => {
          onSendPasswordReset(forgotEmail);
          setForgotPasswordTooltip(
            "Please check your email for a password reset link."
          );
        }}
      >
        Reset password
      </Button>
      <Typography variant="caption">
        {!error.message || error.message.length === 0
          ? forgotPasswordTooltip
          : ""}
      </Typography>
      <Grid container>
        <Grid item xs>
          <Link
            href="#"
            variant="body2"
            color="secondary"
            onClick={() => setShowForgotPassword(false)}
          >
            Back to sign in
          </Link>
        </Grid>
      </Grid>
    </>
  );

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <a href={"http://foodworks.online"}>
          <img src={logo} alt="Logo" className={classes.logo} />
        </a>
        <Typography variant="h3">Sign in to Foodworks.online</Typography>
        {showForgotPassword ? forgotPasswordForm : signInForm}
      </div>

      <Box mt={8} className={classes.copyrightContainer}>
        <Copyright />
      </Box>
    </Container>
  );
};

const SignIn = withRouter(SignInInner);

export default SignIn;
