import {
  DocumentCacheActions,
  ADD_DOCUMENT,
  REMOVE_DOCUMENT,
  UPDATE_DOCUMENT,
} from "../actions/documentCache";
import { Document } from "../../../data/models/document";

export interface DocumentMap {
  [key: string]: Document;
}

export type DocumentCacheState = DocumentMap;

export const initialDocumentCacheState: DocumentCacheState = {};

export const documentCache = (
  state: DocumentCacheState = initialDocumentCacheState,
  action: DocumentCacheActions
): DocumentCacheState => {
  switch (action.type) {
    case ADD_DOCUMENT:
      return state.hasOwnProperty(action.documentMapId)
        ? state
        : { ...state, [action.documentMapId]: action.document };
    case UPDATE_DOCUMENT:
      return { ...state, [action.documentMapId]: { ...action.document } };
    case REMOVE_DOCUMENT: {
      const documentCache = { ...state };
      for (const id of Object.keys(state)) {
        if (id === action.documentMapId) {
          delete documentCache[id];
        }
      }
      return documentCache;
    }

    default:
      return state;
  }
};
