import React, { ReactNode } from "react";

import { Checkbox, createStyles, makeStyles } from "@material-ui/core";
import { IndeterminateCheckBox } from "@material-ui/icons";
import { useDispatch } from "react-redux";

import { Section } from "../../../../../../../data/models/documentProperties/section";
import { CheckboxStatus, useSectionCheckbox } from "./checkboxHooks";
import { FoodItemPosition } from "../../../../../../../data/models/foodItemPosition";
import { setSelectedRows } from "../../../../../../../store/ui/actionCreators/recipeGrid";
import { FoodItem } from "../../../../../../../data/models/documentProperties/foodItem";

const INDETERMINATE_ICON: ReactNode = (
  <IndeterminateCheckBox color="secondary" />
);

const useStyles = makeStyles(() =>
  createStyles({
    checkbox: {
      paddingLeft: 11,
    },
  })
);

interface SectionCheckboxProps {
  dayIndex: number;
  section: Section;
  selectedRows: FoodItemPosition[];
}

export const SectionCheckbox = React.memo<SectionCheckboxProps>(
  ({ dayIndex, section, selectedRows }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const onSetSelectedRows = (rowsToSelect: FoodItemPosition[]) =>
      dispatch(setSelectedRows(rowsToSelect));

    const checkboxStatus: CheckboxStatus = useSectionCheckbox(
      dayIndex,
      section,
      selectedRows
    );

    const onSelectSection = () => {
      let rowsToSelect: FoodItemPosition[] = [];
      if (checkboxStatus.checked && !checkboxStatus.indeterminate) {
        rowsToSelect = selectedRows.filter(
          (position: FoodItemPosition): boolean =>
            position.day !== dayIndex || position.section !== section.index
        );
      } else {
        rowsToSelect = selectedRows
          .filter(
            (position: FoodItemPosition): boolean =>
              position.day !== dayIndex || position.section !== section.index
          )
          .concat(
            section.foodItems.items.map(
              (item: FoodItem): FoodItemPosition =>
                new FoodItemPosition(dayIndex, section.index, item.rowIndex)
            )
          );
      }

      onSetSelectedRows(rowsToSelect);
    };

    return (
      <Checkbox
        className={classes.checkbox}
        data-cy="sectionCheckbox"
        size="small"
        indeterminate={checkboxStatus.indeterminate}
        indeterminateIcon={INDETERMINATE_ICON}
        checked={checkboxStatus.checked}
        disabled={!section.foodItems.length}
        onClick={onSelectSection}
      />
    );
  }
);
