import React, { FunctionComponent, ReactNode, useState } from "react";

import { MenuItem } from "@material-ui/core";
import { batch, useDispatch, useSelector } from "react-redux";

import { RetentionFactorDialog } from "../../../retention-factors/RetentionFactorDialog";
import { pasteFoodItems } from "../../../../../../store/data/current-document/thunks/foodItems";
import {
  ClipboardDataType,
  ClipboardState,
} from "../../../../../../store/reducers/clipboardReducers";
import { RootState } from "../../../../../../store/reducers";
import { CurrentClipboardSelector } from "../../../../../../store/selectors/clipboardSelectors";
import { FoodworksMenu } from "../../../../../common/FoodworksMenu";
import { templateIdSelector } from "../../../../../../store/data/current-document/selectors/document";
import { isRecipe } from "../../../../../../constants/FoodTemplate";
import { setSelectedSectionTags } from "../../../../../../store/ui/actionCreators/nutritionPaneActionCreators";
import { setSelectedRows } from "../../../../../../store/ui/actionCreators/recipeGrid";
import { setClipboard } from "../../../../../../store/action_creators/clipboardActionCreators";
import {
  FoodItem,
  FoodItems,
} from "../../../../../../data/models/documentProperties/foodItem";
import { RetentionFactor } from "../../../../../../data/models/documentProperties/retentionFactor";
import { getRetentionFactorMap } from "../../../../../../store/data/selectors/referenceData";
import { addAlert } from "../../../../../../store/ui/actionCreators/alert";
import { removeFoodItem } from "../../../../../../store/data/current-document/action-creators/foodItems";

export interface IngredientMenuProps {
  dayIndex: number;
  sectionIndex: number;
  rowIndex: number;
  open: boolean;
  parentButtonRef: HTMLElement | null;
  onClose: () => void;
  foodItem: FoodItem | undefined;
}

export const IngredientMenu: FunctionComponent<IngredientMenuProps> = ({
  dayIndex,
  sectionIndex,
  rowIndex,
  open,
  parentButtonRef,
  onClose,
  foodItem,
}) => {
  const dispatch = useDispatch();

  const onResetSelection = () =>
    batch(() => {
      dispatch(setSelectedRows([]));
      dispatch(setSelectedSectionTags([]));
    });

  const onOpenRetentionFactorClick = () => {
    setOpenRetentionFactorDialog(true);
    onClose();
  };

  const onRetentionFactorDialogClose = () => {
    setOpenRetentionFactorDialog(false);
  };

  const onPasteFoodItems = () =>
    batch(() => {
      dispatch(pasteFoodItems(clipboard, dayIndex, sectionIndex, rowIndex));
      onResetSelection();
    });

  const onAddAlert = (message: string) => dispatch(addAlert(message));

  const onSetClipboard = () =>
    dispatch(
      setClipboard({
        dataType: ClipboardDataType.INGREDIENT_ROWS,
        data: new FoodItems([foodItem!.object], retentionFactorMap),
      })
    );

  const onDeleteRow = () =>
    batch(() => {
      dispatch(removeFoodItem(dayIndex, sectionIndex, rowIndex));
      dispatch(setSelectedRows([]));
      dispatch(setSelectedSectionTags([]));
    });

  const retentionFactorMap: Map<string, RetentionFactor> = useSelector<
    RootState,
    Map<string, RetentionFactor>
  >(getRetentionFactorMap);

  const [openRetentionFactorDialog, setOpenRetentionFactorDialog] = useState(
    false
  );

  const clipboard: ClipboardState = useSelector<RootState, ClipboardState>(
    CurrentClipboardSelector
  );

  const documentTemplateId: string = useSelector<RootState, string>(
    templateIdSelector
  );

  const pasteAboveMenuItem: ReactNode = (
    <MenuItem
      key="paste"
      onClick={() => {
        onPasteFoodItems();
        onClose();
      }}
      dense
      disabled={!clipboard.clipboardData}
    >
      Paste above
    </MenuItem>
  );

  const copyRowMenuItem: ReactNode = (
    <MenuItem
      key="copy"
      onClick={() => {
        onSetClipboard();
        onAddAlert("Row copied successfully!");
        onClose();
      }}
      dense
      disabled={!foodItem}
    >
      Copy row
    </MenuItem>
  );

  const deleteRowMenuItem: ReactNode = (
    <MenuItem
      key="delete"
      onClick={() => {
        onDeleteRow();
        onClose();
      }}
      dense
      disabled={!foodItem}
    >
      Delete row
    </MenuItem>
  );

  const menuItems = [];

  if (isRecipe(documentTemplateId)) {
    menuItems.push(
      <MenuItem
        key="retFactor"
        onClick={onOpenRetentionFactorClick}
        disabled={!foodItem}
        dense
      >
        Set retention factor
      </MenuItem>
    );
  }

  menuItems.push(pasteAboveMenuItem);
  menuItems.push(copyRowMenuItem);
  menuItems.push(deleteRowMenuItem);

  return (
    <>
      <FoodworksMenu
        id="ingredientsMenu"
        onBackdropClick={onClose}
        anchorEl={parentButtonRef}
        open={open}
      >
        {menuItems}
      </FoodworksMenu>
      {openRetentionFactorDialog ? (
        <RetentionFactorDialog
          dayIndex={dayIndex}
          sectionIndex={sectionIndex}
          currentFoodItemIndex={rowIndex}
          open={openRetentionFactorDialog}
          onClose={onRetentionFactorDialogClose}
        />
      ) : null}
    </>
  );
};
