import React from "react";

import { batch, useDispatch, useSelector } from "react-redux";

import {
  DialogOption,
  FoodWorksDialog,
} from "../../../../../common/FoodWorksDialog";
import { Day } from "../../../../../../data/models/documentProperties/day";
import { FoodItemPosition } from "../../../../../../data/models/foodItemPosition";
import { deleteSections } from "../../../../../../store/data/current-document/action-creators/sections";
import { daySelector } from "../../../../../../store/data/current-document/selectors/days";
import { templateIdSelector } from "../../../../../../store/data/current-document/selectors/document";
import { deleteSelectedFoodItems } from "../../../../../../store/data/current-document/thunks/foodItems";
import { RootState } from "../../../../../../store/reducers";
import { appTheme } from "../../../../../../styling/style";
import { checkForOnlySectionsSelected } from "./IngredientRowCopy";
import { setSelectedRows } from "../../../../../../store/ui/actionCreators/recipeGrid";
import { setSelectedSectionTags } from "../../../../../../store/ui/actionCreators/nutritionPaneActionCreators";

interface DeleteRowsDialogProps {
  dayIndex: number;
  open: boolean;
  onClose: () => void;
  rowsToRemove: FoodItemPosition[];
}

export const DeleteRowsDialog = React.memo<DeleteRowsDialogProps>(
  ({ dayIndex, open, onClose, rowsToRemove }) => {
    const dispatch = useDispatch();

    const onResetSelection = () =>
      batch(() => {
        dispatch(setSelectedRows([]));
        dispatch(setSelectedSectionTags([]));
      });

    const onDeleteFoodItems = (rowsToRemove: FoodItemPosition[]) =>
      dispatch(deleteSelectedFoodItems(rowsToRemove));

    const onDeleteSection = (sectionsToDelete: number[]) =>
      dispatch(deleteSections(dayIndex, sectionsToDelete, templateId));

    const templateId: string = useSelector<RootState, string>(
      templateIdSelector
    );

    const onConfirmDeleteFoodItems = () => {
      onDeleteFoodItems(rowsToRemove);
      onResetSelection();
      onClose();
    };

    const onConfirmDeleteSections = () => {
      batch(() => {
        onDeleteSection(sectionsToDelete);
        onResetSelection();
      });
      onClose();
    };

    const day: Day = useSelector<RootState, Day>(
      (state: RootState) => daySelector(state, dayIndex)!
    );

    const sectionsToDelete = checkForOnlySectionsSelected(day, rowsToRemove);

    const dialogOptions: DialogOption[] = [
      {
        text: "Cancel",
        onClick: onClose,
        color: appTheme.colors.xiketic,
      },
      {
        text: `Delete (${rowsToRemove.length}) food item(s)`,
        onClick: onConfirmDeleteFoodItems,
        color: appTheme.colors.error,
      },
    ];

    if (sectionsToDelete.length && day.sections.length > 1)
      dialogOptions.push({
        text: `Delete (${sectionsToDelete.length}) section(s)`,

        onClick: onConfirmDeleteSections,
        color: appTheme.colors.error,
      });

    return (
      <FoodWorksDialog
        open={open}
        onClose={onClose}
        title={`Confirm deletion of ${rowsToRemove.length} items`}
        description={"Are you certain you want to continue with your deletion?"}
        options={dialogOptions}
      />
    );
  }
);
