import React, {
  FunctionComponent,
  ReactNode,
  useState,
  useEffect,
  useCallback,
} from "react";

import {
  Typography,
  makeStyles,
  List,
  Dialog,
  DialogContent,
  IconButton,
  Collapse,
  DialogTitle,
  ListItem,
  ListItemAvatar,
  ListItemText,
  DialogActions,
  Card,
  Divider,
  Button,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useSelector, useDispatch } from "react-redux";
import { useAuthState } from "react-firebase-hooks/auth";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { UserDatabaseSummary } from "../../data/models/userDatabase";
import { userDatabaseSummariesSelector } from "../../store/data/selectors/user";
import { RootState } from "../../store/reducers";
import Firebase, { withFirebase } from "../../data/Firebase";
import {
  fetchUserDatabaseSummaries,
  updateUserDatabaseName,
  changeCurrentDatabase,
} from "../../store/data/thunks/database";
import { FoodWorksTextInput } from "../common/FoodWorksTextInput";
import { currentDate } from "../../data/models/documentProperties/date";
import { addUserDatabaseSummary } from "../../store/data/action-creators/user";
import { appTheme } from "../../styling/style";
import {
  databaseIdSelector,
  databaseNameSelector,
} from "../../store/data/selectors/database";
import { DeleteDatabaseDialog } from "./DeleteDatabaseDialog";
import { ReactComponent as DatabaseIcon } from "../../images/icons/Database.svg";
import { DisabledButtonToolTip } from "../common/InfoTooltip";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: "1",
    flexDirection: "column",
    alignItems: "center",
  },
  listContainer: {
    width: 800,
    display: "flex",
    flex: "1",
    flexDirection: "column",
    overflow: "auto",
  },
  list: {
    padding: 0,
    maxHeight: 300,
    flexDirection: "column",
  },
  titleBar: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  databaseActionBar: {
    display: "flex",
    flex: "1",
  },
  databaseName: {
    marginLeft: 5,
    width: 200,
  },
  availableDatabases: {
    display: "flex",
    flex: "1",
    flexDirection: "column",
    paddingBottom: 10,
    paddingLeft: 10,
    paddingRight: 10,
  },
  removeDatabase: {
    color: appTheme.colors.error,
  },
  icon: {
    fill: appTheme.colors.primary,
    height: 24,
    width: 24,
  },
  nameEditing: {
    display: "flex",
    alignItems: "center",
  },
}));

const NEW_DATABASE_NAME = "New database";
const DELAY = 200;

interface DatabaseDialogProps extends RouteComponentProps {
  firebase?: Firebase;
  onClose: () => void;
  open: boolean;
}

export const DatabaseDialogInner: FunctionComponent<DatabaseDialogProps> = ({
  history,
  firebase,
  onClose,
  open,
}) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const onFetchUserDatabaseSummaries = useCallback(
    (uid: string) => dispatch(fetchUserDatabaseSummaries(uid)),
    [dispatch]
  );

  const onChangeCurrentDatabase = (databaseId: string) =>
    dispatch(changeCurrentDatabase(databaseId, history));

  const onAddUserDatabaseSummary = (summary: UserDatabaseSummary) =>
    dispatch(addUserDatabaseSummary(summary));

  const onUpdateDatabaseName = () =>
    dispatch(
      updateUserDatabaseName(
        user!.uid,
        selectedDatabaseId,
        selectedDatabaseName.trim()
      )
    );

  const currentDatabaseId = useSelector<RootState, string>(databaseIdSelector);
  const currentDatabaseName = useSelector<RootState, string>(
    databaseNameSelector
  );

  const [user] = useAuthState(firebase!.auth);
  const [selectedDatabaseName, setSelectedDatabaseName] = useState(
    currentDatabaseName
  );
  const [selectedDatabaseId, setSelectedDatabaseId] = useState(
    currentDatabaseId
  );
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const userDatabaseSummaries: UserDatabaseSummary[] = useSelector<
    RootState,
    UserDatabaseSummary[]
  >(userDatabaseSummariesSelector);

  useEffect(() => {
    if (user) {
      onFetchUserDatabaseSummaries(user!.uid);
    }
  }, [user, onFetchUserDatabaseSummaries]);

  const onSelectDatabase = (databaseId: string, databaseName: string) => {
    setSelectedDatabaseId(databaseId);
    setSelectedDatabaseName(databaseName);
  };

  const isDatabaseNameUnique = (name: string) =>
    !userDatabaseSummaries.find(
      (databaseSummary: UserDatabaseSummary): boolean =>
        databaseSummary.name === name
    );

  const onCreateNewDatabase = async () => {
    const newDate = currentDate();
    let databaseCounter = userDatabaseSummaries.length;
    while (!isDatabaseNameUnique(`${NEW_DATABASE_NAME} ${databaseCounter}`)) {
      ++databaseCounter;
    }
    const newDatabaseName: string = `${NEW_DATABASE_NAME} ${databaseCounter}`;
    const databaseId: string = await firebase!.doCreateUserDatabase(
      user!.uid,
      newDatabaseName,
      { created: newDate, lastModified: newDate }
    );

    onAddUserDatabaseSummary({
      id: databaseId,
      name: newDatabaseName,
      date: { created: newDate, lastModified: newDate },
    });
    onSelectDatabase(databaseId, newDatabaseName);
  };

  const onClickOpen = () => {
    onChangeCurrentDatabase(selectedDatabaseId);
    onClose();
  };

  let timer: NodeJS.Timeout;
  let preventClick = false;

  const handleClick = (databaseId: string, databaseName: string) => {
    timer = setTimeout(function () {
      if (!preventClick) {
        onSelectDatabase(databaseId, databaseName);
      }
      preventClick = false;
    }, DELAY);
  };

  const handleDoubleClick = (databaseId: string) => {
    clearTimeout(timer);
    preventClick = true;
    onChangeCurrentDatabase(databaseId);
    onClose();
  };

  const onSaveChanges = () => onUpdateDatabaseName();

  const header: ReactNode = (
    <div className={classes.titleBar}>
      <DialogTitle data-cy="databaseDialogTitle">Databases</DialogTitle>
      <IconButton onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </div>
  );

  const databaseListItem = (summary: UserDatabaseSummary): ReactNode => (
    <ListItem
      button
      style={{
        background:
          selectedDatabaseId === summary.id
            ? appTheme.colors.oceanBlue[0]
            : appTheme.colors.white[0],
      }}
      onClick={() => handleClick(summary.id, summary.name)}
      onDoubleClick={() => handleDoubleClick(summary.id)}
      key={summary.id}
    >
      <ListItemAvatar>
        <DatabaseIcon className={classes.icon} />
      </ListItemAvatar>
      <ListItemText
        data-cy="databaseName"
        primary={summary.name}
        secondary={`Last modified:
            ${new Date(summary.date.lastModified).toLocaleDateString()}`}
      />
    </ListItem>
  );

  const createAvailableDatabasesList = (): ReactNode =>
    userDatabaseSummaries.map(
      (summary: UserDatabaseSummary): ReactNode => {
        return databaseListItem(summary);
      }
    );

  const availableDatabases: ReactNode = (
    <div className={classes.availableDatabases}>
      <Typography variant="body1">Your available databases:</Typography>
      <Card data-cy="availableDatabases" className={classes.listContainer}>
        <List className={classes.list}>{createAvailableDatabasesList()}</List>
      </Card>
    </div>
  );

  const newDatabaseButton: ReactNode = (
    <Button onClick={onCreateNewDatabase}>Create</Button>
  );

  const removeDatabaseButton: ReactNode = (
    <DisabledButtonToolTip
      buttonProps={{
        className: classes.removeDatabase,
        onClick: () => setOpenDeleteDialog(true),
      }}
      disabled={userDatabaseSummaries.length === 1}
      label="Delete"
      tooltip="You may not delete your only database"
    />
  );

  const openDatabaseButton: ReactNode = (
    <Button data-cy="switchDatabase" color="secondary" onClick={onClickOpen}>
      Open
    </Button>
  );

  const databaseActions: ReactNode = (
    <div className={classes.databaseActionBar}>
      {newDatabaseButton}
      {removeDatabaseButton}
    </div>
  );

  const databaseProperties: ReactNode = (
    <div style={{ padding: 5 }}>
      <div className={classes.nameEditing}>
        <Typography variant="body1"> Name:</Typography>
        <FoodWorksTextInput
          data-cy="databaseNameInput"
          className={classes.databaseName}
          value={selectedDatabaseName}
          onChange={(event) =>
            setSelectedDatabaseName(event.currentTarget.value)
          }
        />
      </div>
      <DisabledButtonToolTip
        disabled={
          !!userDatabaseSummaries.find(
            (summary: UserDatabaseSummary): boolean =>
              summary.name === selectedDatabaseName.trim()
          )
        }
        buttonProps={{ onClick: onSaveChanges }}
        label="Save changes"
        tooltip="Database name must be unique"
      ></DisabledButtonToolTip>
    </div>
  );

  const body: ReactNode = (
    <DialogContent>
      {availableDatabases}
      {databaseActions}
      <Collapse in={!!selectedDatabaseId}>
        <>
          <Divider orientation="horizontal" />
          {databaseProperties}
        </>
      </Collapse>
    </DialogContent>
  );

  const dialogActions: ReactNode = [
    <Button onClick={onClose}>Close</Button>,
    openDatabaseButton,
  ];

  return (
    <>
      <Dialog
        open={open}
        onClose={() => onClose()}
        maxWidth="md"
        fullWidth
        className={classes.root}
      >
        {header}
        {body}
        <DialogActions>{dialogActions}</DialogActions>
      </Dialog>
      <DeleteDatabaseDialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        databaseName={selectedDatabaseName}
        databaseId={selectedDatabaseId}
        firebase={firebase!}
        history={history}
      />
    </>
  );
};

const DatabaseDialog = withFirebase(withRouter(DatabaseDialogInner));

export default DatabaseDialog;
