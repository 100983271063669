import React, { FunctionComponent, ReactNode, useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector, batch } from "react-redux";

import { SectionTag } from "../../../data/models/documentProperties/section";
import { addSectionTag } from "../../../store/data/current-document/action-creators/sections";
import { addSectionTags } from "../../../store/data/current-document/action-creators/sectionTags";
import { sectionTagsSelector } from "../../../store/data/current-document/selectors/sectionTags";
import { RootState } from "../../../store/reducers";
import { appTheme } from "../../../styling/style";
import { DisabledButtonToolTip } from "../../common/InfoTooltip";
import { SectionTagCreation } from "./components/SectionTagCreation";
import { SelectSectionsList } from "./components/SelectSectionsList";

const useStyles = makeStyles((theme) => ({
  titleBar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  closeIcon: {
    width: 40,
    height: 40,
  },
}));

interface CreateSectionTagDialogProps {
  dayIndex: number;
  sectionIndex: number;
  onClose: () => void;
}

export const CreateSectionTagDialog: FunctionComponent<CreateSectionTagDialogProps> = ({
  dayIndex,
  sectionIndex,
  onClose,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [selectedSections, setSelectedSections] = useState<
    { day: number; section: number }[]
  >([{ day: dayIndex, section: sectionIndex }]);

  const documentSectionTags: SectionTag[] = useSelector<
    RootState,
    SectionTag[]
  >(sectionTagsSelector);

  const [tagLabel, setTagLabel] = useState("");
  const [colorIndex, setColorIndex] = useState(0);

  const onCreateNewTag = (tag: SectionTag) => {
    batch(() => {
      dispatch(addSectionTags([tag]));
      for (const section of selectedSections) {
        dispatch(addSectionTag(section.day, section.section, tag.id));
      }
    });
  };

  const onClickCreateTag = () => {
    onCreateNewTag({
      id: uuidv4(),
      label: tagLabel,
      activeColor: appTheme.colors.activeTags[colorIndex],
      inactiveColor: appTheme.colors.inactiveTags[colorIndex],
    });
  };

  const allSectionTagLabels: string[] = documentSectionTags.map(
    (tag: SectionTag): string => tag.label
  );

  const titleBar: ReactNode = (
    <div className={classes.titleBar}>
      <DialogTitle id="create-section-tag-dialog-title">
        Create a new tag
      </DialogTitle>
      <IconButton size="small" className={classes.closeIcon} onClick={onClose}>
        <Close />
      </IconButton>
    </div>
  );

  const dialogActions: ReactNode = [
    <Button onClick={onClose} color="default">
      Cancel
    </Button>,
    <DisabledButtonToolTip
      disabled={!tagLabel || allSectionTagLabels.includes(tagLabel.trim())}
      label="Create tag"
      tooltip="Tag label must be unique"
      buttonProps={{ onClick: onClickCreateTag, color: "secondary" }}
    />,
  ];

  return (
    <Dialog maxWidth="md" open={true} onBackdropClick={onClose}>
      {titleBar}
      <DialogContent>
        <div>
          <SectionTagCreation
            tagLabel={tagLabel}
            colorIndex={colorIndex}
            setTagLabel={setTagLabel}
            setColorIndex={setColorIndex}
          />
          <SelectSectionsList
            selectedSections={selectedSections}
            setSelectedSections={setSelectedSections}
            disabled={false}
          />
        </div>
      </DialogContent>
      <DialogActions>{dialogActions}</DialogActions>
    </Dialog>
  );
};
