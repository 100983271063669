import { IActionsSetDisableSaveButton } from "../actions/documentEditing";

export const setDisableSaveButton = (
  disable: boolean
): IActionsSetDisableSaveButton => {
  return {
    type: "SET_DISABLE_SAVE_BUTTON",
    disable: disable,
  };
};
