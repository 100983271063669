import { Action, combineReducers } from "redux";

import {
  initialNavigationState,
  navigation,
  NavigationState,
} from "./ui/reducers/navigationReducers";
import {
  initialReferenceDataState,
  referenceData,
  ReferenceDataState,
} from "./data/reducers/referenceData";
import {
  initialNutritionPaneState,
  nutritionPane,
  NutritionPaneState,
} from "./ui/reducers/nutritionPaneReducers";
import {
  documentCache,
  DocumentCacheState,
  initialDocumentCacheState,
} from "./data/reducers/documentCache";
import {
  currentDocument,
  CurrentDocumentState,
  initialCurrentDocumentState,
} from "./data/current-document/reducers/currentDocument";
import {
  ErrorState,
  currentError,
  initialErrorState,
} from "./reducers/errorReducers";
import {
  ClipboardState,
  clipboard,
  initialClipboardState,
} from "./reducers/clipboardReducers";
import {
  DatabaseState,
  database,
  initialDatabaseState,
} from "./data/reducers/database";
import { UserState, user, initialUserState } from "./data/reducers/user";
import { LoginState, login, initialLoginState } from "./ui/reducers/login";
import {
  RecipeGridState,
  recipeGrid,
  initialRecipeGridState,
} from "./ui/reducers/recipeGrid";
import {
  OverridesScreenState,
  overridesScreen,
  initialOverridesScreenState,
} from "./ui/reducers/overridesScreen";
import {
  DocumentSavingState,
  documentSaving,
  initialDocumentSavingState,
} from "./ui/reducers/documentSaving";
import {
  DocumentEditingState,
  documentEditing,
  initialDocumentEditingState,
} from "./ui/reducers/documentEditing";
import { AlertState, alert, initialAlertState } from "./ui/reducers/alert";
import {
  CompositionCacheState,
  compositionCache,
  initialCompositionCacheState,
} from "./data/reducers/compositionCache";
import {
  DocumentScreenState,
  documentScreen,
  initialDocumentScreenState,
} from "./ui/reducers/documentScreen";

export type RootState = {
  navigation: NavigationState;
  nutritionPane: NutritionPaneState;
  currentDocument: CurrentDocumentState;
  referenceData: ReferenceDataState;
  database: DatabaseState;
  documentCache: DocumentCacheState;
  compositionCache: CompositionCacheState;
  currentError: ErrorState;
  clipboard: ClipboardState;
  user: UserState;
  login: LoginState;
  recipeGrid: RecipeGridState;
  overridesScreen: OverridesScreenState;
  documentSaving: DocumentSavingState;
  documentEditing: DocumentEditingState;
  documentScreen: DocumentScreenState;
  alert: AlertState;
};

export const initialRootState: RootState = {
  navigation: initialNavigationState,
  nutritionPane: initialNutritionPaneState,
  currentDocument: initialCurrentDocumentState,
  referenceData: initialReferenceDataState,
  database: initialDatabaseState,
  documentCache: initialDocumentCacheState,
  compositionCache: initialCompositionCacheState,
  currentError: initialErrorState,
  clipboard: initialClipboardState,
  user: initialUserState,
  login: initialLoginState,
  recipeGrid: initialRecipeGridState,
  overridesScreen: initialOverridesScreenState,
  documentSaving: initialDocumentSavingState,
  documentEditing: initialDocumentEditingState,
  documentScreen: initialDocumentScreenState,
  alert: initialAlertState,
};

const appReducer = combineReducers({
  navigation,
  nutritionPane,
  currentDocument,
  referenceData,
  database,
  documentCache,
  compositionCache,
  currentError,
  clipboard,
  user,
  login,
  recipeGrid,
  overridesScreen,
  documentSaving,
  documentEditing,
  documentScreen,
  alert,
});

const reducers = (state: any, action: Action) => {
  if (action.type === "USER_LOGOUT") {
    state = undefined;
  }

  return appReducer(state, action);
};

export default reducers;
