import React, { FunctionComponent, ReactNode } from "react";

import {
  List,
  ListItem,
  Collapse,
  makeStyles,
  Button,
  Typography,
  Divider,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

import { appTheme } from "../../styling/style";

const useStyles = makeStyles((theme: any) => ({
  item: {
    paddingBottom: 0,
    paddingTop: 0,
    marginLeft: 5,
  },
  accordionHeader: {
    color: "secondary",
    marginBlockStart: 0,
    marginBlockEnd: 0,
    display: "flex",
    textTransform: "none",
    marginLeft: 5,
  },
  accordionHeaderText: {
    color: appTheme.colors.xiketic,
  },
  accordionHeaderItems: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    display: "flex",
    justifyItems: "start",
    alignItems: "center",
    flex: 1,
  },
}));

export interface AccordionItem {
  id: string;
  item: ReactNode;
}
export interface AccordionProps {
  id?: number;
  header: string;
  items: AccordionItem[];
  open: boolean;
  icon?: ReactNode;
}

export interface AccordionListProps {
  values: AccordionProps[];
  toggledCallback: (id: number) => void;
}

export const AccordionList: FunctionComponent<AccordionListProps> = ({
  values,
  toggledCallback,
}) => {
  const classes = useStyles();

  return (
    <>
      <List disablePadding={true} component="nav">
        {values.map((accordion: AccordionProps) => {
          return (
            <div key={accordion.id}>
              <Button
                data-cy="accordionHeader"
                color="secondary"
                fullWidth={true}
                className={classes.accordionHeader}
                onClick={() => toggledCallback(accordion.id!)}
                startIcon={accordion.icon}
              >
                <div className={classes.accordionHeaderItems}>
                  <Typography variant="h4">{accordion.header}</Typography>

                  {accordion.open ? <ExpandLess /> : <ExpandMore />}
                </div>
              </Button>
              <Divider variant="middle" />

              <Collapse in={accordion.open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {accordion.items.map((item) => (
                    <ListItem className={classes.item} key={item.id}>
                      {item.item}
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            </div>
          );
        })}
      </List>
    </>
  );
};
