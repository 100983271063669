import React, {
  FunctionComponent,
  useState,
  useEffect,
  ReactNode,
} from "react";

import {
  Select,
  MenuItem,
  makeStyles,
  withStyles,
  Theme,
  createStyles,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";

import {
  YieldType,
  Yield,
} from "../../../../../data/models/documentProperties/yield";
import {
  updateDocumentYield,
  updateDocumentServes,
} from "../../../../../store/data/current-document/action-creators/document";
import {
  ServeType,
  Serve,
} from "../../../../../data/models/documentProperties/serve";
import { RootState } from "../../../../../store/reducers";
import {
  servesSelector,
  yieldSelector,
} from "../../../../../store/data/current-document/selectors/document";
import { appTheme } from "../../../../../styling/style";
import {
  FoodWorksTextInput,
  FoodWorksNumberInput,
} from "../../../../common/FoodWorksTextInput";

const useStyles = makeStyles((theme) => ({
  inputGroup: {
    fontSize: appTheme.fontSize.textInput,
    display: "flex",
  },
  select: {
    width: 180,
    height: 30,
  },
  yieldButtonGroup: {
    marginLeft: 30,
  },
  input: {
    borderRadius: "0px 4px 4px 0px",
    margin: 0,
    width: 60,
    "&:focus": {
      borderWidth: "1px",
    },
  },
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
}));

const SelectInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      alignItems: "stretch",
    },
    input: {
      borderRadius: "4px 0px 0px 4px",
      margin: 0,
      "&:focus": {
        backgroundColor: appTheme.colors.white[12],
        border: "1px solid #ced4da",
      },
    },
  })
)(FoodWorksTextInput);

interface SelectOptions {
  type: YieldType | ServeType;
  label: string;
  onClick: () => void;
}

export const YieldAndServeSelection: FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onYieldUpdated = (
    yieldType: YieldType,
    yieldAmount: number | undefined
  ) => dispatch(updateDocumentYield(yieldType, yieldAmount));

  const onServesUpdated = (serveType: ServeType, amount: number) =>
    dispatch(updateDocumentServes(serveType, amount));

  const serveValue: Serve = useSelector<RootState, Serve>(servesSelector);
  const yieldValue: Yield = useSelector<RootState, Yield>(yieldSelector);

  const [yieldInput, setYieldInput] = useState("");
  const [serveInput, setServeInput] = useState("");

  useEffect(() => {
    setYieldInput(yieldValue.amount?.toString() || "");
  }, [yieldValue]);

  useEffect(() => {
    setServeInput(serveValue.value?.toString() || "");
  }, [serveValue]);
  const serveSelectOptions: SelectOptions[] = [
    {
      type: ServeType.PERCENT,
      onClick: () => onServesUpdated(ServeType.PERCENT, serveValue.value!),
      label: "Number of serves",
    },
    {
      type: ServeType.WEIGHT,
      onClick: () => onServesUpdated(ServeType.WEIGHT, serveValue.value!),
      label: "Serve weight (g)",
    },
  ];
  const yieldSelectOptions: SelectOptions[] = [
    {
      type: YieldType.PERCENT,
      onClick: () => onYieldUpdated(YieldType.PERCENT, yieldValue.amount),
      label: "Yield (%)",
    },
    {
      type: YieldType.WEIGHT,
      onClick: () => onYieldUpdated(YieldType.WEIGHT, yieldValue.amount),
      label: "Final weight (g)",
    },
  ];

  function selectAndInputGroup<T>(
    type: T,
    selectOptions: SelectOptions[],
    inputValue: string,
    onInputBlur: (type: T) => void,
    onInputChange: (input: string) => void
  ): ReactNode {
    return (
      <div className={classes.inputGroup}>
        <Select
          className={classes.select}
          labelWidth={20}
          value={type}
          input={<SelectInput />}
        >
          {selectOptions.map(
            (option: SelectOptions): ReactNode => (
              <MenuItem
                onClick={option.onClick}
                value={option.type}
                key={option.type}
              >
                {option.label}
              </MenuItem>
            )
          )}
        </Select>
        <FoodWorksNumberInput
          className={classes.input}
          setValue={onInputChange}
          inputProps={{
            value: inputValue,
            onBlur: (event) => onInputBlur(type),
          }}
        />
      </div>
    );
  }

  const onServeInputBlur = (type: ServeType) =>
    onServesUpdated(
      type,
      Boolean(serveInput) && Number(serveInput) > 0
        ? Number(serveInput)
        : serveValue.value!
    );

  const onYieldInputBlur = (type: YieldType) =>
    onYieldUpdated(type, Boolean(yieldInput) ? Number(yieldInput) : undefined);

  return (
    <div className={classes.root}>
      {selectAndInputGroup<ServeType>(
        serveValue.type,
        serveSelectOptions,
        serveInput,
        onServeInputBlur,
        setServeInput
      )}
      <div className={classes.yieldButtonGroup}>
        {selectAndInputGroup<YieldType>(
          yieldValue.type,
          yieldSelectOptions,
          yieldInput,
          onYieldInputBlur,
          setYieldInput
        )}
      </div>
    </div>
  );
};
