import React, { FunctionComponent } from "react";

import { Checkbox } from "@material-ui/core";
import { IndeterminateCheckBox } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";

import { Day } from "../../../../../data/models/documentProperties/day";
import { FoodItem } from "../../../../../data/models/documentProperties/foodItem";
import { FoodItemPosition } from "../../../../../data/models/foodItemPosition";
import { daySelector } from "../../../../../store/data/current-document/selectors/days";
import { RootState } from "../../../../../store/reducers";
import { setSelectedRows } from "../../../../../store/ui/actionCreators/recipeGrid";
import {
  currentDaySelector,
  selectedRowsSelector,
} from "../../../../../store/ui/selectors/recipeGrid";
import {
  CheckboxStatus,
  useDayCheckbox,
} from "./editing_grid/section/checkboxHooks";

export const DayCheckbox: FunctionComponent = () => {
  const dispatch = useDispatch();

  const onSetSelectedRows = (rowsToSelect: FoodItemPosition[]) =>
    dispatch(setSelectedRows(rowsToSelect));

  const currentDayIndex: number = useSelector<RootState, number>(
    currentDaySelector
  );

  const day: Day = useSelector<RootState, Day | undefined>((state: RootState) =>
    daySelector(state, currentDayIndex)
  )!;

  const selectedRows: FoodItemPosition[] = useSelector<
    RootState,
    FoodItemPosition[]
  >(selectedRowsSelector);

  const checkboxStatus: CheckboxStatus = useDayCheckbox(day, selectedRows);

  const onSelectDay = () => {
    let rowsToSelect: FoodItemPosition[] = [];
    if (checkboxStatus.checked && !checkboxStatus.indeterminate) {
      rowsToSelect = selectedRows.filter(
        (position: FoodItemPosition): boolean =>
          position.day !== currentDayIndex
      );
    } else {
      rowsToSelect = selectedRows.filter(
        (position: FoodItemPosition): boolean =>
          position.day !== currentDayIndex
      );
      for (const section of day.sections.items) {
        rowsToSelect = rowsToSelect.concat(
          section.foodItems.items.map(
            (item: FoodItem): FoodItemPosition =>
              new FoodItemPosition(
                currentDayIndex,
                section.index,
                item.rowIndex
              )
          )
        );
      }
    }

    onSetSelectedRows(rowsToSelect);
  };

  return (
    <Checkbox
      data-cy="sectionCheckbox"
      size="small"
      indeterminate={checkboxStatus.indeterminate}
      indeterminateIcon={<IndeterminateCheckBox color="secondary" />}
      checked={checkboxStatus.checked}
      disabled={!day.foodItemCount}
      onClick={onSelectDay}
    />
  );
};
