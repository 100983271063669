import React from "react";

import { useDispatch, useSelector } from "react-redux";

import {
  DialogOption,
  FoodWorksDialog,
} from "../../../../../../common/FoodWorksDialog";
import { deleteSections } from "../../../../../../../store/data/current-document/action-creators/sections";
import { templateIdSelector } from "../../../../../../../store/data/current-document/selectors/document";
import { RootState } from "../../../../../../../store/reducers";
import { appTheme } from "../../../../../../../styling/style";

interface SectionDeleteDialogProps {
  open: boolean;
  onClose: () => void;
  dayIndex: number;
  sectionIndex: number;
  foodItemsLength: number;
}

export const SectionDeleteDialog = React.memo<SectionDeleteDialogProps>(
  ({ open, onClose, dayIndex, sectionIndex, foodItemsLength }) => {
    const dispatch = useDispatch();

    const documentTemplateId: string = useSelector<RootState, string>(
      templateIdSelector
    );

    const onDelete = () => {
      onClose();
      dispatch(deleteSections(dayIndex, [sectionIndex], documentTemplateId));
    };

    const dialogOptions: DialogOption[] = [
      {
        text: "Cancel",
        onClick: onClose,
        color: appTheme.colors.xiketic,
      },
      {
        text: `Delete`,
        onClick: onDelete,
        color: appTheme.colors.error,
      },
    ];

    return (
      <FoodWorksDialog
        open={open}
        options={dialogOptions}
        onClose={onClose}
        title="Confirm section deletion"
        description={`This section contains ${foodItemsLength} food item(s). Are you certain you want to continue with your deletion?`}
      />
    );
  }
);
