import { Section } from "../../../../data/models/documentProperties/section";
import {
  IActionsAddSection,
  IActionsAddSectionTag,
  IActionsDeleteSections,
  IActionsRemoveSectionTag,
  IActionsUpdateSection,
  IActionsUpdateSectionIsCollapsed,
  IActionsUpdateSectionTitle,
} from "../actions/sections";

export const addSection = (
  dayIndex: number,
  section: Section
): IActionsAddSection => {
  return {
    type: "ADD_SECTION",
    dayIndex: dayIndex,
    section: section,
  };
};

export const updateSection = (
  dayIndex: number,
  section: Section
): IActionsUpdateSection => {
  return {
    type: "UPDATE_SECTION",
    dayIndex: dayIndex,
    section: section,
  };
};

export const updateSectionTitle = (
  dayIndex: number,
  sectionIndex: number,
  title: string
): IActionsUpdateSectionTitle => {
  return {
    type: "UPDATE_SECTION_TITLE",
    dayIndex: dayIndex,
    sectionIndex: sectionIndex,
    title: title,
  };
};

export const deleteSections = (
  dayIndex: number,
  sectionIndexes: number[],
  templateId: string
): IActionsDeleteSections => {
  return {
    type: "DELETE_SECTIONS",
    dayIndex: dayIndex,
    sectionIndexes: sectionIndexes,
    templateId: templateId,
  };
};

export const updateSectionIsCollapsed = (
  dayIndex: number,
  sectionIndex: number,
  isCollapsed: boolean
): IActionsUpdateSectionIsCollapsed => {
  return {
    type: "UPDATE_SECTION_IS_COLLAPSED",
    dayIndex: dayIndex,
    sectionIndex: sectionIndex,
    isCollapsed: isCollapsed,
  };
};

export const addSectionTag = (
  dayIndex: number,
  sectionIndex: number,
  tagId: string
): IActionsAddSectionTag => {
  return {
    type: "ADD_SECTION_TAG",
    dayIndex: dayIndex,
    sectionIndex: sectionIndex,
    tagId: tagId,
  };
};

export const removeSectionTag = (
  dayIndex: number,
  sectionIndex: number,
  tagId: string
): IActionsRemoveSectionTag => {
  return {
    type: "REMOVE_SECTION_TAG",
    dayIndex: dayIndex,
    sectionIndex: sectionIndex,
    tagId: tagId,
  };
};
