import React, { FunctionComponent, ReactElement } from "react";

import {
  Button,
  ButtonProps,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";
import MuiButton from "@material-ui/core/Button";
import InformationIcon from "@material-ui/icons/InfoOutlined";

import { appTheme } from "../../styling/style";

const useStyles = makeStyles(() => ({
  infoIcon: {
    color: appTheme.colors.xiketic,
    alignSelf: "center",
    height: 12,
    width: 12,
    marginRight: 4,
    "&:hover": {
      color: appTheme.colors.help,
    },
  },
}));

export const StyledTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: appTheme.colors.gainsbruh,
    color: appTheme.colors.xiketic,
    maxWidth: 300,
    fontSize: appTheme.fontSize.textInput,
    border: `1px solid ${appTheme.colors.gainsbruh}`,
  },
  tooltipPlacementTop: {
    margin: 0,
  },
  tooltipPlacementBottom: {
    margin: 0,
  },
}))(Tooltip);

type TooltipPlacement =
  | "bottom-end"
  | "bottom-start"
  | "bottom"
  | "left-end"
  | "left-start"
  | "left"
  | "right-end"
  | "right-start"
  | "right"
  | "top-end"
  | "top-start"
  | "top"
  | undefined;

interface FoodworksToolTipProps {
  title: string;
  placement?: TooltipPlacement;
  children: ReactElement;
  className?: string;
  interactive?: boolean;
  enterDelay?: number;
}

export const FoodworksTooltip = React.memo<FoodworksToolTipProps>(
  ({
    title,
    placement = "bottom",
    children,
    className = "",
    interactive,
    enterDelay,
  }) => {
    return (
      <StyledTooltip
        title={title && <Typography variant="caption">{title}</Typography>}
        placement={placement}
        className={className}
        enterDelay={enterDelay}
        interactive={interactive}
      >
        {children}
      </StyledTooltip>
    );
  }
);

interface InfoTooltipProps {
  title: string;
}

export const InfoTooltip: FunctionComponent<InfoTooltipProps> = ({ title }) => {
  const classes = useStyles();

  if (!title) return <span className={classes.infoIcon} />;

  return (
    <FoodworksTooltip className={classes.infoIcon} title={title}>
      <InformationIcon />
    </FoodworksTooltip>
  );
};

interface DisabledButtonToolTipProps {
  disabled: boolean;
  label: string;
  tooltip: string;
  buttonProps: ButtonProps;
}

export const DisabledButtonToolTip: FunctionComponent<DisabledButtonToolTipProps> = ({
  disabled,
  label,
  tooltip,
  buttonProps,
}) => {
  const StyledButton = withStyles({
    root: {
      "&.Mui-disabled": {
        pointerEvents: "auto",
      },
    },
  })(MuiButton);

  const adjustedButtonProps = {
    disabled: disabled,
    component: disabled ? "div" : undefined,
    onClick: disabled ? undefined : buttonProps.onClick,
  };

  return disabled ? (
    <FoodworksTooltip title={tooltip}>
      <StyledButton
        data-cy="deleteDatabaseButton"
        {...buttonProps}
        {...adjustedButtonProps}
      >
        {label}
      </StyledButton>
    </FoodworksTooltip>
  ) : (
    <Button {...adjustedButtonProps} {...buttonProps}>
      {label}
    </Button>
  );
};
