import { Action } from "redux";

import { SectionTag } from "../../../../data/models/documentProperties/section";

export const ADD_SECTION_TAGS = "ADD_SECTION_TAGS";
export const DELETE_SECTION_TAG = "DELETE_SECTION_TAG";
export const UPDATE_SECTION_TAG = "UPDATE_SECTION_TAG";

export interface IActionsAddSectionTags extends Action {
  type: "ADD_SECTION_TAGS";
  tags: SectionTag[];
}

export interface IActionsDeleteSectionTag extends Action {
  type: "DELETE_SECTION_TAG";
  tagId: string;
}

export interface IActionsUpdateSectionTag extends Action {
  type: "UPDATE_SECTION_TAG";
  tag: SectionTag;
}

export type SectionTagsActions =
  | IActionsAddSectionTags
  | IActionsDeleteSectionTag
  | IActionsUpdateSectionTag;
