import { SectionTag } from "../../../../data/models/documentProperties/section";
import {
  IActionsAddSectionTags,
  IActionsDeleteSectionTag,
  IActionsUpdateSectionTag,
} from "../actions/sectionTags";

export const addSectionTags = (tags: SectionTag[]): IActionsAddSectionTags => {
  return {
    type: "ADD_SECTION_TAGS",
    tags: tags,
  };
};

export const deleteSectionTag = (tagId: string): IActionsDeleteSectionTag => {
  return {
    type: "DELETE_SECTION_TAG",
    tagId: tagId,
  };
};

export const updateSectionTag = (tag: SectionTag): IActionsUpdateSectionTag => {
  return {
    type: "UPDATE_SECTION_TAG",
    tag: tag,
  };
};
