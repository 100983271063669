import React, { useState } from "react";

import {
  Popper,
  createStyles,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { batch, useDispatch, useSelector } from "react-redux";

import { RetentionFactor } from "../../../../../../../data/models/documentProperties/retentionFactor";
import { Section } from "../../../../../../../data/models/documentProperties/section";
import { addSectionsFromIndex } from "../../../../../../../store/data/current-document/thunks/sections";
import { getRetentionFactorMap } from "../../../../../../../store/data/selectors/referenceData";
import { RootState } from "../../../../../../../store/reducers";
import { appTheme } from "../../../../../../../styling/style";
import { FoodItemPosition } from "../../../../../../../data/models/foodItemPosition";
import { setSelectedRows } from "../../../../../../../store/ui/actionCreators/recipeGrid";
import { setSelectedSectionTags } from "../../../../../../../store/ui/actionCreators/nutritionPaneActionCreators";

const useStyles = makeStyles(() =>
  createStyles({
    buttonBase: {
      borderRadius: 4,
    },
    popper: {
      margin: 0,
    },
    hoverDiv: {
      marginTop: 8,
      marginLeft: 6,
      marginRight: 6,
      border: "none",
      borderTopWidth: "2px",
      borderTopStyle: "dashed",
      cursor: "pointer",
    },
    plusIcon: {
      backgroundColor: appTheme.colors.success,
      color: "white",
      borderRadius: 4,
    },
  })
);

interface SectionInsertProps {
  dayIndex: number;
  sectionIndex: number;
  selectedRows: FoodItemPosition[];
}

const createNewSection = (
  retentionFactorMap: Map<string, RetentionFactor>,
  nextSectionIndex: number
): Section =>
  Section.fromObject(
    {
      id: "",
      index: nextSectionIndex,
      foodItems: [],
      title: "New section",
      isCollapsed: false,
      tags: [],
    },
    retentionFactorMap
  );

export const SectionInsert = React.memo<SectionInsertProps>(
  ({ dayIndex, sectionIndex, selectedRows }) => {
    const classes = useStyles();

    const dispatch = useDispatch();

    const onResetSelection = () =>
      batch(() => {
        dispatch(setSelectedRows([]));
        dispatch(setSelectedSectionTags([]));
      });

    const onInsertSection = () =>
      batch(() => {
        dispatch(
          addSectionsFromIndex(
            [createNewSection(retentionFactorMap, sectionIndex + 1)],
            dayIndex,
            sectionIndex + 1
          )
        );
        onResetSelection();
      });

    const retentionFactorMap = useSelector<
      RootState,
      Map<string, RetentionFactor>
    >(getRetentionFactorMap);

    const [isHovered, setIsHovered] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const onMouseEnterDivider = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(anchorEl ? null : event.currentTarget);
      setIsHovered(true);
    };

    const onMouseLeaveDivider = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(anchorEl ? null : event.currentTarget);
      setIsHovered(false);
    };

    const onMouseEnterAddPopper = () => setIsHovered(true);

    const onMouseLeaveAddPopper = () => setIsHovered(false);

    return (
      <>
        <div
          data-cy="sectionInsert"
          onMouseEnter={onMouseEnterDivider}
          onMouseLeave={onMouseLeaveDivider}
          onClick={onInsertSection}
          className={classes.hoverDiv}
          style={{
            borderColor: isHovered
              ? appTheme.colors.success
              : appTheme.colors.white[8],
          }}
        />
        <Popper
          open={isHovered}
          anchorEl={anchorEl}
          placement="left"
          onClick={onInsertSection}
          onMouseEnter={onMouseEnterAddPopper}
          onMouseLeave={onMouseLeaveAddPopper}
        >
          <IconButton size="small" className={classes.buttonBase}>
            <AddIcon fontSize="small" className={classes.plusIcon} />
          </IconButton>
        </Popper>
      </>
    );
  }
);
