import React, { FunctionComponent, useState, useEffect } from "react";

import { Button, makeStyles, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Resizable } from "re-resizable";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { useSelector } from "react-redux";

import { NutrientAnalysis } from "./NutrientAnalysis";
import { appTheme } from "../../../styling/style";
import { AccordionProps } from "../../common/AccordionList";
import { HandleAlignment, ResizableHandle } from "../../common/ResizableHandle";
import { RootState } from "../../../store/reducers";
import {
  NutrientValuesSelector,
  templateIdSelector,
} from "../../../store/data/current-document/selectors/document";
import {
  FoodWorksError,
  YieldWeightError,
  CyclicDependencyError,
  MappedDocumentError,
} from "../../../errors";
import { CurrentErrorSelector } from "../../../store/selectors/errorSelectors";
import { NutrientValues } from "../../../data/models/nutrientValue";
import { LoadingBarPadding } from "../../common/LoadingBarPadding";
import { FoodItemPosition } from "../../../data/models/foodItemPosition";
import { selectedRowsSelector } from "../../../store/ui/selectors/recipeGrid";
import { isRecipe, isFood } from "../../../constants/FoodTemplate";
import { CompositionSelectionBar } from "./CompositionSelectionBar";

const useStyles = makeStyles(() => ({
  root: {
    flex: 1,
    background: appTheme.colors.white[8],
    display: "flex",
    flexDirection: "column",
    alignContent: "start",
    overflowX: "auto",
    paddingTop: 8,
    zIndex: 25,
  },
  resizable: {
    display: "flex",
    background: appTheme.colors.white[8],
  },
  nutritionPaneError: {
    marginLeft: 15,
    marginRight: 15,
    flex: 1,
    display: "flex",
    justifyContent: "start",
    maxHeight: 100,
  },
  collapsed: {
    padding: 5,
    background: appTheme.colors.white[8],
    borderLeft: `1px solid ${appTheme.colors.gainsbro}`,
  },
  collapseButton: { alignSelf: "flex-start", minWidth: 24 },
  header: {
    display: "flex",
    justifyContent: "start",
  },
  tooltip: {
    alignSelf: "flex-end",
    margin: 5,
  },
  container: {
    zIndex: 26,
  },
}));

const isNutritionPaneError = (error: FoodWorksError): boolean =>
  error instanceof YieldWeightError ||
  error instanceof CyclicDependencyError ||
  error instanceof MappedDocumentError;

export interface NutritionPanelData {
  values: AccordionProps[];
  toggledCallback?: (id: number) => void;
}

export const NutritionPane: FunctionComponent = () => {
  const classes = useStyles();

  const nutrientValues: NutrientValues = useSelector<RootState, NutrientValues>(
    NutrientValuesSelector
  );

  const selectedRows: FoodItemPosition[] = useSelector<
    RootState,
    FoodItemPosition[]
  >(selectedRowsSelector);

  const documentTemplateId: string = useSelector<RootState, string>(
    templateIdSelector
  );

  const currentError: FoodWorksError = useSelector<RootState, FoodWorksError>(
    CurrentErrorSelector
  );

  const [showNutrientAnalysis, setShowNutrientAnalysis] = useState(true);
  const [open, setOpen] = useState(true);
  const [navResizeHovered, setNavResizeHovered] = useState(false);
  const [isDragged, setIsDragged] = useState(false);

  useEffect(() => {
    isNutritionPaneError(currentError)
      ? setShowNutrientAnalysis(false)
      : setShowNutrientAnalysis(true);
  }, [currentError, nutrientValues]);

  const collapseButton = (
    <Button className={classes.collapseButton} onClick={() => setOpen(!open)}>
      {open ? <NavigateNextIcon /> : <NavigateBeforeIcon />}
    </Button>
  );

  const handleComponent = (
    <ResizableHandle
      isDragged={isDragged}
      isHovered={navResizeHovered}
      onMouseEnter={() => setNavResizeHovered(true)}
      onMouseLeave={() => setNavResizeHovered(false)}
      alignment={HandleAlignment.LEFT}
    />
  );

  const daysContainingSelectedItems = () =>
    selectedRows.reduce<string[]>((days, position) => {
      if (!days.includes(position.day.toString())) {
        days.push(position.day.toString());
      }
      return days;
    }, []).length;

  const selectedItemsText =
    isRecipe(documentTemplateId) || isFood(documentTemplateId)
      ? `Calculation based on ${selectedRows.length} selected item(s).`
      : `Calculation based on ${
          selectedRows.length
        } selected item(s) across ${daysContainingSelectedItems()} day(s)`;

  const resizableArea = (
    <div
      className={classes.root}
      style={{
        borderLeft:
          navResizeHovered || isDragged
            ? `3px solid ${appTheme.colors.oceanBlue[3]}`
            : `1px solid ${appTheme.colors.gainsbro}`,
        paddingLeft: navResizeHovered || isDragged ? 0 : 2,
      }}
    >
      <LoadingBarPadding />
      <div className={classes.header}>
        {collapseButton}
        <CompositionSelectionBar />
      </div>

      {selectedRows.length ? (
        <Typography color="secondary" className={classes.tooltip}>
          {selectedItemsText}
        </Typography>
      ) : null}

      {showNutrientAnalysis ? (
        <NutrientAnalysis nutrientValues={nutrientValues} />
      ) : (
        <Alert className={classes.nutritionPaneError} severity="error">
          Error detected calculating nutritional analysis
        </Alert>
      )}
    </div>
  );

  const resizableSides = {
    top: false,
    right: false,
    bottom: false,
    left: true,
    topRight: false,
    bottomRight: false,
    bottomLeft: false,
    topLeft: false,
  };

  return open ? (
    <Resizable
      className={classes.resizable}
      handleWrapperClass={classes.container}
      minWidth={200}
      maxWidth={450}
      defaultSize={{ width: 450, height: "auto" }}
      handleStyles={{ left: { display: "flex", cursor: "ew-resize" } }}
      onResizeStart={() => setIsDragged(true)}
      onResizeStop={() => {
        setIsDragged(false);
        setNavResizeHovered(false);
      }}
      handleComponent={{
        left: handleComponent,
      }}
      enable={resizableSides}
    >
      {resizableArea}
    </Resizable>
  ) : (
    <div className={classes.collapsed}>
      <LoadingBarPadding />
      {collapseButton}
    </div>
  );
};
