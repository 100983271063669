import { Action } from "redux";

import { YieldType } from "../../../../data/models/documentProperties/yield";
import { ServeType } from "../../../../data/models/documentProperties/serve";
import { VolumeConversionObject } from "../../../../data/models/documentProperties/volumeConversionFactor";
import { DaysActions } from "./days";
import { SectionsActions } from "./sections";
import { FoodItemsActions } from "./foodItems";
import { CommonMeasuresActions } from "./commonMeasures";
import { NutrientOverridesActions } from "./nutrientOverrides";
import { SectionTagsActions } from "./sectionTags";

export const UPDATE_DOCUMENT_NAME = "UPDATE_DOCUMENT_NAME";
export const UPDATE_DOCUMENT_IDENTIFIER = "UPDATE_DOCUMENT_IDENTIFIER";
export const UPDATE_DOCUMENT_ALT_IDENTIFIER = "UPDATE_DOCUMENT_ALT_IDENTIFIER";
export const UPDATE_DOCUMENT_FOOD_GROUP = "UPDATE_DOCUMENT_FOOD_GROUP";
export const UPDATE_DOCUMENT_DESCRIPTION = "UPDATE_DOCUMENT_DESCRIPTION";
export const UPDATE_DOCUMENT_NOTE = "UPDATE_DOCUMENT_NOTE";
export const UPDATE_DOCUMENT_METHOD = "UPDATE_DOCUMENT_METHOD";
export const UPDATE_DOCUMENT_YIELD = "UPDATE_DOCUMENT_YIELD";
export const UPDATE_DOCUMENT_SERVES = "UPDATE_DOCUMENT_SERVES";
export const UPDATE_DOCUMENT_MODIFIED_DATE = "UPDATE_DOCUMENT_MODIFIED_DATE";
export const UPDATE_VOLUME_CONVERSION = "UPDATE_VOLUME_CONVERSION";
export const TOGGLE_PROPERTY_IS_LIQUID = "TOGGLE_PROPERTY_IS_LIQUID";
export const TOGGLE_PROPERTY_IS_DELETED = "TOGGLE_PROPERTY_IS_DELETED";
export const UPDATE_CALCULATION_METHOD = "UPDATE_CALCULATION_METHOD";
export const SET_DOCUMENT_MAPPING_ID = "SET_DOCUMENT_MAPPING_ID";

export interface IActionsUpdateVolumeConversion extends Action {
  type: "UPDATE_VOLUME_CONVERSION";
  volumeConversion: VolumeConversionObject;
}

export interface IActionsUpdateDocumentMethod extends Action {
  type: "UPDATE_DOCUMENT_METHOD";
  method: string;
}
export interface IActionsUpdateDocumentYield extends Action {
  type: "UPDATE_DOCUMENT_YIELD";
  yieldType: YieldType;
  amount: number | undefined;
}

export interface IActionsUpdateDocumentServes extends Action {
  type: "UPDATE_DOCUMENT_SERVES";
  serveType: ServeType;
  amount: number | undefined;
}

export interface IActionsUpdateDocumentName extends Action {
  type: "UPDATE_DOCUMENT_NAME";
  name: string;
}

export interface IActionsUpdateDocumentIdentifier extends Action {
  type: "UPDATE_DOCUMENT_IDENTIFIER";
  identifier: string;
}

export interface IActionsUpdateDocumentAltIdentifier extends Action {
  type: "UPDATE_DOCUMENT_ALT_IDENTIFIER";
  altIdentifier: string;
}

export interface IActionsUpdateDocumentFoodGroup extends Action {
  type: "UPDATE_DOCUMENT_FOOD_GROUP";
  foodGroup: string;
}

export interface IActionsUpdateDocumentDescription extends Action {
  type: "UPDATE_DOCUMENT_DESCRIPTION";
  description: string;
}

export interface IActionsUpdateDocumentModifiedDate extends Action {
  type: "UPDATE_DOCUMENT_MODIFIED_DATE";
  date: string;
}

export interface IActionsToggleIsLiquid extends Action {
  type: "TOGGLE_PROPERTY_IS_LIQUID";
}

export interface IActionsToggleIsDeleted extends Action {
  type: "TOGGLE_PROPERTY_IS_DELETED";
}

export interface IActionsUpdateDocumentNote extends Action {
  type: "UPDATE_DOCUMENT_NOTE";
  note: string;
}

export interface IActionsUpdateCalculationMethod extends Action {
  type: "UPDATE_CALCULATION_METHOD";
  calculationMethod: number;
  mappedDocumentId: string;
}

export interface IActionsSetDocumentMappingId extends Action {
  type: "SET_DOCUMENT_MAPPING_ID";
  documentId: string;
}

export type DocumentActions =
  | IActionsUpdateVolumeConversion
  | IActionsUpdateDocumentName
  | IActionsUpdateDocumentIdentifier
  | IActionsUpdateDocumentAltIdentifier
  | IActionsUpdateDocumentFoodGroup
  | IActionsUpdateDocumentDescription
  | IActionsUpdateDocumentMethod
  | IActionsUpdateDocumentServes
  | IActionsUpdateDocumentYield
  | IActionsToggleIsLiquid
  | IActionsToggleIsDeleted
  | IActionsUpdateDocumentModifiedDate
  | IActionsUpdateDocumentNote
  | IActionsUpdateCalculationMethod
  | IActionsSetDocumentMappingId
  | SectionTagsActions
  | DaysActions
  | SectionsActions
  | FoodItemsActions
  | NutrientOverridesActions
  | CommonMeasuresActions;
