import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import reducers from "./reducers";
import { compositionSaga } from "./data/sagas/composition";
import Firebase from "../data/Firebase";
import {
  changeDocumentSaga,
  fetchingDocumentsSaga,
} from "./data/sagas/document";
import { fetchCompositionSaga } from "./data/sagas/fetchComposition";

const sagaMiddleware = createSagaMiddleware();

export const createOurStore = (firebase: Firebase) => {
  const middleware = [sagaMiddleware, thunk.withExtraArgument(firebase)];

  const devTools =
    process.env.NODE_ENV === "production"
      ? applyMiddleware(...middleware)
      : composeWithDevTools(applyMiddleware(...middleware));

  const store = createStore(reducers, devTools);

  sagaMiddleware.run(compositionSaga);
  sagaMiddleware.run(changeDocumentSaga, firebase);
  sagaMiddleware.run(fetchingDocumentsSaga, firebase);
  sagaMiddleware.run(fetchCompositionSaga);

  return store;
};
