import { ThunkAction } from "redux-thunk";
import { Action } from "redux";
import { batch } from "react-redux";

import Firebase from "../../../data/Firebase";
import {
  IActionsSetDatabaseName,
  IActionsSetUserDocumentSummaries,
} from "../actions/database";
import {
  setDatabaseName,
  setUserDocumentSummaries,
} from "../action-creators/database";
import { databaseIdSelector } from "../selectors/database";
import { setNavIsLoading } from "../../ui/actionCreators/navigationActionCreators";
import {
  Database,
  DatabaseProperties,
  DocumentSummary,
  UserDatabaseSummary,
} from "../../../data/models/userDatabase";
import { fetchNavigationSummaries } from "../../../data/Firebase/helpers/fetchNavigationSummaries";
import { IActionSetNavIsLoading } from "../../ui/actions/navigationActions";
import {
  IActionsSetUserDatabaseSummaries,
  IActionsRemoveUserDatabaseSummary,
} from "../actions/user";
import { createDocument } from "../current-document/thunks/currentDocument";
import { RECIPES } from "../../../constants/FoodTemplate";
import { Document, documentsAreEqual } from "../../../data/models/document";
import {
  ServerDocumentSelector,
  CurrentDocumentIdSelector,
} from "../current-document/selectors/currentDocument";
import { documentSelector } from "../current-document/selectors/document";
import {
  setUnsavedDocumentChanges,
  setDocumentIdToChangeTo,
} from "../../ui/actionCreators/documentSaving";
import {
  IActionsSetUnsavedDocumentChanges,
  IActionsSetDocumentIdToChangeTo,
} from "../../ui/actions/documentSaving";
import {
  setUserDatabaseSummaries,
  removeUserDatabaseSummary,
} from "../action-creators/user";
import { userDatabaseSummariesSelector } from "../selectors/user";
import { HOME } from "../../../constants/routes";
import { RouterHistory } from "../../ui/thunks/login";
import { RootState } from "../../reducers";
import { setDisplayedNutrients } from "../action-creators/databaseProperties";
import { IActionsSetDisplayedNutrients } from "../actions/databaseProperties";

export const fetchSummaryData = (): ThunkAction<
  void,
  RootState,
  Firebase,
  Action<any>
> => (dispatch) => {
  batch(() => {
    dispatch(fetchUserDocumentSummaries());
  });
};

export const setDatabaseProperties = (): ThunkAction<
  Promise<void>,
  RootState,
  Firebase,
  IActionsSetDisplayedNutrients
> => async (dispatch, getState, firebase) => {
  const currentDatabaseId: string = databaseIdSelector(getState());

  const database:
    | Database
    | undefined = await firebase.doGetUserDatabaseDocument(currentDatabaseId);
  if (!database) return;

  const databaseProperties: DatabaseProperties = database.properties;

  dispatch(
    setDisplayedNutrients(
      databaseProperties ? databaseProperties.displayedNutrients : []
    )
  );
};

export const fetchUserDocumentSummaries = (): ThunkAction<
  Promise<void>,
  RootState,
  Firebase,
  | IActionsSetUserDocumentSummaries
  | IActionSetNavIsLoading
  | IActionsSetDocumentIdToChangeTo
> => async (dispatch, getState, firebase) => {
  const databaseId: string = databaseIdSelector(getState());
  const userDocumentSummaries: DocumentSummary[] = await fetchNavigationSummaries(
    firebase,
    [databaseId]
  );

  dispatch(setUserDocumentSummaries(userDocumentSummaries));

  // This will take the first summary returned and set it as our selected document
  const documentId =
    userDocumentSummaries.filter(
      (summary: DocumentSummary): boolean => !summary.isDeleted
    )[0]?.documentId || "";

  if (documentId) {
    dispatch(setDocumentIdToChangeTo(documentId));
  } else {
    dispatch(createDocument(RECIPES));
  }

  dispatch(setNavIsLoading(false));
};

export const fetchUserDatabaseSummaries = (
  uid: string
): ThunkAction<
  Promise<void>,
  RootState,
  Firebase,
  IActionsSetUserDatabaseSummaries
> => async (dispatch, getState, firebase) => {
  const onSetUserDatabaseSummaries = (summaries: UserDatabaseSummary[]) =>
    dispatch(setUserDatabaseSummaries(summaries));

  const databaseSummaries: UserDatabaseSummary[] = await firebase.doGetUserDatabaseSummaries(
    uid
  );
  onSetUserDatabaseSummaries(databaseSummaries);
};

export const updateUserDatabaseName = (
  uid: string,
  databaseId: string,
  databaseName: string
): ThunkAction<
  Promise<void>,
  RootState,
  Firebase,
  IActionsSetUserDatabaseSummaries | IActionsSetDatabaseName
> => async (dispatch, getState, firebase) => {
  const onSetUserDatabaseSummaries = (summaries: UserDatabaseSummary[]) =>
    dispatch(setUserDatabaseSummaries(summaries));

  await firebase?.doUpdateUserDatabaseName(uid, databaseId, databaseName);

  const currentDatabaseId: string = databaseIdSelector(getState());
  if (currentDatabaseId === databaseId) {
    dispatch(setDatabaseName(databaseName));
  }

  //TODO: update locally instead
  const databaseSummaries: UserDatabaseSummary[] = await firebase.doGetUserDatabaseSummaries(
    uid
  );
  onSetUserDatabaseSummaries(databaseSummaries);
};

export const changeCurrentDatabase = (
  databaseId: string,
  history: RouterHistory
): ThunkAction<
  Promise<void>,
  RootState,
  Firebase,
  IActionsSetUnsavedDocumentChanges
> => async (dispatch, getState) => {
  const currentDocument: Document = documentSelector(getState());

  const currentDocumentId: string = CurrentDocumentIdSelector(getState());

  const serverDocument: Document = ServerDocumentSelector(getState())!;

  if (!documentsAreEqual(currentDocument, serverDocument)) {
    dispatch(setUnsavedDocumentChanges(true, currentDocumentId, databaseId));
  } else {
    history.replace(`${HOME}/${databaseId}`);
  }
};

export const deleteDatabase = (
  uid: string,
  databaseId: string,
  history: RouterHistory
): ThunkAction<
  Promise<void>,
  RootState,
  Firebase,
  IActionsRemoveUserDatabaseSummary
> => async (dispatch, getState, firebase) => {
  const currentDatabaseId: string = databaseIdSelector(getState());

  const databaseSummaries: UserDatabaseSummary[] = userDatabaseSummariesSelector(
    getState()
  );

  await firebase.doDeleteUserDatabaseSummary(
    uid,
    databaseSummaries.find(
      (summary: UserDatabaseSummary): boolean => summary.id === databaseId
    )!
  );

  if (databaseId === currentDatabaseId) {
    const newDatabaseId: string = databaseSummaries.filter(
      (summary: UserDatabaseSummary): boolean => summary.id !== databaseId
    )[0].id;
    await firebase.doUpdateUserLastUsedDatabase(uid, newDatabaseId);
    history.replace(`${HOME}/${newDatabaseId}`);
  }
  dispatch(removeUserDatabaseSummary(databaseId));
};
