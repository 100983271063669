import React from "react";

import { makeStyles, createStyles } from "@material-ui/core";
import { useDispatch } from "react-redux";

import { EditableTitleInput } from "../../../../../../common/EditableTitleInput";
import { updateSectionTitle } from "../../../../../../../store/data/current-document/action-creators/sections";
import { appTheme } from "../../../../../../../styling/style";

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      paddingLeft: 5,
      fontSize: appTheme.fontSize.textInput,
      backgroundColor: appTheme.colors.white[10],
      border: `2px solid ${appTheme.colors.white[10]}`,
      "&:hover": {
        backgroundColor: appTheme.colors.white[4],
        paddingLeft: 5,
      },
      "&:focus": {
        backgroundColor: appTheme.colors.white[2],
        paddingLeft: 5,
      },
    },
  })
);

interface SectionTitleProps {
  dayIndex: number;
  sectionIndex: number;
  title: string;
}

export const SectionTitle = React.memo<SectionTitleProps>(
  ({ dayIndex, sectionIndex, title }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const onUpdateSectionTitle = (
      event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) =>
      dispatch(
        updateSectionTitle(dayIndex, sectionIndex, event.currentTarget.value)
      );

    return (
      <EditableTitleInput
        data-cy="sectionTitle"
        classes={{ input: classes.title }}
        value={title}
        onChange={onUpdateSectionTitle}
      />
    );
  }
);
