import { Action } from "redux";
import { QuickFilter } from "../../../constants/QuickFilter";

export const SET_NAV_SEARCH_TEXT = "SET_NAV_SEARCH_TEXT";
export const SET_NAV_SEARCH_FILTERS = "SET_NAV_SEARCH_FILTERS";
export const TOGGLE_QUICK_FILTERS = "TOGGLE_QUICK_FILTERS";
export const TAPPED_NAV_ACCORDION = "TAPPED_NAV_ACCORDION";
export const SET_NAV_IS_LOADING = "SET_NAV_IS_LOADING";

export interface IActionsSetNavSearchText extends Action {
  type: "SET_NAV_SEARCH_TEXT";
  text: string;
}

export interface IActionSetQuickFilters extends Action {
  type: "SET_NAV_SEARCH_FILTERS";
  filters: QuickFilter[];
}

export interface IActionsToggleQuickFilters extends Action {
  type: "TOGGLE_QUICK_FILTERS";
}

export interface IActionTappedNavAccordion extends Action {
  type: "TAPPED_NAV_ACCORDION";
  templateId: number;
}

export interface IActionSetNavIsLoading extends Action {
  type: "SET_NAV_IS_LOADING";
  isLoading: boolean;
}

export type NavigationActions =
  | IActionsSetNavSearchText
  | IActionSetQuickFilters
  | IActionsToggleQuickFilters
  | IActionTappedNavAccordion
  | IActionSetNavIsLoading;
