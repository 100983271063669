import React from "react";

import { Button, makeStyles, Typography } from "@material-ui/core";

import { DocumentSummary } from "../../../data/models/userDatabase";
import { appTheme } from "../../../styling/style";

const useStyles = makeStyles(() => ({
  item: {
    justifyContent: "start",
    textTransform: "none",
  },
  selected: {
    backgroundColor: appTheme.colors.oceanBlue[0],
  },
  buttonText: {
    marginBlockStart: 0,
    marginBlockEnd: 0,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  selectedText: {
    fontWeight: 600,
    color: appTheme.colors.primary,
  },
  dayList: {
    paddingTop: 0,
    paddingLeft: 5,
    maxHeight: 200,
    overflow: "auto",
  },
  expandingRoot: {
    display: "flex",
    flexDirection: "column",
  },
}));

interface DocumentItemProps {
  currentDocumentId: string;
  documentSummary: DocumentSummary;
  onDocumentClick: (documentId: string) => void;
}

export const DocumentItem = React.memo<DocumentItemProps>(
  ({ currentDocumentId, documentSummary, onDocumentClick }) => {
    const classes = useStyles();

    return (
      <Button
        className={
          documentSummary.documentId === currentDocumentId
            ? `${classes.item} ${classes.selected}`
            : classes.item
        }
        fullWidth={true}
        size="small"
        onClick={() => onDocumentClick(documentSummary.documentId)}
      >
        <Typography variant="body1">
          <span
            className={
              documentSummary.documentId === currentDocumentId
                ? `${classes.buttonText} ${classes.selectedText}`
                : classes.buttonText
            }
          >
            {documentSummary.label}
          </span>
        </Typography>
      </Button>
    );
  }
);
