import React, { FunctionComponent, ReactNode } from "react";

import { Collapse } from "@material-ui/core";

import { IngredientRow } from "../IngredientRow";
import { InlineInsert } from "../InlineInsert";
import { IngredientSummaryItem } from "../cells/IngredientCell";
import { FoodItem } from "../../../../../../../data/models/documentProperties/foodItem";

interface IngredientListProps {
  open: boolean;
  dayIndex: number;
  sectionIndex: number;
  foodItems: FoodItem[];
  focusedCell: string;
  setFocusedCell: (cell: string) => void;
  summaries: IngredientSummaryItem[];
  searchTermMap: Map<string, string[]>;
  isLastSection: boolean;
}

const propsAreEqual = (
  prev: IngredientListProps,
  current: IngredientListProps
): boolean =>
  prev.dayIndex === current.dayIndex &&
  prev.sectionIndex === current.sectionIndex &&
  JSON.stringify(prev.foodItems) === JSON.stringify(current.foodItems) &&
  prev.open === current.open &&
  prev.focusedCell === current.focusedCell &&
  prev.isLastSection === current.isLastSection;

const IngredientListInner: FunctionComponent<IngredientListProps> = ({
  open,
  dayIndex,
  sectionIndex,
  foodItems,
  focusedCell,
  setFocusedCell,
  summaries,
  searchTermMap,
  isLastSection,
}) => {
  const rowCount: number = foodItems.length;

  const rows: ReactNode[] = [];

  for (let i = 0; i < rowCount + 1; i++) {
    rows.push(
      <IngredientRow
        key={i}
        dayIndex={dayIndex}
        sectionIndex={sectionIndex}
        rowIndex={i}
        focusedCell={focusedCell}
        setFocusedCell={setFocusedCell}
        summaries={summaries}
        isLastRow={i === rowCount}
        searchTermMap={searchTermMap}
        isLastSection={isLastSection}
      />
    );

    if (i !== rowCount) {
      rows.push(
        <InlineInsert
          key={`insert-${i}`}
          addAfterRowIndex={i}
          dayIndex={dayIndex}
          sectionIndex={sectionIndex}
        />
      );
    }
  }

  return <Collapse in={open}>{rows}</Collapse>;
};

export const IngredientList = React.memo(IngredientListInner, propsAreEqual);
