import React, {
  FunctionComponent,
  useEffect,
  useState,
  useCallback,
} from "react";

import { useDispatch, useSelector } from "react-redux";
import { withRouter, RouteComponentProps, Redirect } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { makeStyles } from "@material-ui/core";

import NavigationPane from "./navigation/NavigationPane";
import { FoodScreen } from "./foods/FoodScreen";
import FoodworksAppBar from "./FoodworksAppBar";
import Firebase, { withFirebase } from "../../data/Firebase";
import { RootState } from "../../store/reducers";
import { IngredientSummaryItem } from "./foods/tabs/ingredients/editing_grid/cells/IngredientCell";
import { fetchPublicIngredientSummaries } from "../../data/Firebase/helpers/fetchIngredientSummaries";
import { FoodWorksError } from "../../errors";
import { CurrentErrorSelector } from "../../store/selectors/errorSelectors";
import { initialErrorState } from "../../store/reducers/errorReducers";
import { ErrorPopup } from "../common/ErrorPopup";
import { fetchReferenceData } from "../../store/data/thunks/referenceData";
import { SIGN_UP, HOME } from "../../constants/routes";
import {
  fetchUserDocumentSummaries,
  fetchUserDatabaseSummaries,
  setDatabaseProperties,
} from "../../store/data/thunks/database";
import { SaveDocumentDialog } from "../common/SaveDocumentDialog";
import { DatabasePropertiesState } from "../../store/data/reducers/databaseProperties";
import { enabledDatasourcesSelector } from "../../store/data/selectors/databaseProperties";
import { databaseIdSelector } from "../../store/data/selectors/database";
import {
  setDatabaseId,
  setDatabaseName,
} from "../../store/data/action-creators/database";
import { UserDatabaseSummary } from "../../data/models/userDatabase";
import { userDatabaseSummariesSelector } from "../../store/data/selectors/user";
import { NutritionPane } from "./nutrition/NutritionPane";
import { LoadingBar } from "./LoadingBar";
import { SuccessAlert } from "../common/SuccessAlert";

export interface HomeProps {
  firebase?: Firebase;
}

const useStyles = makeStyles(() => ({
  root: { display: "flex", flex: 1, flexDirection: "column", width: "100%" },
  content: {
    display: "flex",
    flex: 1,
    overflow: "hidden",
  },
  navPane: {
    display: "flex",
  },
  documentScreen: {
    display: "flex",
    flex: 1,
    overflow: "auto",
  },
  loadingBar: {
    height: LOADING_BAR_HEIGHT,
  },
}));

export const LOADING_BAR_HEIGHT = 3;

const initialSummaries: IngredientSummaryItem[] = [];

interface MatchProps {
  id: string;
}

const HomeInner: FunctionComponent<
  HomeProps & RouteComponentProps<MatchProps>
> = ({ history, match, firebase }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [user, loading] = useAuthState(firebase!.auth);
  const [publicSummaries, setPublicSummaries] = useState(initialSummaries);
  const [showAlert, setShowAlert] = useState(false);
  const [errorToShow, setErrorToShow] = useState(initialErrorState);

  const currentError: FoodWorksError = useSelector<RootState, FoodWorksError>(
    CurrentErrorSelector
  );

  const currentDatabaseId: string = useSelector<RootState, string>(
    databaseIdSelector
  );

  const enabledDatasources: string[] = useSelector<
    RootState,
    DatabasePropertiesState["enabledDatasources"]
  >(enabledDatasourcesSelector);

  const databaseSummaries: UserDatabaseSummary[] = useSelector<
    RootState,
    UserDatabaseSummary[]
  >(userDatabaseSummariesSelector);

  const onSetReferenceData = useCallback(() => dispatch(fetchReferenceData()), [
    dispatch,
  ]);

  const onSetDatabaseId = useCallback(
    (databaseId: string) => dispatch(setDatabaseId(databaseId)),
    [dispatch]
  );

  const onSetDatabaseName = useCallback(
    (name: string) => dispatch(setDatabaseName(name)),
    [dispatch]
  );

  const onSetDatabaseFromUrl = useCallback(
    (uid: string, databaseId: string) => {
      const databaseName: string = databaseSummaries.find(
        (databaseSummary: UserDatabaseSummary): boolean =>
          databaseSummary.id === databaseId
      )!.name;

      onSetDatabaseId(databaseId);
      onSetDatabaseName(databaseName);
      firebase?.doUpdateUserLastUsedDatabase(uid, databaseId);
    },
    [firebase, databaseSummaries, onSetDatabaseId, onSetDatabaseName]
  );

  const onFetchUserDocumentSummaries = useCallback(
    () => dispatch(fetchUserDocumentSummaries()),
    [dispatch]
  );

  const onFetchUserDatabaseSummaries = useCallback(
    (uid: string) => dispatch(fetchUserDatabaseSummaries(uid)),
    [dispatch]
  );

  const onSetDatabaseProperties = useCallback(() => {
    dispatch(setDatabaseProperties());
  }, [dispatch]);

  useEffect(() => {
    onSetReferenceData();
  }, [onSetReferenceData]);

  useEffect(() => {
    if (user) {
      onFetchUserDatabaseSummaries(user!.uid);
    }
  }, [user, onFetchUserDatabaseSummaries]);

  useEffect(() => {
    const setCurrentDatabase = async () => {
      if (user) {
        const urlId: string = match.params.id;
        if (!urlId) {
          const databaseId = await firebase?.doGetUserLastUsedDatabase(
            user!.uid
          );
          history.push(`${HOME}/${databaseId}`);
          return;
        }
        if (databaseSummaries.length) {
          if (!currentDatabaseId || currentDatabaseId !== urlId) {
            onSetDatabaseFromUrl(user!.uid, urlId);
          }
        }
      }
    };

    setCurrentDatabase();
  }, [
    user,
    currentDatabaseId,
    databaseSummaries,
    onFetchUserDocumentSummaries,
    onSetDatabaseFromUrl,
    match,
    history,
    firebase,
  ]);

  useEffect(() => {
    if (user && currentDatabaseId) {
      onFetchUserDocumentSummaries();
      onSetDatabaseProperties();
    }
  }, [
    user,
    currentDatabaseId,
    onFetchUserDocumentSummaries,
    onSetDatabaseProperties,
  ]);

  useEffect(() => {
    if (currentError.message) {
      setShowAlert(true);
      setErrorToShow(currentError);
    }
  }, [currentError]);

  useEffect(() => {
    fetchPublicIngredientSummaries(firebase!, enabledDatasources).then(
      setPublicSummaries
    );
  }, [enabledDatasources, firebase]);

  if (loading) return null;
  if (!user) return <Redirect to={SIGN_UP} />;

  const handleErrorSnackbarClose = (reason: string) => {
    if (reason === "timeout") {
      setShowAlert(false);
    }
  };

  const handleErrorAlertClose = () => {
    setShowAlert(false);
  };

  return (
    <div className={classes.root}>
      <FoodworksAppBar />
      <LoadingBar />

      <div className={classes.content}>
        <NavigationPane />
        <div data-cy="recipeScreen" className={classes.documentScreen}>
          <FoodScreen publicSummaries={publicSummaries} />
        </div>
        <NutritionPane />
      </div>
      <SaveDocumentDialog uid={user!.uid} history={history} />
      <ErrorPopup
        showError={showAlert}
        onAlertClose={handleErrorAlertClose}
        onSnackBarClose={handleErrorSnackbarClose}
        error={errorToShow}
      />
      <SuccessAlert />
    </div>
  );
};

export const Home = withRouter(withFirebase(HomeInner));
