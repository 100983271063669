import { Action } from "redux";

import { Day } from "../../../../data/models/documentProperties/day";
import { SectionsActions } from "./sections";

export const ADD_DAY = "ADD_DAY";
export const SET_DAYS = "SET_DAYS";
export const UPDATE_DAY_DATE = "UPDATE_DAY_DATE";
export const UPDATE_DAY_TITLE = "UPDATE_DAY_TITLE";

export interface IActionsAddDay extends Action {
  type: "ADD_DAY";
  day: Day;
}

export interface IActionsSetDays extends Action {
  type: "SET_DAYS";
  days: Day[];
}

export interface IActionsUpdateDayDate extends Action {
  type: "UPDATE_DAY_DATE";
  dayIndex: number;
  date: string;
}

export interface IActionsUpdateDayTitle extends Action {
  type: "UPDATE_DAY_TITLE";
  dayIndex: number;
  title: string;
}
export type DaysActions =
  | IActionsAddDay
  | IActionsSetDays
  | IActionsUpdateDayDate
  | IActionsUpdateDayTitle
  | SectionsActions;
