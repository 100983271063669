import { createSelector } from "reselect";

import { NutrientOverride } from "../../../../data/models/documentProperties/nutrientOverride";
import { RootState } from "../../../reducers";
import { NutrientsDataSelector } from "../../selectors/referenceData";
import { WEIGHT_ID, Nutrient } from "../../../../data/models/nutrient";
import {
  BaseCompositionSelector,
  FinalCompositionSelector,
} from "./currentDocument";
import { DocumentState } from "../reducers/document";
import { NutrientOverridesSelector } from "./nutrientOverrides";
import { VolumeConversionFactor } from "../../../../data/models/documentProperties/volumeConversionFactor";
import { Document } from "../../../../data/models/document";
import {
  NutrientValue,
  NutrientValues,
} from "../../../../data/models/nutrientValue";
import { CompositionState } from "../../../../data/models/composition";

const DEFAULT_WEIGHT_FACTOR = 1.0;

export const documentSelector = (state: RootState): Document =>
  state.currentDocument.document;

export const nameSelector = (state: RootState): DocumentState["name"] =>
  state.currentDocument.document.name;

export const templateIdSelector = (
  state: RootState
): DocumentState["templateId"] => state.currentDocument.document.templateId;

export const methodSelector = (state: RootState): string =>
  state.currentDocument.document.method;

export const servesSelector = (state: RootState): DocumentState["serve"] =>
  state.currentDocument.document.serve;

export const yieldSelector = (state: RootState): DocumentState["yield"] =>
  state.currentDocument.document.yield;

export const documentPropertiesSelector = (
  state: RootState
): DocumentState["properties"] => state.currentDocument.document.properties;

export const isLiquidSelector = (state: RootState): boolean =>
  state.currentDocument.document.properties.isLiquid;

export const volumeConversionSelector = (
  state: RootState
): VolumeConversionFactor =>
  new VolumeConversionFactor(state.currentDocument.document.volumeConversion);

export const NutrientValuesSelector = createSelector<
  RootState,
  Map<string, Nutrient>,
  CompositionState,
  CompositionState,
  NutrientOverride[],
  NutrientValues
>(
  NutrientsDataSelector,
  BaseCompositionSelector,
  FinalCompositionSelector,
  NutrientOverridesSelector,

  (nutrients, baseComposition, finalComposition, nutrientOverrides) => {
    return NutrientValues.fromState(
      DEFAULT_WEIGHT_FACTOR,
      nutrients,
      baseComposition,
      finalComposition,
      nutrientOverrides
    );
  }
);

export const OverridesNutrientValuesSelector = createSelector<
  RootState,
  Map<string, Nutrient>,
  CompositionState,
  CompositionState,
  NutrientOverride[],
  NutrientValues
>(
  NutrientsDataSelector,
  BaseCompositionSelector,
  FinalCompositionSelector,
  NutrientOverridesSelector,

  (nutrients, baseComposition, finalComposition, nutrientOverrides) => {
    const weightOverrideValue: number = nutrientOverrides.find(
      (override: NutrientOverride): boolean => override.id === WEIGHT_ID
    )?.value!;
    const weightValue: number =
      baseComposition[WEIGHT_ID] || weightOverrideValue;
    let weightFactor = weightOverrideValue / weightValue;
    if (isNaN(weightFactor)) {
      weightFactor = 0;
    }

    return NutrientValues.fromState(
      weightFactor,
      nutrients,
      baseComposition,
      finalComposition,
      nutrientOverrides
    );
  }
);

export const overridesNutrientValueSelector = (id: string) =>
  createSelector<RootState, NutrientValues, NutrientValue>(
    OverridesNutrientValuesSelector,
    (overrideNutrientValues) => overrideNutrientValues.nutrientValue(id)
  );

export const calculationMethodSelector = (state: RootState): number =>
  state.currentDocument.document.calculationMethod;

export const documentMappingIdSelector = (state: RootState): string =>
  state.currentDocument.document.documentMappingId;
