export class FoodId {
  datasourceId: string;
  documentId: string;

  constructor(foodId: FoodIdObject) {
    this.datasourceId = foodId!.datasourceId;
    this.documentId = foodId!.documentId;
  }

  public get identifier(): string {
    return `${this.datasourceId}:${this.documentId}`;
  }
}

export interface FoodIdObject {
  datasourceId: string;
  documentId: string;
}

export const foodIdConverter = {
  toJson: (foodId: FoodIdObject | undefined): FoodIdObject => {
    return !foodId
      ? { datasourceId: "undefined", documentId: "undefined" }
      : {
          datasourceId: foodId.datasourceId,
          documentId: foodId.documentId,
        };
  },

  fromJson: (
    data: firebase.firestore.DocumentData
  ): FoodIdObject | undefined => {
    if (!data) return undefined;
    return data.datasourceId === "undefined" && data.documentId === "undefined"
      ? undefined
      : {
          datasourceId: data.datasourceId,
          documentId: data.documentId,
        };
  },
};
