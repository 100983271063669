import React, { FunctionComponent, ReactNode, useState } from "react";

import { MenuItem } from "@material-ui/core";
import { batch, useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import { FoodworksMenu } from "../../../../../../common/FoodworksMenu";
import {
  Section,
  Sections,
  SectionTag,
} from "../../../../../../../data/models/documentProperties/section";
import { addSectionsFromIndex } from "../../../../../../../store/data/current-document/thunks/sections";
import { RootState } from "../../../../../../../store/reducers";
import {
  ClipboardDataType,
  ClipboardState,
} from "../../../../../../../store/reducers/clipboardReducers";
import { CurrentClipboardSelector } from "../../../../../../../store/selectors/clipboardSelectors";
import { setSelectedRows } from "../../../../../../../store/ui/actionCreators/recipeGrid";
import { setSelectedSectionTags } from "../../../../../../../store/ui/actionCreators/nutritionPaneActionCreators";
import { deleteSections } from "../../../../../../../store/data/current-document/action-creators/sections";
import { templateIdSelector } from "../../../../../../../store/data/current-document/selectors/document";
import { SectionDeleteDialog } from "./SectionDeleteDialog";
import { SectionTagMenu } from "./SectionTagMenu";
import { RetentionFactor } from "../../../../../../../data/models/documentProperties/retentionFactor";
import { setClipboard } from "../../../../../../../store/action_creators/clipboardActionCreators";
import { getRetentionFactorMap } from "../../../../../../../store/data/selectors/referenceData";
import { addAlert } from "../../../../../../../store/ui/actionCreators/alert";
import { sectionTagsSelector } from "../../../../../../../store/data/current-document/selectors/sectionTags";

interface SectionMenuProps {
  dayIndex: number;
  section: Section;
  anchorElement: HTMLElement | null;
  onClose: () => void;
  onClickCreateTag: () => void;
}

export const SectionMenu: FunctionComponent<SectionMenuProps> = ({
  dayIndex,
  section,
  anchorElement,
  onClose,
  onClickCreateTag,
}) => {
  const dispatch = useDispatch();

  const onResetSelection = () =>
    batch(() => {
      dispatch(setSelectedRows([]));
      dispatch(setSelectedSectionTags([]));
    });

  const onDeleteSections = () =>
    dispatch(deleteSections(dayIndex, [section.index], templateId));

  const onPasteFoodItems = () => {
    const sections = clipboard.clipboardData?.data as Sections;

    batch(() => {
      dispatch(
        addSectionsFromIndex(
          sections.items.map(
            (section: Section): Section => {
              const currentDocumentTagsIds: string[] = documentSectionTags.map(
                (tag: SectionTag): string => tag.id
              );
              const tagsToCopy: string[] = section.tags.reduce<string[]>(
                (tags, tagId) => {
                  if (currentDocumentTagsIds.includes(tagId)) {
                    tags.push(tagId);
                  }
                  return tags;
                },
                []
              );
              return new Section(
                uuidv4(),
                section.index,
                section.foodItems,
                section.title,
                false,
                tagsToCopy
              );
            }
          ),
          dayIndex,
          section.index
        )
      );
      onResetSelection();
    });
  };

  const onSetClipboard = () =>
    dispatch(
      setClipboard({
        dataType: ClipboardDataType.SECTIONS,
        data: new Sections([section.object], retentionFactorMap),
      })
    );

  const onAddAlert = (message: string) => dispatch(addAlert(message));

  const templateId: string = useSelector<RootState, string>(templateIdSelector);

  const clipboard: ClipboardState = useSelector<RootState, ClipboardState>(
    CurrentClipboardSelector
  );

  const documentSectionTags: SectionTag[] = useSelector<
    RootState,
    SectionTag[]
  >(sectionTagsSelector);

  const retentionFactorMap: Map<string, RetentionFactor> = useSelector<
    RootState,
    Map<string, RetentionFactor>
  >(getRetentionFactorMap);

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [tagMenuAnchorEl, setTagMenuAnchorEl] = useState<HTMLElement | null>(
    null
  );

  const onCopySection = () => {
    onSetClipboard();
    onAddAlert("Section copied successfully!");
  };

  const onDeleteSection = () =>
    !section.foodItems.length
      ? batch(() => {
          onDeleteSections();
          onResetSelection();
        })
      : setShowDeleteDialog(true);

  const onCloseDeleteDialog = () => {
    setShowDeleteDialog(false);
  };

  const onClickPasteAbove = () => {
    onPasteFoodItems();
    onClose();
  };

  const onAddTag = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    setTagMenuAnchorEl(event.currentTarget);
  };

  const pasteSectionAboveMenuItem: ReactNode = (
    <MenuItem
      data-cy="sectionPasteMenuItem"
      key="paste"
      onClick={onClickPasteAbove}
      dense
      disabled={
        !clipboard.clipboardData ||
        clipboard.clipboardData?.dataType !== ClipboardDataType.SECTIONS
      }
    >
      Paste above
    </MenuItem>
  );

  const copySectionMenuItem: ReactNode = (
    <MenuItem
      data-cy="sectionCopyMenuItem"
      key="copy"
      onClick={onCopySection}
      dense
    >
      Copy section
    </MenuItem>
  );

  const deleteSectionMenuItem: ReactNode = (
    <MenuItem
      data-cy="sectionDeleteMenuItem"
      key="delete"
      onClick={onDeleteSection}
      dense
    >
      Delete section
    </MenuItem>
  );

  const addSectionTagMenuItem: ReactNode = (
    <MenuItem data-cy="sectionTagMenuItem" key="tag" onClick={onAddTag} dense>
      Add section tag
    </MenuItem>
  );

  const createMenuItems = (): ReactNode[] => {
    const menuItems: ReactNode[] = [];

    menuItems.push(pasteSectionAboveMenuItem);
    menuItems.push(copySectionMenuItem);
    menuItems.push(deleteSectionMenuItem);
    menuItems.push(addSectionTagMenuItem);

    return menuItems;
  };

  const onCloseSectionTagMenu = () => {
    setTagMenuAnchorEl(null);
    onClose();
  };

  return (
    <>
      <FoodworksMenu
        id={`sectionsMenu-${section.index}`}
        onBackdropClick={onClose}
        anchorEl={anchorElement}
        open={!!anchorElement}
      >
        {createMenuItems()}
      </FoodworksMenu>
      <SectionTagMenu
        dayIndex={dayIndex}
        section={section}
        anchorElement={tagMenuAnchorEl}
        onClose={onCloseSectionTagMenu}
        onClickCreateTag={onClickCreateTag}
      />
      <SectionDeleteDialog
        open={showDeleteDialog}
        onClose={onCloseDeleteDialog}
        dayIndex={dayIndex}
        sectionIndex={section.index}
        foodItemsLength={section.foodItems.length}
      />
    </>
  );
};
