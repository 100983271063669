import { SectionTag } from "../../../../data/models/documentProperties/section";
import {
  ADD_SECTION_TAGS,
  DELETE_SECTION_TAG,
  SectionTagsActions,
  UPDATE_SECTION_TAG,
} from "../actions/sectionTags";

export type SectionTagsState = SectionTag[];

export const initialSectionTagsState: SectionTagsState = [];

export const sectionTags = (
  state: SectionTagsState = initialSectionTagsState,
  action: SectionTagsActions
): SectionTagsState => {
  switch (action.type) {
    case ADD_SECTION_TAGS:
      return state.concat(action.tags);
    case DELETE_SECTION_TAG:
      return state.filter(
        (tag: SectionTag): boolean => tag.id !== action.tagId
      );
    case UPDATE_SECTION_TAG:
      return state.map(
        (tag: SectionTag): SectionTag =>
          tag.id === action.tag.id ? action.tag : tag
      );
    default:
      return state;
  }
};
