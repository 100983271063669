import {
  DocumentSavingActions,
  SET_UNSAVED_DOCUMENT_CHANGES,
  SET_DOCUMENT_ID_TO_CHANGE_TO,
} from "../actions/documentSaving";

export interface DocumentSavingState {
  openSaveDialog: boolean;
  documentIdToChangeTo: string;
  databaseIdToChangeTo: string;
}

export const initialDocumentSavingState: DocumentSavingState = {
  openSaveDialog: false,
  documentIdToChangeTo: "",
  databaseIdToChangeTo: "",
};

export const documentSaving = (
  state: DocumentSavingState = initialDocumentSavingState,
  action: DocumentSavingActions
): DocumentSavingState => {
  switch (action.type) {
    case SET_UNSAVED_DOCUMENT_CHANGES:
      return {
        ...state,
        openSaveDialog: action.openSaveDialog,
        documentIdToChangeTo: action.documentIdToChangeTo,
        databaseIdToChangeTo: action.databaseIdToChangeTo,
      };
    case SET_DOCUMENT_ID_TO_CHANGE_TO:
      return { ...state, documentIdToChangeTo: action.documentId };
    default:
      return state;
  }
};
