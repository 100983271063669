import { createSelector } from "reselect";

import {
  RetentionFactorProfileMap,
  ENABLED_NUTRIENTS,
  NutrientMap,
  CategoryMap,
  ReferenceMeasureMap,
} from "../reducers/referenceData";
import { RootState } from "../../reducers";
import {
  RetentionFactor,
  RetentionFactorProfile,
} from "../../../data/models/documentProperties/retentionFactor";
import { Category } from "../../../data/models/category";
import {
  ReferenceMeasure,
  ReferenceMeasureObject,
} from "../../../data/models/referenceMeasure";
import { Nutrient } from "../../../data/models/nutrient";

export const CategoriesSelectorLogic = (
  categories: CategoryMap
): [string, Category][] =>
  Object.entries(categories).sort(
    (a: [string, Category], b: [string, Category]) =>
      a[1].displayOrder - b[1].displayOrder
  );

export const CategoriesSelector = createSelector(
  (state: RootState) => state.referenceData.categories,
  CategoriesSelectorLogic
);

export const NutrientsDataSelectorLogic = (
  nutrients: NutrientMap
): Map<string, Nutrient> => {
  for (const id of Object.keys(nutrients)) {
    if (!ENABLED_NUTRIENTS.includes(id)) {
      delete nutrients[id];
    }
  }
  const sorted = Object.entries(nutrients).sort(
    (a: [string, Nutrient], b: [string, Nutrient]) =>
      a[1].displayOrder - b[1].displayOrder
  );
  return new Map(sorted);
};

export const NutrientsDataSelector = createSelector(
  (state: RootState) => state.referenceData.nutrientData,
  NutrientsDataSelectorLogic
);

export const ReferenceMeasuresSelectorLogic = (
  referenceMeasures: ReferenceMeasureMap
): ReferenceMeasure[] =>
  Object.entries(referenceMeasures).map(
    ([id, referenceMeasure]: [
      string,
      ReferenceMeasureObject
    ]): ReferenceMeasure =>
      new ReferenceMeasure(
        id,
        referenceMeasure.name,
        referenceMeasure.displayName,
        referenceMeasure.isVolume,
        referenceMeasure.isWeight,
        referenceMeasure.conversionFactor,
        referenceMeasure.note
      )
  );

export const ReferenceMeasuresSelector = createSelector(
  (state: RootState) => state.referenceData.referenceMeasures,
  ReferenceMeasuresSelectorLogic
);

export const RetentionFactorGroupsSelector = (
  state: RootState
): [string, string][] =>
  Object.entries(
    state.referenceData.retentionFactorData.retentionFactorGroupMap
  );

export const RetentionFactorProfilesSelector = (
  state: RootState,
  groupId: string | null
): RetentionFactorProfile[] =>
  groupId
    ? state.referenceData.retentionFactorData.retentionFactorProfileMap[groupId]
    : [];

export const AllRetentionFactorProfilesSelector = (
  state: RootState
): RetentionFactorProfileMap =>
  state.referenceData.retentionFactorData.retentionFactorProfileMap;

export const getRetentionFactorMap = createSelector(
  AllRetentionFactorProfilesSelector,
  (profiles) => {
    const retentionFactorProfiles = new Map<string, RetentionFactor>();
    for (const [groupId, group] of Object.entries(profiles)) {
      for (const profile of group) {
        retentionFactorProfiles.set(profile.id, {
          profileId: profile.id,
          groupId: groupId,
          description: profile.description,
        });
      }
    }
    return retentionFactorProfiles;
  }
);
