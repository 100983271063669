import React, { FunctionComponent, ReactNode } from "react";

import { Button, makeStyles } from "@material-ui/core";
import { Delete, Restore } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../../store/reducers";
import {
  documentPropertiesSelector,
  documentSelector,
  nameSelector,
} from "../../../store/data/current-document/selectors/document";
import {
  Document,
  documentsAreEqual,
  getDocumentErrors,
} from "../../../data/models/document";
import { EditableTitleInput } from "../../common/EditableTitleInput";
import {
  toggleIsDeleted,
  updateDocumentName,
} from "../../../store/data/current-document/action-creators/document";
import {
  TEMPORARY_DOCUMENT,
  TEMPORARY_NEW_DOCUMENT,
} from "../../../store/data/current-document/reducers/currentDocument";
import {
  CurrentDocumentIdSelector,
  ServerDocumentSelector,
} from "../../../store/data/current-document/selectors/currentDocument";
import {
  saveDocument,
  saveDocumentDeletion,
} from "../../../store/data/current-document/thunks/currentDocument";
import { useAuthState } from "react-firebase-hooks/auth";
import Firebase, { withFirebase } from "../../../data/Firebase";
import { appTheme } from "../../../styling/style";
import SaveIcon from "@material-ui/icons/Save";
import { disableSaveButtonSelector } from "../../../store/ui/selectors/documentEditing";
import { DocumentSummary } from "../../../data/models/userDatabase";
import { userDocumentSummariesSelector } from "../../../store/data/selectors/database";
import { addError } from "../../../store/action_creators/errorActionCreators";
import { DocumentSaveError } from "../../../errors";
import { FoodworksTooltip } from "../../common/InfoTooltip";
import { addAlert } from "../../../store/ui/actionCreators/alert";
import { ReferenceMeasure } from "../../../data/models/referenceMeasure";
import { ReferenceMeasuresSelector } from "../../../store/data/selectors/referenceData";

type TitleBarProps = {
  firebase?: Firebase;
};

const useStyles = makeStyles(() => ({
  title: {
    flex: 1,
    margin: 5,
  },
  titleButton: { width: 75 },
  deleteIcon: {
    color: "white",
  },
  saveSuccessAlert: { marginTop: 40 },
  titleBar: { display: "flex" },
}));

const TitleBarInner: FunctionComponent<TitleBarProps> = ({ firebase }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [user] = useAuthState(firebase!.auth);

  const onDocumentNameChanged = (name: string) => {
    dispatch(updateDocumentName(name));
  };

  const onDocumentToggleDeleted = () => {
    dispatch(toggleIsDeleted());
    dispatch(saveDocumentDeletion(user!.uid, currentDocumentId));
  };

  const onSaveDocument = () =>
    dispatch(saveDocument(user!.uid, currentDocumentId));

  const onDocumentSaveError = (documentErrors: ReactNode) =>
    dispatch(addError(new DocumentSaveError(documentErrors)));

  const onAddAlert = (message: string) => dispatch(addAlert(message));

  const documentName = useSelector<RootState, Document["name"]>(nameSelector);

  const currentDocumentId: string = useSelector<RootState, string>(
    CurrentDocumentIdSelector
  );

  const documentProperties = useSelector<RootState, Document["properties"]>(
    documentPropertiesSelector
  );

  const isDocumentEditingDisabled: boolean = useSelector<RootState, boolean>(
    disableSaveButtonSelector
  );

  const currentDocument: Document = useSelector<RootState, Document>(
    documentSelector
  );

  const serverDocument: Document | undefined = useSelector<
    RootState,
    Document | undefined
  >(ServerDocumentSelector);

  const userDocumentSummaries: DocumentSummary[] = useSelector<
    RootState,
    DocumentSummary[]
  >(userDocumentSummariesSelector);

  const referenceMeasures: ReferenceMeasure[] = useSelector<
    RootState,
    ReferenceMeasure[]
  >(ReferenceMeasuresSelector);

  const documentIsUnsaved: boolean =
    currentDocumentId === TEMPORARY_DOCUMENT ||
    currentDocumentId === TEMPORARY_NEW_DOCUMENT;

  const documentHasChanges: boolean =
    documentIsUnsaved || !documentsAreEqual(currentDocument, serverDocument);

  const tooltipText = documentProperties.isDeleted
    ? "Restore document"
    : "Delete document";

  const deleteButton = (
    <FoodworksTooltip title={tooltipText}>
      <Button
        data-cy="deleteDocument"
        size="small"
        disabled={documentIsUnsaved}
        className={classes.titleButton}
        style={{
          color: documentIsUnsaved
            ? "revert"
            : documentProperties.isDeleted
            ? appTheme.colors.success
            : appTheme.colors.error,
        }}
        onClick={onDocumentToggleDeleted}
        startIcon={documentProperties.isDeleted ? <Restore /> : <Delete />}
      >
        {documentProperties.isDeleted ? "Restore" : "Delete"}
      </Button>
    </FoodworksTooltip>
  );

  const handleSaveClick = () => {
    const documentErrors: string[] | undefined = getDocumentErrors(
      currentDocument,
      userDocumentSummaries,
      currentDocumentId,
      referenceMeasures.map((measure: ReferenceMeasure): string => measure.name)
    );

    if (!documentErrors.length) {
      onSaveDocument();
      onAddAlert("Document saved successfully!");
    } else {
      const errorBody: ReactNode = (
        <div>
          {"Please fix the following errors in your document: "}
          <ul>
            {documentErrors.map(
              (error: string): ReactNode => (
                <li key={error}>{error}</li>
              )
            )}
          </ul>
        </div>
      );
      onDocumentSaveError(errorBody);
    }
  };

  const saveButton = (
    <FoodworksTooltip
      title={
        isDocumentEditingDisabled || !documentHasChanges ? "" : "Save document"
      }
    >
      <Button
        className={classes.titleButton}
        disabled={isDocumentEditingDisabled || !documentHasChanges}
        data-cy="saveButton"
        color="secondary"
        startIcon={<SaveIcon />}
        onClick={handleSaveClick}
      >
        Save
      </Button>
    </FoodworksTooltip>
  );

  return (
    <div className={classes.titleBar}>
      <EditableTitleInput
        className={classes.title}
        value={documentName}
        data-cy="recipeTitle"
        onChange={(event) => onDocumentNameChanged(event.target.value)}
      />
      {saveButton}
      {deleteButton}
    </div>
  );
};

export const TitleBar = withFirebase(TitleBarInner);
