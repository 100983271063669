import React, {
  FunctionComponent,
  useEffect,
  useState,
  ReactNode,
  useCallback,
} from "react";

import { makeStyles, Divider, Button, Typography } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { Resizable } from "re-resizable";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import SearchIcon from "@material-ui/icons/Search";

import {
  AccordionList,
  AccordionProps,
  AccordionItem,
} from "../../common/AccordionList";
import { appTheme } from "../../../styling/style";
import { DocumentSummary } from "../../../data/models/userDatabase";
import { NavigationFilter } from "./NavigationFilter";
import { NavigationState } from "../../../store/ui/reducers/navigationReducers";
import { HandleAlignment, ResizableHandle } from "../../common/ResizableHandle";
import { RootState } from "../../../store/reducers";
import { SearchDocuments } from "./NavigationSearch";
import {
  SearchTextSelector,
  SelectedQuickFiltersSelector,
  QuickFiltersEnabledSelector,
  NavIsLoadingSelector,
  navAccordionsExpandedSelector,
} from "../../../store/ui/selectors/navigationSelectors";
import { FOOD_TEMPLATES } from "../../../constants/FoodTemplate";
import { tappedNavAccordion } from "../../../store/ui/actionCreators/navigationActionCreators";
import {
  databaseNameSelector,
  userDocumentSummariesSelector,
} from "../../../store/data/selectors/database";
import { createSearchMap } from "../foods/tabs/ingredients/editing_grid/cells/ingredientUtils";
import { CurrentDocumentIdSelector } from "../../../store/data/current-document/selectors/currentDocument";
import { setDocumentIdToChangeTo } from "../../../store/ui/actionCreators/documentSaving";
import { FeedbackDialog } from "../../dialogs/FeedbackDialog";
import { LoadingBarPadding } from "../../common/LoadingBarPadding";
import { ReactComponent as DatabaseIcon } from "../../../images/icons/Database.svg";
import { addAlert } from "../../../store/ui/actionCreators/alert";
import { DocumentItem } from "./NavigationListItems";
import { DELETE_FILTER_ID, QuickFilter } from "../../../constants/QuickFilter";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    background: appTheme.colors.white[8],
    padding: 5,
  },
  columnFlex: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    overflowX: "hidden",
  },
  paper: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "stretch",
    overflowX: "hidden",
  },
  body: {
    flex: 1,
    overflowY: "auto",
    overflowX: "hidden",
  },
  noItemsFound: {
    display: "flex",
    flexDirection: "column",
    margin: 10,
  },
  noItemsIcon: { width: 50, height: 50 },
  noItemsDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  buttonIcon: {
    fill: appTheme.colors.xiketic,
    width: 24,
    height: 24,
  },
  currentDatabaseDiv: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "15px 0px",
    overflow: "hidden",
  },
  noDocumentsFound: {
    margin: 5,
  },
  collapseButton: { minWidth: 24 },
  collapsed: {
    padding: 5,
    background: appTheme.colors.white[8],
    borderRight: `1px solid ${appTheme.colors.gainsbro}`,
    height: "100%",
  },
  flex: { display: "flex" },
  databaseIcon: {
    margin: "0px 15px",
    fill: appTheme.colors.primary,
    height: 24,
    width: 24,
  },
  databaseText: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  databaseDiv: { overflow: "hidden", alignItems: "center", display: "flex" },
  bottomNavButtons: {
    marginBottom: 5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  supportLink: {
    fontSize: 10,
    marginLeft: 5,
    color: appTheme.colors.xiketic,
    textDecoration: "none",
    alignContent: "center",
    "&:hover": {
      color: appTheme.colors.help,
      textDecoration: "underline",
    },
  },
  learnMoreTypography: {
    justifyContent: "center",
    display: "flex",
  },
  bottomNavText: {
    fontSize: 10,
    marginRight: 5,
    "&:hover": {
      color: appTheme.colors.help,
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
  saveSuccessAlert: { marginTop: 40 },
}));

const NavigationPane: FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onTappedNavAccordion = (templateId: number) =>
    dispatch(tappedNavAccordion(templateId));

  const onSetDocumentIdToChangeTo = useCallback(
    (id: string) => dispatch(setDocumentIdToChangeTo(id)),
    [dispatch]
  );

  const onAddAlert = (message: string) => dispatch(addAlert(message));

  const userDocumentSummaries: DocumentSummary[] = useSelector<
    RootState,
    DocumentSummary[]
  >(userDocumentSummariesSelector);

  const quickFiltersEnabled = useSelector<
    RootState,
    NavigationState["quickFiltersEnabled"]
  >(QuickFiltersEnabledSelector);

  const currentDocumentId: string = useSelector<RootState, string>(
    CurrentDocumentIdSelector
  );

  const searchText = useSelector<RootState, NavigationState["searchText"]>(
    SearchTextSelector
  );

  const quickFilters = useSelector<
    RootState,
    NavigationState["selectedQuickFilters"]
  >(SelectedQuickFiltersSelector);

  const navIsLoading: boolean = useSelector<RootState, boolean>(
    NavIsLoadingSelector
  );

  const databaseName: string = useSelector<RootState, string>(
    databaseNameSelector
  );

  const navAccordionsExpanded = useSelector<RootState, number[]>(
    navAccordionsExpandedSelector
  );

  const [open, setOpen] = useState(true);
  const [navResizeHovered, setNavResizeHovered] = useState(false);
  const [isDragged, setIsDragged] = useState(false);
  const [showFeedbackDialog, setShowFeedbackDialog] = useState(false);

  /**
   * Create preprocessed data for searching
   */
  const [searchTermMap, setSearchTermMap] = useState(
    new Map<string, string[]>()
  );

  useEffect(() => {
    setSearchTermMap(createSearchMap(userDocumentSummaries));
  }, [userDocumentSummaries]);

  const visibleItems = SearchDocuments(
    userDocumentSummaries,
    {
      quickFilters: quickFiltersEnabled ? quickFilters : [],
      searchText: searchText,
    },
    searchTermMap
  );

  const accordionValues: AccordionProps[] = [];

  for (const template of FOOD_TEMPLATES) {
    let items: AccordionItem[] = [];

    for (const item of visibleItems) {
      if (item.templateId === template.id) {
        items.push({
          id: item.documentId,
          item: (
            <DocumentItem
              documentSummary={item}
              onDocumentClick={onSetDocumentIdToChangeTo}
              currentDocumentId={currentDocumentId}
            />
          ),
        });
      }
    }

    if (items.length !== 0) {
      accordionValues.push({
        id: template.id,
        header: template.title,
        icon: <template.icon className={classes.buttonIcon} />,
        items: items,
        open: navAccordionsExpanded.includes(template.id),
      });
    }
  }

  function buildNavigationBody(items: AccordionProps[]) {
    const defaultAccordions = [];
    for (const template of FOOD_TEMPLATES) {
      defaultAccordions.push({
        id: template.id,
        header: template.title,
        items: [],
        open: false,
      });
    }

    let noItemsSpan: ReactNode;

    if (
      !navIsLoading &&
      !quickFilters.find(
        (filter: QuickFilter): boolean => filter.id === DELETE_FILTER_ID
      ) &&
      !userDocumentSummaries.filter(
        (summary: DocumentSummary): boolean => !summary.isDeleted
      ).length
    ) {
      noItemsSpan = (
        <div className={classes.noItemsDiv} style={{ marginTop: 50 }}>
          <Typography
            variant="h5"
            className={classes.noDocumentsFound}
            data-cy="noItemsFound"
          >
            Save your first document to view it here in the navigation pane.
          </Typography>
        </div>
      );
    } else {
      noItemsSpan = navIsLoading ? (
        <AccordionList toggledCallback={() => {}} values={defaultAccordions} />
      ) : items.length === 0 ? (
        <div className={classes.noItemsDiv}>
          <SearchIcon className={classes.noItemsIcon} />
          <Typography
            variant="h5"
            className={classes.noDocumentsFound}
            data-cy="noItemsFound"
          >
            We couldn't find anything matching your search.
          </Typography>

          <Typography>
            Try again with a different term, or refine your results with Quick
            Filters.
          </Typography>
        </div>
      ) : null;
    }

    return (
      <>
        <AccordionList
          toggledCallback={(templateId: number) => {
            onTappedNavAccordion(templateId);
          }}
          values={accordionValues}
        />
        <div className={classes.noItemsFound}>{noItemsSpan}</div>
      </>
    );
  }

  const collapseButton = (
    <Button
      data-cy="navPaneCollapseButton"
      className={classes.collapseButton}
      onClick={() => setOpen(!open)}
    >
      {open ? <NavigateBeforeIcon /> : <NavigateNextIcon />}
    </Button>
  );

  const onGiveFeedbackClick = () => setShowFeedbackDialog(true);

  const bottomNavButtons: ReactNode = (
    <div className={classes.bottomNavButtons}>
      <Typography
        onClick={onGiveFeedbackClick}
        className={classes.bottomNavText}
      >
        Give feedback
      </Typography>

      <Typography className={classes.learnMoreTypography}>
        <a
          className={classes.supportLink}
          href="https://support.foodworks.online/hc/en-au"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn more
        </a>
      </Typography>
    </div>
  );

  const body = (
    <>
      <div className={classes.flex}>
        <div className={classes.currentDatabaseDiv}>
          <div className={classes.databaseDiv}>
            <DatabaseIcon className={classes.databaseIcon} />
            <Typography
              data-cy="database"
              variant="h3"
              color="textPrimary"
              className={classes.databaseText}
            >
              {databaseName}
            </Typography>
          </div>
          {collapseButton}
        </div>
      </div>
      <NavigationFilter />
      <Divider />
      <div data-cy="navBody" className={classes.body}>
        {buildNavigationBody(accordionValues)}
      </div>
      {bottomNavButtons}
    </>
  );

  const resizableSides = {
    top: false,
    right: true,
    bottom: false,
    left: false,
    topRight: false,
    bottomRight: false,
    bottomLeft: false,
    topLeft: false,
  };

  return (
    <>
      {open ? (
        <Resizable
          className={classes.root}
          style={{
            borderRight:
              navResizeHovered || isDragged
                ? `3px solid ${appTheme.colors.oceanBlue[3]}`
                : `1px solid ${appTheme.colors.gainsbro}`,
            paddingRight: navResizeHovered || isDragged ? 2 : 4,
          }}
          minWidth="10%"
          maxWidth="30%"
          defaultSize={{ width: "15%", height: "auto" }}
          handleStyles={{ right: { display: "flex", cursor: "ew-resize" } }}
          onResizeStart={() => setIsDragged(true)}
          onResizeStop={() => {
            setIsDragged(false);
            setNavResizeHovered(false);
          }}
          handleComponent={{
            right: (
              <ResizableHandle
                isDragged={isDragged}
                isHovered={navResizeHovered}
                onMouseEnter={() => setNavResizeHovered(true)}
                onMouseLeave={() => setNavResizeHovered(false)}
                alignment={HandleAlignment.RIGHT}
              />
            ),
          }}
          enable={resizableSides}
        >
          <div className={classes.columnFlex}>
            <LoadingBarPadding />
            <div className={classes.paper}>{body}</div>
          </div>
        </Resizable>
      ) : (
        <div className={classes.collapsed}>
          <LoadingBarPadding />
          {collapseButton}
        </div>
      )}
      {showFeedbackDialog && (
        <FeedbackDialog
          onClose={() => setShowFeedbackDialog(false)}
          onSend={() => {
            setShowFeedbackDialog(false);
            onAddAlert("Thank you for your feedback!");
          }}
        />
      )}
    </>
  );
};

export default NavigationPane;
