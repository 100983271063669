import { ENABLED_NUTRIENTS } from "../../store/data/reducers/referenceData";
import { DartNutrient, ENERGY_ID, WEIGHT_ID } from "./nutrient";
import { FOODWORKS_DECIMAL_PLACES } from "./nutrientValue";

export class Composition {
  nutrientValues: Map<string, number>;

  constructor(compositionState: { [id: string]: number }) {
    this.nutrientValues = new Map(Object.entries(compositionState));
  }

  static fromDartComposition = (
    nutrientValues: DartNutrient[]
  ): Composition => {
    return new Composition(
      nutrientValues.reduce<{ [id: string]: number }>(
        (composition, nutrient) => {
          composition[nutrient.id.toString()] = nutrient.value;
          return composition;
        },
        {}
      )
    );
  };

  applyBaseQuantity = (quantity: number): Composition => {
    const initialWeight = this.weight;
    for (const [id, value] of this.nutrientValues.entries()) {
      this.nutrientValues.set(id, (value * quantity) / initialWeight);
    }

    return this;
  };

  multiplyByFactor = (factor: number): Composition => {
    for (const [id, value] of this.nutrientValues.entries()) {
      this.nutrientValues.set(id, value * factor);
    }

    return this;
  };

  addComposition = (composition: Composition | undefined): Composition => {
    if (!composition) return this;
    for (const [id, value] of composition.nutrientValues.entries()) {
      this.nutrientValues.has(id)
        ? this.nutrientValues.set(id, this.nutrientValues.get(id)! + value)
        : this.nutrientValues.set(id, value);
    }
    return this;
  };

  getNutrientValue = (id: string): number | undefined =>
    this.nutrientValues.get(id);

  getRoundedNutrientValue = (id: string): number | undefined =>
    Number(this.getNutrientValue(id)?.toFixed(FOODWORKS_DECIMAL_PLACES));

  setToZero = (): Composition => {
    for (const id of ENABLED_NUTRIENTS) {
      this.nutrientValues.set(id, 0);
    }
    this.nutrientValues.set(WEIGHT_ID, 100);
    return this;
  };

  public get weight(): number {
    return this.nutrientValues.get(WEIGHT_ID)!;
  }

  public get energy(): number {
    return this.nutrientValues.get(ENERGY_ID)!;
  }

  public get object(): { [id: string]: number } {
    return Array.from(this.nutrientValues.entries()).reduce<{
      [id: string]: number;
    }>((composition, [id, value]) => {
      composition[id] = value;
      return composition;
    }, {});
  }
}

export type CompositionState = { [id: string]: number };

export class CompositionCache {
  compositionMap: Map<string, Composition>;

  constructor(compositionCache: { [documentId: string]: CompositionState }) {
    this.compositionMap = new Map(
      Object.entries(
        compositionCache
      ).map(([id, composition]: [string, CompositionState]): [
        string,
        Composition
      ] => [id, new Composition(composition)])
    );
  }

  hasComposition = (id: string): boolean => this.compositionMap.has(id);

  getComposition = (id: string): Composition | undefined =>
    this.compositionMap.get(id);
}
