import { Action } from "redux";

import { Section } from "../../../../data/models/documentProperties/section";
import { FoodItemsActions } from "./foodItems";

export const ADD_SECTION = "ADD_SECTION";
export const UPDATE_SECTION = "UPDATE_SECTION";
export const DELETE_SECTIONS = "DELETE_SECTIONS";
export const UPDATE_SECTION_TITLE = "UPDATE_SECTION_TITLE";
export const UPDATE_SECTION_IS_COLLAPSED = "UPDATE_SECTION_IS_COLLAPSED";
export const ADD_SECTION_TAG = "ADD_SECTION_TAG";
export const REMOVE_SECTION_TAG = "REMOVE_SECTION_TAG";

export interface IActionsAddSection extends Action {
  type: "ADD_SECTION";
  dayIndex: number;
  section: Section;
}

export interface IActionsUpdateSection extends Action {
  type: "UPDATE_SECTION";
  dayIndex: number;
  section: Section;
}

export interface IActionsDeleteSections extends Action {
  type: "DELETE_SECTIONS";
  dayIndex: number;
  sectionIndexes: number[];
  templateId: string;
}

export interface IActionsUpdateSectionTitle extends Action {
  type: "UPDATE_SECTION_TITLE";
  dayIndex: number;
  sectionIndex: number;
  title: string;
}

export interface IActionsUpdateSectionIsCollapsed extends Action {
  type: "UPDATE_SECTION_IS_COLLAPSED";
  dayIndex: number;
  sectionIndex: number;
  isCollapsed: boolean;
}

export interface IActionsAddSectionTag extends Action {
  dayIndex: number;
  sectionIndex: number;
  type: "ADD_SECTION_TAG";
  tagId: string;
}

export interface IActionsRemoveSectionTag extends Action {
  dayIndex: number;
  sectionIndex: number;
  type: "REMOVE_SECTION_TAG";
  tagId: string;
}

export type SectionsActions =
  | IActionsAddSection
  | IActionsUpdateSection
  | IActionsUpdateSectionTitle
  | IActionsDeleteSections
  | IActionsUpdateSectionIsCollapsed
  | IActionsAddSectionTag
  | IActionsRemoveSectionTag
  | FoodItemsActions;
