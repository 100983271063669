import React, {
  FunctionComponent,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";

import {
  Checkbox,
  Typography,
  TextField,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import { Visibility, Edit, Lock } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";

import {
  ENERGY_CAL_ID,
  ENERGY_ID,
  Nutrient,
  WEIGHT_ID,
} from "../../../../../data/models/nutrient";
import { ENABLED_NUTRIENTS } from "../../../../../store/data/reducers/referenceData";
import { displayedNutrientsSelector } from "../../../../../store/data/selectors/databaseProperties";
import { NutrientsDataSelector } from "../../../../../store/data/selectors/referenceData";
import { RootState } from "../../../../../store/reducers";
import {
  toggleDisplayNutrientValues,
  toggleLockNutrientColumns,
} from "../../../../../store/ui/actionCreators/recipeGrid";
import {
  displayNutrientValuesSelector,
  lockNutrientColumnsSelector,
} from "../../../../../store/ui/selectors/recipeGrid";
import { FoodworksTooltip } from "../../../../common/InfoTooltip";
import { updateDisplayedNutrients } from "../../../../../store/data/thunks/databaseProperties";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },

  filterOption: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  selection: {
    width: 300,
  },
}));

interface NutrientOption {
  id: string;
  nutrient: Nutrient;
}

export const NutrientValueSelection: FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onToggleDisplayNutrientValues = () =>
    dispatch(toggleDisplayNutrientValues());

  const onUpdateDisplayedNutrients = (nutrientIds: string[]) =>
    dispatch(updateDisplayedNutrients(nutrientIds));

  const onToggleLockNutrientColumns = () =>
    dispatch(toggleLockNutrientColumns());

  const displayNutrientValues: boolean = useSelector<RootState, boolean>(
    displayNutrientValuesSelector
  );

  const lockNutrientColumns: boolean = useSelector<RootState, boolean>(
    lockNutrientColumnsSelector
  );

  const displayedNutrients: string[] = useSelector<RootState, string[]>(
    displayedNutrientsSelector
  );

  const nutrientData: Map<string, Nutrient> = useSelector<
    RootState,
    Map<string, Nutrient>
  >(NutrientsDataSelector);

  const createNutrientOptionsList = (): NutrientOption[] => {
    const nutrientOptions: NutrientOption[] = ENABLED_NUTRIENTS.reduce<
      NutrientOption[]
    >((nutrients, id) => {
      nutrients.push({ id: id, nutrient: nutrientData.get(id)! });
      return nutrients;
    }, [])
      .filter((option: NutrientOption): boolean => option.id !== WEIGHT_ID)
      .sort(
        (a, b) =>
          Number(a.nutrient.category) - Number(b.nutrient.category) ||
          a.nutrient.displayOrder - b.nutrient.displayOrder
      );

    nutrientOptions.unshift({
      id: WEIGHT_ID,
      nutrient: nutrientData.get(WEIGHT_ID)!,
    });

    return nutrientOptions;
  };

  const [showNutrientSelection, setShowNutrientSelection] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  const anchorElement = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (displayNutrientValues && !displayedNutrients.length) {
      setShowNutrientSelection(true);
    }
  }, [displayNutrientValues, displayedNutrients]);

  const selectOption = (option: NutrientOption): ReactNode => {
    let optionLabel: string = option.nutrient.name;
    if (option.id === ENERGY_ID) {
      optionLabel = `${option.nutrient.name} (kJ)`;
    } else if (option.id === ENERGY_CAL_ID) {
      optionLabel = `${option.nutrient.name} (kcal)`;
    }

    return (
      <div className={classes.filterOption}>
        <Checkbox checked={displayedNutrients.includes(option.id)} />
        <Typography variant="body1">{optionLabel}</Typography>
      </div>
    );
  };

  const onSelectNutrient = (option: NutrientOption | null) => {
    if (!option) return;
    displayedNutrients.includes(option.id)
      ? onUpdateDisplayedNutrients(
          displayedNutrients.filter((id: string): boolean => id !== option.id)
        )
      : onUpdateDisplayedNutrients([...displayedNutrients, option.id]);
  };

  const onButtonClick = () => {
    if (displayNutrientValues) {
      if (lockNutrientColumns) {
        onToggleLockNutrientColumns();
      }
    }
    onToggleDisplayNutrientValues();
  };

  const listboxProps = {
    style: {
      overflowY: "auto",
      maxHeight: 500,
      padding: 0,
    },
  };

  const nutrientSelection = (
    <Autocomplete
      options={createNutrientOptionsList()}
      openOnFocus
      inputValue={searchInput}
      onChange={(event, option) => onSelectNutrient(option)}
      onInputChange={(event, value, reason) => {
        if (reason === "input") {
          setSearchInput(value);
        }
      }}
      getOptionDisabled={(option) =>
        displayedNutrients.length >= 3 &&
        !displayedNutrients.includes(option.id)
      }
      ListboxProps={listboxProps}
      disableCloseOnSelect
      getOptionLabel={(option) => option.nutrient.name}
      renderOption={(option) => selectOption(option)}
      filterOptions={(options) =>
        options.filter((option: NutrientOption): boolean =>
          option.nutrient.name.toLowerCase().includes(searchInput.toLowerCase())
        )
      }
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{ ...params.InputProps }}
          placeholder="Search nutrients..."
          autoFocus={true}
          className={classes.selection}
        />
      )}
      onClose={() => setShowNutrientSelection(false)}
    />
  );

  const displayNutrientValuesButton: ReactNode = (
    <FoodworksTooltip title="Show nutrient columns">
      <IconButton
        onClick={onButtonClick}
        color={displayNutrientValues ? "secondary" : "default"}
        size="small"
      >
        <Visibility />
      </IconButton>
    </FoodworksTooltip>
  );

  const editDisplayedNutrientsButton: ReactNode = (
    <FoodworksTooltip title="Edit displayed nutrients">
      <IconButton
        ref={anchorElement}
        color={showNutrientSelection ? "secondary" : "default"}
        onClick={() => setShowNutrientSelection((prev) => !prev)}
        size="small"
      >
        <Edit />
      </IconButton>
    </FoodworksTooltip>
  );

  const lockNutrientColumnsButton: ReactNode = (
    <FoodworksTooltip title="Lock nutrient columns">
      <IconButton
        onClick={onToggleLockNutrientColumns}
        size="small"
        color={lockNutrientColumns ? "secondary" : "default"}
      >
        <Lock />
      </IconButton>
    </FoodworksTooltip>
  );

  return (
    <div className={classes.root}>
      {displayNutrientValuesButton}
      {displayNutrientValues && editDisplayedNutrientsButton}
      {showNutrientSelection && nutrientSelection}
      {displayNutrientValues && lockNutrientColumnsButton}
    </div>
  );
};
