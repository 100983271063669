import React, { FunctionComponent } from "react";

import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";

import { RootState } from "../../store/reducers";
import { DocumentIsLoadingSelector } from "../../store/data/current-document/selectors/currentDocument";
import { NavIsLoadingSelector } from "../../store/ui/selectors/navigationSelectors";
import { LOADING_BAR_HEIGHT } from "../document_view/LoadingBar";

const useStyles = makeStyles(() => ({
  loadingBarPadding: {
    backgroundColor: "inherit",
    height: LOADING_BAR_HEIGHT,
    width: "100%",
  },
}));

export const LoadingBarPadding: FunctionComponent = () => {
  const classes = useStyles();
  const documentIsLoading: boolean = useSelector<RootState, boolean>(
    DocumentIsLoadingSelector
  );

  const navIsLoading: boolean = useSelector<RootState, boolean>(
    NavIsLoadingSelector
  );

  const isLoading = documentIsLoading || navIsLoading;

  return isLoading ? null : <div className={classes.loadingBarPadding} />;
};
