import React, { FunctionComponent } from "react";

import {
  InputBase,
  withStyles,
  Theme,
  makeStyles,
  InputProps,
} from "@material-ui/core";

import { appTheme } from "../../styling/style";
import { isNumber } from "../document_view/foods/tabs/ingredients/editing_grid/cells/quantityUtil";

export const FoodWorksTextInput = withStyles((theme: Theme) => ({
  root: {
    display: "flex",
    "&.Mui-error": {
      border: `2px solid ${appTheme.colors.error}`,
      borderRadius: "4px",
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: appTheme.colors.input,
    border: `1px solid ${appTheme.colors.gainsbro}`,
    fontSize: appTheme.fontSize.textInput,
    fontFamily: appTheme.fontFamily.textInput,
    padding: "6px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    margin: 1,
    "&:hover": {
      background: appTheme.colors.inputHover,
    },
    "&:focus": {
      border: `2px solid ${theme.palette.secondary.main}`,
      margin: 0,
      background: appTheme.colors.white[0],
    },
    "&:disabled": {
      background: appTheme.colors.gainsbro,
    },
  },
}))(InputBase);

interface FoodworksNumberInputProps {
  setValue: (value: string) => void;
  maxValue?: number;
  inputProps?: InputProps;
  className?: string;
}

const DECIMAL_POINT: string = ".";

export const FoodWorksNumberInput: FunctionComponent<FoodworksNumberInputProps> = ({
  setValue,
  maxValue,
  inputProps,
  className,
}) => {
  const useStyles = makeStyles(() => ({
    input: {
      textAlign: "right",
    },
  }));

  const classes = useStyles();
  const onChange = (input: string): void => {
    if (
      !input ||
      input === DECIMAL_POINT ||
      (isNumber(input) &&
        Number(input) >= 0 &&
        Number(input) < Number.MAX_SAFE_INTEGER)
    ) {
      setValue(input);
    }

    if (maxValue && Number(input) > maxValue) {
      setValue(maxValue.toString());
    }
  };
  return (
    <FoodWorksTextInput
      data-cy="numberInput"
      classes={{ input: `${classes.input} ${className || ""}` }}
      onChange={(event) => onChange(event.currentTarget.value)}
      {...inputProps}
    />
  );
};
