import { ThunkAction } from "redux-thunk";

import Firebase from "../data/Firebase";
import { documentsAreEqual } from "../data/models/document";
import { IActionsUserLogout, userLogout } from "./actions";
import {
  CurrentDocumentIdSelector,
  ServerDocumentSelector,
} from "./data/current-document/selectors/currentDocument";
import { documentSelector } from "./data/current-document/selectors/document";
import { RootState } from "./reducers";
import { setUnsavedDocumentChanges } from "./ui/actionCreators/documentSaving";
import { IActionsSetUnsavedDocumentChanges } from "./ui/actions/documentSaving";
import { Document } from "../data/models/document";
import { RouterHistory } from "./ui/thunks/login";
import { SIGN_IN } from "../constants/routes";

export const signUserOut = (
  history: RouterHistory
): ThunkAction<
  Promise<void>,
  RootState,
  Firebase,
  IActionsSetUnsavedDocumentChanges | IActionsUserLogout
> => async (dispatch, getState, firebase) => {
  const currentDocument: Document = documentSelector(getState());

  const currentDocumentId: string = CurrentDocumentIdSelector(getState());

  const serverDocument: Document = ServerDocumentSelector(getState())!;

  if (!documentsAreEqual(currentDocument, serverDocument)) {
    dispatch(setUnsavedDocumentChanges(true, currentDocumentId, ""));
  } else {
    await firebase?.auth.signOut();
    dispatch(userLogout());
    history.replace(SIGN_IN);
  }
};
