import { ThunkAction } from "redux-thunk";

import Firebase from "../../../../data/Firebase";
import { FoodItems } from "../../../../data/models/documentProperties/foodItem";
import { Section } from "../../../../data/models/documentProperties/section";
import { RootState } from "../../../reducers";
import { getRetentionFactorMap } from "../../selectors/referenceData";
import { foodItemsPasted } from "../action-creators/foodItems";
import { addSection, updateSection } from "../action-creators/sections";
import { IActionsFoodItemsPasted } from "../actions/foodItems";
import { IActionsAddSection, IActionsUpdateSection } from "../actions/sections";
import { daysSelector } from "../selectors/days";

export const addSectionsFromIndex = (
  sectionsToInsert: Section[],
  dayIndex: number,
  sectionIndex: number
): ThunkAction<
  void,
  RootState,
  Firebase,
  IActionsUpdateSection | IActionsAddSection | IActionsFoodItemsPasted
> => async (dispatch, getState) => {
  const onUpdateSection = (section: Section) => {
    dispatch(updateSection(dayIndex, section));
  };

  const retentionFactorMap = getRetentionFactorMap(getState());

  const onAddSection = (section: Section) =>
    dispatch(addSection(dayIndex, section));

  const onFoodItemsPasted = () => dispatch(foodItemsPasted());

  const sections = daysSelector(getState()).days[dayIndex]!.sections;

  const sectionsLength = sections.length;

  const rowsToInsert = sectionsToInsert.length;

  for (let i = 0; i < rowsToInsert; i++) {
    onAddSection(
      new Section(
        sectionsToInsert[i].id,
        sectionsLength + i,
        new FoodItems([], retentionFactorMap),
        "New section",
        false,
        []
      )
    );
  }

  const sortedSections = sections.items.sort(
    (a: Section, b: Section) => a.index - b.index
  );

  for (let i = sortedSections.length - 1; i >= 0; i--) {
    const section = sortedSections[i];

    if (section.index < sectionIndex) break;

    section.index = section.index + rowsToInsert;
    onUpdateSection(section);
  }

  for (let i = 0; i < rowsToInsert; i++) {
    sectionsToInsert[i].index = sectionIndex + i;
    onUpdateSection(sectionsToInsert[i]);
  }

  onFoodItemsPasted();
};
