import React, { FunctionComponent, useState } from "react";

import { ButtonProps } from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import MuiButton from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { withRouter, RouteComponentProps } from "react-router-dom";

import logo from "../../images/favicon.png";
import { SIGN_IN } from "../../constants/routes";
import { useDispatch, useSelector } from "react-redux";
import {
  createUserWithEmailAndPassword,
  LoginError,
} from "../../store/ui/thunks/login";
import { RootState } from "../../store/reducers";
import { signUpErrorSelector } from "../../store/ui/selectors/login";
import { appTheme } from "../../styling/style";
import { FoodworksTooltip } from "../common/InfoTooltip";

export enum SignUpErrorTypes {
  EMAIL = "email",
  PASSWORD = "password",
  GENERAL = "general",
}

function Copyright() {
  return (
    <Typography variant="body1">
      {"Copyright © "}
      <Link color="inherit" href="https://xyris.com.au/">
        Xyris Pty Ltd{" "}
      </Link>
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "auto",
    width: "100%",
    paddingBottom: 50,
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    marginBottom: 16,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  logo: {
    height: 60,
    width: 60,
    marginBottom: 10,
  },
  errorText: {
    color: theme.palette.error.main,
    display: "flex",
    alignItems: "center",
  },
  copyrightContainer: {
    display: "flex",
    justifyContent: "center",
  },
  termsLink: {
    textDecoration: "underline",
    color: appTheme.colors.help,
    "&:hover": {
      color: appTheme.colors.information[6],
    },
  },
}));

const Button = withStyles({
  root: {
    "&.Mui-disabled": {
      pointerEvents: "auto",
    },
  },
})(MuiButton);

const ButtonWithTooltip = (
  disabled: boolean,
  buttonText: string,
  tooltipText: string,
  buttonProps: ButtonProps
) => {
  const adjustedButtonProps = {
    disabled: disabled,
    component: disabled ? "div" : undefined,
  };
  return disabled ? (
    <FoodworksTooltip title={tooltipText}>
      <Button data-cy="signupButton" {...adjustedButtonProps} {...buttonProps}>
        {buttonText}
      </Button>
    </FoodworksTooltip>
  ) : (
    <Button data-cy="signupButton" {...adjustedButtonProps} {...buttonProps}>
      {buttonText}
    </Button>
  );
};

export const SignUpInner: FunctionComponent<RouteComponentProps> = ({
  history,
}) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const onCreateUserWithEmailAndPassword = () =>
    dispatch(
      createUserWithEmailAndPassword(
        email,
        password,
        firstName,
        lastName,
        allowMarketingEmails,
        history
      )
    );

  const error: LoginError = useSelector<RootState, LoginError>(
    signUpErrorSelector
  );

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [allowMarketingEmails, setAllowMarketingEmails] = useState(false);
  const [confirmTerms, setConfirmTerms] = useState(false);
  const [confirmPreviewTerms, setConfirmPreviewTerms] = useState(false);

  const isEmailError = error.type === SignUpErrorTypes.EMAIL;
  const isPasswordError = error.type === SignUpErrorTypes.PASSWORD;

  return (
    <div className={classes.root}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <a href={"http://foodworks.online"}>
            <img src={logo} alt="Logo" className={classes.logo} />
          </a>

          <Typography className={classes.title} variant="h3">
            Sign up to Foodworks.online
          </Typography>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              onCreateUserWithEmailAndPassword();
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="fname"
                  name="firstName"
                  variant="outlined"
                  required
                  fullWidth
                  id="firstName"
                  label="First name"
                  value={firstName}
                  onChange={(event) => setFirstName(event.target.value)}
                  autoFocus
                  color="secondary"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="lastName"
                  label="Last name"
                  value={lastName}
                  onChange={(event) => setLastName(event.target.value)}
                  name="lastName"
                  autoComplete="lname"
                  color="secondary"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email address"
                  name="email"
                  autoComplete="email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  error={isEmailError}
                  helperText={isEmailError && error.message}
                  color="secondary"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                  error={isPasswordError}
                  helperText={isPasswordError && error.message}
                  color="secondary"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={allowMarketingEmails}
                      color="secondary"
                      onChange={() =>
                        setAllowMarketingEmails(!allowMarketingEmails)
                      }
                    />
                  }
                  label="I want to join the Foodworks.online mailing list to be notified of the latest updates"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      data-cy="termsCheckbox"
                      checked={confirmTerms}
                      color="secondary"
                      onChange={() => setConfirmTerms(!confirmTerms)}
                    />
                  }
                  label={
                    <Typography>
                      {"I have read and agree to the "}
                      <a
                        className={classes.termsLink}
                        href="https://support.foodworks.online/hc/en-au/articles/360002159296-Foodworks-online-Terms-"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Terms of Service
                      </a>
                    </Typography>
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      data-cy="previewTermsCheckbox"
                      checked={confirmPreviewTerms}
                      color="secondary"
                      onChange={() =>
                        setConfirmPreviewTerms(!confirmPreviewTerms)
                      }
                    />
                  }
                  label={
                    <Typography component={"span"}>
                      <p>
                        <b>Special Terms for this Preview</b>: I understand that
                        this free preview is pre-release software, supplied as
                        is and with all faults.
                      </p>
                      <p>
                        I acknowledge that the preview is not suitable for work,
                        that calculations may be incorrect, that features may be
                        missing, and that at any time my data and / or account
                        may be lost or not preserved. Using this preview, I will
                        not store any data that is or may be subject to
                        heightened legal or regulatory requirements. Using this
                        preview does not entitle me to any support services,
                        which will be provided at the sole discretion of Xyris
                        Pty Ltd.
                      </p>
                      <p>
                        I recognise that this preview has been provided to a
                        select group of users, and I will not share this preview
                        with any other persons without the written consent of
                        Xyris Pty Ltd.
                      </p>
                    </Typography>
                  }
                />
              </Grid>
            </Grid>
            {error.type === SignUpErrorTypes.GENERAL ? (
              <div className={classes.errorText}>
                <ErrorOutlineIcon color="error" data-cy="errorIcon" />
                {error.message}
              </div>
            ) : null}
            {ButtonWithTooltip(
              // TODO: Make this conditional on if we are currently in the preview environment
              !confirmTerms || !confirmPreviewTerms,
              "Sign up",
              "You must first agree to the Terms of Service",
              {
                fullWidth: true,
                variant: "contained",
                color: "secondary",
                type: "submit",
                className: classes.submit,
              }
            )}
          </form>
          <Grid container justify="flex-end">
            <Grid item>
              <Link href={SIGN_IN} variant="body2" color="secondary">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </div>
        <Box mt={5} className={classes.copyrightContainer}>
          <Copyright />
        </Box>
      </Container>
    </div>
  );
};

const SignUp = withRouter(SignUpInner);

export default SignUp;
