import React, { FunctionComponent, useEffect, useState } from "react";

import { makeStyles, Typography, Checkbox } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";

import { appTheme } from "../../../../../styling/style";
import {
  FoodWorksTextInput,
  FoodWorksNumberInput,
} from "../../../../common/FoodWorksTextInput";
import { RootState } from "../../../../../store/reducers";
import { Document } from "../../../../../data/models/document";
import { MeasuresTable } from "./MeasuresTable";
import {
  volumeConversionSelector,
  documentPropertiesSelector,
} from "../../../../../store/data/current-document/selectors/document";
import {
  updateVolumeConversion,
  toggleIsLiquid,
} from "../../../../../store/data/current-document/action-creators/document";
import { IngredientSummaryItem } from "../ingredients/editing_grid/cells/IngredientCell";
import { MeasuresSearch } from "./MeasureSearch";
import { VolumeConversionObject } from "../../../../../data/models/documentProperties/volumeConversionFactor";

export interface FoodMeasuresProps {
  summaries: IngredientSummaryItem[];
}

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    background: appTheme.colors.white[0],
  },
  inputs: {
    display: "flex",
    flexDirection: "column",
    padding: 10,
    flex: 1,
  },
  flexDisplay: {
    display: "flex",
    alignItems: "center",
  },
  volumeText: {
    width: 75,
  },
  liquidText: {
    width: 75,
    alignSelf: "center",
  },
  tableDiv: {
    margin: 10,
    flex: 3,
    overflow: "hidden",
    border: `1px solid ${appTheme.colors.gainsbro}`,
    borderRadius: 4,
  },
  input: {
    margin: "0px 5px 0px 5px",
    width: 70,
  },
  flex: {
    flex: 1,
  },
  displayAndFlex: {
    flex: 1,
    display: "flex",
  },
  mlLabel: {
    minWidth: 30,
  },
  gLabel: {
    minWidth: 30,
  },
}));

export const FoodMeasures: FunctionComponent<FoodMeasuresProps> = ({
  summaries,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  // *** Actions ***
  const onVolumeGUpdated = (grams: number | undefined) =>
    dispatch(
      updateVolumeConversion({
        volumeG: grams,
        volumeMl: volumeConversionData.volumeMl,
      })
    );

  const onVolumeMlUpdated = (milliliters: number | undefined) =>
    dispatch(
      updateVolumeConversion({
        volumeG: volumeConversionData.volumeG,
        volumeMl: milliliters,
      })
    );
  // *** ----- ***

  // *** State ***
  const volumeConversionData = useSelector<RootState, VolumeConversionObject>(
    volumeConversionSelector
  );

  const documentProperties = useSelector<RootState, Document["properties"]>(
    documentPropertiesSelector
  );

  const [volumeGInput, setVolumeGInput] = useState("");
  const [volumeMlInput, setVolumeMlInput] = useState("");

  useEffect(() => {
    setVolumeGInput(volumeConversionData.volumeG?.toString() || "");
    setVolumeMlInput(volumeConversionData.volumeMl?.toString() || "");
  }, [volumeConversionData]);

  // *** ----- ***

  const onToggleIsLiquid = () => dispatch(toggleIsLiquid());

  const onVolumeMlInputBlur = () => {
    if (!volumeMlInput) {
      onVolumeMlUpdated(undefined);
    } else if (Number(volumeMlInput) > 0) {
      onVolumeMlUpdated(Number(volumeMlInput));
    } else {
      setVolumeMlInput(volumeConversionData.volumeMl?.toString() || "");
    }
  };

  const onVolumeGInputBlur = () => {
    if (!volumeGInput) {
      onVolumeGUpdated(undefined);
    } else if (Number(volumeGInput) > 0) {
      onVolumeGUpdated(Number(volumeGInput));
    } else {
      setVolumeGInput(volumeConversionData.volumeG?.toString() || "");
    }
  };

  const volumeConversion = (
    <div className={classes.flexDisplay}>
      <Typography variant="body1" className={classes.volumeText}>
        Volume:
      </Typography>
      <FoodWorksNumberInput
        data-cy="volumeConversionMl"
        className={classes.input}
        inputProps={{
          value: volumeMlInput,
          onBlur: onVolumeMlInputBlur,
        }}
        setValue={setVolumeMlInput}
      />
      <Typography variant="body1" className={classes.mlLabel}>
        mL =
      </Typography>
      <FoodWorksNumberInput
        data-cy="volumeConversionG"
        className={classes.input}
        inputProps={{
          value: volumeGInput,
          onBlur: onVolumeGInputBlur,
        }}
        setValue={setVolumeGInput}
      />
      <Typography variant="body1" className={classes.gLabel}>
        g
      </Typography>
    </div>
  );

  const isLiquid = (
    <div className={classes.flexDisplay}>
      <Typography variant="body1" className={classes.liquidText}>
        Liquid:
      </Typography>
      <Checkbox
        data-cy="isLiquidCheckbox"
        checked={documentProperties.isLiquid}
        onChange={() => onToggleIsLiquid()}
      />
    </div>
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const ediblePortion = (
    <div className={classes.flexDisplay}>
      <Typography variant="body1">Edible portion (%):</Typography>
      <FoodWorksTextInput className={classes.input} value="Unimplemented" />
    </div>
  );

  return (
    <div className={classes.root}>
      <div className={classes.tableDiv}>
        <MeasuresTable />
      </div>

      <div className={classes.inputs}>
        <div className={classes.displayAndFlex}>
          <div className={classes.flex}>
            {volumeConversion}
            {isLiquid}
            {/* {ediblePortion} */}
          </div>

          <MeasuresSearch combinedSummaries={summaries} />
        </div>
      </div>
    </div>
  );
};
