import React, {
  FunctionComponent,
  ReactNode,
  useState,
  useEffect,
} from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Card,
  DialogActions,
  Button,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";

import { IngredientSummaryItem } from "./tabs/ingredients/editing_grid/cells/IngredientCell";
import { VirtualizedDocumentList } from "../../common/VirtualizedDocumentList";
import { RootState } from "../../../store/reducers";
import { databaseIdSelector } from "../../../store/data/selectors/database";
import { updateCalculationMethod } from "../../../store/data/current-document/action-creators/document";
import { FoodWorksTextInput } from "../../common/FoodWorksTextInput";
import { filterOptions } from "./tabs/ingredients/editing_grid/cells/ingredientUtils";
import { FoodId } from "../../../data/models/documentProperties/foodId";
import { appTheme } from "../../../styling/style";
import { recentMappedDocumentIdSelector } from "../../../store/ui/selectors/overridesScreen";
import { calculationMethodSelector } from "../../../store/data/current-document/selectors/document";
import { fetchDocument } from "../../../store/data/action-creators/documentCache";
import { setRecentMappedDocumentId } from "../../../store/ui/actionCreators/overridesScreen";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  body: { display: "flex", flex: 1, flexDirection: "column" },
  listContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    background: appTheme.colors.white[8],
    border: `1px solid ${appTheme.colors.white[12]}`,
    borderRadius: 5,
    padding: 10,
    height: 700,
  },
}));

const SEARCH_RESULT_LIMIT = 500;

interface MappedDocumentDialogOption {
  text: string;
  onClick: () => void;
  disabled?: boolean;
  color?: "secondary" | "default" | "inherit" | "primary" | undefined;
}

interface MappedFoodDialogProps {
  open: boolean;
  onClose: () => void;
  combinedSummaries: IngredientSummaryItem[];
  searchTermMap: Map<string, string[]>;
}

interface SelectedMappedDocument {
  foodId: FoodId | undefined;
  isPublic: boolean | undefined;
}

const initialSelectedMappedDocument: SelectedMappedDocument = {
  foodId: undefined,
  isPublic: undefined,
};

export const MappedFoodDialog: FunctionComponent<MappedFoodDialogProps> = ({
  open,
  onClose,
  combinedSummaries,
  searchTermMap,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onFetchMappedDocument = (foodId: FoodId, isPublic: boolean) =>
    dispatch(fetchDocument(foodId, isPublic, true));

  const onUpdateCalculationMethod = (
    calculationMethod: number,
    documentId: string
  ) => dispatch(updateCalculationMethod(calculationMethod, documentId));

  const mappedDocumentId: string = useSelector<RootState, string>(
    recentMappedDocumentIdSelector
  );

  const previousCalculationMethod: number = useSelector<RootState, number>(
    calculationMethodSelector
  );

  const databaseId: string = useSelector<RootState, string>(databaseIdSelector);

  const [selectedMappedDocument, setSelectedMappedDocument] = useState(
    initialSelectedMappedDocument
  );
  const [searchInput, setSearchInput] = useState<string>("");
  const [searchResultCount, setSearchResultCount] = useState(0);

  useEffect(() => {
    const setCurrentMappedDocument = async () => {
      if (mappedDocumentId) {
        const [datasourceId, documentId] = mappedDocumentId.split(":");
        const foodId = new FoodId({
          datasourceId: datasourceId,
          documentId: documentId,
        });
        const isPublic: boolean = datasourceId !== databaseId;
        setSelectedMappedDocument({ foodId: foodId, isPublic: isPublic });
      } else {
        setSelectedMappedDocument({ foodId: undefined, isPublic: undefined });
      }
    };
    setCurrentMappedDocument();
  }, [mappedDocumentId, databaseId]);

  const getFilteredSearchResults = (): IngredientSummaryItem[] => {
    const options = filterOptions(
      combinedSummaries,
      searchInput,
      searchTermMap
    );
    if (searchResultCount !== options.length) {
      setSearchResultCount(options.length);
    }
    return options.slice(0, SEARCH_RESULT_LIMIT) as IngredientSummaryItem[];
  };

  const onListItemClicked = (item: IngredientSummaryItem) =>
    setSelectedMappedDocument({ foodId: item.foodId, isPublic: item.isPublic });

  const onListItemDoubleClicked = (item: IngredientSummaryItem) => {
    handleFoodSelection({ foodId: item.foodId, isPublic: item.isPublic });
  };

  const header: ReactNode = <DialogTitle>Select Food/Recipe</DialogTitle>;

  const searchCappedText: ReactNode = (
    <Typography variant="caption">
      Search results limited to 500 items
    </Typography>
  );

  const foodDocumentList: ReactNode = (
    <Card className={classes.listContainer}>
      <FoodWorksTextInput
        autoFocus
        data-cy="mappedFoodsDialogSearch"
        placeholder="Search foods..."
        value={searchInput}
        onChange={(event) => setSearchInput(event.target.value)}
      />
      <VirtualizedDocumentList
        onListItemClicked={onListItemClicked}
        onListItemDoubleClicked={onListItemDoubleClicked}
        summaries={getFilteredSearchResults()}
        selectedItem={selectedMappedDocument.foodId?.identifier || ""}
      />
      {searchResultCount > SEARCH_RESULT_LIMIT && searchCappedText}
    </Card>
  );

  const handleFoodSelection = (mappedDocument?: SelectedMappedDocument) => {
    let documentSelected = mappedDocument || selectedMappedDocument;
    if (documentSelected.foodId && documentSelected.isPublic !== undefined) {
      onFetchMappedDocument(
        documentSelected.foodId!,
        documentSelected.isPublic!
      );
      dispatch(setRecentMappedDocumentId(documentSelected.foodId.identifier));
      onClose();
    }
  };

  const handleCloseDialog = () => {
    if (
      !selectedMappedDocument.foodId &&
      selectedMappedDocument.isPublic === undefined
    ) {
      onUpdateCalculationMethod(previousCalculationMethod, "");
    }
    onClose();
  };

  const bottomDialogActions: MappedDocumentDialogOption[] = [
    {
      text: "Cancel",
      onClick: () => handleCloseDialog(),
      disabled: false,
      color: "default",
    },
    {
      text: "Ok",
      onClick: () => handleFoodSelection(),
      disabled: !selectedMappedDocument.foodId,
      color: "secondary",
    },
  ];

  const bottomActionButtons: ReactNode[] = bottomDialogActions.map(
    (option: MappedDocumentDialogOption) => (
      <Button
        data-cy="mappedDialogAction"
        key={option.text}
        onClick={option.onClick}
        disabled={option.disabled}
        color={option.color}
      >
        {option.text}
      </Button>
    )
  );

  const body: ReactNode = (
    <DialogContent className={classes.body}>
      {foodDocumentList}

      <DialogActions>{bottomActionButtons}</DialogActions>
    </DialogContent>
  );

  return (
    <Dialog
      data-cy="mappedFoodDialog"
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      className={classes.root}
    >
      {header}
      {body}
    </Dialog>
  );
};
