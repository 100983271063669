import { RootState } from "../../reducers";
import { DatabasePropertiesState } from "../reducers/databaseProperties";

export const DatabasePropertiesSelector = (
  state: RootState
): DatabasePropertiesState => state.database.databaseProperties;

export const enabledDatasourcesSelector = (
  state: RootState
): DatabasePropertiesState["enabledDatasources"] =>
  state.database.databaseProperties.enabledDatasources;

export const displayedNutrientsSelector = (state: RootState): string[] =>
  state.database.databaseProperties.displayedNutrients;
