import { RootState } from "../../reducers";
import { NutritionPaneState } from "../reducers/nutritionPaneReducers";

export const compositionOptionSelector = (
  state: RootState
): NutritionPaneState["selectedAmount"] => state.nutritionPane.selectedAmount;

export const customCompositionAmountSelector = (
  state: RootState
): NutritionPaneState["customAmount"] => state.nutritionPane.customAmount;

export const expandedCategoriesSelector = (state: RootState): string[] =>
  state.nutritionPane.categoriesExpanded;

export const selectedSectionTagsSelector = (state: RootState): string[] =>
  state.nutritionPane.selectedSectionTags;
