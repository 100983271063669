import React, { ReactNode, useState } from "react";

import { Chip, createStyles, makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import { removeSectionTag } from "../../../../../../../store/data/current-document/action-creators/sections";
import { sectionTagsSelector } from "../../../../../../../store/data/current-document/selectors/sectionTags";
import { RootState } from "../../../../../../../store/reducers";
import { FoodworksTooltip } from "../../../../../../common/InfoTooltip";
import { SectionTag } from "../../../../../../../data/models/documentProperties/section";
import { selectedSectionTagsSelector } from "../../../../../../../store/ui/selectors/nutritionPaneSelectors";
import { SimpleCreateSectionTagDialog } from "../../../../../../dialogs/section_tags/SimpleCreateSectionTagDialog";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "center",
    },
    tag: {
      fontSize: 10,
      marginLeft: 1,
      marginRight: 1,
      borderRadius: 4,
      overflow: "ellipsis",
      whiteSpace: "nowrap",
      maxWidth: 100,
    },
  })
);

const COMPLETE_TAGS_TO_SHOW = 2;

interface SectionTagProps {
  dayIndex: number;
  sectionIndex: number;
  sectionTagIds: string[];
}

export const SectionTags = React.memo<SectionTagProps>(
  ({ dayIndex, sectionIndex, sectionTagIds }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const onRemoveSectionTag = (tagId: string) =>
      dispatch(removeSectionTag(dayIndex, sectionIndex, tagId));

    const selectedSectionTags: string[] = useSelector<RootState, string[]>(
      selectedSectionTagsSelector
    );

    const documentSectionTags: SectionTag[] = useSelector<
      RootState,
      SectionTag[]
    >(sectionTagsSelector);

    const [selectedTag, setSelectedTag] = useState("");

    const sectionTags: SectionTag[] = documentSectionTags.filter(
      (tag: SectionTag): boolean => sectionTagIds.includes(tag.id)
    );

    const tags: ReactNode = sectionTags.map(
      (tag: SectionTag): ReactNode => (
        <FoodworksTooltip
          key={`info-${sectionIndex}-${tag.id}`}
          title={sectionTags.length <= COMPLETE_TAGS_TO_SHOW ? "" : tag.label}
        >
          <Chip
            key={`${sectionIndex}-${tag.id}`}
            size="small"
            label={sectionTags.length > COMPLETE_TAGS_TO_SHOW ? "" : tag.label}
            clickable
            className={`notSelectable ${classes.tag}`}
            style={{
              backgroundColor: selectedSectionTags.includes(tag.id)
                ? tag.activeColor
                : tag.inactiveColor,
              border: `1px solid ${tag.activeColor}`,
            }}
            onClick={() => setSelectedTag(tag.id)}
            onDelete={() => onRemoveSectionTag(tag.id)}
          />
        </FoodworksTooltip>
      )
    );

    return (
      <div className={classes.root}>
        {tags}
        {!!selectedTag && (
          <SimpleCreateSectionTagDialog
            tagId={selectedTag}
            isEditing
            onClose={() => setSelectedTag("")}
          />
        )}
      </div>
    );
  }
);
