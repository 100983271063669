import React, { FunctionComponent, ReactNode, useState } from "react";

import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  List,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Add, Close } from "@material-ui/icons";
import { v4 as uuidv4 } from "uuid";
import { batch, useDispatch, useSelector } from "react-redux";

import {
  DEFAULT_MEAL_PLAN_SECTIONS,
  isMealPlan,
} from "../../../constants/FoodTemplate";
import { Day, Days } from "../../../data/models/documentProperties/day";
import { RetentionFactor } from "../../../data/models/documentProperties/retentionFactor";
import { daysSelector } from "../../../store/data/current-document/selectors/days";
import { RootState } from "../../../store/reducers";
import { DaySummaryListItem } from "./components/DaySummaryListItem";
import { templateIdSelector } from "../../../store/data/current-document/selectors/document";
import { setDays } from "../../../store/data/current-document/action-creators/days";
import { setSelectedSectionTags } from "../../../store/ui/actionCreators/nutritionPaneActionCreators";
import {
  updateCurrentDay,
  setSelectedRows,
} from "../../../store/ui/actionCreators/recipeGrid";

const useStyles = makeStyles((theme) => ({
  titleBar: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  body: {
    margin: 25,
    marginTop: 5,
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "start",
  },
  daySummaryContainer: {
    marginLeft: 10,
    width: "100%",
    display: "flex",
  },
  daySummaryList: {
    overflow: "auto",
    height: 300,
    padding: 5,
    flex: 1,
  },
  modifyPlanBottomButtons: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  summaryTitle: {
    marginLeft: 10,
    marginTop: 10,
  },
  list: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
}));

interface EditMealPlanDialogProps {
  onClose: () => void;
}

export const EditMealPlanDialog: FunctionComponent<EditMealPlanDialogProps> = ({
  onClose,
}) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const onSetDays = (days: Day[]) => dispatch(setDays(days));

  const onResetCurrentDay = () => dispatch(updateCurrentDay(0));

  const onResetSelection = () =>
    batch(() => {
      dispatch(setSelectedRows([]));
      dispatch(setSelectedSectionTags([]));
    });

  const templateId: string = useSelector<RootState, string>(templateIdSelector);

  const days: Days = useSelector<RootState, Days>(daysSelector);
  const [currentDays, setCurrentDays] = useState<Day[]>(days.days);

  const onUpdateDayTitle = (dayIndex: number, title: string) =>
    setCurrentDays(
      currentDays.map(
        (day: Day): Day =>
          day.index === dayIndex
            ? new Day(day.id, day.index, day.sections, title, day.date)
            : day
      )
    );

  const onUpdateDayDate = (dayIndex: number, date: string) =>
    setCurrentDays(
      currentDays.map(
        (day: Day): Day =>
          day.index === dayIndex
            ? new Day(day.id, day.index, day.sections, day.title, date)
            : day
      )
    );

  const onDeleteDay = (dayIndex: number) => {
    const filteredDays: Day[] = currentDays.filter(
      (day: Day): boolean => day.index !== dayIndex
    );

    setCurrentDays(
      filteredDays.map(
        (day: Day, index: number): Day =>
          new Day(day.id, index, day.sections, day.title, day.date)
      )
    );
  };

  const onClickAddDay = () => {
    setCurrentDays(
      currentDays.concat(
        Day.fromObject(
          {
            id: uuidv4(),
            index: currentDays.length,
            sections: DEFAULT_MEAL_PLAN_SECTIONS,
            title: `Day ${currentDays.length + 1}`,
            date: "",
          },
          new Map<string, RetentionFactor>([])
        )
      )
    );
  };

  const onSaveChanges = () => {
    batch(() => {
      onSetDays(currentDays);
      onResetSelection();
      onResetCurrentDay();
    });
    onClose();
  };

  const addDayButton: ReactNode = (
    <Button
      data-cy="createSectionTag"
      startIcon={<Add color="secondary" />}
      onClick={onClickAddDay}
      size="small"
    >
      Add day
    </Button>
  );

  const daySummaryList: ReactNode = (
    <div className={classes.daySummaryContainer}>
      <List className={classes.daySummaryList}>
        {currentDays.map(
          (day: Day): ReactNode => (
            <DaySummaryListItem
              key={day.index}
              day={day}
              onUpdateTitle={(title: string) =>
                onUpdateDayTitle(day.index, title)
              }
              onUpdateDate={(date: string) => onUpdateDayDate(day.index, date)}
              onDelete={() => onDeleteDay(day.index)}
            />
          )
        )}
        {addDayButton}
      </List>
    </div>
  );

  const header: ReactNode = (
    <div className={classes.titleBar}>
      <DialogTitle data-cy="editMealPlan">
        {isMealPlan(templateId)
          ? "Edit meal plan details"
          : "Edit food record details"}
      </DialogTitle>
      <IconButton onClick={onClose}>
        <Close />
      </IconButton>
    </div>
  );

  const dialogActions: ReactNode = (
    <DialogActions>
      <Button color="default" onClick={onClose}>
        Cancel
      </Button>
      <Button color="secondary" onClick={onSaveChanges}>
        Save changes
      </Button>
    </DialogActions>
  );

  return (
    <Dialog maxWidth="sm" fullWidth open onClose={onClose}>
      {header}
      <Card className={classes.body}>
        <Typography className={classes.summaryTitle} variant="body1">
          {isMealPlan(templateId)
            ? "Meal plan summary:"
            : "Food record summary:"}
        </Typography>
        <div className={classes.list}>{daySummaryList}</div>
      </Card>
      {dialogActions}
    </Dialog>
  );
};
