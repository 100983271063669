import { ThunkAction, ThunkDispatch } from "redux-thunk";

import { RootState } from "../../../reducers";
import Firebase from "../../../../data/Firebase";
import {
  IActionsSetDocumentData,
  IActionsUpdateServerDocument,
} from "../actions/currentDocument";
import {
  IActionsAddUserDocumentSummary,
  IActionsUpdateUserDocumentSummary,
} from "../../actions/database";
import { updateServerDocument } from "../action-creators/currentDocument";
import { updateDocumentLastModified } from "../action-creators/document";
import {
  DocumentSummary,
  UserDatabaseSummary,
} from "../../../../data/models/userDatabase";
import {
  addUserDocumentSummary,
  updateUserDocumentSummary,
} from "../../action-creators/database";
import { ServerDocumentSelector } from "../selectors/currentDocument";
import {
  databaseIdSelector,
  userDocumentSummariesSelector,
} from "../../selectors/database";
import { documentSelector } from "../selectors/document";
import { Document } from "../../../../data/models/document";
import { batch } from "react-redux";
import {
  IActionsAddDocument,
  IActionsUpdateDocument,
} from "../../actions/documentCache";
import {
  DEFAULT_MEAL_PLAN_SECTIONS,
  FoodTemplate,
  is24HourRecall,
  isFood,
  isFoodRecord,
  isMealPlan,
} from "../../../../constants/FoodTemplate";
import { initialDocumentState } from "../reducers/document";
import {
  addDocument,
  updateDocument,
} from "../../action-creators/documentCache";
import { allCachedDocumentsSelector } from "../../selectors/documentCache";
import {
  TEMPORARY_NEW_DOCUMENT,
  TEMPORARY_DOCUMENT,
} from "../reducers/currentDocument";
import { setUserDatabaseSummaries } from "../../action-creators/user";
import { IActionsSetUserDatabaseSummaries } from "../../actions/user";
import { currentDate } from "../../../../data/models/documentProperties/date";
import { IActionsUpdateDocumentModifiedDate } from "../actions/document";
import { setDocumentIdToChangeTo } from "../../../ui/actionCreators/documentSaving";
import { IActionsSetDocumentIdToChangeTo } from "../../../ui/actions/documentSaving";
import { setDisableSaveButton } from "../../../ui/actionCreators/documentEditing";
import { IActionsSetDisableSaveButton } from "../../../ui/actions/documentEditing";
import { ENABLED_DATA_SOURCES } from "../../../../constants/datasources";
import { fetchDocument } from "../../../../data/Firebase/helpers/fetchDocument";
import { FoodId } from "../../../../data/models/documentProperties/foodId";
import { FoodItemState } from "../../../../data/models/documentProperties/foodItem";
import { DayState } from "../../../../data/models/documentProperties/day";
import { CalculationMethod } from "../../../../constants/calculationMethod";
import { fetchComposition } from "../../action-creators/compositionCache";
import { IActionsFetchComposition } from "../../actions/compositionCache";

type saveDocumentActions =
  | IActionsSetDocumentData
  | IActionsAddUserDocumentSummary
  | IActionsUpdateUserDocumentSummary
  | IActionsUpdateDocument
  | IActionsAddDocument
  | IActionsUpdateServerDocument
  | IActionsUpdateDocumentModifiedDate
  | IActionsSetUserDatabaseSummaries
  | IActionsSetDocumentIdToChangeTo
  | IActionsSetDisableSaveButton
  | IActionsFetchComposition;
type dispatchType = ThunkDispatch<RootState, Firebase, saveDocumentActions>;

const updateOrCreateDocument = async (
  rootState: RootState,
  currentDocumentId: string,
  document: Document,
  firebase: Firebase,
  dispatch: dispatchType
): Promise<string> => {
  const currentDatabaseId: string = databaseIdSelector(rootState);
  const serverDocument: Document | undefined = ServerDocumentSelector(
    rootState
  );
  if (currentDocumentId !== TEMPORARY_DOCUMENT && serverDocument) {
    firebase?.doUpdateDocument(currentDatabaseId, currentDocumentId, document);

    batch(() => {
      dispatch(updateServerDocument(document));
      dispatch(updateDocumentLastModified(document.date.lastModified));

      dispatch(
        updateDocument(`${currentDatabaseId}:${currentDocumentId}`, document)
      );
      dispatch(
        fetchComposition(`${currentDatabaseId}:${currentDocumentId}`, document)
      );
    });
  } else {
    const documentData = await firebase?.doCreateDocument(
      currentDatabaseId,
      document
    );

    currentDocumentId = documentData.id;
    await firebase?.doCreateSummary(
      currentDatabaseId,
      document,
      currentDocumentId
    );

    batch(() => {
      dispatch(updateServerDocument(document));
      dispatch(updateDocumentLastModified(document.date.lastModified));
      dispatch(
        addDocument(document, `${currentDatabaseId}:${currentDocumentId}`)
      );
      dispatch(
        fetchComposition(`${currentDatabaseId}:${currentDocumentId}`, document)
      );
    });

    if (currentDocumentId || currentDocumentId !== TEMPORARY_DOCUMENT) {
      dispatch(setDocumentIdToChangeTo(currentDocumentId));
    }
  }

  return currentDocumentId;
};

const updateDocumentSummaries = async (
  currentDatabaseId: string,
  existingDocumentSummary: DocumentSummary | undefined,
  documentId: string,
  document: Document,
  firebase: Firebase,
  dispatch: dispatchType
): Promise<void> => {
  if (existingDocumentSummary) {
    if (
      existingDocumentSummary.label !== document.name ||
      existingDocumentSummary.isDeleted !== document.properties.isDeleted ||
      existingDocumentSummary.searchableProperties !==
        { ...document.identifier }
    ) {
      await firebase?.doUpdateSummary(
        currentDatabaseId,
        document,
        documentId,
        existingDocumentSummary
      );
      dispatch(
        updateUserDocumentSummary({
          ...existingDocumentSummary,
          label: document.name,
          isDeleted: document.properties.isDeleted,
          searchableProperties: {
            ...document.identifier,
          },
          sectionTags: document.sectionTags,
          days:
            isMealPlan(document.templateId) || isFoodRecord(document.templateId)
              ? document.days.map((day: DayState): string =>
                  day.date ? `${day.title}-${day.date}` : day.title
                )
              : [],
        })
      );
    }
  } else {
    dispatch(
      addUserDocumentSummary({
        documentId: documentId,
        label: document.name,
        templateId: Number(document.templateId),
        isDeleted: document.properties.isDeleted,
        searchableProperties: {
          ...document.identifier,
        },
        sectionTags: document.sectionTags,
        days:
          isMealPlan(document.templateId) || isFoodRecord(document.templateId)
            ? document.days.map((day: DayState): string =>
                day.date ? `${day.title}-${day.date}` : day.title
              )
            : [],
      })
    );
  }
};

export const saveDocumentDeletion = (
  uid: string,
  documentIdToSave: string
): ThunkAction<void, RootState, Firebase, saveDocumentActions> => async (
  dispatch,
  getState,
  firebase
) => {
  const lastModifiedDate = currentDate();
  const document: Document | undefined = {
    ...(ServerDocumentSelector(getState()) || initialDocumentState),
  };

  document.date.lastModified = lastModifiedDate;
  document.properties.isDeleted = !document.properties.isDeleted;

  dispatch(handleSaveForDocument(uid, documentIdToSave, document));
};

export const saveDocument = (
  uid: string,
  documentIdToSave: string
): ThunkAction<
  Promise<void>,
  RootState,
  Firebase,
  saveDocumentActions
> => async (dispatch, getState, firebase) => {
  dispatch(setDisableSaveButton(true));
  const lastModifiedDate = currentDate();
  let document: Document = { ...documentSelector(getState()) };
  document.date.lastModified = lastModifiedDate;
  return dispatch(handleSaveForDocument(uid, documentIdToSave, document));
};

export const getUpdatedRecipes = (
  measureDocument: Document,
  measureAdded: boolean,
  documentId: string,
  measureId: string
) => {
  const recipesUsingMeasure: string[] =
    measureDocument.commonMeasures.measures.find(
      (element) => element.id === measureId
    )?.usedIn || [];

  if (measureAdded) {
    if (recipesUsingMeasure.includes(documentId)) {
      return recipesUsingMeasure;
    }
    return recipesUsingMeasure.concat(documentId);
  }
  return recipesUsingMeasure.filter(
    (recipeId: string) => recipeId !== documentId
  );
};

export const handleMeasureUsedInUpdate = async (
  firebase: Firebase,
  foodItem: FoodItemState,
  documentIdToSave: string,
  measureId: string,
  shouldAdd: boolean
): Promise<void> => {
  const measureDocument = await fetchDocument(
    firebase,
    new FoodId(foodItem.foodId!),
    false
  );

  const updatedRecipes = getUpdatedRecipes(
    measureDocument,
    shouldAdd,
    documentIdToSave,
    measureId
  );

  measureDocument.commonMeasures.measures = measureDocument.commonMeasures.measures.map(
    (element) => {
      if (element.id === measureId) {
        return {
          ...element,
          usedIn: updatedRecipes,
        };
      }
      return element;
    }
  );

  await firebase.doUpdateDocument(
    foodItem.foodId!.datasourceId,
    foodItem.foodId!.documentId,
    measureDocument
  );
};

export const getValidFoodItems = (
  document: Document | undefined
): FoodItemState[] => {
  let documentSet = new Set<FoodItemState>();
  if (!document) return [...documentSet];

  for (const day of document.days) {
    for (const section of day.sections) {
      for (const foodItem of section.foodItems) {
        const isPublicMeasure = ENABLED_DATA_SOURCES.includes(
          foodItem.foodId?.datasourceId || ""
        );

        const isReferenceMeasure =
          foodItem.quantity && Number(foodItem.quantity.measureId);

        const isValidMeasure = !isPublicMeasure && !isReferenceMeasure;

        if (foodItem.quantity && isValidMeasure) {
          documentSet.add(foodItem);
        }
      }
    }
  }

  return [...documentSet];
};

export const updateMeasureReferences = async (
  document: Document,
  serverDocument: Document | undefined,
  firebase: Firebase,
  documentIdToSave: string
) => {
  const documentItems: FoodItemState[] = getValidFoodItems(document);
  const serverItems: FoodItemState[] = getValidFoodItems(serverDocument);

  const documentMeasures = documentItems.map(
    (item: FoodItemState): string => item.quantity!.measureId
  );

  const serverMeasures = serverItems.map(
    (item: FoodItemState): string => item.quantity!.measureId
  );

  for (let i = 0; i < documentMeasures.length; i++) {
    const measureId = documentMeasures[i];

    if (!serverMeasures.includes(measureId)) {
      const foodItem = documentItems[i];

      await handleMeasureUsedInUpdate(
        firebase,
        foodItem,
        documentIdToSave,
        measureId,
        true
      );
    }
  }

  for (let i = 0; i < serverMeasures.length; i++) {
    const measureId = serverMeasures[i];

    if (!documentMeasures.includes(measureId)) {
      const foodItem = serverItems[i];

      await handleMeasureUsedInUpdate(
        firebase,
        foodItem,
        documentIdToSave,
        measureId,
        false
      );
    }
  }
};

export const handleSaveForDocument = (
  uid: string,
  documentIdToSave: string,
  document: Document
): ThunkAction<void, RootState, Firebase, saveDocumentActions> => async (
  dispatch,
  getState,
  firebase
) => {
  const onSetUserDatabaseSummaries = (summaries: UserDatabaseSummary[]) =>
    dispatch(setUserDatabaseSummaries(summaries));

  const rootState = getState();
  const serverDocument = ServerDocumentSelector(getState());
  const lastModifiedDate = currentDate();
  const currentDatabaseId: string = databaseIdSelector(rootState);
  const userDocumentSummaries: DocumentSummary[] = userDocumentSummariesSelector(
    rootState
  );

  await updateMeasureReferences(
    document,
    serverDocument,
    firebase,
    documentIdToSave
  );

  // The ID will change if a document is created
  const documentId: string = await updateOrCreateDocument(
    rootState,
    documentIdToSave,
    document,
    firebase,
    dispatch
  );

  const documentSummary:
    | DocumentSummary
    | undefined = userDocumentSummaries.find(
    (summary: DocumentSummary): boolean => summary.documentId === documentId
  );

  await updateDocumentSummaries(
    currentDatabaseId,
    documentSummary,
    documentId,
    document,
    firebase,
    dispatch
  );

  await firebase?.doUpdateUserDatabaseLastModified(
    uid,
    currentDatabaseId,
    lastModifiedDate
  );

  // todo remove this and update locally.
  const summaries: UserDatabaseSummary[] = await firebase?.doGetUserDatabaseSummaries(
    uid
  );
  onSetUserDatabaseSummaries(summaries);
  return dispatch(setDisableSaveButton(false));
};

export const createDocument = (
  template: FoodTemplate
): ThunkAction<
  Promise<void>,
  RootState,
  Firebase,
  IActionsSetDocumentIdToChangeTo | IActionsAddDocument | IActionsUpdateDocument
> => async (dispatch, getState) => {
  const onAddDocument = (document: Document, id: string) =>
    dispatch(addDocument(document, id));

  const onUpdateDocument = (document: Document, id: string) =>
    dispatch(updateDocument(id, document));

  const onSetDocumentIdToChangeTo = (id: string) =>
    dispatch(setDocumentIdToChangeTo(id));

  const cachedDocuments = allCachedDocumentsSelector(getState());

  const userDocumentSummaries: DocumentSummary[] = userDocumentSummariesSelector(
    getState()
  );

  const templateId: string = template.id.toString();

  const newDocumentId: string = TEMPORARY_NEW_DOCUMENT;

  let documentNumber =
    userDocumentSummaries.filter(
      (summary: DocumentSummary): boolean => summary.templateId === template.id
    ).length + 1;
  let name = `New ${template.title.slice(0, -1)} ${documentNumber}`;

  let days: DayState[] = initialDocumentState.days;

  if (isFood(templateId)) {
    days = [];
  } else if (
    is24HourRecall(templateId) ||
    isMealPlan(templateId) ||
    isFoodRecord(templateId)
  ) {
    days = [
      {
        title: "Day 1",
        index: 0,
        id: "",
        sections: DEFAULT_MEAL_PLAN_SECTIONS,
        date: "",
      },
    ];
  }
  const date: string = currentDate();
  const newDocument: Document = {
    ...initialDocumentState,
    name: name,
    calculationMethod: isFood(templateId)
      ? CalculationMethod.SET_TO_UNKNOWN
      : CalculationMethod.INGREDIENTS,
    templateId: templateId,
    date: { created: date, lastModified: date },
    days: days,
    note: is24HourRecall(templateId)
      ? "** Personal information and Nutrient Reference Values are coming soon! **"
      : "",
  };

  const document: Document = cachedDocuments[TEMPORARY_NEW_DOCUMENT];
  batch(() => {
    document
      ? onUpdateDocument(newDocument, TEMPORARY_NEW_DOCUMENT)
      : onAddDocument(newDocument, TEMPORARY_NEW_DOCUMENT);
    onSetDocumentIdToChangeTo(newDocumentId);
  });
};
