import firebase from "firebase";

export interface DataSourceSummaryItem {
  documentId: string;
  label: string;
}

export interface DataSource {
  documentSummaries: DataSourceSummaryItem[];
  name: string;
}

export const dataSourceConverter = {
  toFirestore: function (database: DataSource) {
    throw new Error("Unimplemented");
  },

  fromFirestore: function (
    snapshot: firebase.firestore.QueryDocumentSnapshot
  ): DataSource {
    const data: firebase.firestore.DocumentData = snapshot.data();
    const summaries: firebase.firestore.DocumentData[] = data.summaries;

    return {
      documentSummaries: summaries.map((summary) => ({
        documentId: summary.documentId as string,
        label: summary.name as string,
      })),
      name: data.name,
    };
  },
};
