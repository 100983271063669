import _ from "lodash";

import { FoodWorksDate } from "./documentProperties/date";
import { Identifier } from "./documentProperties/identifier";
import { NutrientOverride } from "./documentProperties/nutrientOverride";
import { DocumentProperties } from "./documentProperties/extraProperties";
import { CommonMeasures, CommonMeasure } from "./documentProperties/measure";
import { Serve, serveConverter } from "./documentProperties/serve";
import { Yield, yieldConverter } from "./documentProperties/yield";
import {
  VolumeConversionObject,
  volumeConversionConverter,
} from "./documentProperties/volumeConversionFactor";
import { DocumentSummary } from "./userDatabase";
import { daysConverter, DayState } from "./documentProperties/day";
import { SectionTag } from "./documentProperties/section";

export interface Document {
  name: string;
  templateId: string;
  date: FoodWorksDate;
  identifier: Identifier;
  foodGroup: string;
  yield: Yield;
  calculationMethod: number;
  documentMappingId: string;
  note: string;
  description: string;
  method: string;
  properties: DocumentProperties;
  days: DayState[];
  commonMeasures: CommonMeasures;
  volumeConversion: VolumeConversionObject;
  serve: Serve;
  nutrientOverrides: NutrientOverride[];
  nutrientOverrideMappings: string[];
  sectionTags: SectionTag[];
}

export const documentsAreEqual = (
  a: Document | undefined,
  b: Document | undefined
): boolean => _.isEqual(a, b);

export const documentToJson = (
  document: Document
): firebase.firestore.DocumentData => {
  return {
    name: document.name,
    templateId: document.templateId,
    date: document.date,
    identifier: document.identifier,
    foodGroup: document.foodGroup,
    yield: yieldConverter.toFirestore(document.yield),
    calculationMethod: document.calculationMethod,
    documentMappingId: document.documentMappingId,
    note: document.note,
    description: document.description,
    method: document.method,
    properties: document.properties,
    days: daysConverter.toFirestore(document.days),
    commonMeasures: document.commonMeasures,
    volumeConversion: volumeConversionConverter.toFirestore(
      document.volumeConversion
    ),
    serve: serveConverter.toFirestore(document.serve),
    nutrientOverrides: document.nutrientOverrides,
    nutrientOverrideMappings: document.nutrientOverrideMappings,
    sectionTags: document.sectionTags,
  };
};

export const documentFromJson = (
  documentData: firebase.firestore.DocumentData
): Document => {
  return {
    name: documentData.name,
    templateId: documentData.templateId,
    date: documentData.date,
    identifier: documentData.identifier,
    foodGroup: documentData.foodGroup,
    yield: yieldConverter.fromFirestore(documentData.yield),
    calculationMethod: documentData.calculationMethod,
    documentMappingId: documentData.documentMappingId,
    note: documentData.note,
    description: documentData.description,
    method: documentData.method,
    properties: documentData.properties,
    days: daysConverter.fromFirestore(
      documentData.foodItems ||
        documentData.childDocuments ||
        documentData.days ||
        []
    ),
    commonMeasures: documentData.commonMeasures,
    volumeConversion: volumeConversionConverter.fromFirestore(
      documentData.volumeConversion
    ),
    serve: serveConverter.fromFirestore(documentData.serve),
    nutrientOverrides: documentData.nutrientOverrides,
    nutrientOverrideMappings: documentData.nutrientOverrideMappings,
    sectionTags: documentData.sectionTags || [],
  };
};

export const documentConverter = {
  toFirestore: function (document: Document): firebase.firestore.DocumentData {
    return documentToJson(document);
  },

  fromFirestore: function (
    snapshot: firebase.firestore.QueryDocumentSnapshot
  ): Document {
    const data: firebase.firestore.DocumentData = snapshot.data();

    return documentFromJson(data);
  },
};

const documentNameIsNotUnique = (
  userDocumentSummaries: DocumentSummary[],
  currentDocumentId: string,
  documentName: string
): boolean =>
  Boolean(
    userDocumentSummaries.filter(
      (summary: DocumentSummary): boolean =>
        summary.documentId !== currentDocumentId &&
        summary.label.trim() === documentName.trim()
    ).length
  );

const checkIfDuplicateMeasureExists = (measureNames: string[]): boolean =>
  new Set(measureNames).size !== measureNames.length;

export const getDocumentErrors = (
  document: Document,
  documentSummaries: DocumentSummary[],
  currentDocumentId: string,
  referenceMeasureNames: string[]
): string[] => {
  const errorsFound: string[] = [];

  const unnamedMeasures: CommonMeasure[] = document.commonMeasures.measures.filter(
    (commonMeasure: CommonMeasure): boolean => !commonMeasure.name
  );

  if (unnamedMeasures.length) {
    errorsFound.push("Custom measures must have a name");
  }
  if (
    checkIfDuplicateMeasureExists(
      document.commonMeasures.measures.map(
        (measure: CommonMeasure): string => measure.name
      )
    )
  ) {
    errorsFound.push("Custom measures must have unique names");
  }
  for (const measure of document.commonMeasures.measures) {
    if (referenceMeasureNames.includes(measure.name)) {
      errorsFound.push(
        `Cannot use measure ${measure.name}. This measure name is reserved.`
      );
      break;
    }
  }

  if (!document.name) {
    errorsFound.push("Document must have a name");
  } else if (
    documentNameIsNotUnique(documentSummaries, currentDocumentId, document.name)
  ) {
    errorsFound.push("Documents must have unique names");
  }

  if (document.properties.isDeleted) {
    errorsFound.push(
      "This document is marked as deleted. Please first restore this document before saving any changes"
    );
  }

  return errorsFound;
};
