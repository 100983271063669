import React, { ReactNode, useCallback, useEffect, useState } from "react";

import {
  Button,
  Checkbox,
  createMuiTheme,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  ThemeProvider,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import DateFnsUtils from "@date-io/date-fns";
import { differenceInCalendarDays } from "date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { v4 as uuidv4 } from "uuid";
import { batch, useDispatch, useSelector } from "react-redux";

import {
  DEFAULT_MEAL_PLAN_SECTIONS,
  DAYS_OF_THE_WEEK,
  isMealPlan,
} from "../../../constants/FoodTemplate";
import { Day, Days } from "../../../data/models/documentProperties/day";
import { RetentionFactor } from "../../../data/models/documentProperties/retentionFactor";
import { setDays } from "../../../store/data/current-document/action-creators/days";
import { daysSelector } from "../../../store/data/current-document/selectors/days";
import { templateIdSelector } from "../../../store/data/current-document/selectors/document";
import { RootState } from "../../../store/reducers";
import { setSelectedSectionTags } from "../../../store/ui/actionCreators/nutritionPaneActionCreators";
import {
  updateCurrentDay,
  setSelectedRows,
} from "../../../store/ui/actionCreators/recipeGrid";
import { appTheme } from "../../../styling/style";
import { FoodWorksNumberInput } from "../../common/FoodWorksTextInput";

const useStyles = makeStyles((theme) => ({
  input: {
    width: 40,
    marginLeft: 10,
  },
  titleBar: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  alignCentre: {
    display: "flex",
    alignItems: "center",
  },
  select: {
    marginLeft: 10,
    paddingLeft: 5,
    width: 100,
  },
  checkBox: {
    borderRadius: 10,
  },
  body: {
    margin: 25,
    marginTop: 5,
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "start",
  },
  container: {
    display: "flex",
    justifyContent: "space-evenly",
    flex: 1,
    width: "100%",
  },
  inputContainer: {
    display: "flex",
    justifyContent: "space-evenly",
  },
  dateContainer: {
    display: "flex",
    height: 80,
    justifyContent: "space-evenly",
    flex: 1,
    width: "100%",
  },
  modifyPlanBottomButtons: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  checkboxContainer: {
    marginLeft: 10,
    display: "flex",
    alignItems: "center",
  },
}));

const materialCalendarTheme = createMuiTheme({
  overrides: {
    //@ts-ignore
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: appTheme.colors.secondary,
      },
    },
    MuiPickersDay: {
      day: {
        color: appTheme.colors.xiketic,
      },
      daySelected: {
        color: appTheme.colors.primary,
        "&:nth-child(1)": {
          color: "white",
        },
      },
      current: {
        color: appTheme.colors.primary,
      },
    },
  },
});

interface SetMealPlanDialogProps {
  open: boolean;
  onClose: () => void;
}

const DAY_IN_MILLISECONDS = 86400000;
const DAY = "Day(s)";
const WEEK = "Week(s)";
const DATE = "By date";
const MAX_DAYS = 84;

type PeriodOption = typeof DAY | typeof WEEK | typeof DATE;

export const SetMealPlanDialog = React.memo<SetMealPlanDialogProps>(
  ({ open, onClose }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const onSetDays = (days: Day[]) => dispatch(setDays(days));

    const onResetCurrentDay = () => dispatch(updateCurrentDay(0));

    const onResetSelection = () =>
      batch(() => {
        dispatch(setSelectedRows([]));
        dispatch(setSelectedSectionTags([]));
      });

    const days: Days = useSelector<RootState, Days>(daysSelector);

    const templateId: string = useSelector<RootState, string>(
      templateIdSelector
    );

    const [type, setType] = useState<PeriodOption>(DAY);
    const [value, setValue] = useState(days.days.length.toString());
    const [setDates, setSetDates] = useState(false);
    const [startDate, setStartDate] = React.useState<Date | null>(new Date());
    const [endDate, setEndDate] = React.useState<Date | null>(new Date());
    const [currentDays, setCurrentDays] = useState<Day[]>(days.days);
    const [keepExistingEntries, setKeepExistingEntries] = useState(true);

    useEffect(() => {
      setCurrentDays(days.days);
    }, [days]);

    const updateEndDate = (
      startDate: Date | null,
      inputValue: number,
      period: PeriodOption
    ) => {
      if (!startDate) {
        return;
      }
      if (period === DAY) {
        endDate?.setTime(
          startDate.getTime() + (inputValue - 1) * DAY_IN_MILLISECONDS
        );
      } else if (period === WEEK) {
        endDate?.setTime(
          startDate.getTime() + (inputValue * 7 - 1) * DAY_IN_MILLISECONDS
        );
      }

      if (differenceInCalendarDays(endDate!, startDate!) > MAX_DAYS - 1) {
        endDate!.setTime(
          startDate!.getTime() + (MAX_DAYS - 1) * DAY_IN_MILLISECONDS
        );
      }
    };

    const handleDateChange = (date: Date | null) => {
      setStartDate(date);
      updateEndDate(date, Number(value), type);
    };

    const handleEndDateChange = (date: Date | null) => setEndDate(date);

    const getNumberOfDays = useCallback(
      (value: string): number => {
        let totalDays: number = 0;
        switch (type) {
          case DAY:
            totalDays = Number(value);
            break;
          case WEEK:
            totalDays = Number(value) * 7;
            break;
          case DATE:
            totalDays = differenceInCalendarDays(endDate!, startDate!) + 1;
            break;
        }
        return totalDays;
      },
      [type, endDate, startDate]
    );

    const onPeriodQuantityChange = (value: string) => {
      setValue(value);
      updateEndDate(startDate!, Number(value), type);
    };

    const onSelectPeriodType = (period: PeriodOption) => {
      setType(period);
      if (period === WEEK) {
        if (Number(value) > 12) {
          setValue("12");
        }
      }
      if (period === DATE) {
        setSetDates(true);
      }
      updateEndDate(startDate!, Number(value), period);
    };

    const getMaxInputValue = (): number => {
      if (type === WEEK) {
        return 12;
      }

      return MAX_DAYS;
    };

    const onAutofillDatesChecked = (checked: boolean) => setSetDates(checked);

    const onApplyChanges = () => {
      const numberOfDaysToAdd: number = getNumberOfDays(value);
      let newDays: Day[] = [];

      for (let i = 0; i < numberOfDaysToAdd; ++i) {
        if (keepExistingEntries && i < currentDays.length) {
          const currentDay: Day = currentDays[i];
          const date = new Date();
          date.setTime(startDate!.getTime() + i * DAY_IN_MILLISECONDS);
          newDays.push(
            new Day(
              currentDay.id,
              i,
              currentDay.sections,
              currentDay.title,
              setDates ? date.toLocaleDateString() : currentDay.date
            )
          );
        } else {
          const date = new Date();
          date.setTime(startDate!.getTime() + i * DAY_IN_MILLISECONDS);
          let dateString: string = setDates ? date.toLocaleDateString() : "";

          newDays.push(
            Day.fromObject(
              {
                id: uuidv4(),
                index: i,
                sections: DEFAULT_MEAL_PLAN_SECTIONS,
                title: setDates
                  ? DAYS_OF_THE_WEEK[date.getDay()]
                  : `Day ${i + 1}`,
                date: dateString,
              },
              new Map<string, RetentionFactor>([])
            )
          );
        }
      }
      batch(() => {
        onSetDays(newDays);
        onResetSelection();
        onResetCurrentDay();
      });
      onClose();
    };

    const onToggleKeepExistingEntries = () =>
      setKeepExistingEntries((prev) => !prev);

    const periodInputs: ReactNode = (
      <div className={classes.alignCentre}>
        <Typography variant="body1">Plan period: </Typography>
        <FoodWorksNumberInput
          setValue={onPeriodQuantityChange}
          maxValue={getMaxInputValue()}
          className={classes.input}
          inputProps={{
            value: value,
            disabled: type === DATE,
            type: "number",
          }}
        />
        <Select className={classes.select} value={type}>
          {[DAY, WEEK, DATE].map(
            (value: string): ReactNode => (
              <MenuItem
                value={value}
                onClick={() => onSelectPeriodType(value as PeriodOption)}
                key={value}
              >
                {value}
              </MenuItem>
            )
          )}
        </Select>
      </div>
    );

    const autofillDatesCheckbox: ReactNode = (
      <div className={classes.checkboxContainer}>
        <Typography variant="body1">Set dates</Typography>
        <Checkbox
          className={classes.checkBox}
          checked={setDates}
          onChange={(event) => onAutofillDatesChecked(event.target.checked)}
          disabled={type === DATE}
        />
      </div>
    );

    const startDatePicker: ReactNode = (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ThemeProvider theme={materialCalendarTheme}>
          <DatePicker
            variant="dialog"
            format="dd/MM/yyyy"
            margin="normal"
            id="start-date-picker-inline"
            label="Start date"
            value={startDate}
            TextFieldComponent={(params) => (
              <TextField
                {...params}
                value={startDate?.toLocaleDateString() || ""}
              />
            )}
            onChange={handleDateChange}
            disabled={type !== DATE && !setDates}
          />
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    );

    const endDatePicker: ReactNode = (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ThemeProvider theme={materialCalendarTheme}>
          <DatePicker
            maxDate={new Date().setTime(
              startDate!.getTime() + (MAX_DAYS - 1) * DAY_IN_MILLISECONDS
            )}
            variant="dialog"
            format="dd/MM/yyyy"
            margin="normal"
            label="End date"
            value={endDate}
            maxDateMessage=""
            TextFieldComponent={(params) => (
              <TextField
                {...params}
                value={endDate?.toLocaleDateString() || ""}
              />
            )}
            onChange={handleEndDateChange}
            disabled={type !== DATE}
          />
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    );

    const header: ReactNode = (
      <div className={classes.titleBar}>
        <DialogTitle data-cy="setMealPlan">
          {isMealPlan(templateId)
            ? "Set meal plan details"
            : "Set food record details"}
        </DialogTitle>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
    );

    const dialogActions: ReactNode = (
      <DialogActions>
        <Button color="default" onClick={onClose}>
          Cancel
        </Button>
        <Button color="secondary" onClick={onApplyChanges}>
          Save changes
        </Button>
      </DialogActions>
    );

    const keepExistingContentCheckbox: ReactNode = (
      <div className={classes.checkboxContainer}>
        <Typography variant="body1">Keep existing entries</Typography>
        <Checkbox
          color="secondary"
          checked={keepExistingEntries}
          onChange={onToggleKeepExistingEntries}
        />
      </div>
    );

    const body: ReactNode = (
      <div className={classes.body}>
        <div className={classes.container}>
          <div className={classes.inputContainer}>{periodInputs}</div>
          {autofillDatesCheckbox}
        </div>
        <div className={classes.dateContainer}>
          {startDatePicker}
          {endDatePicker}
        </div>
        <div className={classes.modifyPlanBottomButtons}>
          {keepExistingContentCheckbox}
        </div>
      </div>
    );

    return (
      <Dialog maxWidth="sm" fullWidth open={open} onClose={onClose}>
        {header}
        {body}
        {dialogActions}
      </Dialog>
    );
  }
);
