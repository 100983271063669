import React, { FunctionComponent, ReactNode, useState } from "react";

import {
  Checkbox,
  TableRow,
  TableCell,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from "@material-ui/icons/Delete";

import {
  FoodWorksTextInput,
  FoodWorksNumberInput,
} from "../../../../common/FoodWorksTextInput";
import { CommonMeasure } from "../../../../../data/models/documentProperties/measure";
import { appTheme } from "../../../../../styling/style";
import {
  setDefaultCommonMeasure,
  updateCommonMeasure,
  addCommonMeasure,
  removeCommonMeasure,
} from "../../../../../store/data/current-document/action-creators/commonMeasures";
import {
  DialogOption,
  FoodWorksDialog,
} from "../../../../common/FoodWorksDialog";
import { FoodworksTooltip } from "../../../../common/InfoTooltip";
import { RootState } from "../../../../../store/reducers";
import { ReferenceMeasuresSelector } from "../../../../../store/data/selectors/referenceData";
import { ReferenceMeasure } from "../../../../../data/models/referenceMeasure";

export interface MeasureRowProps {
  isLastRow: boolean;
  measure: CommonMeasure;
  isDefault: boolean;
}

const useStyles = makeStyles({
  flex: {
    flex: 1,
  },
  displayFlex: {
    display: "flex",
  },
  errorIcon: {
    alignSelf: "center",
    margin: 5,
  },
});

export const MeasureRow: FunctionComponent<MeasureRowProps> = ({
  isLastRow,
  measure,
  isDefault,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const onMeasureUpdated = (measure: CommonMeasure) => {
    dispatch(updateCommonMeasure(measure));
  };

  const addNewMeasure = (measure: CommonMeasure) => {
    dispatch(addCommonMeasure(measure));
  };

  const onSetDefaultCommonMeasure = (id: string) =>
    dispatch(setDefaultCommonMeasure(id));

  const onDeleteMeasure = (measureId: string) =>
    dispatch(removeCommonMeasure(measureId));

  const referenceMeasures: ReferenceMeasure[] = useSelector<
    RootState,
    ReferenceMeasure[]
  >(ReferenceMeasuresSelector);

  const [hovered, setHovered] = useState(false);
  const [name, setName] = useState(measure.name);
  const [description, setDescription] = useState(measure.description);
  const [value, setValue] = useState(measure.value.toString());
  const [dialogVisible, setDialogVisible] = useState(false);

  const nameSharedWithReferenceMeasure = (name: string): boolean =>
    !!referenceMeasures.find(
      (value: ReferenceMeasure): boolean => value.displayName === name
    );

  const errorIconWithTitle = (title: string): ReactNode => (
    <FoodworksTooltip title={title}>
      <ErrorOutlineIcon
        color="error"
        className={classes.errorIcon}
        data-cy="errorIcon"
      />
    </FoodworksTooltip>
  );

  const commonMeasureInput: ReactNode = (
    <div className={classes.displayFlex}>
      <FoodWorksTextInput
        data-cy="commonMeasureInput"
        className={classes.flex}
        value={name}
        placeholder={"Common Measure"}
        onBlur={(event) => {
          measure.name = name;
          onMeasureUpdated(measure);
        }}
        onChange={(event) => {
          setName(event.target.value);
          if (isLastRow) {
            measure.name = event.target.value;
            addNewMeasure(measure);
          }
        }}
      />
      {!isLastRow && measure.name === ""
        ? errorIconWithTitle("Common Measure must have a value.")
        : null}
      {nameSharedWithReferenceMeasure(measure.name) &&
        errorIconWithTitle(
          `Cannot use measure ${measure.name}. This measure name is reserved.`
        )}
    </div>
  );

  const onDefaultMeasureCheckboxClick = (measureId: string) =>
    onSetDefaultCommonMeasure(isDefault ? "" : measureId);

  const defaultCheckbox: ReactNode = (
    <Checkbox
      data-cy="defaultMeasureCheckbox"
      disabled={
        !(measure.name || false) || nameSharedWithReferenceMeasure(measure.name)
      }
      checked={isDefault}
      onChange={() => {
        onDefaultMeasureCheckboxClick(measure.id);
      }}
    />
  );

  const weightInput: ReactNode = (
    <div className={classes.displayFlex}>
      <FoodWorksNumberInput
        className={classes.flex}
        setValue={setValue}
        inputProps={{
          value: value,
          disabled:
            !(measure.name || false) ||
            nameSharedWithReferenceMeasure(measure.name),
          onBlur: (event) => {
            measure.value = Number(event.target.value);
            onMeasureUpdated(measure);
          },
        }}
      />
      {!isLastRow && measure.value <= 0
        ? errorIconWithTitle("Weight must be greater than 0.")
        : null}
    </div>
  );

  const descriptionInput: ReactNode = (
    <FoodWorksTextInput
      data-cy="measureDescriptionInput"
      value={description}
      disabled={
        !(measure.name || false) || nameSharedWithReferenceMeasure(measure.name)
      }
      onBlur={(event) => {
        measure.description = event.target.value;
        onMeasureUpdated(measure);
      }}
      onChange={(event) => {
        setDescription(event.target.value);
      }}
    />
  );

  const buildConfirmDialog = (measure: CommonMeasure) => {
    const measureIsUsed = !!measure.usedIn.length;

    const options: DialogOption[] = [
      {
        text: "Cancel",
        onClick: () => setDialogVisible(false),
        color: appTheme.colors.xiketic,
      },
      {
        text: measureIsUsed ? "Delete Anyway" : "Confirm",
        onClick: () => {
          setDialogVisible(false);
          onDeleteMeasure(measure.id);
        },
        color: appTheme.colors.error,
      },
    ];

    let description = `Are you sure you want to delete this measure? ${
      measureIsUsed
        ? `There are ${measure.usedIn.length} documents that currently use this measure`
        : ""
    }`;

    return (
      <FoodWorksDialog
        open={dialogVisible}
        onClose={() => setDialogVisible(false)}
        title="Are you sure you want to Delete this measure?"
        description={description}
        options={options}
      />
    );
  };

  const onDelete = () => {
    setDialogVisible(true);
  };

  return (
    <>
      <TableRow
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        key={measure.id}
        style={{
          background: hovered ? appTheme.colors.white[8] : "inherit",
        }}
      >
        <TableCell key="name">{commonMeasureInput}</TableCell>
        <TableCell align="center" key="default">
          {defaultCheckbox}
        </TableCell>
        <TableCell key="weight">{weightInput}</TableCell>
        <TableCell key="desc">{descriptionInput}</TableCell>
        <TableCell key="bin">
          {!isLastRow ? (
            <IconButton onClick={onDelete} data-cy="deleteMeasure">
              <DeleteIcon />
            </IconButton>
          ) : null}
        </TableCell>
      </TableRow>
      {buildConfirmDialog(measure)}
    </>
  );
};
