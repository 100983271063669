import {
  IActionsSetUnsavedDocumentChanges,
  IActionsSetDocumentIdToChangeTo,
} from "../actions/documentSaving";

export const setUnsavedDocumentChanges = (
  openSaveDialog: boolean,
  documentIdToChangeTo: string,
  databaseIdToChangeTo: string
): IActionsSetUnsavedDocumentChanges => {
  return {
    type: "SET_UNSAVED_DOCUMENT_CHANGES",
    openSaveDialog: openSaveDialog,
    documentIdToChangeTo: documentIdToChangeTo,
    databaseIdToChangeTo: databaseIdToChangeTo,
  };
};

export const setDocumentIdToChangeTo = (
  documentId: string
): IActionsSetDocumentIdToChangeTo => {
  return {
    type: "SET_DOCUMENT_ID_TO_CHANGE_TO",
    documentId: documentId,
  };
};
