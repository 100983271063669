import { IngredientSummaryItem } from "../../../components/document_view/foods/tabs/ingredients/editing_grid/cells/IngredientCell";
import {
  DatabaseActions,
  SET_USER_DOCUMENT_SUMMARIES,
  SET_DATABASE_ID,
  ADD_USER_DOCUMENT_SUMMARY,
  UPDATE_USER_DOCUMENT_SUMMARY,
  SET_DATABASE_NAME,
} from "../actions/database";
import { DocumentSummary } from "../../../data/models/userDatabase";
import {
  databaseProperties,
  DatabasePropertiesState,
  initialDatabasePropertiesState,
} from "./databaseProperties";
import {
  DatabasePropertyActions,
  SET_DISPLAYED_NUTRIENTS,
  SET_ENABLED_DATASOURCES,
} from "../actions/databaseProperties";

export interface DatabaseSummaryMap {
  [key: string]: IngredientSummaryItem[];
}

export interface DatabaseState {
  databaseId: string;
  name: string;
  userDocumentSummaries: DocumentSummary[];
  referenceSummaries: DatabaseSummaryMap;
  databaseProperties: DatabasePropertiesState;
}

export const initialDatabaseState: DatabaseState = {
  databaseId: "",
  name: "",
  userDocumentSummaries: [],
  referenceSummaries: {},
  databaseProperties: initialDatabasePropertiesState,
};

export const database = (
  state: DatabaseState = initialDatabaseState,
  action: DatabaseActions | DatabasePropertyActions
) => {
  switch (action.type) {
    case SET_USER_DOCUMENT_SUMMARIES:
      return { ...state, userDocumentSummaries: action.userDocumentSummaries };
    case SET_DATABASE_ID:
      return { ...state, databaseId: action.databaseId };
    case SET_DATABASE_NAME:
      return { ...state, name: action.name };
    case ADD_USER_DOCUMENT_SUMMARY:
      return {
        ...state,
        userDocumentSummaries: [
          ...state.userDocumentSummaries,
          action.documentSummary,
        ],
      };
    case UPDATE_USER_DOCUMENT_SUMMARY:
      return {
        ...state,
        userDocumentSummaries: [
          ...state.userDocumentSummaries.map(
            (item: DocumentSummary): DocumentSummary =>
              item.documentId === action.documentSummary.documentId
                ? action.documentSummary
                : item
          ),
        ],
      };
    case SET_ENABLED_DATASOURCES:
      return {
        ...state,
        databaseProperties: databaseProperties(
          state.databaseProperties,
          action
        ),
      };
    case SET_DISPLAYED_NUTRIENTS:
      return {
        ...state,
        databaseProperties: databaseProperties(
          state.databaseProperties,
          action
        ),
      };
    default:
      return state;
  }
};
