import { Action } from "redux";

export const UPDATE_SELECTED_AMOUNT = "UPDATE_SELECTED_AMOUNT";
export const UPDATE_CUSTOM_AMOUNT = "UPDATE_CUSTOM_AMOUNT";
export const TAPPED_CATEGORY_ACCORDION = "TAPPED_CATEGORY_ACCORDION";
export const SET_SELECTED_SECTION_TAGS = "SET_SELECTED_SECTION_TAGS";

export interface IActionsUpdateSelectedAmount extends Action {
  type: "UPDATE_SELECTED_AMOUNT";
  selectedAmount: string;
}

export interface IActionsUpdateCustomAmount extends Action {
  type: "UPDATE_CUSTOM_AMOUNT";
  customAmount: number;
}

export interface IActionsTappedCategoryAccordion extends Action {
  type: "TAPPED_CATEGORY_ACCORDION";
  categoryId: string;
}

export interface IActionsSetSelectedSectionTags extends Action {
  type: "SET_SELECTED_SECTION_TAGS";
  tagIds: string[];
}

export type NutritionPaneActions =
  | IActionsUpdateSelectedAmount
  | IActionsUpdateCustomAmount
  | IActionsTappedCategoryAccordion
  | IActionsSetSelectedSectionTags;
