import { createMuiTheme } from "@material-ui/core";

const white = [
  "#FFFFFF", // white
  "#FEFEFE", // cultured 1
  "#FDFDFE", // cultured 2
  "#FDFDFD", // cultured 3
  "#FCFCFD", // cultured 4
  "#FBFBFC", // cultured 5
  "#FAFAFC", // cultured 6
  "#FAFAFB", // cultured 7
  "#F9F9FB", // cultured 8
  "#F7F7FA", // ghost white 5
  "#F6F6F9", // ghost white 6
  "#F4F4F8", // ghost white 7
  "#F2F2F7", // ghost white 8
  "#F7F7F8", // silver 1
  "#EFEFF0", // silver 2
  "#E7E7E9", // silver 3
  "#DFDFE1", // silver 4
  "#D8D8DA", // silver 5
  "#D0D0D2", // silver 6
  "#C8C8CB", // silver 7
  "#C0C0C4", // silver 8
];

const oceanBlue = [
  "#EAE7FA",
  "#D5CEF5",
  "#C0B6F0",
  "#AB9DEC",
  "#8785E7",
  "#826CE2",
  "#6D54DD",
  "#583AD8",
  "#4528C9",
  "#3B22AC",
  "#321C90",
  "#281773",
  "#1E1156",
];

const marjorelleBlue = [
  "#ECE9FF",
  "#D8D2FF",
  "#C5BCFF",
  "#B2A6FF",
  "#9E8FFF",
  "#8B79FF",
  "#7863FF",
  "#634CFF",
  "#4023FF",
  "#2100F9",
  "#1C00CF",
  "#1600A6",
  "#11007C",
];

const info = [
  "#E4B1F2",
  "#DD9DEE",
  "#D689EB",
  "#CF76E8",
  "#C862E4",
  "#BC3FDE",
  "#AC23D1",
  "#8F1EAE",
  "#72188C",
];

const inactiveTags = [
  "#EFFAEA",
  "#DAFEF0",
  "#EAFBF4",
  "#DBFDFF",
  "#E7F0FD",
  "#F8EBFC",
  "#FCEBF3",
  "#FFEBEB",
  "#FFF7EA",
  "#FEFAE9",
];

const activeTagTextColor = [
  "black",
  "white",
  "black",
  "white",
  "white",
  "white",
  "white",
  "white",
  "black",
  "black",
];

const activeTags = [
  "#7ED957",
  "#05C779",
  "#56DCA9",
  "#00D5E0",
  "#3B85EC",
  "#C862E4",
  "#E45FA0",
  "#FF5C5C",
  "#FFBD59",
  "#F5D751",
];

const appColors = {
  gainsbro: "#D9D9DE",
  gainsbruh: "#E6E6EB",

  primary: oceanBlue[7],
  secondary: marjorelleBlue[7],
  information: info,
  white: white,
  oceanBlue: oceanBlue,
  marjorelleBlue: marjorelleBlue,
  activeTags: activeTags,
  inactiveTags: inactiveTags,
  activeTagTextColors: activeTagTextColor,
  xiketic: "#131420",

  input: white[4],
  inputHover: white[13],
  error: "#FF5C5C",
  help: info[4],
  warning: "#FF9A01",
  success: "#3FD79C",
};

const appFontSizes = {
  textInput: 14,
  tableText: 18,
  title: 20,
};

const appFontFamilies = {
  main: [
    "-apple-system",
    "BlinkMacSystemFont",
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    "sans-serif",
  ].join(","),
  textInput: [
    "SFMono-Regular",
    "Menlo",
    "Monaco",
    "Consolas",
    "Liberation Mono",
    "Courier New",
    "monospace",
  ].join(","),
};

export const appTheme = {
  colors: appColors,
  fontSize: appFontSizes,
  fontFamily: appFontFamilies,
};

export const h1Style = {
  fontSize: appFontSizes.title,
  fontWeight: 600,
  fontFamily: appFontFamilies.main,
  color: appColors.xiketic,
};

export const muiTheme = createMuiTheme({
  palette: {
    text: {
      primary: appTheme.colors.xiketic,
    },
    primary: {
      main: appTheme.colors.white[0],
      light: appTheme.colors.warning,
    },
    secondary: {
      main: appTheme.colors.primary,
    },
    error: { main: appTheme.colors.error },
    success: {
      main: appTheme.colors.success,
    },
  },
  typography: {
    fontSize: 14,
    fontFamily: appFontFamilies.main,
    h1: h1Style,
    h2: {
      fontSize: 18,
      fontWeight: 600,
      color: appColors.xiketic,
    },
    h3: {
      fontSize: 16,
      fontWeight: 600,
      color: appColors.xiketic,
    },
    h4: {
      fontSize: 15,
      fontWeight: 600,
      color: appColors.xiketic,
    },
    h5: {
      fontSize: 14,
      fontWeight: 600,
      color: appColors.xiketic,
    },
    body1: {
      fontSize: 14,
      fontWeight: 400,
      color: appColors.xiketic,
    },
    caption: {
      fontSize: 11,
      color: appColors.xiketic,
    },
    button: {
      fontSize: 14,
      fontWeight: 600,
      color: appColors.white[0],
      textTransform: "none",
    },
  },
  props: {
    MuiTypography: {
      variantMapping: {
        h1: "h1",
        h2: "h2",
        h3: "h3",
        h4: "h4",
        h5: "h5",
        h6: "h5",
        subtitle1: "h2",
        subtitle2: "h2",
        body2: "body1",
      },
    },
  },
});
