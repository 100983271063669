import {
  DatabasePropertyActions,
  SET_DISPLAYED_NUTRIENTS,
  SET_ENABLED_DATASOURCES,
} from "../actions/databaseProperties";
import { ENABLED_DATA_SOURCES } from "../../../constants/datasources";

export type DatabasePropertiesState = {
  enabledDatasources: string[];
  displayedNutrients: string[];
};

export const initialDatabasePropertiesState: DatabasePropertiesState = {
  enabledDatasources: ENABLED_DATA_SOURCES,
  displayedNutrients: [],
};

export const databaseProperties = (
  state: DatabasePropertiesState = initialDatabasePropertiesState,
  action: DatabasePropertyActions
): DatabasePropertiesState => {
  switch (action.type) {
    case SET_ENABLED_DATASOURCES:
      return {
        ...state,
        enabledDatasources: action.sources,
      };
    case SET_DISPLAYED_NUTRIENTS:
      return { ...state, displayedNutrients: action.nutrientIds };
    default:
      return state;
  }
};
