import { put, select, fork, call, takeEvery } from "redux-saga/effects";

import { documentSelector } from "../current-document/selectors/document";
import {
  ServerDocumentSelector,
  CurrentDocumentIdSelector,
} from "../current-document/selectors/currentDocument";
import { databaseIdSelector } from "../selectors/database";
import {
  setUnsavedDocumentChanges,
  setDocumentIdToChangeTo,
} from "../../ui/actionCreators/documentSaving";
import {
  TEMPORARY_DOCUMENT,
  TEMPORARY_NEW_DOCUMENT,
} from "../current-document/reducers/currentDocument";
import Firebase from "../../../data/Firebase";
import { DocumentMap } from "../reducers/documentCache";
import { allCachedDocumentsSelector } from "../selectors/documentCache";
import { fetchDocument } from "../../../data/Firebase/helpers/fetchDocument";
import { FoodId } from "../../../data/models/documentProperties/foodId";
import {
  setDocumentData,
  updateCalculationMethod,
} from "../current-document/action-creators/document";
import { setRecentMappedDocumentId } from "../../ui/actionCreators/overridesScreen";
import {
  allDocumentsFetched,
  changeDocumentLoadingState,
  setDocumentId,
} from "../current-document/action-creators/currentDocument";
import { Document, documentsAreEqual } from "../../../data/models/document";
import {
  DocumentMapReturn,
  getChildDocuments,
} from "../../../data/dart_to_js_conversion/FoodworksObjectConversion";
import { addError } from "../../action_creators/errorActionCreators";
import { addDocument } from "../action-creators/documentCache";
import {
  FETCH_DOCUMENT,
  IActionsFetchDocument,
} from "../actions/documentCache";
import { CalculationMethod } from "../../../constants/calculationMethod";
import { hasFoodItems, isFood } from "../../../constants/FoodTemplate";
import { FoodItemState } from "../../../data/models/documentProperties/foodItem";
import { documentIdToChangeToSelector } from "../../ui/selectors/documentSaving";
import { SET_DOCUMENT_ID_TO_CHANGE_TO } from "../../ui/actions/documentSaving";
import { updateCurrentDay } from "../../ui/actionCreators/recipeGrid";
import { setSelectedRows } from "../../ui/actionCreators/recipeGrid";
import { fetchComposition } from "../action-creators/compositionCache";
import { setCurrentTab } from "../../ui/actionCreators/documentScreen";
import { updateSelectedAmount } from "../../ui/actionCreators/nutritionPaneActionCreators";
import { NutritionRadioOption } from "../../ui/reducers/nutritionPaneReducers";

const actionsToListenTo = [SET_DOCUMENT_ID_TO_CHANGE_TO];

export const getDocumentsToAddToCache = async (
  document: Document,
  foodId: FoodId,
  documentsToAddToCache: DocumentMap,
  firebase: Firebase
): Promise<DocumentMapReturn> => {
  let documentMapReturn: DocumentMapReturn = {
    documentMap: documentsToAddToCache,
  };

  let foodIdAsString: string = foodId.identifier;
  documentsToAddToCache[foodIdAsString] = document;
  const documentHasFoodItems: boolean = hasFoodItems(document.templateId);

  const documentIsMapped: boolean =
    document.calculationMethod === CalculationMethod.MAPPED;

  if (documentHasFoodItems || documentIsMapped) {
    let foodItems: FoodItemState[] = [];
    for (const day of document.days) {
      for (const section of day.sections) {
        foodItems = [...foodItems, ...section.foodItems];
      }
    }
    const definedFoodItems = foodItems.filter(
      (foodItem: FoodItemState): boolean => Boolean(foodItem.foodId)
    );
    const foodIdsToFetch: FoodId[] = [];
    if (document.documentMappingId) {
      const [datasourceId, documentId] = document.documentMappingId.split(":");
      const foodId = new FoodId({
        datasourceId: datasourceId,
        documentId: documentId,
      });
      foodIdsToFetch.push(foodId);
    }
    documentMapReturn = await getChildDocuments(
      firebase,
      foodId.documentId!,
      foodIdsToFetch.concat(
        definedFoodItems.map(
          (foodItem: FoodItemState): FoodId => new FoodId(foodItem.foodId!)
        )
      )
    );

    documentMapReturn = {
      ...documentMapReturn,
      documentMap: {
        ...documentsToAddToCache,
        ...documentMapReturn.documentMap,
      },
    };
  }
  return documentMapReturn;
};

export function* fetchChildDocuments(
  document: Document,
  foodId: FoodId,
  documentCache: DocumentMap,
  firebase: Firebase
) {
  let documentsToAddToCache: DocumentMap = {};

  const documentNotInCache: boolean = !documentCache.hasOwnProperty(
    foodId.identifier
  );

  if (documentNotInCache) {
    const documentMapReturn: DocumentMapReturn = yield call(
      getDocumentsToAddToCache,
      document,
      foodId,
      documentsToAddToCache,
      firebase
    );
    if (documentMapReturn.error) {
      yield put(addError(documentMapReturn.error));
    }
    documentsToAddToCache = documentMapReturn.documentMap;

    for (const [id, document] of Object.entries(documentsToAddToCache)) {
      yield put(addDocument(document, id));
    }
    const nonRecipeDocuments = Object.entries(documentsToAddToCache).filter(
      ([_, document]: [string, Document]): boolean =>
        !(
          document.calculationMethod === CalculationMethod.INGREDIENTS &&
          !!document.days.length
        )
    );
    for (const [id, document] of nonRecipeDocuments) {
      yield put(fetchComposition(id, document));
    }
    const recipeDocuments = Object.entries(documentsToAddToCache).filter(
      ([_, document]: [string, Document]): boolean =>
        document.calculationMethod === CalculationMethod.INGREDIENTS &&
        !!document.days.length
    );

    for (const [id, document] of recipeDocuments) {
      yield put(fetchComposition(id, document));
    }
  }
  yield put(changeDocumentLoadingState(false));
}

export function* addAllRelatedDocumentsToCache(
  firebase: Firebase,
  foodId: FoodId,
  isPublic: boolean,
  documentCache: DocumentMap
) {
  yield put(changeDocumentLoadingState(true));
  const document: Document = yield fetchDocument(firebase, foodId, isPublic);

  yield call(fetchChildDocuments, document, foodId, documentCache, firebase);

  return document;
}

export function* fetchDocumentAndChildren(
  currentDocumentId: string,
  firebase: Firebase
) {
  const documentCache: DocumentMap = yield select(allCachedDocumentsSelector);

  const databaseId: string = yield select(databaseIdSelector);

  let document: Document =
    currentDocumentId === TEMPORARY_DOCUMENT
      ? documentCache[TEMPORARY_NEW_DOCUMENT]
      : documentCache[`${databaseId}:${currentDocumentId}`];

  if (!document || currentDocumentId !== TEMPORARY_DOCUMENT) {
    document = yield call(
      addAllRelatedDocumentsToCache,
      firebase!,
      new FoodId({ datasourceId: databaseId, documentId: currentDocumentId }),
      false,
      documentCache
    );
  }

  if (document) {
    if (isFood(document.templateId)) {
      yield put(updateSelectedAmount(NutritionRadioOption.ONE_HUNDRED_G));
    } else {
      yield put(updateSelectedAmount(NutritionRadioOption.TOTAL));
    }
  }

  yield put(updateCurrentDay(0));
  yield put(setDocumentId(currentDocumentId));
  yield put(setDocumentData(document));
  yield put(setCurrentTab(0));
  yield put(setSelectedRows([]));
  yield put(setRecentMappedDocumentId(document.documentMappingId));
  yield put(allDocumentsFetched());
}

export function* detectUnsavedChanges(
  firebase: Firebase,
  documentIdToChangeTo: string
) {
  const currentDocument: Document = yield select(documentSelector);

  const serverDocument: Document = yield select(ServerDocumentSelector);

  const documentHasUnsavedChanges: boolean = !documentsAreEqual(
    currentDocument,
    serverDocument
  );

  if (documentIdToChangeTo === TEMPORARY_DOCUMENT) {
    yield call(fetchDocumentAndChildren, documentIdToChangeTo, firebase);
    return;
  }

  if (serverDocument?.name && documentHasUnsavedChanges) {
    yield put(setUnsavedDocumentChanges(true, documentIdToChangeTo, ""));
    return;
  }

  if (documentIdToChangeTo === TEMPORARY_NEW_DOCUMENT) {
    yield put(setDocumentIdToChangeTo(TEMPORARY_DOCUMENT));
    return;
  }

  yield call(fetchDocumentAndChildren, documentIdToChangeTo, firebase);
}

export function* onDocumentIdChange(firebase: Firebase) {
  const currentDocumentId: string = yield select(CurrentDocumentIdSelector);

  const documentIdToChangeTo: string = yield select(
    documentIdToChangeToSelector
  );

  (currentDocumentId && currentDocumentId !== documentIdToChangeTo) ||
  documentIdToChangeTo === TEMPORARY_NEW_DOCUMENT
    ? yield call(detectUnsavedChanges, firebase, documentIdToChangeTo)
    : yield call(fetchDocumentAndChildren, documentIdToChangeTo, firebase);
}

export function* fetchDocumentSaga(
  firebase: Firebase,
  action: IActionsFetchDocument
) {
  const documentCache: DocumentMap = yield select(allCachedDocumentsSelector);
  yield call(
    addAllRelatedDocumentsToCache,
    firebase,
    action.foodId,
    action.isPublic,
    documentCache
  );

  if (action.isMapped) {
    yield put(
      updateCalculationMethod(
        CalculationMethod.MAPPED,
        action.foodId.identifier
      )
    );
  }
}

function* detectDocumentAndDatabaseChange(firebase: Firebase) {
  yield takeEvery(actionsToListenTo, onDocumentIdChange, firebase);
}

export function* changeDocumentSaga(firebase: Firebase) {
  yield fork(detectDocumentAndDatabaseChange, firebase);
}

export function* fetchingDocumentsSaga(firebase: Firebase) {
  yield takeEvery(FETCH_DOCUMENT, fetchDocumentSaga, firebase);
}
