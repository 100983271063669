import {
  IActionsSetDisplayedNutrients,
  IActionsSetEnabledDatasources,
} from "../actions/databaseProperties";

export const setEnabledDatasources = (
  sources: string[]
): IActionsSetEnabledDatasources => {
  return {
    type: "SET_ENABLED_DATASOURCES",
    sources: sources,
  };
};

export const setDisplayedNutrients = (
  nutrientIds: string[]
): IActionsSetDisplayedNutrients => {
  return {
    type: "SET_DISPLAYED_NUTRIENTS",
    nutrientIds: nutrientIds,
  };
};
