import React, {
  FunctionComponent,
  ReactNode,
  useEffect,
  useState,
} from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  LinearProgress,
  makeStyles,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useAuthState } from "react-firebase-hooks/auth";
import { useDispatch } from "react-redux";

import Firebase, { withFirebase } from "../../data/Firebase";
import { InfoTooltip } from "../common/InfoTooltip";
import { FoodWorksTextInput } from "../common/FoodWorksTextInput";
import { appTheme } from "../../styling/style";
import { addAlert } from "../../store/ui/actionCreators/alert";

export interface AccountDialogProps {
  onClose: () => void;
  firebase?: Firebase;
}

const useStyles = makeStyles(() => ({
  inputRow: {
    display: "flex",
    alignItems: "center",
  },
  input: {
    margin: "4px 10px",
  },
  titles: {
    width: 150,
    textAlign: "end",
    whiteSpace: "nowrap",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  infoIcon: {
    color: appTheme.colors.xiketic,
    alignSelf: "center",
    height: 20,
    width: 20,
    marginLeft: 5,
    "&:hover": {
      color: appTheme.colors.help,
    },
  },
  tooltip: {
    backgroundColor: "white",
    border: `1px solid ${appTheme.colors.gainsbruh}`,
  },
  indented: { marginLeft: 160 },
  loadingMargin: { marginTop: 10 },
  changeEmail: { display: "flex" },
  hiddenDetails: { display: "flex", margin: "10px 0px" },
  dialogPadding: {
    padding: 40,
  },
  titleBar: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  divider: {
    margin: "10px 0px 10px 0px",
  },
}));

export const AccountDialogInner: FunctionComponent<AccountDialogProps> = ({
  onClose,
  firebase,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onAddAlert = (message: string) => dispatch(addAlert(message));

  const [showPassword, setShowPassword] = useState(false);
  const [passwordVerifyText, setPasswordVerifyText] = useState("");
  const [passwordVerified, setPasswordVerified] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [oldEmail, setOldEmail] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");

  const [hasChanges, setHasChanges] = useState(false);

  const [errorText, setErrorText] = useState("");

  const [loading, setLoading] = useState(false);

  const [user] = useAuthState(firebase!.auth);
  useEffect(() => {
    if (!user) return;
    firebase!.doGetUserData(user.uid).then((userDetails) => {
      setFirstName(userDetails.firstName);
      setLastName(userDetails.lastName);
      setOldEmail(user?.email || "");
    });
  }, [firebase, user]);

  const onFirstNameInputChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setHasChanges(true);
    setFirstName(event.target.value);
  };

  const firstNameInput = (
    <div className={classes.inputRow}>
      <Typography
        color="textPrimary"
        className={classes.titles}
        variant="subtitle1"
      >
        First Name:
      </Typography>
      <FoodWorksTextInput
        className={classes.input}
        value={firstName}
        onChange={onFirstNameInputChange}
      />
    </div>
  );

  const onLastNameInputChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setHasChanges(true);
    setLastName(event.target.value);
  };

  const lastNameInput = (
    <div className={classes.inputRow}>
      <Typography
        color="textPrimary"
        className={classes.titles}
        variant="subtitle1"
      >
        Last Name:
      </Typography>
      <FoodWorksTextInput
        className={classes.input}
        value={lastName}
        onChange={onLastNameInputChange}
      />
    </div>
  );

  const passwordAdornment = (
    <InputAdornment position="end">
      <IconButton onClick={() => setShowPassword(!showPassword)}>
        {showPassword ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </InputAdornment>
  );

  const onVerifyPassword = () => {
    setLoading(true);
    firebase
      ?.doSignInWithEmailAndPassword(user?.email || "", passwordVerifyText)
      .then(() => setPasswordVerified(true))
      .catch((error) => setErrorText("Password is invalid."))
      .finally(() => setLoading(false));
  };
  const passwordInput = (
    <div>
      To change your login details please enter your password
      <div className={classes.inputRow}>
        <Typography
          color="textPrimary"
          className={classes.titles}
          variant="subtitle1"
        >
          Password:
        </Typography>
        <FoodWorksTextInput
          error={errorText !== ""}
          type={showPassword ? "text" : "password"}
          value={passwordVerifyText}
          endAdornment={passwordAdornment}
          className={classes.input}
          onChange={(event) => setPasswordVerifyText(event.target.value)}
        />
      </div>
      <Typography className={classes.indented}>{errorText}</Typography>
      <Button
        size="small"
        variant="contained"
        color="secondary"
        disabled={!passwordVerifyText.length}
        className={classes.indented}
        onClick={onVerifyPassword}
      >
        Show me
      </Button>
    </div>
  );

  const onChangePassword = async () => {
    setLoading(true);

    user!
      .updatePassword(password)
      .then((result) => {
        onAddAlert("Sign in password changed successfully!");
      })
      .catch((error) => {
        setErrorText(error.message);
      });

    setLoading(false);
    setPassword("");
    setConfirmPassword("");
  };

  const newPasswordInput = (
    <div className={classes.inputRow}>
      <Typography
        color="textPrimary"
        className={classes.titles}
        variant="subtitle1"
      >
        New Password:
      </Typography>
      <FoodWorksTextInput
        error={errorText === "Password should be at least 6 characters"}
        className={classes.input}
        type={showPassword ? "text" : "password"}
        endAdornment={passwordAdornment}
        value={password}
        onChange={(event) => setPassword(event.target.value)}
      />
    </div>
  );

  const confirmPasswordInput = (
    <div className={classes.inputRow}>
      <Typography
        color="textPrimary"
        className={classes.titles}
        variant="subtitle1"
      >
        Confirm Password:
      </Typography>
      <FoodWorksTextInput
        className={classes.input}
        type={showPassword ? "text" : "password"}
        endAdornment={passwordAdornment}
        value={confirmPassword}
        onChange={(event) => setConfirmPassword(event.target.value)}
      />
    </div>
  );

  const changePasswordButton = (
    <div className={classes.loadingMargin}>
      <Button
        variant="contained"
        size="small"
        color="secondary"
        disabled={confirmPassword.length === 0 || password !== confirmPassword}
        className={classes.indented}
        onClick={onChangePassword}
      >
        Change Password
      </Button>
      <InfoTooltip title="Passwords don't match" />
    </div>
  );

  const resetPasswordSection = (
    <div>
      {newPasswordInput}
      {confirmPasswordInput}
      {errorText === "Password should be at least 6 characters" ? (
        <Typography className={classes.indented}>{errorText}</Typography>
      ) : null}
      {changePasswordButton}
    </div>
  );

  const onChangeEmail = async () => {
    setLoading(true);
    user!
      .updateEmail(newEmail)
      .then((result) => {
        onAddAlert("Sign in email changed successfully!");
        setConfirmEmail("");
        setNewEmail("");
        setOldEmail(newEmail);
      })
      .catch((error) => {
        console.log(error);
        setErrorText(error.message);
      });

    setLoading(false);
  };

  const changeEmailButton = (
    <div className={classes.changeEmail}>
      <Button
        variant="contained"
        size="small"
        color="secondary"
        disabled={newEmail.length === 0 || newEmail !== confirmEmail}
        className={classes.indented}
        onClick={onChangeEmail}
      >
        Change Email
      </Button>
      <InfoTooltip title="Emails don't match" />
    </div>
  );

  const oldEmailInput = (
    <div className={classes.inputRow}>
      <Typography
        color="textPrimary"
        className={classes.titles}
        variant="subtitle1"
      >
        Old Email:
      </Typography>
      <FoodWorksTextInput
        className={classes.input}
        type={"email"}
        value={oldEmail}
        readOnly
      />
    </div>
  );

  const newEmailInput = (
    <div className={classes.inputRow}>
      <Typography
        color="textPrimary"
        className={classes.titles}
        variant="subtitle1"
      >
        New Email:
      </Typography>
      <FoodWorksTextInput
        error={
          errorText === "The email address is badly formatted." ||
          errorText ===
            "The email address is already in use by another account."
        }
        className={classes.input}
        type={"email"}
        value={newEmail}
        onChange={(event) => setNewEmail(event.target.value)}
      />
    </div>
  );

  const confirmEmailInput = (
    <div className={classes.inputRow}>
      <Typography
        color="textPrimary"
        className={classes.titles}
        variant="subtitle1"
      >
        Confirm Email:
      </Typography>
      <FoodWorksTextInput
        className={classes.input}
        type={"email"}
        value={confirmEmail}
        onChange={(event) => setConfirmEmail(event.target.value)}
      />
    </div>
  );

  const changeEmailSection = (
    <div>
      {oldEmailInput}
      {newEmailInput}
      {confirmEmailInput}
      <div>
        {errorText === "The email address is badly formatted." ||
        errorText ===
          "The email address is already in use by another account." ? (
          <Typography className={classes.indented}>{errorText}</Typography>
        ) : null}
        {changeEmailButton}
      </div>
    </div>
  );

  const hiddenDetails = (
    <div className={classes.hiddenDetails}>
      {resetPasswordSection}
      {changeEmailSection}
    </div>
  );

  const header: ReactNode = (
    <div className={classes.titleBar}>
      <DialogTitle id="account-dialog-title">Account Details</DialogTitle>
      <IconButton onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </div>
  );

  const onSaveChanges = async () => {
    await firebase!.doUpdateUserSurname(user!.uid, lastName);
    await firebase!.doUpdateUserFirstName(user!.uid, firstName);

    onAddAlert("Details saved successfully!");
    onClose();
  };

  return (
    <>
      <Dialog maxWidth="md" open={true} onClose={onClose} fullWidth>
        {header}
        <DialogContent className={classes.dialogPadding}>
          {firstNameInput} {lastNameInput}
          {loading ? (
            <LinearProgress color="secondary" />
          ) : (
            <Divider className={classes.divider} />
          )}
          {!passwordVerified ? passwordInput : hiddenDetails}
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose} color="default">
            Close
          </Button>
          <Button
            onClick={onSaveChanges}
            disabled={!hasChanges}
            color="secondary"
          >
            Save changes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const AccountDialog = withFirebase(AccountDialogInner);
