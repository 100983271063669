import { FoodWorksDate } from "./documentProperties/date";
import firebase from "firebase";
import { SectionTag } from "./documentProperties/section";

export interface DocumentSummary {
  documentId: string;
  label: string;
  templateId: number;
  isDeleted: boolean;
  searchableProperties: {
    primary: string;
    alternate: string;
  };
  days: string[];
  sectionTags: SectionTag[];
}

export interface UserDatabaseSummary {
  id: string;
  name: string;
  date: FoodWorksDate;
}

export interface DatabaseProperties {
  displayedNutrients: string[];
}

export interface Database {
  documentSummaries: DocumentSummary[];
  summary: UserDatabaseSummary;
  properties: DatabaseProperties;
}

export const databaseConverter = {
  toFirestore: function (database: Database) {
    throw new Error("Unimplemented");
  },

  fromFirestore: function (
    snapshot: firebase.firestore.QueryDocumentSnapshot
  ): Database {
    const data: firebase.firestore.DocumentData = snapshot.data();

    const summaries: firebase.firestore.DocumentData[] = data.documentSummaries;

    return {
      summary: data.summary,
      documentSummaries: summaries.map((summary) => ({
        documentId: summary.documentId as string,
        label: summary.name as string,
        templateId: Number(summary.templateId as string),
        // todo remove || false once all summaries have been set properly
        isDeleted: summary.isDeleted || false,
        searchableProperties: summary.searchableProperties || {
          primary: "",
          alternate: "",
        },
        days: summary.days || [],
        sectionTags: summary.sectionTags || [],
      })),
      properties: data.properties,
    };
  },
};
