import { ThunkAction } from "redux-thunk";

import { RootState } from "../../reducers";
import Firebase from "../../../data/Firebase";

import { HOME } from "../../../constants/routes";
import { IActionsSetSignUpError } from "../actions/login";
import { setSignUpError } from "../actionCreators/login";
import { currentDate } from "../../../data/models/documentProperties/date";
import { IActionsSetDatabaseId } from "../../data/actions/database";

export interface LoginError {
  type: string;
  message: string | null;
}

const ERRORS: { [key: string]: LoginError } = {
  "auth/email-already-in-use": {
    type: "email",
    message: "Account already exists",
  },
  "auth/invalid-email": {
    type: "email",
    message: "Invalid email address",
  },
  "auth/weak-password": {
    type: "password",
    message: null,
  },
  "auth/user-not-found": {
    type: "email",
    message: "User not found",
  },
  "auth/user-disabled": {
    type: "email",
    message: "User not found",
  },
  "auth/wrong-password": {
    type: "password",
    message: "Incorrect password",
  },
};

export interface RouterHistory {
  push: (path: string) => void;
  replace: (path: string) => void;
}

export const createUserWithEmailAndPassword = (
  email: string,
  password: string,
  firstName: string,
  lastName: string,
  allowMarketingEmails: boolean,
  history: RouterHistory
): ThunkAction<
  Promise<void>,
  RootState,
  Firebase,
  IActionsSetSignUpError
> => async (dispatch, getState, firebase) => {
  const onSignUpError = (error: LoginError) => dispatch(setSignUpError(error));

  await firebase?.setPersistence(false);

  return firebase
    ?.doCreateUserWithEmailAndPassword(email, password)
    .then((authResult) => {
      const userId = authResult.user?.uid;

      authResult.user?.sendEmailVerification();

      firebase
        .doCreateUser(userId!, firstName, lastName, allowMarketingEmails)
        .then(() => {
          const createdAndModifiedDate = currentDate();
          firebase
            ?.doCreateUserDatabase(userId!, "New Database", {
              created: createdAndModifiedDate,
              lastModified: createdAndModifiedDate,
            })
            .then((databaseId: string) => {
              history.push(`${HOME}/${databaseId}`);
            });
        });
    })
    .catch((error) => {
      const errorInfo = ERRORS[error.code];
      onSignUpError({
        type: errorInfo?.type || "general",
        message: errorInfo?.message || error.message,
      });
    });
};

export const signInUserWithEmailAndPassword = (
  email: string,
  password: string,
  rememberMe: boolean,
  history: RouterHistory
): ThunkAction<
  Promise<void>,
  RootState,
  Firebase,
  IActionsSetSignUpError | IActionsSetDatabaseId
> => async (dispatch, getState, firebase) => {
  const onSignUpError = (error: LoginError) => dispatch(setSignUpError(error));

  await firebase.setPersistence(rememberMe);

  return firebase
    ?.doSignInWithEmailAndPassword(email, password)
    .then((auth: firebase.auth.UserCredential) => {
      firebase
        ?.doGetUserLastUsedDatabase(auth!.user!.uid)
        .then((databaseId: string) => {
          history.push(`${HOME}/${databaseId}`);
        });
    })
    .catch((error) => {
      const errorInfo = ERRORS[error.code];
      onSignUpError({
        type: errorInfo?.type || "general",
        message: errorInfo?.message || error.message,
      });
    });
};

export const sendForgotPasswordEmail = (
  email: string
): ThunkAction<
  Promise<void>,
  RootState,
  Firebase,
  IActionsSetSignUpError | IActionsSetDatabaseId
> => async (dispatch, getState, firebase) => {
  const onSignUpError = (error: LoginError) => dispatch(setSignUpError(error));
  const onSignUpSuccess = () =>
    dispatch(setSignUpError({ type: "", message: null }));

  return firebase
    ?.doPasswordReset(email)
    .then((result) => {
      onSignUpSuccess();
      return result;
    })
    .catch((error) => {
      const errorInfo = ERRORS[error.code];
      onSignUpError({
        type: errorInfo?.type || "general",
        message: errorInfo?.message || error.message,
      });
    });
};
