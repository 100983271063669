import React, { FunctionComponent } from "react";

import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";

import { appTheme } from "../../../../../styling/style";
import { RecipeGridToolbar } from "./RecipeGridToolbar";
import { RecipeGrid } from "./editing_grid/RecipeGrid";
import { IngredientSummaryItem } from "./editing_grid/cells/IngredientCell";
import { RootState } from "../../../../../store/reducers";
import { templateIdSelector } from "../../../../../store/data/current-document/selectors/document";
import {
  isFoodRecord,
  isMealPlan,
} from "../../../../../constants/FoodTemplate";
import { currentDaySelector } from "../../../../../store/ui/selectors/recipeGrid";
import { DayStepper } from "./DayStepper";

export interface RecipeIngredientsProps {
  summaries: IngredientSummaryItem[];
  searchTermMap: Map<string, string[]>;
}

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    margin: "10px 0px 0px 0px",
  },
  gridArea: {
    flex: 4,
    margin: "0px 10px",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    background: appTheme.colors.white[0],
  },
  textArea: {
    resize: "none",
    borderStyle: "none",
    borderRadius: 4,
    flex: 1,
    background: appTheme.colors.white[0],
  },
  paper: {
    display: "flex",
    flex: 1,
    padding: 10,
    background: appTheme.colors.white[0],
  },
  margin: {
    margin: 2,
  },
}));

export const RecipeIngredients: FunctionComponent<RecipeIngredientsProps> = ({
  summaries,
  searchTermMap,
}) => {
  const classes = useStyles();

  const documentTemplateId: string = useSelector<RootState, string>(
    templateIdSelector
  );

  const currentDayIndex: number = useSelector<RootState, number>(
    currentDaySelector
  );

  const showDayNavigationBar: boolean =
    isMealPlan(documentTemplateId) || isFoodRecord(documentTemplateId);

  return (
    <>
      <div className={classes.root}>
        <div className={classes.gridArea}>
          <div>
            {showDayNavigationBar && <DayStepper />}
            <RecipeGridToolbar dayIndex={currentDayIndex} />
          </div>
          <RecipeGrid
            summaries={summaries}
            dayIndex={currentDayIndex}
            searchTermMap={searchTermMap}
          />
        </div>
      </div>
    </>
  );
};
