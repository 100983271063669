import React, { FunctionComponent, ReactNode } from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";

import { SectionTagManagement } from "./components/SectionTagManagement";

const useStyles = makeStyles((theme) => ({
  titleBar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  closeIcon: {
    width: 40,
    height: 40,
  },
}));

interface SectionTagManagerDialogProps {
  onClose: () => void;
}

export const SectionTagManagerDialog: FunctionComponent<SectionTagManagerDialogProps> = ({
  onClose,
}) => {
  const classes = useStyles();

  const titleBar: ReactNode = (
    <div className={classes.titleBar}>
      <DialogTitle id="create-section-tag-dialog-title">
        Manage section tags for this document
      </DialogTitle>
      <IconButton size="small" className={classes.closeIcon} onClick={onClose}>
        <Close />
      </IconButton>
    </div>
  );

  const dialogActions: ReactNode = [<Button onClick={onClose}>Close</Button>];

  return (
    <Dialog maxWidth="sm" open={true} fullWidth onBackdropClick={onClose}>
      {titleBar}
      <DialogContent>
        <SectionTagManagement />
      </DialogContent>
      <DialogActions>{dialogActions}</DialogActions>
    </Dialog>
  );
};
