import React, {
  FunctionComponent,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";

import {
  Button,
  IconButton,
  makeStyles,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import {
  FiberManualRecord,
  FirstPage,
  LastPage,
  OpenInNew,
} from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { useResizeDetector } from "react-resize-detector";

import { dayCountSelector } from "../../../../../store/data/current-document/selectors/currentDocument";
import { RootState } from "../../../../../store/reducers";
import { updateCurrentDay } from "../../../../../store/ui/actionCreators/recipeGrid";
import {
  currentDaySelector,
  selectedRowsSelector,
} from "../../../../../store/ui/selectors/recipeGrid";
import { appTheme } from "../../../../../styling/style";
import { FoodworksTooltip } from "../../../../common/InfoTooltip";
import { Days, Day } from "../../../../../data/models/documentProperties/day";
import { FoodItemPosition } from "../../../../../data/models/foodItemPosition";
import { daysSelector } from "../../../../../store/data/current-document/selectors/days";
import { DayCheckbox } from "./DayCheckbox";
import { templateIdSelector } from "../../../../../store/data/current-document/selectors/document";
import { isMealPlan } from "../../../../../constants/FoodTemplate";
import { EditMealPlanDialog } from "../../../../dialogs/document_editing/EditMealPlanDialog";

const TAB_PADDING = 10;

const useStyles = makeStyles(() => ({
  daysShowing: {
    marginLeft: 10,
  },
  flex: {
    display: "flex",
  },
  alignCentre: {
    display: "flex",
    flex: 1,
  },
  stepperButtonsContainer: {
    backgroundColor: appTheme.colors.white[8],
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    overflowX: "auto",
  },
  tab: {
    color: appTheme.colors.primary,
    paddingLeft: TAB_PADDING,
    paddingRight: TAB_PADDING,
    "&:hover": {
      backgroundColor: appTheme.colors.oceanBlue[0],
    },
    overflow: "hidden",
  },
  tabText: {
    color: appTheme.colors.xiketic,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  tabSize: {
    minWidth: 0,
    display: "flex",
  },
  columnFlex: { display: "flex", flexDirection: "column" },
  tabScrollButton: {
    color: appTheme.colors.primary,
    "&:hover": {
      backgroundColor: appTheme.colors.oceanBlue[0],
    },
  },
  selectedDayIndicator: {
    color: appTheme.colors.primary,
    width: 5,
    height: 5,
    marginRight: 2,
  },
  tabDateText: {
    color: appTheme.colors.xiketic,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    fontSize: 10,
  },
  stepperContainer: {
    display: "flex",
    justifyContent: "center",
  },
}));

const MAX_DAY_COUNT = 7;

export const DayStepper: FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onUpdateCurrentDay = useCallback(
    (day: number) => dispatch(updateCurrentDay(day)),
    [dispatch]
  );

  const days: Days = useSelector<RootState, Days>(daysSelector);

  const dayCount: number = useSelector<RootState, number>(dayCountSelector);

  const currentDay: number = useSelector<RootState, number>(currentDaySelector);

  const step: number = useSelector<RootState, number>(currentDaySelector);

  const selectedRows: FoodItemPosition[] = useSelector<
    RootState,
    FoodItemPosition[]
  >(selectedRowsSelector);

  const templateId: string = useSelector<RootState, string>(templateIdSelector);

  const [page, setPage] = useState(0);

  const [tabWidth, setTabWidth] = useState(1200);

  const [openDialog, setOpenDialog] = useState(false);

  const { width, ref } = useResizeDetector<HTMLDivElement>();

  useEffect(() => {
    if (width) {
      //This is just some initial proportional size, should be normalised with rest of app when we do proper ratio styling changes
      setTabWidth(width < 1200 ? width / 11 : width / 6);
    }
  }, [width]);

  useEffect(() => {
    setPage(Math.floor(currentDay / MAX_DAY_COUNT));
  }, [dayCount, currentDay]);

  const onClickEditPlan = () => setOpenDialog(true);

  const onCloseEditDialog = () => setOpenDialog(false);

  const handleFirst = () => {
    setPage((prev) => prev - 1);
    onUpdateCurrentDay((page - 1) * MAX_DAY_COUNT);
  };

  const handleLast = () => {
    setPage((prev) => prev + 1);
    onUpdateCurrentDay((page + 1) * MAX_DAY_COUNT);
  };

  const handleChange = (value: number) => {
    onUpdateCurrentDay(page * MAX_DAY_COUNT + value);
  };

  const dayContainsSelectedItem = (dayIndex: number): boolean =>
    !!selectedRows.find(
      (position: FoodItemPosition): boolean => position.day === dayIndex
    );

  const dayTabs = (
    <Tabs value={currentDay % MAX_DAY_COUNT}>
      {days.days
        .slice(page * MAX_DAY_COUNT, (page + 1) * MAX_DAY_COUNT)
        .map((day: Day, index: number) => (
          <Tab
            key={`day-${index}`}
            classes={{ root: classes.tabSize }}
            className={classes.tab}
            onClick={() => handleChange(index)}
            label={
              <div className={classes.columnFlex}>
                <div className={`${classes.flex} ${classes.alignCentre}`}>
                  {dayContainsSelectedItem(day.index) && (
                    <FiberManualRecord
                      className={classes.selectedDayIndicator}
                    />
                  )}
                  <Typography
                    variant="body1"
                    className={classes.tabText}
                    style={{
                      maxWidth: tabWidth - TAB_PADDING,
                    }}
                  >
                    {day.title}
                  </Typography>
                </div>
                <Typography
                  variant="caption"
                  className={classes.tabDateText}
                  style={{
                    maxWidth: tabWidth - TAB_PADDING,
                  }}
                >
                  {day.date}
                </Typography>
              </div>
            }
          ></Tab>
        ))}
    </Tabs>
  );

  const editPlanButton: ReactNode = (
    <Button
      size="small"
      startIcon={<OpenInNew color="inherit" />}
      color="secondary"
      onClick={onClickEditPlan}
    >
      <Typography variant="button" color="inherit" noWrap>
        {isMealPlan(templateId) ? "Edit plan details" : "Edit record details"}
      </Typography>
    </Button>
  );

  const pageNavigation: ReactNode = (
    <div className={classes.stepperButtonsContainer} ref={ref}>
      <DayCheckbox />
      <div className={classes.stepperContainer}>
        <FoodworksTooltip title="Previous week">
          <IconButton
            color="secondary"
            size="small"
            onClick={handleFirst}
            disabled={step < MAX_DAY_COUNT}
          >
            <FirstPage />
          </IconButton>
        </FoodworksTooltip>
        <div>{dayTabs}</div>
        <FoodworksTooltip title="Next week">
          <IconButton
            color="secondary"
            size="small"
            onClick={handleLast}
            disabled={dayCount - page * MAX_DAY_COUNT <= MAX_DAY_COUNT}
          >
            <LastPage />
          </IconButton>
        </FoodworksTooltip>
      </div>
      {editPlanButton}
    </div>
  );

  return (
    <div className={classes.alignCentre}>
      {pageNavigation}
      {openDialog && <EditMealPlanDialog onClose={onCloseEditDialog} />}
    </div>
  );
};
