import React, { FunctionComponent, ReactNode } from "react";

import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import { FoodGeneral } from "./tabs/General";
import { FoodMeasures } from "./tabs/measures/Measures";
import { Overrides } from "./tabs/overrides/Overrides";
import { TabbedPane } from "../../common/TabbedPane";
import { RecipeIngredients } from "./tabs/ingredients/Ingredients";
import { RootState } from "../../../store/reducers";
import { templateIdSelector } from "../../../store/data/current-document/selectors/document";
import { IngredientSummaryItem } from "./tabs/ingredients/editing_grid/cells/IngredientCell";
import { MethodTab } from "./MethodInput";
import { LOADING_BAR_HEIGHT } from "../Home";
import { TitleBar } from "./TitleBar";
import {
  is24HourRecall,
  isFood,
  isFoodRecord,
  isMealPlan,
  isRecipe,
} from "../../../constants/FoodTemplate";
import { LoadingBarPadding } from "../../common/LoadingBarPadding";
import { useSummaries } from "./tabs/ingredients/foodScreenHooks";
import { createSearchMap } from "./tabs/ingredients/editing_grid/cells/ingredientUtils";
import { setCurrentTab } from "../../../store/ui/actionCreators/documentScreen";
import { currentTabSelector } from "../../../store/ui/selectors/documentScreen";

type FoodScreenProps = {
  publicSummaries: IngredientSummaryItem[];
};

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    margin: 5,
    marginBottom: 0,
  },
  title: {
    flex: 1,
    margin: 5,
  },
  loadingBarPadding: {
    paddingTop: LOADING_BAR_HEIGHT,
  },
  titleButton: { margin: 5, minWidth: 40 },
  deleteIcon: {
    color: "white",
  },
  saveSuccessAlert: { marginTop: 40 },
  titleBar: { display: "flex" },
}));

export const FoodScreen: FunctionComponent<FoodScreenProps> = ({
  publicSummaries,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onSetCurrentTab = (index: number) => dispatch(setCurrentTab(index));

  const currentDocumentTemplateId: string = useSelector<RootState, string>(
    templateIdSelector
  );

  const currentTab: number = useSelector<RootState, number>(currentTabSelector);

  const summaries: IngredientSummaryItem[] = useSummaries(publicSummaries);
  const searchTermMap = createSearchMap(summaries);

  const addGeneralTab = (
    tabs: { title: string; content: ReactNode }[],
    templateId: string
  ): void => {
    tabs.push({
      title: "General",
      content: <FoodGeneral />,
    });
  };

  const addIngredientsTab = (
    tabs: { title: string; content: ReactNode }[],
    id: string
  ): void => {
    if (isRecipe(id)) {
      tabs.push({
        title: "Ingredients",
        content: (
          <RecipeIngredients
            summaries={summaries}
            searchTermMap={searchTermMap}
          />
        ),
      });
    } else if (is24HourRecall(id) || isMealPlan(id) || isFoodRecord(id)) {
      tabs.push({
        title: "Foods",
        content: (
          <RecipeIngredients
            summaries={summaries}
            searchTermMap={searchTermMap}
          />
        ),
      });
    }
  };

  const addMethodTab = (
    tabs: { title: string; content: ReactNode }[],
    id: string
  ): void => {
    if (isRecipe(id)) {
      tabs.push({
        title: "Method",
        content: <MethodTab />,
      });
    }
  };

  const addOverridesTab = (
    tabs: { title: string; content: ReactNode }[],
    id: string
  ): void => {
    if (isRecipe(id)) {
      tabs.push({
        title: "Overrides",
        content: (
          <Overrides summaries={summaries} searchTermMap={searchTermMap} />
        ),
      });
    } else if (isFood(id)) {
      tabs.push({
        title: "Nutrients",
        content: (
          <Overrides summaries={summaries} searchTermMap={searchTermMap} />
        ),
      });
    }
  };

  const addMeasuresTab = (
    tabs: { title: string; content: ReactNode }[],
    id: string
  ): void => {
    if (isRecipe(id) || isFood(id)) {
      tabs.push({
        title: "Measures",
        content: <FoodMeasures summaries={summaries} />,
      });
    }
  };

  const addNRVTab = (
    tabs: { title: string; content: ReactNode }[],
    id: string
  ): void => {
    if (is24HourRecall(id) || isMealPlan(id) || isFoodRecord(id)) {
      tabs.push({ title: "NRVs/Goals", content: <div>Content to come</div> });
    }
  };

  const createTabs = () => {
    const tabs: { title: string; content: ReactNode }[] = [];
    addGeneralTab(tabs, currentDocumentTemplateId);
    addIngredientsTab(tabs, currentDocumentTemplateId);
    addMethodTab(tabs, currentDocumentTemplateId);
    addOverridesTab(tabs, currentDocumentTemplateId);
    addMeasuresTab(tabs, currentDocumentTemplateId);

    addNRVTab(tabs, currentDocumentTemplateId);

    return tabs;
  };

  return (
    <>
      <div className={classes.root}>
        <LoadingBarPadding />
        <TitleBar />
        <TabbedPane
          tabs={createTabs()}
          currentTab={currentTab}
          onChangeTab={onSetCurrentTab}
        />
      </div>
    </>
  );
};
