import React, { FunctionComponent, ReactNode, useState } from "react";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
  IconButton,
  DialogActions,
  Button,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch } from "react-redux";
import { useAuthState } from "react-firebase-hooks/auth";

import { appTheme } from "../../styling/style";
import { deleteDatabase } from "../../store/data/thunks/database";
import Firebase from "../../data/Firebase";
import { RouterHistory } from "../../store/ui/thunks/login";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: "1",
    flexDirection: "column",
    alignItems: "center",
  },
  titleBar: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  flex: {
    display: "flex",
  },
  deleteButton: {
    color: appTheme.colors.error,
  },
  databaseName: {
    marginLeft: 10,
    fontWeight: "bold",
  },
  confirmationInput: {
    marginTop: 10,
    width: 300,
    fontSize: appTheme.fontSize.textInput,
  },
  none: {},
}));

const ValidationTextField = withStyles({
  root: {
    "& input:valid + fieldset": {
      borderColor: appTheme.colors.success,
      borderWidth: 2,
    },
    "& input:invalid + fieldset": {
      borderColor: appTheme.colors.error,
      borderWidth: 2,
    },
    "& input:valid:focus + fieldset": {
      borderColor: appTheme.colors.error,
      borderWidth: 2,
      borderLeftWidth: 6,
      padding: "4px !important",
    },
  },
})(TextField);

interface DeleteDatabaseDialogProps {
  open: boolean;
  onClose: () => void;
  databaseName: string;
  databaseId: string;
  firebase: Firebase;
  history: RouterHistory;
}

export const DeleteDatabaseDialog: FunctionComponent<DeleteDatabaseDialogProps> = ({
  open,
  onClose,
  databaseName,
  databaseId,
  firebase,
  history,
}) => {
  const [user] = useAuthState(firebase.auth);
  const classes = useStyles();
  const dispatch = useDispatch();

  const onDeleteDatabase = () =>
    dispatch(deleteDatabase(user!.uid, databaseId, history));

  const [confirmText, setConfirmText] = useState("");

  const dialogTitle: ReactNode = (
    <div className={classes.titleBar}>
      <DialogTitle data-cy="databaseDialogTitle">
        Permanently delete this database?
      </DialogTitle>
      <IconButton onClick={() => onClose()}>
        <CloseIcon />
      </IconButton>
    </div>
  );

  const incorrectDatabaseName: boolean = confirmText !== databaseName;

  const dialogBody: ReactNode = (
    <DialogContent>
      <Typography>
        Doing so will permanently delete the data in this database including all
        documents.
      </Typography>
      <div className={classes.flex}>
        <Typography>
          Confirm you want to delete this database by typing its name:{" "}
        </Typography>
        <Typography className={classes.databaseName}>{databaseName}</Typography>
      </div>
      <ValidationTextField
        className={classes.confirmationInput}
        id="databaseToDelete"
        size="small"
        variant="outlined"
        placeholder={databaseName}
        required
        error={incorrectDatabaseName}
        helperText={incorrectDatabaseName ? `Enter the database name` : ""}
        value={confirmText}
        onChange={(event) => setConfirmText(event.currentTarget.value)}
      />
    </DialogContent>
  );

  const onClickDelete = () => {
    onDeleteDatabase();
    onClose();
  };

  const dialogActions: ReactNode = (
    <DialogActions>
      <Button color="default" onClick={onClose}>
        Cancel
      </Button>
      <Button
        className={incorrectDatabaseName ? classes.none : classes.deleteButton}
        disabled={incorrectDatabaseName}
        onClick={onClickDelete}
      >
        Delete
      </Button>
    </DialogActions>
  );
  return (
    <Dialog maxWidth="md" open={open}>
      {dialogTitle}
      {dialogBody}
      {dialogActions}
    </Dialog>
  );
};
