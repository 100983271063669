import { CalculationMethod } from "../../constants/calculationMethod";
import {
  is24HourRecall,
  isFoodRecord,
  isMealPlan,
  isRecipe,
} from "../../constants/FoodTemplate";

import {
  InvalidServeError,
  InvalidVolumeConversionError,
  UnknownDocumentError,
  UnknownMeasureError,
} from "../../errors";

import { DocumentMap } from "../../store/data/reducers/documentCache";
import { ENABLED_NUTRIENTS } from "../../store/data/reducers/referenceData";
import { Composition, CompositionCache } from "./composition";
import { Document } from "./document";
import { FoodItem, FoodItemState } from "./documentProperties/foodItem";
import {
  buildCommonMeasuresForMappedDocument,
  CommonMeasure,
  SERVE_ID,
} from "./documentProperties/measure";
import { NutrientOverride } from "./documentProperties/nutrientOverride";
import { Quantity } from "./documentProperties/quantity";
import { RetentionFactor } from "./documentProperties/retentionFactor";
import { ServeType } from "./documentProperties/serve";
import {
  getVolumeConversionFromMappedDocument,
  VolumeConversionFactor,
} from "./documentProperties/volumeConversionFactor";
import { Yield } from "./documentProperties/yield";
import { FoodItemPosition } from "./foodItemPosition";
import { DartNutrient } from "./nutrient";
import { ReferenceMeasure } from "./referenceMeasure";
import {
  applyNutrientOverridesToCompositionWrapper,
  applyRetentionFactorsToCompositionWrapper,
  applyYieldToCompositionWrapper,
} from "../dart_to_js_conversion/compositionFunctionWrappers";

export class DartCompositionCalculator {
  applyNutrientOverridesToComposition = (
    nutrientValues: Composition,
    overrides: NutrientOverride[]
  ): DartNutrient[] => {
    return Object.values(
      applyNutrientOverridesToCompositionWrapper({
        composition: nutrientValues.object,
        nutrientOverrides: overrides,
        enabledNutrients: ENABLED_NUTRIENTS,
      })._jsObject.nutrients
    );
  };

  applyRetentionFactorsToComposition = (
    nutrientValues: Composition,
    retentionFactorCode: number
  ): DartNutrient[] => {
    return Object.values(
      applyRetentionFactorsToCompositionWrapper({
        composition: nutrientValues.object,
        retentionFactorCode: retentionFactorCode,
      })._jsObject.nutrients
    );
  };

  applyYieldToComposition = (
    nutrientValues: Composition,
    yieldInfo: Yield
  ): DartNutrient[] => {
    return Object.values(
      applyYieldToCompositionWrapper({
        composition: nutrientValues.object,
        yield: yieldInfo,
      })._jsObject.nutrients
    );
  };
}

export class CompositionCalculator {
  private dartCompositionCalculation: DartCompositionCalculator = new DartCompositionCalculator();

  private compositionCache: CompositionCache;
  private documentCache: DocumentMap;
  private referenceMeasures: ReferenceMeasure[];
  private retentionFactorMap: Map<string, RetentionFactor>;

  constructor(
    compositionCache: CompositionCache,
    documentCache: DocumentMap,
    referenceMeasures: ReferenceMeasure[],
    retentionFactorMap: Map<string, RetentionFactor>
  ) {
    this.compositionCache = compositionCache;
    this.documentCache = documentCache;
    this.referenceMeasures = referenceMeasures;
    this.retentionFactorMap = retentionFactorMap;
  }

  private getServeQuantity = (document: Document): number => {
    if (!document.serve.value) {
      throw new InvalidServeError(document.name);
    }
    let measureQuantity: number;

    if (
      document.serve.type === ServeType.WEIGHT ||
      document.serve.type === ServeType.AUS_BRANDS_WEIGHT
    ) {
      measureQuantity = document.serve.value!;
    } else {
      const recipeComposition = this.calculateRecipe(
        this.getValidFoodItems(document, []),
        document.yield,
        document.nutrientOverrides
      );
      measureQuantity = recipeComposition.weight / document.serve.value!;
    }

    return measureQuantity;
  };

  private getReferenceMeasureQuantity = (
    quantity: Quantity,
    document: Document
  ): number | undefined => {
    let measure: ReferenceMeasure | undefined = this.referenceMeasures.find(
      (measure: ReferenceMeasure): boolean => measure.id === quantity!.measureId
    );

    if (!measure) return undefined;

    if (measure.isVolume) {
      let volumeConversion = new VolumeConversionFactor(
        document.volumeConversion
      );
      if (!volumeConversion.factor) {
        if (
          document.calculationMethod === CalculationMethod.MAPPED &&
          document.documentMappingId
        ) {
          volumeConversion = getVolumeConversionFromMappedDocument(
            document,
            this.documentCache
          );

          if (!volumeConversion.factor) {
            throw new InvalidVolumeConversionError(document.name);
          }
        } else {
          throw new InvalidVolumeConversionError(document.name);
        }
      }
      return measure.baseQuantity().value * volumeConversion.factor!;
    }

    return measure?.baseQuantity().value;
  };

  private getBaseQuantity = (
    quantity: Quantity,
    document: Document
  ): number => {
    let measureQuantity: number | undefined;

    if (quantity.measureId === SERVE_ID) {
      return this.getServeQuantity(document);
    }

    measureQuantity = this.getReferenceMeasureQuantity(quantity, document);

    if (measureQuantity) return measureQuantity;

    const commonMeasures: CommonMeasure[] = document.commonMeasures.measures;
    measureQuantity = commonMeasures.find(
      (measure: CommonMeasure): boolean => measure.id === quantity!.measureId
    )?.value;

    if (measureQuantity) return measureQuantity;

    if (
      document.calculationMethod === CalculationMethod.MAPPED &&
      document.documentMappingId
    ) {
      const mappedMeasures: CommonMeasure[] = buildCommonMeasuresForMappedDocument(
        document,
        this.documentCache
      );
      measureQuantity = mappedMeasures.find(
        (measure: CommonMeasure): boolean => measure.id === quantity!.measureId
      )?.value;
    }

    if (measureQuantity) return measureQuantity;

    throw new UnknownMeasureError(document.name);
  };

  applyBaseQuantity(
    composition: Composition,
    quantity: Quantity,
    document: Document
  ): Composition {
    const measureWeight: number = this.getBaseQuantity(quantity, document);

    const amount = measureWeight * quantity.amount;

    return composition.applyBaseQuantity(amount);
  }

  private isSelectedFoodItem = (
    dayIndex: number,
    sectionIndex: number,
    rowIndex: number,
    selectedRows: FoodItemPosition[]
  ): boolean =>
    !!selectedRows.find((position: FoodItemPosition): boolean =>
      FoodItemPosition.fromObject({
        day: dayIndex,
        section: sectionIndex,
        row: rowIndex,
      }).isEqual(position)
    );

  private getDefinedFoodItemsFromSection = (
    dayIndex: number,
    sectionIndex: number,
    foodItems: FoodItemState[],
    selectedRows: FoodItemPosition[]
  ): FoodItemState[] =>
    foodItems.filter(
      (item: FoodItemState): boolean =>
        !!item.foodId &&
        !!item.quantity &&
        (!selectedRows.length ||
          this.isSelectedFoodItem(
            dayIndex,
            sectionIndex,
            item.rowIndex,
            selectedRows
          ))
    );

  getValidFoodItems = (
    document: Document,
    selectedRows: FoodItemPosition[]
  ): FoodItem[] => {
    let foodItems: FoodItemState[] = [];
    for (const day of document.days) {
      for (const section of day.sections) {
        foodItems = foodItems.concat(
          this.getDefinedFoodItemsFromSection(
            day.index,
            section.index,
            section.foodItems,
            selectedRows
          )
        );
      }
    }

    return foodItems.map(
      (foodItemState: FoodItemState): FoodItem =>
        FoodItem.fromObject(foodItemState, this.retentionFactorMap)
    );
  };

  private applyRetentionFactor = (
    composition: Composition,
    retentionFactor: RetentionFactor
  ): Composition => {
    const nutrientValues: DartNutrient[] = this.dartCompositionCalculation.applyRetentionFactorsToComposition(
      composition,
      Number(retentionFactor.profileId)
    );

    return Composition.fromDartComposition(nutrientValues);
  };

  private applyYield = (
    composition: Composition,
    yieldInfo: Yield
  ): Composition => {
    const nutrientValues: DartNutrient[] = this.dartCompositionCalculation.applyYieldToComposition(
      composition,
      yieldInfo
    );

    return Composition.fromDartComposition(nutrientValues);
  };

  private applyOverrides = (
    composition: Composition,
    nutrientOverrides: NutrientOverride[]
  ): Composition => {
    const compositionWithOverrides: DartNutrient[] = this.dartCompositionCalculation.applyNutrientOverridesToComposition(
      composition,
      nutrientOverrides
    );
    return Composition.fromDartComposition(compositionWithOverrides);
  };

  calculateFood = (nutrientOverrides: NutrientOverride[]): Composition =>
    this.applyOverrides(new Composition({}), nutrientOverrides);

  calculateMappedFood = (
    nutrientOverrides: NutrientOverride[],
    mappedDocumentComposition: Composition
  ): Composition =>
    this.applyOverrides(mappedDocumentComposition, nutrientOverrides);

  calculateBaseMappedFood = (mappedDocumentId: string): Composition =>
    this.compositionCache.getComposition(mappedDocumentId) ||
    new Composition({});

  calculateCompositionFromFoodItems(foodItems: FoodItem[]): Composition {
    let totalComposition: Composition = new Composition({});

    for (const item of foodItems) {
      const itemDocument = this.documentCache[item.foodId!.identifier];
      let foodItemComposition: Composition = new Composition({});

      if (isRecipe(itemDocument.templateId)) {
        this.compositionCache.hasComposition(item.foodId!.identifier)
          ? foodItemComposition.addComposition(
              this.compositionCache.getComposition(item.foodId!.identifier)
            )
          : /* This is for when we initially fetch compositions to cache and the document is a recipe which may contain other recipes 
        which have not yet been cached so we recursively calculate the composition for the document's food items */
            foodItemComposition.addComposition(
              this.calculateRecipe(
                this.getValidFoodItems(itemDocument, []),
                itemDocument.yield,
                itemDocument.nutrientOverrides
              )
            );
      } else {
        foodItemComposition.addComposition(
          this.compositionCache.getComposition(item.foodId!.identifier)
        );
      }

      if (item.retentionFactor) {
        foodItemComposition = this.applyRetentionFactor(
          foodItemComposition,
          item.retentionFactor
        );
      }

      totalComposition.addComposition(
        this.applyBaseQuantity(
          foodItemComposition,
          item.quantity!,
          itemDocument
        )
      );
    }

    return totalComposition;
  }

  calculateBaseRecipe = (
    foodItems: FoodItem[],
    yieldInfo: Yield
  ): Composition => {
    let composition: Composition = this.calculateCompositionFromFoodItems(
      foodItems
    );
    return this.applyYield(composition, yieldInfo);
  };

  calculateRecipe = (
    foodItems: FoodItem[],
    yieldInfo: Yield,
    nutrientOverrides: NutrientOverride[]
  ): Composition => {
    let composition: Composition = this.calculateBaseRecipe(
      foodItems,
      yieldInfo
    );
    return this.applyOverrides(composition, nutrientOverrides);
  };

  private getMappedToDocumentComposition = (
    documentMappingId: string
  ): Composition => {
    let compositionToMapTo:
      | Composition
      | undefined = this.compositionCache.getComposition(documentMappingId);

    if (!compositionToMapTo) {
      const documentToMapTo: Document | undefined =
        this.documentCache[documentMappingId] || undefined;

      if (!documentToMapTo) {
        throw new UnknownDocumentError(documentMappingId);
      }

      compositionToMapTo = this.calculateFood(
        documentToMapTo.nutrientOverrides
      );
    }
    return compositionToMapTo;
  };

  calculateComposition = (
    currentDocument: Document,
    foodItems: FoodItem[]
  ): Composition => {
    if (currentDocument.calculationMethod === CalculationMethod.SET_TO_ZERO) {
      return new Composition({}).setToZero();
    }

    if (currentDocument.calculationMethod === CalculationMethod.MAPPED) {
      const compositionToMapTo: Composition = this.getMappedToDocumentComposition(
        currentDocument.documentMappingId
      );

      return this.calculateMappedFood(
        currentDocument.nutrientOverrides,
        compositionToMapTo
      );
    }

    if (
      is24HourRecall(currentDocument.templateId) ||
      isMealPlan(currentDocument.templateId) ||
      isFoodRecord(currentDocument.templateId)
    ) {
      return this.calculateCompositionFromFoodItems(foodItems);
    }

    if (isRecipe(currentDocument.templateId)) {
      return this.calculateRecipe(
        foodItems,
        currentDocument.yield,
        currentDocument.nutrientOverrides
      );
    }

    return this.calculateFood(currentDocument.nutrientOverrides);
  };

  applyFinalWeightToComposition = (
    composition: Composition,
    weightFactor: number
  ): Composition => composition.multiplyByFactor(weightFactor);
}
