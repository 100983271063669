import {
  DocumentEditingActions,
  SET_DISABLE_SAVE_BUTTON,
} from "../actions/documentEditing";

export interface DocumentEditingState {
  disableSaveButton: boolean;
}

export const initialDocumentEditingState: DocumentEditingState = {
  disableSaveButton: false,
};

export const documentEditing = (
  state: DocumentEditingState = initialDocumentEditingState,
  action: DocumentEditingActions
) => {
  switch (action.type) {
    case SET_DISABLE_SAVE_BUTTON:
      return { ...state, disableSaveButton: action.disable };
    default:
      return state;
  }
};
