import { Day } from "../../../../data/models/documentProperties/day";
import {
  IActionsAddDay,
  IActionsSetDays,
  IActionsUpdateDayDate,
  IActionsUpdateDayTitle,
} from "../actions/days";

export const addDay = (day: Day): IActionsAddDay => {
  return {
    type: "ADD_DAY",
    day: day,
  };
};

export const setDays = (days: Day[]): IActionsSetDays => {
  return {
    type: "SET_DAYS",
    days: days,
  };
};

export const updateDayTitle = (
  dayIndex: number,
  title: string
): IActionsUpdateDayTitle => {
  return {
    type: "UPDATE_DAY_TITLE",
    dayIndex: dayIndex,
    title: title,
  };
};

export const updateDayDate = (
  dayIndex: number,
  date: string
): IActionsUpdateDayDate => {
  return {
    type: "UPDATE_DAY_DATE",
    dayIndex: dayIndex,
    date: date,
  };
};
