import React, { FunctionComponent, ReactNode, useState } from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Card,
  List,
  MenuItem,
  makeStyles,
  Typography,
  IconButton,
} from "@material-ui/core";
import { ArrowForward, Close } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";

import { isFood } from "../../../constants/FoodTemplate";
import { SectionTag } from "../../../data/models/documentProperties/section";
import { DocumentSummary } from "../../../data/models/userDatabase";
import { addSectionTags } from "../../../store/data/current-document/action-creators/sectionTags";
import { CurrentDocumentIdSelector } from "../../../store/data/current-document/selectors/currentDocument";
import { sectionTagsSelector } from "../../../store/data/current-document/selectors/sectionTags";
import { userDocumentSummariesSelector } from "../../../store/data/selectors/database";
import { RootState } from "../../../store/reducers";
import { appTheme } from "../../../styling/style";

const useStyles = makeStyles((theme) => ({
  listButton: {
    borderRadius: 4,
    marginTop: 2,
    marginBottom: 2,
    color: appTheme.colors.xiketic,
    width: "100%",
    display: "flex",
    textTransform: "none",
  },
  selectedListButton: {
    backgroundColor: appTheme.colors.oceanBlue[0],
    color: appTheme.colors.primary,
    "&:hover": {
      backgroundColor: appTheme.colors.oceanBlue[0],
      borderColor: appTheme.colors.oceanBlue[0],
      boxShadow: "none",
      color: appTheme.colors.primary,
    },
  },
  sectionsList: {
    height: 300,
    overflowY: "auto",
  },
  sectionTagListContainer: {
    marginTop: 10,
  },
  colorButton: {
    height: 10,
    width: 10,
    minWidth: 10,
    marginRight: 5,
    borderRadius: 4,
  },
  tagListContainer: {
    marginTop: 10,
  },
  tagMenuLabel: {
    display: "flex",
    alignItems: "center",
  },
  importButtonContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  importContainer: {
    display: "flex",
    justifyContent: "space-evenly",
  },
  card: {
    marginTop: 5,
  },
  titleBar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  closeIcon: {
    width: 40,
    height: 40,
  },
}));

interface ImportSectionTagsDialogProps {
  onClose: () => void;
}

export const ImportSectionTagsDialog: FunctionComponent<ImportSectionTagsDialogProps> = ({
  onClose,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onAddSectionTags = (sectionTags: SectionTag[]) =>
    dispatch(addSectionTags(sectionTags));

  const userDocumentSummarries: DocumentSummary[] = useSelector<
    RootState,
    DocumentSummary[]
  >(userDocumentSummariesSelector);

  const currentDocumentId: string = useSelector<RootState, string>(
    CurrentDocumentIdSelector
  );

  const documentSectionTags: SectionTag[] = useSelector<
    RootState,
    SectionTag[]
  >(sectionTagsSelector);

  const [selectedDocuments, setSelectedDocuments] = useState<string[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  const documentsWithSectionsSummaries = userDocumentSummarries.filter(
    (summary: DocumentSummary): boolean =>
      !isFood(summary.templateId.toString()) &&
      summary.documentId !== currentDocumentId &&
      !summary.isDeleted
  );

  const isDocumentSelected = (documentId: string): boolean =>
    selectedDocuments.includes(documentId);

  const onSelectDocument = (documentId: string) =>
    selectedDocuments.includes(documentId)
      ? setSelectedDocuments(
          selectedDocuments.filter((id: string): boolean => id !== documentId)
        )
      : setSelectedDocuments([...selectedDocuments, documentId]);

  const userDocuments = (
    <Card className={classes.card}>
      <List className={classes.sectionsList}>
        {documentsWithSectionsSummaries.map(
          (summary: DocumentSummary): ReactNode => (
            <MenuItem
              className={
                isDocumentSelected(summary.documentId)
                  ? `${classes.listButton} ${classes.selectedListButton}`
                  : classes.listButton
              }
              onClick={() => onSelectDocument(summary.documentId)}
            >
              {summary.label}
            </MenuItem>
          )
        )}
      </List>
    </Card>
  );

  const isTagSelected = (tagId: string) => selectedTags.includes(tagId);

  const validTagsToImport = () => {
    const selectedDocumentSummaries = userDocumentSummarries.filter(
      (summary: DocumentSummary): boolean =>
        selectedDocuments.includes(summary.documentId)
    );
    return selectedDocumentSummaries.reduce<SectionTag[]>(
      (sectionTags, summary) => {
        for (const tag of summary.sectionTags) {
          sectionTags.push(tag);
        }
        return sectionTags;
      },
      []
    );
  };

  const validSectionTags: SectionTag[] = validTagsToImport();

  const onSelectTag = (tagId: string) =>
    selectedTags.includes(tagId)
      ? setSelectedTags(
          selectedTags.filter((id: string): boolean => id !== tagId)
        )
      : setSelectedTags([...selectedTags, tagId]);

  const isTagSelectable = (tag: SectionTag): boolean =>
    !!validSectionTags
      .filter((tag: SectionTag): boolean => selectedTags.includes(tag.id))
      .find(
        (filteredTag: SectionTag): boolean =>
          !selectedTags.includes(tag.id) && filteredTag.label === tag.label
      ) ||
    !!documentSectionTags.find(
      (documentSectionTag: SectionTag): boolean =>
        documentSectionTag.label === tag.label
    );

  const tagsToImport = (
    <div className={classes.tagListContainer}>
      <Typography variant="body1">
        Select one or more tags to import:
      </Typography>
      <Card className={classes.card}>
        <List className={classes.sectionsList}>
          {validSectionTags.map(
            (tag: SectionTag): ReactNode => (
              <MenuItem
                disabled={isTagSelectable(tag)}
                className={
                  isTagSelected(tag.id)
                    ? `${classes.listButton} ${classes.selectedListButton}`
                    : classes.listButton
                }
                onClick={() => onSelectTag(tag.id)}
              >
                <div className={classes.tagMenuLabel}>
                  <div
                    className={classes.colorButton}
                    style={{ backgroundColor: tag.activeColor }}
                  ></div>
                  {tag.label}
                </div>
              </MenuItem>
            )
          )}
        </List>
      </Card>
    </div>
  );

  const currentDocumentSectionTags = (
    <div className={classes.tagListContainer}>
      <Typography variant="body1">Current document's section tags:</Typography>
      <Card className={classes.card}>
        <List className={classes.sectionsList}>
          {documentSectionTags.map(
            (tag: SectionTag): ReactNode => (
              <MenuItem className={classes.listButton}>
                <div className={classes.tagMenuLabel}>
                  <div
                    className={classes.colorButton}
                    style={{ backgroundColor: tag.activeColor }}
                  ></div>
                  {tag.label}
                </div>
              </MenuItem>
            )
          )}
        </List>
      </Card>
    </div>
  );

  const onImportTags = () => {
    onAddSectionTags(
      validSectionTags.filter((tag: SectionTag): boolean =>
        selectedTags.includes(tag.id)
      )
    );
    setSelectedTags([]);
  };

  const titleBar: ReactNode = (
    <div className={classes.titleBar}>
      <DialogTitle id="create-section-tag-dialog-title">
        Import section tags
      </DialogTitle>
      <IconButton size="small" className={classes.closeIcon} onClick={onClose}>
        <Close />
      </IconButton>
    </div>
  );

  const body = (
    <div>
      {
        <Typography variant="body1">
          Select one or more documents to import from:
        </Typography>
      }
      {userDocuments}
      <div className={classes.importContainer}>
        {tagsToImport}
        <div className={classes.importButtonContainer}>
          <Button
            color="secondary"
            disabled={!selectedTags.length}
            onClick={onImportTags}
            endIcon={<ArrowForward color="inherit" />}
          >
            Import
          </Button>
        </div>
        {currentDocumentSectionTags}
      </div>
    </div>
  );

  const dialogActions: ReactNode = [<Button onClick={onClose}>Close</Button>];

  return (
    <Dialog maxWidth="sm" fullWidth open={true} onBackdropClick={onClose}>
      {titleBar}
      <DialogContent>{body}</DialogContent>
      <DialogActions>{dialogActions}</DialogActions>
    </Dialog>
  );
};
