import { ThunkAction } from "redux-thunk";

import Firebase from "../../../data/Firebase";
import { RootState } from "../../reducers";
import { setDisplayedNutrients } from "../action-creators/databaseProperties";
import { IActionsSetDisplayedNutrients } from "../actions/databaseProperties";
import { databaseIdSelector } from "../selectors/database";

export const updateDisplayedNutrients = (
  nutrientIds: string[]
): ThunkAction<
  Promise<void>,
  RootState,
  Firebase,
  IActionsSetDisplayedNutrients
> => async (dispatch, getState, firebase) => {
  const currentDatabaseId: string = databaseIdSelector(getState());

  await firebase.doUpdateUserDatabaseProperties(currentDatabaseId, {
    displayedNutrients: nutrientIds,
  });
  dispatch(setDisplayedNutrients(nutrientIds));
};
