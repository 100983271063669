import React, {
  FunctionComponent,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";

import { useDispatch, useSelector } from "react-redux";
import { Button, makeStyles, Typography } from "@material-ui/core";
import { OpenInNew } from "@material-ui/icons";

import { appTheme } from "../../../../styling/style";
import { FoodWorksTextInput } from "../../../common/FoodWorksTextInput";
import { RootState } from "../../../../store/reducers";
import { Document } from "../../../../data/models/document";
import {
  documentSelector,
  templateIdSelector,
} from "../../../../store/data/current-document/selectors/document";
import {
  updateDocumentName,
  updateDocumentIdentifier,
  updateDocumentAltIdentifier,
  updateDocumentFoodGroup,
  updateDocumentDescription,
  updateDocumentNote,
} from "../../../../store/data/current-document/action-creators/document";
import Firebase from "../../../../data/Firebase";
import { CurrentDocumentIdSelector } from "../../../../store/data/current-document/selectors/currentDocument";
import { TEMPORARY_DOCUMENT } from "../../../../store/data/current-document/reducers/currentDocument";
import { InfoTooltip } from "../../../common/InfoTooltip";
import {
  hasFoodGroup,
  isFood,
  isFoodRecord,
  isMealPlan,
} from "../../../../constants/FoodTemplate";
import { SectionTagManagerDialog } from "../../../dialogs/section_tags/SectionTagManagerDialog";
import { SetMealPlanDialog } from "../../../dialogs/document_editing/SetMealPlanDialog";

export interface FoodGeneralProps {
  firebase?: Firebase;
}

const useStyles = makeStyles(() => ({
  root: {
    flex: 1,
    marginTop: 10,
    background: appTheme.colors.white[0],
    overflowY: "auto",
  },
  inputsContainer: {
    padding: 10,
  },
  inputRow: {
    display: "flex",
    alignItems: "center",
  },
  nameRow: {
    justifyContent: "space-between",
    display: "flex",
    alignItems: "center",
  },
  textAreaRow: {
    display: "flex",
    alignItems: "flex-start",
  },
  input: {
    margin: "4px 10px",
  },
  titles: {
    width: "12ch",
    textAlign: "end",
    whiteSpace: "nowrap",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  noWrap: {
    whiteSpace: "nowrap",
  },
  textArea: {
    resize: "none",
    marginRight: 10,
    marginLeft: 10,
    flex: 1,
  },
  textAreaTitle: { marginTop: 10 },
  flex: { flex: 1 },
  progress: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  nameInput: {
    width: 500,
  },
  bottomButtonContainer: {
    display: "flex",
    marginTop: 25,
    marginLeft: 15,
  },
  manageTagsButton: {
    width: 120,
  },
  editPlanButton: {
    marginLeft: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
}));

export const FoodGeneralInner: FunctionComponent<FoodGeneralProps> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onDocumentNameChanged = (name: string) => {
    dispatch(updateDocumentName(name));
  };

  const onDocumentIdentifierChanged = (identifier: string) => {
    dispatch(updateDocumentIdentifier(identifier));
  };

  const onDocumentAltIdentifierChanged = (altIdentifier: string) => {
    dispatch(updateDocumentAltIdentifier(altIdentifier));
  };

  const onDocumentFoodGroupChanged = (foodGroup: string) => {
    dispatch(updateDocumentFoodGroup(foodGroup));
  };

  const onDocumentDescriptionChanged = (description: string) => {
    dispatch(updateDocumentDescription(description));
  };

  const onDocumentNoteChanged = (note: string) => {
    dispatch(updateDocumentNote(note));
  };

  const currentDocumentId: string = useSelector<RootState, string>(
    CurrentDocumentIdSelector
  );

  const document = useSelector<RootState, Document>(documentSelector);

  const documentTemplate: string = useSelector<RootState, string>(
    templateIdSelector
  );

  const [openTagManager, setOpenTagManager] = useState(false);
  const [openPlanManager, setOpenPlanManager] = useState(false);

  const nameInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (currentDocumentId === TEMPORARY_DOCUMENT) {
      nameInputRef.current?.focus();
      nameInputRef.current?.select();
    }
  }, [currentDocumentId, documentTemplate]);

  const nameInput = (
    <div className={classes.inputRow}>
      <Typography className={classes.titles} variant="body1">
        Name:
      </Typography>
      <FoodWorksTextInput
        inputRef={nameInputRef}
        className={`${classes.nameInput} ${classes.input}`}
        value={document.name}
        onChange={(event) => onDocumentNameChanged(event.target.value)}
      />
    </div>
  );

  const idInput = (
    <div className={classes.inputRow}>
      <Typography className={classes.titles} variant="body1">
        <InfoTooltip title="Optional searchable term for this document." />
        Id:
      </Typography>
      <FoodWorksTextInput
        placeholder="Optional"
        className={classes.input}
        value={document.identifier.primary}
        onChange={(event) => onDocumentIdentifierChanged(event.target.value)}
      />
    </div>
  );

  const altIdInput = (
    <div className={classes.inputRow}>
      <InfoTooltip title="Alternate optional searchable term for this document." />
      <Typography className={classes.noWrap} variant="body1">
        Alt. Id:
      </Typography>
      <FoodWorksTextInput
        placeholder="Optional"
        className={classes.input}
        value={document.identifier.alternate}
        onChange={(event) => onDocumentAltIdentifierChanged(event.target.value)}
      />
    </div>
  );

  const foodGroupInput = (
    <div className={classes.inputRow}>
      <InfoTooltip title="Optional field for user defined food groups." />
      <Typography className={classes.noWrap} variant="body1">
        Food group:
      </Typography>
      <FoodWorksTextInput
        placeholder="Optional"
        className={classes.input}
        value={document.foodGroup}
        onChange={(event) => onDocumentFoodGroupChanged(event.target.value)}
      />
    </div>
  );

  const descriptionInput = (
    <div className={classes.textAreaRow}>
      <Typography
        className={`${classes.titles} ${classes.textAreaTitle}`}
        variant="body1"
      >
        Description:
      </Typography>
      <FoodWorksTextInput
        multiline={true}
        rows={8}
        className={classes.textArea}
        value={document.description}
        onChange={(event) => onDocumentDescriptionChanged(event.target.value)}
      />
    </div>
  );

  const noteInput = (
    <div className={classes.textAreaRow}>
      <Typography
        className={`${classes.titles} ${classes.textAreaTitle}`}
        variant="body1"
      >
        Note:
      </Typography>
      <FoodWorksTextInput
        multiline={true}
        rows={8}
        className={classes.textArea}
        value={document.note}
        onChange={(event) => onDocumentNoteChanged(event.target.value)}
      />
    </div>
  );

  const createdLabel = (
    <div className={classes.inputRow}>
      <Typography className={classes.titles} variant="body1">
        Created:
      </Typography>
      <Typography variant="body1" className={classes.input}>
        {document.date.created
          ? new Date(document.date.created).toLocaleString()
          : ""}
      </Typography>
    </div>
  );

  const modifiedLabel = (
    <div className={classes.inputRow}>
      <Typography className={classes.titles} variant="body1">
        Modified:
      </Typography>
      <Typography variant="body1" className={classes.input}>
        {document.date.lastModified
          ? new Date(document.date.lastModified).toLocaleString()
          : ""}
      </Typography>
    </div>
  );

  const manageSectionTagsButton: ReactNode = (
    <Button
      size="small"
      startIcon={<OpenInNew color="inherit" />}
      color="secondary"
      onClick={() => setOpenTagManager(true)}
    >
      Manage section tags
    </Button>
  );

  const editPlanButton: ReactNode = (
    <Button
      className={classes.editPlanButton}
      size="small"
      startIcon={<OpenInNew color="inherit" />}
      color="secondary"
      onClick={() => setOpenPlanManager(true)}
    >
      {isMealPlan(document.templateId)
        ? "Set plan details"
        : "Set record details"}
    </Button>
  );

  return (
    <div className={classes.root}>
      <div className={classes.inputsContainer} data-cy="recipeInputsContainer">
        <div className={classes.nameRow}>{nameInput}</div>
        <div className={classes.inputRow}>
          {idInput} {altIdInput}
          {hasFoodGroup(document.templateId) ? foodGroupInput : null}
        </div>
        {descriptionInput}
        {noteInput}
        {createdLabel}
        {modifiedLabel}
        <div className={classes.bottomButtonContainer}>
          {!isFood(document.templateId) && manageSectionTagsButton}
          {!isFood(document.templateId) &&
            (isMealPlan(document.templateId) ||
              isFoodRecord(document.templateId)) &&
            editPlanButton}
        </div>
        {openPlanManager && (
          <SetMealPlanDialog
            open={openPlanManager}
            onClose={() => setOpenPlanManager(false)}
          />
        )}
        {openTagManager && (
          <SectionTagManagerDialog onClose={() => setOpenTagManager(false)} />
        )}
      </div>
    </div>
  );
};

export const FoodGeneral = FoodGeneralInner;
