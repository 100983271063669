import React, { MutableRefObject, ReactNode } from "react";

import {
  makeStyles,
  Theme,
  createStyles,
  PopperProps,
  Popper,
  Typography,
} from "@material-ui/core";
import { FilterOptionsState } from "@material-ui/lab/useAutocomplete";
import { ListChildComponentProps, VariableSizeList } from "react-window";
import { Autocomplete, AutocompleteRenderInputParams } from "@material-ui/lab";

import { IngredientSummaryItem } from "./IngredientCell";
import { QuantityOption } from "./QuantityCell";
import { GridCellInput } from "../../../../../../common/GridAutoCompleteInput";
import { appTheme } from "../../../../../../../styling/style";

const useAutoCompleteStyles = makeStyles((theme: Theme) =>
  createStyles({
    listbox: {
      width: 500,
      margin: 0,
      padding: 0,
      zIndex: 1,
      position: "absolute",
      listStyle: "none",
      backgroundColor: theme.palette.background.paper,
      overflow: "auto",
      maxHeight: 300,
      "& li": {
        padding: 0,
      },
      '& li[data-focus="true"]': {
        backgroundColor: appTheme.colors.oceanBlue[0],
        color: appTheme.colors.xiketic,
        cursor: "pointer",
      },
    },
  })
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flex: {
      flex: 1,
    },
    popperCaption: {
      marginLeft: 5,
    },
    popper: { width: 600 },
    listItem: {
      backgroundColor: theme.palette.background.paper,
      "& li:active": {
        backgroundColor: appTheme.colors.primary,
        color: "white",
      },
    },
  })
);

// This is necessary typing - excessive but required.
export type AutocompleteRef =
  | ((instance: HTMLInputElement | null) => void)
  | MutableRefObject<HTMLInputElement | null>
  | null;

export type GridCellType = QuantityOption | IngredientSummaryItem;

export interface CellProps {
  items: GridCellType[];
  initialInput: string;
  cellSize: number;
  placeholder: string;
  useSetSize: boolean;
  inputStyle?: React.CSSProperties | undefined;
  ref: AutocompleteRef;
  onSelect: (value: GridCellType) => void;
  onBlur: () => void;
  onFocus: (event: React.FocusEvent<HTMLInputElement>) => void;
  onInputChange: (event: object, value: string, reason: string) => void;
  filterOptions: (
    options: GridCellType[],
    state: FilterOptionsState<GridCellType>
  ) => GridCellType[];
  renderOption: (option: GridCellType) => ReactNode;
}
const AutocompletePopper = React.memo<PopperProps>(
  ({ children, anchorEl, open }) => {
    if (typeof anchorEl === "function") return null;

    const classes = useStyles();

    return (
      <Popper
        modifiers={{
          hide: {
            enabled: false,
          },
          flip: {
            enabled: false,
          },
          preventOverflow: {
            enabled: false,
            boundariesElement: "scrollParent",
          },
        }}
        anchorEl={anchorEl}
        className={classes.popper}
        open={open}
        placement="bottom-start"
        data-cy="autocompleteDropdown"
      >
        {children}
      </Popper>
    );
  }
);

const ITEMS_IN_AUTOCOMPLETE = 8;

export const AutoCompleteCell = React.memo(
  React.forwardRef<HTMLInputElement, CellProps>((props, ref) => {
    const classes = useStyles();
    const wrapperStyle: object = props.useSetSize
      ? {
          width: `${props.cellSize}ch`,
          maxWidth: "60%",
          fontSize: appTheme.fontSize.textInput,
          fontFamily: appTheme.fontFamily.textInput,
        }
      : {
          display: "flex",
          flex: 1,
        };

    const LISTBOX_PADDING = 8; // px

    function renderRow(props: ListChildComponentProps) {
      const { data, index, style } = props;
      return React.cloneElement(data[index], {
        style: {
          ...style,
          top: (style.top as number) + LISTBOX_PADDING,
        },
        className: classes.listItem,
      });
    }

    const OuterElementContext = React.createContext({});
    const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
      const outerProps = React.useContext(OuterElementContext);
      return (
        <div data-cy="autocompleteMenu" ref={ref} {...props} {...outerProps} />
      );
    });

    function useResetCache(data: any) {
      const ref = React.useRef<VariableSizeList>(null);
      React.useEffect(() => {
        ref?.current?.resetAfterIndex(0, true);
      }, [data]);
      return ref;
    }

    const ListboxComponent = React.forwardRef<HTMLDivElement>(
      function ListboxComponent(listboxProps, ref) {
        const { children, ...other } = listboxProps;
        const itemData = React.Children.toArray(children);
        //const theme = useTheme();
        //const smUp = useMediaQuery(theme.breakpoints.up("sm"), { noSsr: true });
        const itemCount = itemData.length;
        const itemSize = 28;

        const getChildSize = (child: React.ReactNode) => itemSize;

        const getHeight = () =>
          itemCount > ITEMS_IN_AUTOCOMPLETE
            ? ITEMS_IN_AUTOCOMPLETE * itemSize
            : itemData.map(getChildSize).reduce((a, b) => a + b, 0);

        const gridRef = useResetCache(itemCount);

        const captionText =
          itemCount >= 500
            ? "Search results limited to 500 items"
            : itemCount > ITEMS_IN_AUTOCOMPLETE
            ? `${itemCount} results.`
            : "";

        return (
          <div ref={ref}>
            <OuterElementContext.Provider value={other}>
              <VariableSizeList
                itemData={itemData}
                style={{ overflowX: "hidden" }}
                height={getHeight() + 2 * LISTBOX_PADDING}
                width="100%"
                ref={gridRef}
                outerElementType={OuterElementType}
                innerElementType="ul"
                itemSize={(index) => getChildSize(itemData[index])}
                overscanCount={5}
                itemCount={itemCount}
              >
                {renderRow}
              </VariableSizeList>
              {captionText && (
                <Typography className={classes.popperCaption} variant="caption">
                  {captionText}
                </Typography>
              )}
            </OuterElementContext.Provider>
          </div>
        );
      }
    );

    interface AutoCompleteInputProps {
      onBlur: () => void;
      onFocus: () => void;
    }

    const autoCompleteInput = (params: AutocompleteRenderInputParams) => (
      <GridCellInput
        ref={params.InputProps.ref}
        inputProps={{
          ...params.inputProps,
          onBlur: () => {
            props.onBlur();
            (params.inputProps as AutoCompleteInputProps).onBlur();
          },
          onFocus: (event: React.FocusEvent<HTMLInputElement>) => {
            props.onFocus(event);
            (params.inputProps as AutoCompleteInputProps).onFocus();
          },
        }}
        className={classes.flex}
        style={props.inputStyle}
        inputRef={ref}
        placeholder={props.placeholder}
      />
    );

    return (
      <div style={wrapperStyle}>
        <div className={classes.flex}>
          <Autocomplete
            disableListWrap
            classes={useAutoCompleteStyles()}
            ListboxComponent={
              ListboxComponent as React.ComponentType<
                React.HTMLAttributes<HTMLElement>
              >
            }
            PopperComponent={AutocompletePopper}
            options={props.items}
            autoHighlight
            openOnFocus
            disableClearable
            onChange={(event, value) => props.onSelect(value!)}
            onInputChange={(event, value, reason) =>
              props.onInputChange(event, value, reason)
            }
            getOptionSelected={() => true}
            filterOptions={props.filterOptions}
            inputValue={props.initialInput}
            getOptionLabel={(option) => option.label}
            renderInput={autoCompleteInput}
            renderOption={(option: GridCellType, { selected }) =>
              props.renderOption(option)
            }
          />
        </div>
      </div>
    );
  })
);
