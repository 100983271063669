import { Action } from "redux";

export const SET_UNSAVED_DOCUMENT_CHANGES = "SET_UNSAVED_DOCUMENT_CHANGES";
export const SET_DOCUMENT_ID_TO_CHANGE_TO = "SET_DOCUMENT_ID_TO_CHANGE_TO";

export interface IActionsSetUnsavedDocumentChanges extends Action {
  type: "SET_UNSAVED_DOCUMENT_CHANGES";
  openSaveDialog: boolean;
  documentIdToChangeTo: string;
  databaseIdToChangeTo: string;
}

export interface IActionsSetDocumentIdToChangeTo extends Action {
  type: "SET_DOCUMENT_ID_TO_CHANGE_TO";
  documentId: string;
}

export type DocumentSavingActions =
  | IActionsSetUnsavedDocumentChanges
  | IActionsSetDocumentIdToChangeTo;
