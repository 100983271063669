import app, { firestore } from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { documentConverter, Document } from "../models/document";
import firebase from "firebase";
import {
  databaseConverter,
  Database,
  UserDatabaseSummary,
  DocumentSummary,
  DatabaseProperties,
} from "../models/userDatabase";
import { DataSource, dataSourceConverter } from "../models/datasource";
import { Nutrient, nutrientConverter } from "../models/nutrient";
import { Category, categoryConverter } from "../models/category";
import {
  ReferenceMeasureObject,
  referenceMeasureConverter,
} from "../models/referenceMeasure";
import {
  ENABLED_REFERENCE_MEASURES,
  NutrientMap,
  CategoryMap,
  ReferenceMeasureMap,
  RetentionFactorProfileMap,
  RetentionFactorGroupMap,
} from "../../store/data/reducers/referenceData";
import {
  RetentionFactorProfile,
  retentionFactorProfilesConverter,
  NutrientRetentionValue,
  nutrientRetentionValuesConverter,
} from "../models/documentProperties/retentionFactor";
import { FoodId } from "../models/documentProperties/foodId";
import { FoodWorksDate } from "../models/documentProperties/date";
import { User, userConverter } from "../models/user";
import { CommonMeasure } from "../models/documentProperties/measure";
import { isMealPlan, isFoodRecord } from "../../constants/FoodTemplate";
import { DayState } from "../models/documentProperties/day";

declare global {
  interface Window {
    Cypress?: any;
  }
}

export const config = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FB_DATABASE_URL,
  projectId: process.env.REACT_APP_FB_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FB_APP_ID,
  measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID,
};

interface FirebaseCollectionQuery {
  get: (
    options?: firestore.GetOptions | undefined
  ) => Promise<firestore.QuerySnapshot<any>>;
}

interface FirebaseDocumentQuery {
  get: (
    options?: firestore.GetOptions | undefined
  ) => Promise<firestore.DocumentSnapshot<any>>;
}

const DATABASE_DOCUMENTS = "documents";
class Firebase {
  auth: firebase.auth.Auth;
  db: firebase.firestore.Firestore;
  storage: firebase.storage.Storage;

  constructor() {
    app.initializeApp(config);
    app.analytics();
    this.auth = app.auth();

    this.db = app.firestore();
    this.storage = app.storage();

    const firestoreSettings: {
      host?: string;
      ssl?: boolean;
      experimentalForceLongPolling?: boolean;
    } = {};
    // Connect to emulators in dev environment
    if (window.location.hostname === "localhost") {
      const firestoreEmulatorHost =
        process.env.REACT_APP_FIRESTORE_EMULATOR_HOST;

      if (firestoreEmulatorHost) {
        firestoreSettings.host = firestoreEmulatorHost;
        firestoreSettings.ssl = false;
      }
    }
    if (window.Cypress) {
      // Needed for Firestore support in Cypress (see https://github.com/cypress-io/cypress/issues/6350)
      firestoreSettings.experimentalForceLongPolling = true;
    }

    this.db.settings(firestoreSettings);

    this.db.enablePersistence().catch(function (err) {
      if (err.code === "failed-precondition") {
        // Multiple tabs open, persistence can only be enabled
        // in one tab at a a time.
        // ...
      } else if (err.code === "unimplemented") {
        // The current browser does not support all of the
        // features required to enable persistence
        // ...
      }
    });
  }

  setPersistence = (shouldPersist: boolean): Promise<void> => {
    return this.auth.setPersistence(
      shouldPersist
        ? firebase.auth.Auth.Persistence.LOCAL
        : firebase.auth.Auth.Persistence.SESSION
    );
  };

  cachedCollectionGet = async (
    firebaseQuery: FirebaseCollectionQuery
  ): Promise<firestore.QuerySnapshot<any>> => {
    let snapshot = await firebaseQuery.get({ source: "cache" });
    if (snapshot.empty) {
      return firebaseQuery.get({ source: "server" });
    }
    return snapshot;
  };

  cachedDocumentGet = async (
    firebaseQuery: FirebaseDocumentQuery
  ): Promise<app.firestore.DocumentSnapshot<any>> => {
    let snapshot: app.firestore.DocumentSnapshot<any>;
    try {
      snapshot = await firebaseQuery.get({ source: "cache" });
    } catch (e) {
      snapshot = await firebaseQuery.get({ source: "server" });
    }
    return snapshot;
  };

  userDatabaseDocument = (
    databaseId: string
  ): firebase.firestore.DocumentReference<firebase.firestore.DocumentData> =>
    this.db.collection("user-databases").doc(databaseId);

  publicDatabaseDocument = (
    databaseId: string
  ): firebase.firestore.DocumentReference<firebase.firestore.DocumentData> =>
    this.db.collection("public-databases").doc(databaseId);

  doCreateUser = async (
    uid: string,
    firstName: string,
    lastName: string,
    allowMarketingEmails: boolean
  ): Promise<void> => {
    await this.db.collection("users").doc(uid).set({
      user: uid,
      firstName: firstName,
      lastName: lastName,
      allowMarketingEmails: allowMarketingEmails,
    });
    return this.db.collection("user-permissions").doc(uid).set({
      databases: [],
    });
  };

  doGetUser = (uid: string) => this.db.collection("users").doc(uid).get();

  doUpdateUserFirstName = (uid: string, name: string) =>
    this.db.collection("users").doc(uid).update({ firstName: name });

  doUpdateUserSurname = (uid: string, name: string) =>
    this.db.collection("users").doc(uid).update({ lastName: name });

  doCreateUserDatabase = async (
    uid: string,
    name: string,
    date: FoodWorksDate
  ): Promise<string> => {
    const databaseData = this.db.collection("user-databases").doc();

    await this.db
      .collection("user-permissions")
      .doc(uid)
      .update({
        databases: firebase.firestore.FieldValue.arrayUnion(databaseData.id!),
      });

    const newDatabase: Database = {
      documentSummaries: [],
      summary: {
        id: databaseData.id!,
        name: name,
        date: date,
      },
      properties: { displayedNutrients: [] },
    };

    await databaseData.set(newDatabase);

    await this.db.collection("users").doc(uid).update({
      lastUsedDatabase: databaseData.id!,
    });

    return databaseData!.id;
  };

  doGetUserData = (uid: string): Promise<User> =>
    this.cachedDocumentGet(
      this.db.collection("users").withConverter(userConverter).doc(uid)
    ).then(
      (snapshot: firebase.firestore.DocumentSnapshot<User | undefined>) =>
        snapshot.data()!
    );

  doGetUserLastUsedDatabase = (uid: string): Promise<string> =>
    this.db
      .collection("users")
      .doc(uid)
      .get()
      .then(
        (snapshot: firebase.firestore.DocumentSnapshot) =>
          snapshot.data()!.lastUsedDatabase
      );

  doUpdateUserLastUsedDatabase = (uid: string, databaseId: string) =>
    this.db
      .collection("users")
      .doc(uid)
      .update({ lastUsedDatabase: databaseId });

  doDeleteUserDatabaseSummary = (uid: string, database: UserDatabaseSummary) =>
    this.db
      .collection("user-permissions")
      .doc(uid)
      .update({
        databases: firebase.firestore.FieldValue.arrayRemove(database.id),
      });

  doUpdateUserDatabaseProperties = async (
    databaseId: string,
    databaseProperties: DatabaseProperties
  ) =>
    this.userDatabaseDocument(databaseId).update({
      properties: databaseProperties,
    });

  doUpdateUserDatabaseName = async (
    uid: string,
    databaseId: string,
    databaseName: string
  ) => {
    let databaseSummary: UserDatabaseSummary = await this.db
      .collection("user-databases")
      .doc(databaseId)
      .get()
      .then(
        (data: firestore.DocumentSnapshot<firestore.DocumentData>) =>
          data.data()!.summary
      );
    databaseSummary = { ...databaseSummary, name: databaseName };
    await this.db
      .collection("user-databases")
      .doc(databaseId)
      .update({ summary: databaseSummary });
  };

  doUpdateUserDatabaseLastModified = async (
    uid: string,
    databaseId: string,
    lastModified: string
  ) => {
    let databaseSummary: UserDatabaseSummary = await this.db
      .collection("user-databases")
      .doc(databaseId)
      .get()
      .then(
        (data: firestore.DocumentSnapshot<firestore.DocumentData>) =>
          data.data()!.summary
      );
    databaseSummary = {
      ...databaseSummary,
      date: { ...databaseSummary.date, lastModified: lastModified },
    };
    this.db
      .collection("user-databases")
      .doc(databaseId)
      .update({ summary: databaseSummary });
  };

  doGetUserDatabaseSummaries = async (uid: string) => {
    const userDatabaseIds: string[] = (
      await this.db.collection("user-permissions").doc(uid).get()
    ).data()!.databases;

    let databaseSummaries: UserDatabaseSummary[] = [];
    for (const id of userDatabaseIds) {
      const databaseSummary: UserDatabaseSummary = (await this.doGetUserDatabaseDocument(
        id
      ))!.summary;

      databaseSummaries.push(databaseSummary);
    }

    return databaseSummaries;
  };

  doCreateDocument = (
    databaseId: string,
    document: Document
  ): Promise<
    firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
  > =>
    this.userDatabaseDocument(databaseId)
      .collection(DATABASE_DOCUMENTS)
      .withConverter(documentConverter)
      .add(document);

  doGetUserDatabaseDocument = async (
    databaseId: string
  ): Promise<Database | undefined> => {
    const data: firebase.firestore.DocumentSnapshot<Database> = await this.userDatabaseDocument(
      databaseId
    )
      .withConverter(databaseConverter)
      .get();

    return data.data();
  };

  doUpdateUserDatabaseDocumentMeasure = async (
    databaseId: string,
    newCommonMeasures: CommonMeasure[]
  ): Promise<void> => {
    return await this.userDatabaseDocument(databaseId)
      .withConverter(databaseConverter)
      .update(newCommonMeasures);
  };

  doCreateSummary = (
    databaseId: string,
    document: Document,
    documentId: string
  ): Promise<void> =>
    this.userDatabaseDocument(databaseId).update({
      documentSummaries: firebase.firestore.FieldValue.arrayUnion({
        documentId: documentId,
        templateId: document.templateId,
        name: document.name,
        isDeleted: document.properties.isDeleted,
        searchableProperties: {
          ...document.identifier,
        },
        days:
          isMealPlan(document.templateId) || isFoodRecord(document.templateId)
            ? document.days.map((day: DayState): string =>
                day.date ? `${day.title}-${day.date}` : day.title
              )
            : [],
        sectionTags: document.sectionTags,
      }),
    });

  doUpdateSummary = async (
    databaseId: string,
    document: Document,
    documentId: string,
    existingDocumentSummary: DocumentSummary
  ) => {
    const batch = this.db.batch();
    const databaseRef = this.userDatabaseDocument(databaseId);

    batch.update(databaseRef, {
      documentSummaries: firebase.firestore.FieldValue.arrayRemove({
        documentId: documentId,
        templateId: document.templateId,
        name: existingDocumentSummary.label,
        isDeleted: existingDocumentSummary.isDeleted,
        searchableProperties: existingDocumentSummary.searchableProperties,
        days: existingDocumentSummary.days,
        sectionTags: existingDocumentSummary.sectionTags,
      }),
    });

    batch.update(databaseRef, {
      documentSummaries: firebase.firestore.FieldValue.arrayUnion({
        documentId: documentId,
        templateId: document.templateId,
        name: document.name,
        isDeleted: document.properties.isDeleted,
        searchableProperties: {
          ...document.identifier,
        },
        days:
          isMealPlan(document.templateId) || isFoodRecord(document.templateId)
            ? document.days.map((day: DayState): string =>
                day.date ? `${day.title}-${day.date}` : day.title
              )
            : [],
        sectionTags: document.sectionTags,
      }),
    });

    await batch.commit();
  };

  doRemoveSummary = (
    databaseId: string,
    document: Document,
    documentId: string,
    oldDocumentName: string,
    isDeleted: boolean
  ): Promise<void> =>
    this.userDatabaseDocument(databaseId).update({
      documentSummaries: firebase.firestore.FieldValue.arrayRemove({
        documentId: documentId,
        templateId: document.templateId,
        name: oldDocumentName,
        isDeleted: isDeleted,
      }),
    });

  doFetchNutrients = async (): Promise<NutrientMap> => {
    const data: app.firestore.QuerySnapshot<Nutrient> = await this.cachedCollectionGet(
      this.db
        .collection("reference-data")
        .doc("nutrients")
        .collection("definitions")
        .withConverter(nutrientConverter)
    );
    const nutrientMap: NutrientMap = {};

    for (const nutrient of data.docs) {
      nutrientMap[nutrient.id!] = nutrient.data()!;
    }

    return nutrientMap;
  };

  doFetchCategories = async (): Promise<CategoryMap> => {
    const data: app.firestore.QuerySnapshot<Category> = await this.cachedCollectionGet(
      this.db
        .collection("reference-data")
        .doc("nutrients")
        .collection("categories")
        .withConverter(categoryConverter)
    );

    const categoryMap: CategoryMap = {};

    for (const category of data.docs) {
      categoryMap[category.id!] = category.data()!;
    }

    return categoryMap;
  };

  doFetchReferenceMeasures = async (): Promise<ReferenceMeasureMap> => {
    const data: app.firestore.QuerySnapshot<ReferenceMeasureObject> = await this.cachedCollectionGet(
      this.db
        .collection("reference-data")
        .doc("reference-measures")
        .collection("definitions")
        .withConverter(referenceMeasureConverter)
        .where(
          firebase.firestore.FieldPath.documentId(),
          "in",
          ENABLED_REFERENCE_MEASURES
        )
    );

    const referenceMeasureMap: ReferenceMeasureMap = {};

    for (const referenceMeasure of data.docs) {
      referenceMeasureMap[referenceMeasure.id!] = referenceMeasure.data()!;
    }

    return referenceMeasureMap;
  };

  doFetchRetentionFactorProfiles = async (): Promise<RetentionFactorProfileMap> => {
    const data: app.firestore.QuerySnapshot<
      RetentionFactorProfile[]
    > = await this.cachedCollectionGet(
      this.db
        .collection("reference-data")
        .doc("retention-factors")
        .collection("profiles")
        .withConverter(retentionFactorProfilesConverter)
    );

    const retentionFactorProfileMap: RetentionFactorProfileMap = {};

    for (const profiles of data.docs) {
      retentionFactorProfileMap[profiles.id!] = profiles.data()!;
    }

    return retentionFactorProfileMap;
  };

  doFetchNutrientRetentionValue = (
    profileId: string
  ): Promise<firebase.firestore.DocumentSnapshot<NutrientRetentionValue[]>> =>
    this.cachedDocumentGet(
      this.db
        .collection("reference-data")
        .doc("retention-factors")
        .collection("nutrient-retention-values")
        .doc(profileId)
        .withConverter(nutrientRetentionValuesConverter)
    );

  doFetchRetentionFactorGroups = async (): Promise<RetentionFactorGroupMap> => {
    const data: app.firestore.DocumentSnapshot = await this.cachedDocumentGet(
      this.db.collection("reference-data").doc("retention-factors")
    );

    const groupMap: RetentionFactorGroupMap = data.data()!.groups;

    return groupMap;
  };

  doGetUserDocument = async (foodId: FoodId): Promise<Document> => {
    const data: app.firestore.DocumentSnapshot<Document> = await this.userDatabaseDocument(
      foodId.datasourceId
    )
      .collection(DATABASE_DOCUMENTS)
      .withConverter(documentConverter)
      .doc(foodId.documentId)
      .get();

    return data.data()!;
  };

  doGetPublicDocument = async (foodId: FoodId): Promise<Document> => {
    const data: app.firestore.DocumentSnapshot<Document> = await this.cachedDocumentGet(
      this.publicDatabaseDocument(foodId.datasourceId)
        .collection(DATABASE_DOCUMENTS)
        .withConverter(documentConverter)
        .doc(foodId.documentId)
    );
    return data.data()!;
  };

  doGetDataSourceDocument = async (
    databaseId: string
  ): Promise<DataSource | undefined> => {
    const data: app.firestore.DocumentSnapshot<DataSource> = await this.cachedDocumentGet(
      this.publicDatabaseDocument(databaseId).withConverter(dataSourceConverter)
    );
    return data.data();
  };

  doGetListOfDocuments = async (
    isPublic: boolean,
    databaseId: string,
    documentIds: string[]
  ): Promise<[string, Document][]> => {
    const databaseDocument = isPublic
      ? this.publicDatabaseDocument(databaseId)
      : this.userDatabaseDocument(databaseId);

    const documentsQuery = databaseDocument
      .collection(DATABASE_DOCUMENTS)
      .withConverter(documentConverter);

    const toFetch = [];
    let documents: app.firestore.DocumentSnapshot<Document>[] = [];
    for (const documentId of documentIds) {
      let cachedDocument: app.firestore.DocumentSnapshot<any>;
      try {
        cachedDocument = await documentsQuery
          .doc(documentId)
          .get({ source: "cache" });
        documents.push(cachedDocument);
      } catch (e) {
        toFetch.push(documentId);
      }
    }

    if (toFetch.length) {
      const data: app.firestore.QuerySnapshot<Document> = await documentsQuery
        .where(firebase.firestore.FieldPath.documentId(), "in", toFetch)
        .get();
      documents = documents.concat(data.docs);
    }

    return documents.map((snapshot: app.firestore.DocumentSnapshot<Document>): [
      string,
      Document
    ] => [snapshot.id!, snapshot.data()!]);
  };

  doUpdateDocument = (
    databaseId: string,
    documentId: string,
    document: Document
  ): Promise<void> => {
    return this.userDatabaseDocument(databaseId)
      .collection("documents")
      .doc(documentId)
      .withConverter(documentConverter)
      .set(document, { merge: true });
  };

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (
    email: string,
    password: string
  ): Promise<firebase.auth.UserCredential> =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (
    email: string,
    password: string
  ): Promise<firebase.auth.UserCredential> =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = (): Promise<void> => this.auth.signOut();

  doPasswordReset = (email: string): Promise<void> =>
    this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = (password: string): Promise<void> | undefined =>
    this.auth.currentUser?.updatePassword(password);

  doSendEmailVerification = (user: firebase.auth.UserCredential) =>
    this.auth.currentUser?.sendEmailVerification();
}

export default Firebase;
