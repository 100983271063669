import React, {
  FunctionComponent,
  ReactNode,
  useCallback,
  useState,
} from "react";

import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  InputBase,
  IconButton,
  TextField,
  Divider,
  LinearProgress,
} from "@material-ui/core";
import FilterIcon from "@material-ui/icons/FilterList";
import ClearIcon from "@material-ui/icons/Clear";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import { NavigationState } from "../../../store/ui/reducers/navigationReducers";
import { RootState } from "../../../store/reducers";
import { QuickFilter, QUICK_FILTERS } from "../../../constants/QuickFilter";
import {
  setNavSearchText,
  toggleQuickFilters,
  setNavQuickFilters,
} from "../../../store/ui/actionCreators/navigationActionCreators";
import { appTheme } from "../../../styling/style";
import { FoodworksTooltip } from "../../common/InfoTooltip";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    advancedOptions: {
      paddingLeft: 15,
      paddingRight: 5,
      paddingBottom: 10,
    },
    searchBar: {
      padding: "2px 4px",
      display: "flex",
      alignItems: "center",
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      marginBottom: 5,
    },
  })
);

export interface NavigationFilterProps {}

export const NavigationFilter: FunctionComponent<NavigationFilterProps> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);

  const delayedSearchTextChanged = useCallback(
    _.debounce((searchText: string) => {
      onSearchTextChanged(searchText);
      setSearchLoading(false);
    }, 500),
    []
  );

  const quickFiltersEnabled = useSelector<
    RootState,
    NavigationState["quickFiltersEnabled"]
  >((state) => state.navigation.quickFiltersEnabled);

  const onSearchTextChanged = (text: string) => {
    dispatch(setNavSearchText(text));
  };

  const toggleQuickFiltersEnabled = () => {
    dispatch(toggleQuickFilters());
  };

  const setQuickFilters = (filters: QuickFilter[]) => {
    dispatch(setNavQuickFilters(filters));
  };

  const advancedFilterOptions = QUICK_FILTERS;

  const buildAdvancedOptionsView = (): ReactNode =>
    quickFiltersEnabled ? (
      <div className={classes.advancedOptions} data-cy="quickFilters">
        <Divider variant="middle" className={classes.divider} />
        <Autocomplete
          multiple
          openOnFocus
          onChange={(event: any, newValue: QuickFilter[] | null) => {
            if (newValue?.length) {
              setQuickFilters(newValue);
            } else {
              setQuickFilters([]);
              toggleQuickFiltersEnabled();
            }
          }}
          limitTags={1}
          options={advancedFilterOptions}
          getOptionLabel={(option) => option.text}
          renderInput={(params) => (
            <TextField
              {...params}
              autoFocus={true}
              label="Selected Quick Filters"
              data-cy="quickFilterInput"
              InputProps={{ ...params.InputProps, disableUnderline: true }}
            />
          )}
        />
      </div>
    ) : null;

  const searchBar = (
    <div className={classes.searchBar}>
      <FoodworksTooltip title="Quick Filters">
        <IconButton
          className={classes.iconButton}
          style={{
            background: quickFiltersEnabled
              ? appTheme.colors.oceanBlue[0]
              : "inherit",
            color: quickFiltersEnabled ? appTheme.colors.primary : "inherit",
          }}
          onClick={() => toggleQuickFiltersEnabled()}
          data-cy="quickFilterIcon"
        >
          <FilterIcon />
        </IconButton>
      </FoodworksTooltip>
      <InputBase
        value={searchText}
        onChange={(event) => {
          setSearchText(event.target.value);
          setSearchLoading(true);
          delayedSearchTextChanged(event.target.value);
        }}
        className={classes.input}
        placeholder="Search"
        type="text"
        data-cy="navSearchInput"
      />
      <IconButton
        className={classes.iconButton}
        onClick={() => {
          setSearchLoading(false);
          setSearchText("");
          onSearchTextChanged("");
          delayedSearchTextChanged("");
        }}
        data-cy="clearSearchIcon"
      >
        <ClearIcon />
      </IconButton>
    </div>
  );

  return (
    <>
      {searchBar}
      {buildAdvancedOptionsView()}
      {searchLoading ? <LinearProgress color="secondary" /> : null}
    </>
  );
};
