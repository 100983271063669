import React, {
  FunctionComponent,
  useState,
  ReactNode,
  useEffect,
  useCallback,
  useMemo,
} from "react";

import {
  makeStyles,
  Theme,
  createStyles,
  IconButton,
  Button,
  DialogActions,
  DialogTitle,
  DialogContent,
  Dialog,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { ArrowBack, ArrowForward } from "@material-ui/icons";
import { useSelector, useDispatch, batch } from "react-redux";

import { GroupListBox } from "./GroupListBox";
import { ProcessListBox } from "./ProcessListBox";
import { RootState } from "../../../../store/reducers";
import { appTheme } from "../../../../styling/style";
import { FoodItem } from "../../../../data/models/documentProperties/foodItem";
import { cachedDocumentSelector } from "../../../../store/data/selectors/documentCache";
import { Document } from "../../../../data/models/document";
import { updateRetentionFactor } from "../../../../store/data/current-document/action-creators/foodItems";
import { FoodId } from "../../../../data/models/documentProperties/foodId";
import { foodItemsSelector } from "../../../../store/data/current-document/selectors/foodItems";
import { FoodworksTooltip } from "../../../common/InfoTooltip";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleBar: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    titleBarButtons: { display: "flex", flexDirection: "row" },
    typography: {
      color: appTheme.colors.xiketic,
    },
    selectButton: {
      background: appTheme.colors.primary,
      marginRight: 40,
    },
    listBoxesCasing: {
      marginTop: 5,
      display: "flex",
      flexDirection: "row",
      height: 700,
      width: 900,
    },
    description: {
      marginTop: 10,
    },
  })
);

interface RetentionProfileData {
  groupId: string | null;
  profileId: string | null;
}

interface RetentionProfileDataMap {
  [key: string]: RetentionProfileData;
}

interface RetentionFactorUpdateData {
  rowIndex: number;
  id: string | null;
}

export interface RetentionFactorDialogProps {
  dayIndex: number;
  sectionIndex: number;
  currentFoodItemIndex: number;
  open: boolean;
  onClose: () => void;
}

interface RetentionFactorDialogOption {
  text: string;
  onClick: () => void;
  disabled?: boolean;
  color?: "secondary" | "default" | "inherit" | "primary" | undefined;
}

export const RetentionFactorDialog: FunctionComponent<RetentionFactorDialogProps> = ({
  dayIndex,
  sectionIndex,
  currentFoodItemIndex,
  open,
  onClose,
}) => {
  const classes = useStyles();

  /* *** Actions *** */

  const dispatch = useDispatch();

  const onUpdateRetentionFactor = (rowIndex: number, id: string | null) => {
    dispatch(updateRetentionFactor(dayIndex, sectionIndex, rowIndex, id));
  };

  /* ----- */

  /* *** State *** */

  const selectFoodItems = useMemo(foodItemsSelector, []);

  const foodItems: FoodItem[] = useSelector<RootState, FoodItem[]>(
    (state) => selectFoodItems(state, dayIndex, sectionIndex).items
  );

  const [foodItemIndex, setFoodItemIndex] = useState(currentFoodItemIndex);
  const [searchInput, setSearchInput] = useState("");

  const currentFoodItemDocument: Document | undefined = useSelector<
    RootState,
    Document | undefined
  >((state) => {
    if (!foodItems[foodItemIndex]?.foodId) return undefined;

    return cachedDocumentSelector(
      state,
      new FoodId(foodItems[foodItemIndex].foodId!).identifier
    );
  });

  const setInitialProfiles = useCallback(() => {
    const initialProfiles: RetentionProfileDataMap = {};
    foodItems.forEach((foodItem: FoodItem, index: number): void => {
      const retentionProfilePair: RetentionProfileData = foodItem.retentionFactor
        ? {
            groupId: foodItem.retentionFactor.groupId,
            profileId: foodItem.retentionFactor.profileId,
          }
        : { groupId: null, profileId: null };
      initialProfiles[index] = retentionProfilePair;
    });
    return initialProfiles;
  }, [foodItems]);

  const [selectedProfiles, setSelectedProfiles] = useState(
    setInitialProfiles()
  );

  /* *** ----- *** */

  const handleSelectGroup = (selectedGroupId: string) => {
    setSelectedProfiles({
      ...selectedProfiles,
      [foodItemIndex]: {
        groupId:
          selectedGroupId === selectedProfiles[foodItemIndex].groupId
            ? null
            : selectedGroupId,
        profileId: null,
      },
    });
  };

  const handleSelectProcess = (selectedProcess: string) => {
    setSelectedProfiles({
      ...selectedProfiles,
      [foodItemIndex]: {
        ...selectedProfiles[foodItemIndex],
        profileId:
          selectedProcess === selectedProfiles[foodItemIndex].profileId
            ? null
            : selectedProcess,
      },
    });
  };

  useEffect(() => {
    setSelectedProfiles(setInitialProfiles());
  }, [foodItems, setInitialProfiles]);

  const getUnsavedRetentionFactors = (): RetentionFactorUpdateData[] => {
    const retentionFactorsToUpdate: RetentionFactorUpdateData[] = [];

    for (const [rowIndex, data] of Object.entries(selectedProfiles)) {
      const isNotChanged: boolean =
        !(
          data.profileId ||
          foodItems[Number(rowIndex)].retentionFactor?.profileId
        ) ||
        data.profileId ===
          foodItems[Number(rowIndex)].retentionFactor?.profileId;

      const isNotValid: boolean = Boolean(data.groupId) && !data.profileId;
      if (isNotChanged || isNotValid) continue;

      retentionFactorsToUpdate.push({
        rowIndex: Number(rowIndex),
        id: data.profileId || null,
      });
    }
    return retentionFactorsToUpdate;
  };

  const handleOnSave = () => {
    const retentionFactorsToUpdate: RetentionFactorUpdateData[] = getUnsavedRetentionFactors();

    batch(() => {
      for (const factor of retentionFactorsToUpdate) {
        onUpdateRetentionFactor(factor.rowIndex, factor.id);
      }
    });
  };

  const handleOnSaveAndExit = () => {
    handleOnSave();
    onClose();
  };

  const handleOnClear = () => {
    setSelectedProfiles({
      ...selectedProfiles,
      [foodItemIndex]: { groupId: null, profileId: null },
    });
  };

  const changeFoodItem = (index: number): void => setFoodItemIndex(index);

  const nextFoodItem = (index: number): void => {
    if (index > foodItems.length - 1) index = 0;

    if (foodItems[index].foodId?.datasourceId) {
      changeFoodItem(index);
      return;
    }
    nextFoodItem(++index);
  };

  const previousFoodItem = (index: number): void => {
    if (index < 0) index = foodItems.length - 1;

    if (foodItems[index].foodId?.datasourceId) {
      changeFoodItem(index);
      return;
    }
    previousFoodItem(--index);
  };

  const hasUnsavedChanges = (): boolean =>
    Boolean(getUnsavedRetentionFactors().length);
  const noRetentionGroupSelected = (): boolean =>
    !selectedProfiles[foodItemIndex]?.groupId;

  const selectedGroupId = (): string | null =>
    selectedProfiles[foodItemIndex]?.groupId || null;
  const selectedProcessId = (): string | null =>
    selectedProfiles[foodItemIndex]?.profileId || null;

  const onNextClick = (index: number): void => {
    nextFoodItem(index);
    setSearchInput("");
  };

  const onPreviousClick = (index: number): void => {
    previousFoodItem(index);
    setSearchInput("");
  };

  const foodItemDialogActions: ReactNode = [
    <FoodworksTooltip title="Previous ingredient">
      <IconButton
        size="small"
        onClick={() => onPreviousClick(foodItemIndex - 1)}
        disabled={foodItems.length === 1}
        color="secondary"
      >
        <ArrowBack />
      </IconButton>
    </FoodworksTooltip>,
    <FoodworksTooltip title="Next ingredient">
      <IconButton
        size="medium"
        onClick={() => onNextClick(foodItemIndex + 1)}
        disabled={foodItems.length === 1}
        color="secondary"
      >
        <ArrowForward />
      </IconButton>
    </FoodworksTooltip>,
  ];

  const titleBar = (
    <div className={classes.titleBar}>
      <DialogTitle className={classes.typography}>
        {currentFoodItemDocument?.name || ""}
      </DialogTitle>
      <div className={classes.titleBarButtons}>
        <DialogActions>{foodItemDialogActions}</DialogActions>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
    </div>
  );

  const getApplyChangesText = (): string => {
    const unsavedChangesAmount: number = getUnsavedRetentionFactors().length;
    switch (unsavedChangesAmount) {
      case 0:
        return `Apply changes`;
      case 1:
        return `Apply (1) change`;
      default:
        return `Apply (${unsavedChangesAmount}) changes`;
    }
  };

  const bottomDialogActions: RetentionFactorDialogOption[] = [
    {
      text: "Cancel",
      onClick: onClose,
      disabled: false,
      color: "default",
    },
    {
      text: "Clear",
      onClick: () => handleOnClear(),
      disabled: noRetentionGroupSelected(),
      color: "secondary",
    },
    {
      text: getApplyChangesText(),
      onClick: () => handleOnSave(),
      disabled: !hasUnsavedChanges(),
      color: "secondary",
    },
    {
      text: `Apply (${getUnsavedRetentionFactors().length}) and exit`,
      onClick: () => handleOnSaveAndExit(),
      disabled: !hasUnsavedChanges(),
      color: "secondary",
    },
  ];

  const bottomActionButtons: ReactNode[] = bottomDialogActions.map(
    (option: RetentionFactorDialogOption) => (
      <Button
        key={option.text}
        onClick={option.onClick}
        disabled={option.disabled}
        color={option.color}
      >
        {option.text}
      </Button>
    )
  );

  const dialogBody: ReactNode = (
    <DialogContent>
      <div className={classes.listBoxesCasing}>
        <GroupListBox
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          currentSelectedGroupId={selectedGroupId()}
          onSelectGroup={handleSelectGroup}
        />
        <ProcessListBox
          selectedGroupId={selectedGroupId()}
          currentSelectedProfileId={selectedProcessId()}
          onSelectProcess={handleSelectProcess}
        />
      </div>
      <DialogActions>{bottomActionButtons}</DialogActions>
    </DialogContent>
  );

  return (
    <Dialog maxWidth="xl" open={open} onBackdropClick={onClose}>
      {titleBar}
      {dialogBody}
    </Dialog>
  );
};
