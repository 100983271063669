import React, { FunctionComponent, useState, ReactNode, useRef } from "react";

import {
  makeStyles,
  createStyles,
  Popper,
  ButtonBase,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { batch, useDispatch } from "react-redux";

import { appTheme } from "../../../../../../styling/style";
import { addFoodItemsFromIndex } from "../../../../../../store/data/current-document/thunks/foodItems";
import { FoodItem } from "../../../../../../data/models/documentProperties/foodItem";
import { setSelectedRows } from "../../../../../../store/ui/actionCreators/recipeGrid";
import { setSelectedSectionTags } from "../../../../../../store/ui/actionCreators/nutritionPaneActionCreators";

const useStyles = makeStyles(() =>
  createStyles({
    buttonBase: {
      background: appTheme.colors.primary,
      borderRadius: 5,
    },
    hoverDiv: {
      borderRadius: "0px 5px 5px 0px",
      height: 3,
    },
    invisiblePopper: {
      cursor: "pointer",
      height: 7,
      zIndex: 20,
      backgroundColor: "transparent",
    },
    addIcon: {
      fill: "white",
    },
  })
);

export interface InlineInsertProps {
  dayIndex: number;
  sectionIndex: number;
  addAfterRowIndex: number;
}

export const InlineInsert: FunctionComponent<InlineInsertProps> = ({
  dayIndex,
  sectionIndex,
  addAfterRowIndex,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onResetSelection = () =>
    batch(() => {
      dispatch(setSelectedRows([]));
      dispatch(setSelectedSectionTags([]));
    });

  const onInsertRow = () =>
    batch(() => {
      dispatch(
        addFoodItemsFromIndex(
          [
            new FoodItem(
              undefined,
              undefined,
              "",
              addAfterRowIndex + 1,
              null,
              ""
            ),
          ],
          addAfterRowIndex + 1,
          dayIndex,
          sectionIndex
        )
      );
      onResetSelection();
    });

  const [isHovered, setIsHovered] = useState(false);

  const anchorRef = useRef<HTMLInputElement>(null);

  const hoveredBar: ReactNode = (
    <div
      ref={anchorRef}
      className={classes.hoverDiv}
      style={{
        background: isHovered ? appTheme.colors.primary : "transparent",
      }}
    />
  );

  const plusPopper: ReactNode = (
    <Popper
      open={isHovered}
      anchorEl={anchorRef.current}
      placement="left"
      onClick={onInsertRow}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <ButtonBase className={classes.buttonBase}>
        <AddIcon color="secondary" className={classes.addIcon} />
      </ButtonBase>
    </Popper>
  );

  const insertLinePopper: ReactNode = (
    <Popper
      open={true}
      anchorEl={anchorRef.current}
      modifiers={{
        flip: { enabled: false },
        offset: {
          enabled: true,
          offset: "0px, -5px",
        },
      }}
      onMouseEnter={(event) => {
        setIsHovered(true);
      }}
      onMouseLeave={(event) => {
        setIsHovered(false);
      }}
      onClick={onInsertRow}
    >
      <div
        className={`notSelectable  ${classes.invisiblePopper}`}
        style={{
          width: anchorRef.current?.offsetWidth,
        }}
      ></div>
    </Popper>
  );

  return (
    <div>
      {insertLinePopper}
      {hoveredBar}
      {plusPopper}
    </div>
  );
};
